import {
  AdjustmentPlan,
  AdjustmentPlanSummary,
  PlanAdjustmentAction as Action,
  PlanAdjustmentActionTypes as ActionTypes,
} from './types';

export const init = (): Action => ({ type: ActionTypes.INIT });

export const refresh = (): Action => ({ type: ActionTypes.REFRESH });

export const setIsLoading = (isLoading: boolean): Action => ({
  type: ActionTypes.LOADING,
  payload: isLoading,
});

export const setIsSubmitting = (isSubmitting: boolean): Action => ({
  type: ActionTypes.SUBMITTING,
  payload: isSubmitting,
});

export const setIsInvalidToken = (isInvalidToken: boolean): Action => ({
  type: ActionTypes.INVALID,
  payload: isInvalidToken,
});

export const setPlans = (plans: AdjustmentPlan[]): Action => ({
  type: ActionTypes.SET_PLANS,
  payload: plans,
});

export const setSummary = (summary: AdjustmentPlanSummary | null): Action => ({
  type: ActionTypes.SET_SUMMARY,
  payload: summary,
});
