import { TagState, TagAction, TagActionTypes as ActionTypes } from './types';

const initialTagState: TagState = {
  refresh: {},
  isLoading: false,
  isSubmitting: false,
  tags: [],
  tagSettingList: [],
};

const tagReducer = (state: TagState = initialTagState, action: TagAction): TagState => {
  switch (action.type) {
    case ActionTypes.INIT:
      return initialTagState;

    case ActionTypes.REFRESH:
      return { ...state, refresh: {} };

    case ActionTypes.LOADING:
      return { ...state, isLoading: action.payload };

    case ActionTypes.SET_TAGS:
      return { ...state, tags: action.payload };

    case ActionTypes.SET_TAG_SETTING_LIST:
      return { ...state, tagSettingList: action.payload };

    default:
      return state;
  }
};

export default tagReducer;
