import { AppState as State } from '@ducks/index';
import {
  SearchParameter,
  MonthlyTableTable,
  MonthlyTableTableSummary,
  DisplayParams,
  Contract,
} from './types';

/**
 * @summary リフレッシュを行うためのstateを返却する
 * @param {State} state
 * @returns {Record<string, never>}
 */
export const getRefreshId = (state: State) => state.plan.monthlyTable.refresh;

/**
 * @summary ローディング状態かを返却する
 * @param {State} state
 * @returns {boolean} ローディング状態か
 */
export const checkIsLoading = (state: State) => state.plan.monthlyTable.isLoading;

/**
 * @summary 契約リストのローディング状態かを返却する
 * @param {State} state
 * @returns {boolean} 契約リストのローディング状態か
 */
export const checkIsLoadingContractList = (state: State) =>
  state.plan.monthlyTable.isLoadingContractList;

/**
 * @summary 送信処理中かを返却する
 * @param {State} state
 * @returns {boolean} 送信処理中か
 */
export const checkIsSubmitting = (state: State) => state.plan.monthlyTable.isSubmitting;

/**
 * @summary 編集中であるかを返却する
 * @param {State} state
 * @returns {boolean} 編集中であるか
 */
export const checkIsEditing = (state: State) => state.plan.monthlyTable.isEditing;

/**
 * @summary ページ番号を返却する
 * @param {State} state
 * @returns {number}
 */
export const getPage: (state: State) => number = (state: State) => state.plan.monthlyTable.page;

/**
 * @summary ソート番号を返却する
 * @param {State} state
 * @returns {number}
 */
export const getSort: (state: State) => number = (state: State) => state.plan.monthlyTable.sort;

/**
 * @summary 詳細検索条件を返却する
 * @param {State} state
 * @returns {SearchParameter}
 */
export const getSearchParameter: (state: State) => SearchParameter = (state: State) =>
  state.plan.monthlyTable.searchParameter;

/**
 * @summary 月次計画詳細リストを返却する
 * @param {State} state
 * @returns {MonthlyTableTable[]}
 */
export const getTables: (state: State) => MonthlyTableTable[] = (state: State) =>
  state.plan.monthlyTable.tables;

/**
 * @summary 月次計画サマリを返却する
 * @param {State} state
 * @returns {MonthlyTableTableSummary|null}
 */
export const getSummary: (state: State) => MonthlyTableTableSummary | null = (state: State) =>
  state.plan.monthlyTable.summary;

/**
 * @summary Pager情報を返却する
 * @param {State} state
 * @returns {DisplayParams}
 */
export const getDisplayParams: (state: State) => DisplayParams = (state: State) =>
  state.plan.monthlyTable.displayParams;

/**
 * @summary 契約リストを返却する
 * @param {State} state
 * @returns {Contract[]}
 */
export const getContractList: (state: State) => Contract[] = (state: State) =>
  state.plan.monthlyTable.contractList;

/**
 * @summary 契約リストPager情報を返却する
 * @param {State} state
 * @returns {DisplayParams}
 */
export const getContractDisplayParams: (state: State) => DisplayParams = (state: State) =>
  state.plan.monthlyTable.contractDisplayParams;

/**
 * @summary 選択を確定した契約を返却する
 * @param {State} state
 * @returns {{ id: number; name: string } | null}
 */
export const getContract: (state: State) => { id: number; name: string } | null = (state: State) =>
  state.plan.monthlyTable.contract;
