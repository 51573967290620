import { AppState as State } from '@ducks/index';

export const getRefreshId = (state: State) => state.user.refresh;

export const checkIsLoading = (state: State) => state.user.isLoading;
export const checkIsSubmitting = (state: State) => state.user.isSubmitting;
export const checkIsNavigating = (state: State) => state.user.isNavigating;
export const checkIsNavigatingTo = (state: State) => state.user.isNavigatingTo;

export const getUsers = (state: State) => state.user.users;
export const getUsersListInfo = (state: State) => state.user.usersListInfo;

export const getUser = (state: State) => state.user.user;
