import reducer from './reducers';

import * as selectMultipleAffiliateSelectors from './selectors';
import * as selectMultipleAffiliateActions from './actions';
import * as selectMultipleAffiliateOperations from './operations';
import type { SelectMultipleAffiliateState, TabType, SelectItem } from './types';

export {
  selectMultipleAffiliateActions,
  selectMultipleAffiliateOperations,
  selectMultipleAffiliateSelectors,
};
export type { SelectMultipleAffiliateState, TabType, SelectItem };

export default reducer;
