import reducer from './reducers';

import * as userSelectors from './selectors';
import * as userActions from './actions';
import * as userOperations from './operations';
import { UserState } from './types';

export { userSelectors, userActions, userOperations };
export type { UserState };

export default reducer;
