import { AppState as State } from '@ducks/index';

export const getRefreshId = (state: State) => state.construction.refresh;
export const getRefreshId2 = (state: State) => state.construction.refresh2;
export const getRefreshId3 = (state: State) => state.construction.refresh3;

export const checkIsLoading = (state: State) => state.construction.isLoading;
export const checkIsLoading2 = (state: State) => state.construction.isLoading2;
export const checkIsSubmitting = (state: State) => state.construction.isSubmitting;
export const checkIsInvalidToken = (state: State) => state.construction.isInvalidToken;

export const getConstructionsList = (state: State) => state.construction.constructionsList;
export const getConstructionsListDisplayStatus = (state: State) =>
  state.construction.constructionsListDisplayStatus;

export const getConstruction = (state: State) => state.construction.construction;

/** @summary 工事-連絡事項情報State取得 */
export const getConstructionInfo = (state: State) => state.construction.constructionInfo;

/** @summary 工事コメント一覧取得 */
export const getConstructionComments = (state: State) => state.construction.constructionComments;
