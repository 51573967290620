import axios from '@providers/axiosProvider';
import * as actions from './actions';
import { APIGetRequestParams, APIGetUserResponse, APIGetCompanyResponse } from './types';

/**
 * 連絡先リストをAPIから取得
 *
 * @param params APIGetUserResponse
 * @returns void
 */
export const getAffiliateUserAsync = (params: APIGetRequestParams) => async (dispatch: any) => {
  dispatch(actions.setIsLoading(true));

  await axios
    .get<APIGetUserResponse>('/items/affiliates/users', {
      params: params,
    })
    .then((response) => {
      const userList = response.data.result;
      dispatch(actions.setAffiliateUserList(userList));
    })
    .catch(() => {
      dispatch(actions.setAffiliateUserList([]));
      alert('連絡先のリスト取得に失敗しました');
    });

  dispatch(actions.setIsLoading(false));
};

/**
 * 企業リストをAPIから取得
 *
 * @param params APIGetPropertiesRequestParams
 * @returns void
 */
export const getAffiliateCompanyAsync = (params: APIGetRequestParams) => async (dispatch: any) => {
  dispatch(actions.setIsLoading(true));

  await axios
    .get<APIGetCompanyResponse>('/items/affiliates/companies', {
      params: params,
    })
    .then((response) => {
      const companyList = response.data.result;
      dispatch(actions.setAffiliateCompanyList(companyList));
    })
    .catch(() => {
      dispatch(actions.setAffiliateCompanyList([]));
      alert('企業のリスト取得に失敗しました');
    });

  dispatch(actions.setIsLoading(false));
};

/**
 * システムユーザリストをAPIから取得
 *
 * @param params APIGetRequestParams
 * @returns void
 */
export const getAffiliateSystemUserAsync =
  (params: APIGetRequestParams) => async (dispatch: any) => {
    dispatch(actions.setIsLoading(true));

    await axios
      .get<APIGetUserResponse>(`/items/users`, {
        params: params,
      })
      .then((response) => {
        const systemUserList = response.data.result;
        dispatch(actions.setAffiliateSystemUserList(systemUserList));
      })
      .catch(() => {
        dispatch(actions.setAffiliateSystemUserList([]));
        alert('システムユーザのリスト取得に失敗しました');
      });

    dispatch(actions.setIsLoading(false));
  };
