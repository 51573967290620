import { AppState as State } from '@ducks/index';
import _ from 'lodash';

export const getRefreshId = (state: State) => state.inspectionChecklistEdit.refresh;

export const checkIsLoading = (state: State) => state.inspectionChecklistEdit.isLoading;
export const checkIsSubmitting = (state: State) => state.inspectionChecklistEdit.isSubmitting;
export const checkIsNavigating = (state: State) => state.inspectionChecklistEdit.isNavigating;
export const checkIsNavigating2 = (state: State) => state.inspectionChecklistEdit.isNavigating2;
export const checkIsNavigatingTo = (state: State) => state.inspectionChecklistEdit.isNavigatingTo;

export const getChecklist = (state: State) => state.inspectionChecklistEdit.checklist;
export const getLayout = (state: State) => state.inspectionChecklistEdit.layout;

export const getPublishedLayoutComponentIds = (state: State) =>
  state.inspectionChecklistEdit.publishedLayoutComponentIds;

export const getLayoutForApi = (state: State) => {
  const groups = state.inspectionChecklistEdit.layout.groups.map((g) => ({
    ...g,
    sections: g.sections.map((s) => ({
      ...s,
      equipment: s.equipment != null ? s.equipment : { equipment_id: null, equipment_name: '' },
    })),
  }));
  return { groups };
};

export const checkIsActiveLayoutComponentsNotEmpty = (state: State) =>
  _.flattenDeep(
    state.inspectionChecklistEdit.layout.groups.map((g) =>
      g.sections.map((s) => s.components.filter((c) => !c.disable)),
    ),
  ).length > 0;
