import axios from '@providers/axiosProvider';
import * as actions from './actions';
import {
  APIGetContractListResponse,
  APIGetMonthlyTablesRequestParams,
  APIGetMonthlyTablesResponse,
  APIPostMonthlyTablePriceRequestParams,
  AddMonthlyTableFormData,
  EditMonthlyTableFormData,
  SearchParameter,
} from './types';
import { APIGetPropertyContractsRequestParams } from '@ducks/property/types';
import { LIST_SIZE_PER_PAGE } from '@utils/constants';
import { formatDateString } from '@utils/dateUtils';
import { confirmDialog } from '@utils/confirmDialog';

/**
 * @summary 検索パラメータを元に月次計画詳細のリストを取得
 * @param {number | null} propertyId 建物ID
 * @param {number} year 年
 * @param {number} month 月
 * @param {number} page ページ番号
 * @param {number} sort ソート番号
 * @param {string} keyword 検索キーワード
 * @param {SearchParameter} params 詳細検索条件
 * @param {number[]} tagIds 選択タグIDリスト
 * @returns {void}
 */
export const getMonthlyTableAsync =
  (
    propertyId: number | null,
    year: number,
    month: number,
    page: number,
    sort: number,
    keyword: string,
    params: SearchParameter,
    tagIds: number[],
  ) =>
  async (dispatch: any) => {
    dispatch(actions.setIsLoading(true));

    const reqParams: APIGetMonthlyTablesRequestParams = {
      year: year,
      month: month,
      page: page,
      sort: sort,
      size_per_page: LIST_SIZE_PER_PAGE,
    };

    if (propertyId != null) reqParams.property_id = propertyId;
    if (keyword) reqParams.keyword = keyword;
    if (tagIds.length) reqParams.tag = tagIds.join(',');
    if (params.title) reqParams.title = params.title;
    if (params.contract_type) reqParams.contract_type = params.contract_type;
    if (params.inspection_type) reqParams.inspection_type = params.inspection_type;
    if (params.inspection_division) reqParams.inspection_division = params.inspection_division;
    if (params.status) reqParams.status = params.status;
    if (params.company) reqParams.company = params.company;
    if (params.start_since) reqParams.start_since = formatDateString(params.start_since);
    if (params.start_until) reqParams.start_until = formatDateString(params.start_until);
    if (params.end_since) reqParams.end_since = formatDateString(params.end_since);
    if (params.end_until) reqParams.end_until = formatDateString(params.end_until);
    if (params.supervisor) reqParams.supervisor = params.supervisor;
    if (params.worker) reqParams.worker = params.worker;
    if (params.is_adjustment_email_sent)
      reqParams.is_adjustment_email_sent = params.is_adjustment_email_sent !== '';
    if (params.is_fixed_email_sent)
      reqParams.is_fixed_email_sent = params.is_fixed_email_sent !== '';
    if (params.is_remind_email_sent)
      reqParams.is_remind_email_sent = params.is_remind_email_sent !== '';
    if (params.is_report_request_email_sent)
      reqParams.is_report_request_email_sent = params.is_report_request_email_sent !== '';
    if (params.has_invoice_amount) reqParams.has_invoice_amount = params.has_invoice_amount !== '';
    if (params.has_payment_amount) reqParams.has_payment_amount = params.has_payment_amount !== '';

    await axios
      .get<APIGetMonthlyTablesResponse>('/plans/monthly/table', {
        params: reqParams,
      })
      .then((response) => {
        const { items, summary, display_params } = response.data.result;
        dispatch(actions.setTables(items));
        dispatch(actions.setSummary(summary));
        dispatch(actions.setDisplayParams(display_params));
      })
      .catch(() => {
        dispatch(actions.setTables([]));
        dispatch(actions.setSummary(null));
        dispatch(actions.setDisplayParams(null));
      });

    dispatch(actions.setIsLoading(false));
  };

/**
 * @summary チェックを元に月次計画詳細のリストを出力
 * @param {number} year 年
 * @param {number} month 月
 * @param {number} sort ソート番号
 * @param {number[]} planIds 計画IDリスト
 * @returns {void}
 */
export const downloadMonthlyTableAsync =
  (year: number, month: number, sort: number, planIds: number[]) => async () => {
    const reqParams: APIGetMonthlyTablesRequestParams = {
      year: year,
      month: month,
      sort: sort,
      is_all: false,
    };
    if (planIds.length) reqParams.id = planIds.join(',');

    return await axios
      .get('/plans/monthly/table/export', {
        params: reqParams,
      })
      .then((response) => {
        return response.data.result;
      })
      .catch(() => {
        return null;
      });
  };

/**
 * @summary 検索パラメータを元に月次計画詳細のリストを出力
 * @param {number | null} propertyId 建物ID
 * @param {number} year 年
 * @param {number} month 月
 * @param {number} page ページ番号
 * @param {number} sort ソート番号
 * @param {string} keyword 検索キーワード
 * @param {SearchParameter} params 詳細検索条件
 * @param {number[]} tagIds 選択タグIDリスト
 * @returns {void}
 */
export const downloadMonthlyTableAllAsync =
  (
    propertyId: number | null,
    year: number,
    month: number,
    page: number,
    sort: number,
    keyword: string,
    params: SearchParameter,
    tagIds: number[],
  ) =>
  async () => {
    const reqParams: APIGetMonthlyTablesRequestParams = {
      year: year,
      month: month,
      page: page,
      sort: sort,
      is_all: true,
    };

    if (propertyId != null) reqParams.property_id = propertyId;
    if (keyword) reqParams.keyword = keyword;
    if (tagIds.length) reqParams.tag = tagIds.join(',');
    if (params.title) reqParams.title = params.title;
    if (params.contract_type) reqParams.contract_type = params.contract_type;
    if (params.inspection_type) reqParams.inspection_type = params.inspection_type;
    if (params.inspection_division) reqParams.inspection_division = params.inspection_division;
    if (params.status) reqParams.status = params.status;
    if (params.company) reqParams.company = params.company;
    if (params.start_since) reqParams.start_since = params.start_since;
    if (params.start_until) reqParams.start_until = params.start_until;
    if (params.end_since) reqParams.end_since = params.end_since;
    if (params.end_until) reqParams.end_until = params.end_until;
    if (params.supervisor) reqParams.supervisor = params.supervisor;
    if (params.worker) reqParams.worker = params.worker;
    if (params.is_adjustment_email_sent)
      reqParams.is_adjustment_email_sent = params.is_adjustment_email_sent !== '';
    if (params.is_fixed_email_sent)
      reqParams.is_fixed_email_sent = params.is_fixed_email_sent !== '';
    if (params.is_remind_email_sent)
      reqParams.is_remind_email_sent = params.is_remind_email_sent !== '';
    if (params.is_report_request_email_sent)
      reqParams.is_report_request_email_sent = params.is_report_request_email_sent !== '';
    if (params.has_invoice_amount) reqParams.has_invoice_amount = params.has_invoice_amount !== '';
    if (params.has_payment_amount) reqParams.has_payment_amount = params.has_payment_amount !== '';

    return await axios
      .get('/plans/monthly/table/export', {
        params: reqParams,
      })
      .then((response) => {
        return response.data.result;
      })
      .catch(() => {
        return null;
      });
  };

/**
 * @summary 建物の契約一覧を取得する
 * @param propertyId 建物ID
 */
export const getPropertyContractList =
  (propertyId: number, page: number, keyword: string) => async (dispatch: any) => {
    dispatch(actions.setIsLoadingContactList(true));

    const reqParams: APIGetPropertyContractsRequestParams = {
      sort: '1',
      page: page,
      keyword: keyword,
      status: 1,
      size_per_page: LIST_SIZE_PER_PAGE,
    };
    await axios
      .get<APIGetContractListResponse>(`/properties/${propertyId}/contracts`, {
        params: reqParams,
      })
      .then((response) => {
        const { contract_list: contractList, display_params: displayParams } = response.data.result;
        dispatch(actions.setContractList(contractList));
        dispatch(actions.setContractDisplayParams(displayParams));
      })
      .catch(() => {
        dispatch(actions.setContractList([]));
        dispatch(actions.setContractDisplayParams(null));
      });

    dispatch(actions.setIsLoadingContactList(false));
  };

/**
 * @summary 新規計画を登録する
 * @param {AddMonthlyTableFormData} params 新規作成フォーム入力データ
 * @returns {void}
 */
export const createMonthlyTableAsync =
  (params: AddMonthlyTableFormData) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .post(`/plans/monthly/table`, {
        ...params,
      })
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

/**
 * @summary 計画を編集する
 * @param {EditMonthlyTableFormData[]} params フォーム入力データ
 * @param {string} confirmText 建物担当者に登録されていないユーザを建物スタッフとして登録するかの確認文言
 * @param {boolean} isSet 建物担当者に登録されていないユーザを建物スタッフとして登録するか
 * @returns {void}
 */
export const editMonthlyTableAsync =
  (params: EditMonthlyTableFormData[], confirmText = '', isSet = false) =>
  async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .patch(`/plans/monthly/table${isSet ? `?is_set=${true}` : ''}`, {
        plan_list: params,
      })
      .then(async (response) => {
        if (response.status !== 202) {
          dispatch(actions.setIsEditing(false));
          dispatch(actions.refresh());
          return;
        }

        if (!(await confirmDialog(confirmText))) return;
        return dispatch(editMonthlyTableAsync(params, confirmText, true));
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

/**
 * @summary 対応状況を変更する
 * @param {{plan_id: number}[]} planIdList 計画IDリスト
 * @param {number} statusId 対応状況ID
 * @returns {void}
 */
export const updateStatusAsync =
  (planIdList: { plan_id: number }[], statusId: number) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .patch('/plans/monthly/table/status', {
        status_id: statusId,
        plan_list: planIdList,
      })
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

/**
 * @summary 監督者を変更する
 * @param {{plan_id: number}[]} planIdList 計画IDリスト
 * @param {{user_id: number}[]} userIdList ユーザーIDリスト
 * @param {string} confirmText 建物担当者に登録されていないユーザを建物スタッフとして登録するかの確認文言
 * @param {boolean} isSet 建物担当者に登録されていないユーザを建物スタッフとして登録するか
 * @returns {void}
 */
export const updateSupervisorsAsync =
  (
    planIdList: { plan_id: number }[],
    userIdList: { user_id: number }[],
    confirmText = '',
    isSet = false,
  ) =>
  async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .post(`/plans/monthly/table/supervisors${isSet ? `?is_set=${true}` : ''}`, {
        user_list: userIdList,
        plan_list: planIdList,
      })
      .then(async (response) => {
        if (response.status !== 202) {
          dispatch(actions.refresh());
          return;
        }

        if (!(await confirmDialog(confirmText))) return;
        return dispatch(updateSupervisorsAsync(planIdList, userIdList, confirmText, true));
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

/**
 * @summary 作業者を変更する
 * @param {{plan_id: number}[]} planIdList 計画IDリスト
 * @param {{user_id: number}[]} userIdList ユーザーIDリスト
 * @param {string} confirmText 建物担当者に登録されていないユーザを建物スタッフとして登録するかの確認文言
 * @param {boolean} isSet 建物担当者に登録されていないユーザを建物スタッフとして登録するか
 * @returns {void}
 */
export const updateWorkersAsync =
  (
    planIdList: { plan_id: number }[],
    userIdList: { user_id: number }[],
    confirmText = '',
    isSet = false,
  ) =>
  async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .post(`/plans/monthly/table/workers${isSet ? `?is_set=${true}` : ''}`, {
        user_list: userIdList,
        plan_list: planIdList,
      })
      .then(async (response) => {
        if (response.status !== 202) {
          dispatch(actions.refresh());
          return;
        }

        if (!(await confirmDialog(confirmText))) return;
        return dispatch(updateWorkersAsync(planIdList, userIdList, confirmText, true));
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

/**
 * @summary 協力会社を変更する
 * @param {{plan_id: number}[]} planIdList 計画IDリスト
 * @param {{company_id: number}[]} companyIdList 企業IDリスト
 * @returns {void}
 */
export const updateCompaniesAsync =
  (planIdList: { plan_id: number }[], companyIdList: { company_id: number }[]) =>
  async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .post('/plans/monthly/table/companies', {
        company_list: companyIdList,
        plan_list: planIdList,
      })
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

/**
 * @summary 予定調整先を変更する
 * @param {{plan_id: number}[]} planIdList 計画IDリスト
 * @param {{user_id: number}[]} userIdList ユーザーIDリスト
 * @param {string} confirmText 建物担当者に登録されていないユーザを建物スタッフとして登録するかの確認文言
 * @param {boolean} isSet 建物担当者に登録されていないユーザを建物スタッフとして登録するか
 * @returns {void}
 */
export const updateAdjustmentsAsync =
  (
    planIdList: { plan_id: number }[],
    userIdList: { user_id: number }[],
    confirmText = '',
    isSet = false,
  ) =>
  async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .post(`plans/monthly/table/adjustment${isSet ? `?is_set=${true}` : ''}`, {
        user_list: userIdList,
        plan_list: planIdList,
      })
      .then(async (response) => {
        if (response.status !== 202) {
          dispatch(actions.refresh());
          return;
        }

        if (!(await confirmDialog(confirmText))) return;
        return dispatch(updateAdjustmentsAsync(planIdList, userIdList, confirmText, true));
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

/**
 * @summary 予定調整先を削除する
 * @param {{plan_id: number}[]} planIdList 計画IDリスト
 * @returns {void}
 */
export const deleteAdjustmentsAsync =
  (planIdList: { plan_id: number }[]) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .post(`plans/monthly/table/adjustment/reset`, {
        plan_list: planIdList,
      })
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

/**
 * @summary 販売結果を登録する
 * @param {APIPostMonthlyTablePriceRequestParams} params リクエストパラメータ
 * @returns {void}
 */
export const updateSalesResult =
  (params: APIPostMonthlyTablePriceRequestParams) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .patch(`plans/monthly/table/price`, {
        ...params,
      })
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

/**
 * @summary 計画を削除する
 * @param {{plan_id: number}[]} planIdList 計画IDリスト
 * @returns {void}
 */
export const deletePlanListAsync = (planIdList: { plan_id: number }[]) => async (dispatch: any) => {
  dispatch(actions.setIsSubmitting(true));

  await axios
    .patch('/plans/monthly/table/delete', {
      plan_list: planIdList,
    })
    .then(() => {
      dispatch(actions.refresh());
    })
    .catch(() => {});

  dispatch(actions.setIsSubmitting(false));
};
