import {
  InspectionChecklist,
  InspectionChecklistAction as Action,
  InspectionChecklistActionTypes as ActionTypes,
  InspectionChecklistFormatPlaceholders,
  InspectionChecklistsList,
  InspectionChecklistsListInfo,
} from './types';

export const init = (): Action => ({
  type: ActionTypes.INIT,
});

export const refresh = (): Action => ({
  type: ActionTypes.REFRESH,
});

export const setIsLoading = (isLoading: boolean): Action => ({
  type: ActionTypes.LOADING,
  payload: isLoading,
});

export const setIsSubmitting = (isSubmitting: boolean): Action => ({
  type: ActionTypes.SUBMITTING,
  payload: isSubmitting,
});

export const setIsNavigating = (isNavigating: boolean): Action => ({
  type: ActionTypes.NAVIGATING,
  payload: isNavigating,
});

export const setInspectionChecklists = (
  inspectionChecklists: InspectionChecklistsList[],
): Action => ({
  type: ActionTypes.SET_INSPECTION_CHECKLISTS,
  payload: inspectionChecklists,
});

export const setInspectionChecklistsListInfo = (
  info: InspectionChecklistsListInfo | null,
): Action => ({
  type: ActionTypes.SET_INSPECTION_CHECKLISTS_LIST_INFO,
  payload: info,
});

export const setInspectionChecklist = (
  inspectionChecklist: InspectionChecklist | null,
): Action => ({
  type: ActionTypes.SET_INSPECTION_CHECKLIST,
  payload: inspectionChecklist,
});

export const setInspectionChecklistFormatPlaceholders = (
  placeholders: InspectionChecklistFormatPlaceholders[],
): Action => ({
  type: ActionTypes.SET_INSPECTION_CHECKLIST_FORMAT_PLACEHOLDERS,
  payload: placeholders,
});
