import {
  InspectionItem,
  InspectionAction as Action,
  InspectionActionTypes as ActionTypes,
  InspectionsList,
  InspectionsListInfo,
  Photo,
  // 基本情報タブで利用するInspectionBasicのAPIの戻り値
  InspectionBasic,
  InspectionInfoData,
  InspectionCommentsData,
  InspectionResponseItem,
} from './types';
import { InspectionChecklistsList } from '../inspectionChecklist/types';

export const init = (): Action => ({
  type: ActionTypes.INIT,
});

export const refresh = (): Action => ({
  type: ActionTypes.REFRESH,
});
export const refreshNotificationTab = (): Action => ({
  type: ActionTypes.REFRESH_NOTIFICATION_TAB,
});
export const refreshWorkResultTab = (): Action => ({
  type: ActionTypes.REFRESH_WORK_RESULT_TAB,
});
export const refreshCommentTab = (): Action => ({
  type: ActionTypes.REFRESH_COMMENT_TAB,
});

/**
 * 画面全体のロードを制御しているので、タブ画面の中でこちらを利用すると、この変数の変化を検知して画面全体が再レンダリングされる
 * ので、isLoading2を擁してタブ内では利用すること
 * @param isLoading
 * @returns
 */
export const setIsLoading = (isLoading: boolean): Action => ({
  type: ActionTypes.LOADING,
  payload: isLoading,
});
export const setIsLoadingTab = (isLoadingTab: boolean): Action => ({
  type: ActionTypes.LOADING_TAB,
  payload: isLoadingTab,
});
export const setIsSubmitting = (isSubmitting: boolean): Action => ({
  type: ActionTypes.SUBMITTING,
  payload: isSubmitting,
});

export const setIsNavigating = (isNavigating: boolean): Action => ({
  type: ActionTypes.NAVIGATING,
  payload: isNavigating,
});

export const setIsNavigating2 = (isNavigating2: boolean): Action => ({
  type: ActionTypes.NAVIGATING2,
  payload: isNavigating2,
});

export const setIsNavigatingTo = (isNavigatingTo: number | null): Action => ({
  type: ActionTypes.NAVIGATING_TO,
  payload: isNavigatingTo,
});

export const setInspections = (inspections: InspectionsList[]): Action => ({
  type: ActionTypes.SET_INSPECTIONS,
  payload: inspections,
});

export const setInspectionsListInfo = (info: InspectionsListInfo | null): Action => ({
  type: ActionTypes.SET_INSPECTIONS_LIST_INFO,
  payload: info,
});

export const setTargetInspection = (data: InspectionResponseItem | null): Action => ({
  type: ActionTypes.SET_INSPECTION,
  payload: data,
});

export const setPrevInspection = (data: InspectionResponseItem | null): Action => ({
  type: ActionTypes.SET_PREV_INSPECTION,
  payload: data,
});

export const setInspectionChecklists = (data: InspectionChecklistsList[]): Action => ({
  type: ActionTypes.SET_INSPECTION_CHECKLISTS,
  payload: data,
});

export const setPhotos = (data: Photo[]): Action => ({
  type: ActionTypes.SET_PHOTOS,
  payload: data,
});

export const setPhoto = (data: Photo): Action => ({
  type: ActionTypes.SET_PHOTO,
  payload: data,
});

export const setDeletePhoto = (data: Photo): Action => ({
  type: ActionTypes.SET_DELETE_PHOTO,
  payload: data,
});

// 基本情報タブで利用する APIからの戻り値に入っているデータの型
/**
 * @summary Reduxアクションクリエーターの定義。Reduxでは、アクションクリエーターは
 *          アクションオブジェクトを生成する関数。
 *          アプリケーションの状態を変更するために使用されるアクションを返します。
 *
 *          生成されたアクションオブジェクトは、Reduxのdispatch関数を通じてストアに送信される。
 *         これをアクションを「ディスパッチする」と言う。
 * @param inspectionBasic InspectionBasic 型の引数
 * @returns
 */
export const setInspectionBasic = (inspectionBasic: InspectionBasic | null): Action => ({
  type: ActionTypes.SET_INSPECTION_BASIC,
  payload: inspectionBasic,
});

/** @summary 定期業務-連絡事項情報取得のstate変更 */
export const setInspectionInfo = (inspectionInfo: InspectionInfoData | null): Action => ({
  type: ActionTypes.SET_INSPECTION_INFO,
  payload: inspectionInfo,
});

/** @summary 定期業務-コメント一覧取得のstate変更 */
export const setInspectionComments = (
  inspectionCommentsData: InspectionCommentsData | null,
): Action => ({
  type: ActionTypes.SET_INSPECTION_COMMENTS,
  payload: inspectionCommentsData,
});

/** @summary  operations.tsxのAPI呼び出し処理が失敗し、catchに飛んだ際に、
 *  dispatch(actions.setIsInvalidToken(true)); として、設定して画面側で
 *  IsInvalidTokenを取り出すと Invalidな状態を検知できる
 * */
export const setIsInvalidToken = (isInvalidToken: boolean): Action => ({
  type: ActionTypes.INVALID,
  payload: isInvalidToken,
});
