import { AppState as State } from '@ducks/index';
import _ from 'lodash';
import { CANVAS_H, CANVAS_W } from './types';

export const getRefreshId = (state: State) => state.floorMap.refresh;
export const getFloorMapRedraw = (state: State) => state.floorMap.floorMapRedraw;

export const checkIsLoading = (state: State) => state.floorMap.isLoading;
export const checkIsImageLoading = (state: State) => state.floorMap.isImageLoading;
export const checkIsSubmitting = (state: State) => state.floorMap.isSubmitting;

export const getFloors = (state: State) => state.floorMap.floors;
export const getFloorMapUrl = (state: State) => state.floorMap.floorMapUrl;
export const getRooms = (state: State) => state.floorMap.rooms;
export const getEquipments = (state: State) => {
  let eq = state.floorMap.equipments;
  const f = state.floorMap.filters;

  if (f.equipmentTypeId != null && f.equipmentTypeId !== 0) {
    eq = eq.filter((v) => v.equipmentTypeId === f.equipmentTypeId);
  }

  if (f.diagramId != null && f.diagramId !== 0) {
    eq = eq.filter((v) => v.diagramId === f.diagramId);
  }

  if (f.modelNumber != null && f.modelNumber !== '') {
    eq = eq.filter((v) => v.modelNumber === f.modelNumber);
  }

  if (f.serialNumber != null && f.serialNumber !== '') {
    eq = eq.filter((v) => v.serialNumber === f.serialNumber);
  }

  if (f.roomId != null && f.roomId !== 0) {
    eq = eq.filter((v) => v.roomId === f.roomId);
  }

  if (f.keyword != null && f.keyword !== '') {
    const reg = new RegExp(f.keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
    eq = eq.filter(
      (v) =>
        v.equipmentName.match(reg) ||
        v.diagramName.match(reg) ||
        v.modelNumber.match(reg) ||
        v.serialNumber.match(reg),
    );
  }

  return eq;
};

export const getDiagrams = (state: State) =>
  _.uniqBy(
    state.floorMap.equipments.map((v) => ({ id: v.diagramId, name: v.diagramName })),
    'id',
  );
export const getModelNumbers = (state: State) =>
  _.uniqBy(
    state.floorMap.equipments.map((v) => ({
      id: v.modelNumber,
      name: v.modelNumber,
    })),
    'id',
  );
export const getSerialNumbers = (state: State) =>
  _.uniqBy(
    state.floorMap.equipments.map((v) => ({
      id: v.serialNumber,
      name: v.serialNumber,
    })),
    'id',
  );

export const getTab = (state: State) => state.floorMap.tab;
export const getSelectedItem = (state: State) => state.floorMap.selectedItem;

export const checkIsEditMode = (state: State) => state.floorMap.editModeState != null;
export const getEditModeState = (state: State) => state.floorMap.editModeState;
export const getEditItemModeState = (state: State) => state.floorMap.editItemModeState;
export const getIsMoving = (state: State) => state.floorMap.moveState != null;

export const getViewBox = (state: State) => {
  const s = state.floorMap;

  // フロアマップ SVG の ViewBox の値を計算する
  // x,y -> ViewBox の基点の座標
  // width, height -> ViewBox の大きさ

  return {
    x: s.refPoint.left,
    y: s.refPoint.top,
    width: CANVAS_W / s.imageScale,
    height: CANVAS_H / s.imageScale,
  };
};
export const getImageScale = (state: State) => state.floorMap.imageScale;
export const getImageSize = (state: State) => state.floorMap.imageSize;

export const getEditModeEquipments = (state: State) => {
  const eq = getEquipments(state);
  const eqEdit = state.floorMap.editModeState?.equipments ?? [];

  const editModeEquipments = eq.map((v) => {
    const target = eqEdit.find((a) => a.id === v.equipmentId);
    if (target == null) {
      return {
        id: v.equipmentId,
        coordinate: v.coordinate,
        equipmentTypeId: v.equipmentTypeId,
      };
    } else {
      return {
        id: v.equipmentId,
        coordinate: target.coordinate,
        equipmentTypeId: v.equipmentTypeId,
      };
    }
  });

  return editModeEquipments;
};
export const getEditModeRooms = (state: State) => {
  const rm = state.floorMap.rooms;

  const rmEdit = state.floorMap.editModeState?.rooms ?? [];
  const rmEditIds = rmEdit.map((v) => v.id);

  const rm2 = rm
    .filter((v) => !rmEditIds.includes(v.roomId))
    .map((w) => ({ id: w.roomId, coordinate: w.coordinate }));

  return [...rm2, ...rmEdit];
};
