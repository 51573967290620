import React from 'react';
import { useMessageSource } from 'react-message-source';
import './style.scss';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';

type ModalProps = {
  className?: string;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  show: boolean;
  title?: string;
  icon?: IconProp;
  noTitle?: boolean; // true -> do not show modal title and icon
  noClose?: boolean; // true -> do not show close button
  bgClose?: boolean; // true -> also closes when clicking outside the modal
  onClose: () => void;
};

const Modal = (props: ModalProps) => {
  const { getMessage } = useMessageSource();
  const className1 = 'modal-background' + (props.show ? ' show' : '');
  const className2 = 'modal-window' + (props.className ? ' ' + props.className : '');

  return (
    <div
      className={className1}
      onClick={() => {
        if (!(props.bgClose === false)) props.onClose();
      }}
    >
      {props.show && (
        <div className={className2} onClick={(e) => e.stopPropagation()}>
          <div className="modal-header">
            <h2>
              {!props.noTitle && (
                <>
                  <FontAwesomeIcon icon={props.icon ?? faCircleInfo} />
                  <span>{props.title ?? getMessage('project.modal.0001')}</span>
                </>
              )}
            </h2>
            {!props.noClose && (
              <div className="modal-close" onClick={props.onClose}>
                <FontAwesomeIcon icon={faXmark} />
              </div>
            )}
          </div>
          <div className="modal-body scrollbar">{props.children}</div>
          {props.footer && <div className="modal-footer">{props.footer}</div>}
        </div>
      )}
    </div>
  );
};

export default Modal;
