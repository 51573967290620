import axios from '@providers/axiosProvider';
import axios_ from 'axios';
import * as actions from './actions';
import {
  APIApproveConstructionRequest,
  APICreateConstructionReport,
  APIGetConstructionGuestRequest,
  APIGetConstructionInfoResponse,
  APIGetConstructionResponse,
  APIGetConstructionsListParams,
  APIGetConstructionsListResponse,
  Construction,
  ConstructionReportSubmitData,
  ConstructionsListData,
  ConstructionsListDisplayStatus,
  ConstructionInfoData,
  APIConstructionsCommentsResponse,
  ConstructionCommentsData,
  APISubmitConstructionRequest,
} from './types';
import { LIST_SIZE_PER_PAGE } from '@utils/constants';

/**
 *  @summary 工事一覧取得
 */
export const getConstructionsListAsync =
  (params: APIGetConstructionsListParams) => async (dispatch: any) => {
    dispatch(actions.setIsLoading(true));

    const reqParams: APIGetConstructionsListParams = {
      ...params,
      size_per_page: LIST_SIZE_PER_PAGE,
    };

    await axios
      .get<APIGetConstructionsListResponse>(`/constructions`, {
        params: reqParams,
      })
      .then((response) => {
        const v = response.data.result;
        const constructionsList: ConstructionsListData[] = v.data.map((data) => ({
          construction_id: data.construction_id,
          property_id: data.property_id,
          property_name: data.property_name,
          status_id: data.status_id,
          status_name: data.status_name,
          start_date: data.start_date,
          title: data.title,
          reporter_id: data.reporter_id,
          reporter_name: data.reporter_name,
          latest_comment: data.latest_comment,
          update_datetime: data.update_datetime,
        }));

        const constructionsListDisplayStatus: ConstructionsListDisplayStatus = {
          num_per_page: v.display_params.num_per_page,
          current_page: v.display_params.current_page,
          total_page: v.display_params.total_page,
          total: v.display_params.total,
          sort: v.display_params.sort,
        };

        dispatch(actions.setConstructionsList(constructionsList));
        dispatch(actions.setConstructionsListDisplayStatus(constructionsListDisplayStatus));
      })
      .catch(() => {
        dispatch(actions.setConstructionsList([]));
        dispatch(actions.setConstructionsListDisplayStatus(null));
      });
    dispatch(actions.setIsLoading(false));
  };

export const getConstructionAsync = (constructionId: number) => async (dispatch: any) => {
  dispatch(actions.setIsLoading(true));

  await axios
    .get<APIGetConstructionResponse>(`/constructions/${constructionId}`)
    .then((response) => {
      const v = response.data.result;

      const construction: Construction = {
        constructionId: v.construction_id,
        title: v.title,
        propertyId: v.property_id,
        propertyName: v.property_name,
        operators: v.operators.map((o) => ({
          companyId: o.company_id,
          companyName: o.company_name,
          userId: o.user_id,
          userName: o.user_name,
          emailAddress: o.email_address,
          isRepresentative: o.is_representative,
        })),
        workers: v.worker.map((w) => ({
          companyId: w.company_id,
          companyName: w.company_name,
          userId: w.user_id,
          userName: w.user_name,
          emailAddress: w.email_address,
          isRepresentative: w.is_representative,
        })),
        statusId: v.status_id,
        statusName: v.status_name,
        remarks: v.remarks,
        description: v.description,
        reporterId: v.reporter_id,
        reporterName: v.reporter_name,
        updateDatetime: v.upload_datetime,
        files: v.reports.map((f) => ({
          fileName: f.file_name,
          memo: f.memo,
          fileUrl: f.file_url,
        })),
        reports: v.reports.map((r) => ({
          fileName: r.file_name,
          memo: r.memo,
          fileUrl: r.file_url,
          fileSize: r.file_size,
        })),
      };

      dispatch(actions.setConstruction(construction));
    })
    .catch(() => {
      dispatch(actions.setConstruction(null));
    });

  dispatch(actions.setIsLoading(false));
};

export const createConstructionReportAsync =
  (constructionId: number, data: ConstructionReportSubmitData) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const submitData: APICreateConstructionReport = {
      description: data.description,
      reports: data.reports.map((r) => ({
        file_name: r.fileName,
        file_content: r.fileUrl,
        is_delete_file: r.deleteFlag,
        memo: '',
      })),
      files: [],
    };

    const requestData: APICreateConstructionReport = submitData;

    await axios
      .patch(`/constructions/${constructionId}`, requestData)
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const submitConstructionAsync =
  (constructionId: number, reqParams: APISubmitConstructionRequest) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .patch(`/constructions/${constructionId}/submit`, reqParams)
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const approveConstructionAsync =
  (constructionId: number, approveRequest: APIApproveConstructionRequest) =>
  async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .patch(`/constructions/${constructionId}/approve`, approveRequest)
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const getConstructionGuestAsync =
  (constructionId: number, token: string) => async (dispatch: any) => {
    dispatch(actions.setIsLoading(true));
    const reqParams: APIGetConstructionGuestRequest = { token };

    await axios_
      .get<APIGetConstructionResponse>(`/api/guest/constructions/${constructionId}`, {
        params: reqParams,
      })
      .then((response) => {
        const v = response.data.result;

        const construction: Construction = {
          constructionId: v.construction_id,
          title: v.title,
          propertyId: v.property_id,
          propertyName: v.property_name,
          operators: v.operators.map((o) => ({
            companyId: o.company_id,
            companyName: o.company_name,
            userId: o.user_id,
            userName: o.user_name,
            emailAddress: o.email_address,
            isRepresentative: o.is_representative,
          })),
          workers: v.worker.map((w) => ({
            companyId: w.company_id,
            companyName: w.company_name,
            userId: w.user_id,
            userName: w.user_name,
            emailAddress: w.email_address,
            isRepresentative: w.is_representative,
          })),
          statusId: v.status_id,
          statusName: v.status_name,
          remarks: v.remarks,
          description: v.description,
          reporterId: v.reporter_id,
          reporterName: v.reporter_name,
          updateDatetime: v.upload_datetime,
          files: v.reports.map((f) => ({
            fileName: f.file_name,
            memo: f.memo,
            fileUrl: f.file_url,
          })),
          reports: v.reports.map((r) => ({
            fileName: r.file_name,
            memo: r.memo,
            fileUrl: r.file_url,
            fileSize: r.file_size,
          })),
        };
        console.log(construction);
        dispatch(actions.setConstruction(construction));
      })
      .catch((error) => {
        alert(error.response?.data.message ?? '');
        dispatch(actions.setConstruction(null));
        dispatch(actions.setIsInvalidToken(true));
      });

    dispatch(actions.setIsLoading(false));
  };

export const createConstructionReportGuestAsync =
  (constructionId: number, token: string, data: ConstructionReportSubmitData) =>
  async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const reqParams: APIGetConstructionGuestRequest = { token };

    const submitData: APICreateConstructionReport = {
      description: data.description,
      reports: data.reports.map((r) => ({
        file_name: r.fileName,
        file_content: r.fileUrl,
        is_delete_file: r.deleteFlag,
        memo: '',
      })),
      files: [],
    };

    const requestData: APICreateConstructionReport = submitData;

    await axios_
      .patch(`/api/guest/constructions/${constructionId}`, requestData, { params: reqParams })
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch((error) => {
        alert(error.response?.data.message ?? '');
      });

    dispatch(actions.setIsSubmitting(false));
  };

export const submitConstructionGuestAsync =
  (
    constructionId: number,
    params: {
      comment: string;
    },
    token: string,
  ) =>
  async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const reqParams: APIGetConstructionGuestRequest = { token };

    await axios_
      .patch(`/api/guest/constructions/${constructionId}/submit`, params, {
        params: reqParams,
      })
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch((error) => {
        alert(error.response?.data.message ?? '');
      });

    dispatch(actions.setIsSubmitting(false));
  };

/** @summary 工事-連絡事項情報取得 */
export const setConstructionInformationAsync =
  (constructionId: number) => async (dispatch: any) => {
    dispatch(actions.setIsLoading2(true));

    await axios
      .get<APIGetConstructionInfoResponse>(`/constructions/${constructionId}/information`)
      .then((response) => {
        const resultData = response.data.result;
        const constructionInfoData: ConstructionInfoData = {
          constructionId: resultData.construction_id,
          title: resultData.title,
          propertyId: resultData.property_id,
          propertyName: resultData.property_name,
          message: resultData.message,
          fileList: (resultData.file_list ?? []).map((f) => ({
            fileId: f.file_id,
            fileName: f.file_name,
            fileUrl: f.file_url,
            fileSize: f.file_size,
          })),
        };
        dispatch(actions.setConstructionInfo(constructionInfoData));
      })
      .catch(() => {
        dispatch(actions.setConstructionInfo(null));
      });
    dispatch(actions.setIsLoading2(false));
  };

/** @summary 工事-連絡事項情報取得 */
export const setConstructionInformationGuestAsync =
  (constructionId: number, token: string) => async (dispatch: any) => {
    dispatch(actions.setIsLoading2(true));
    const reqParams: APIGetConstructionGuestRequest = { token };

    await axios_
      .get<APIGetConstructionInfoResponse>(
        `/api/guest/constructions/${constructionId}/information`,
        {
          params: reqParams,
        },
      )
      .then((response) => {
        const resultData = response.data.result;
        const constructionInfoData: ConstructionInfoData = {
          constructionId: resultData.construction_id,
          title: resultData.title,
          propertyId: resultData.property_id,
          propertyName: resultData.property_name,
          message: resultData.message,
          fileList: (resultData.file_list ?? []).map((f) => ({
            fileId: f.file_id,
            fileName: f.file_name,
            fileUrl: f.file_url,
            fileSize: f.file_size,
          })),
        };
        dispatch(actions.setConstructionInfo(constructionInfoData));
      })
      .catch(() => {
        dispatch(actions.setConstructionInfo(null));
      });
    dispatch(actions.setIsLoading2(false));
  };

/** @summary 工事コメント一覧取得 */
export const getConstructionsCommentsAsync = (constructionId: number) => async (dispatch: any) => {
  dispatch(actions.setIsLoading2(true));

  await axios
    .get<APIConstructionsCommentsResponse>(`/constructions/${constructionId}/comments`)
    .then((response) => {
      const resultData = response.data.result;
      const constructionsCommentsData: ConstructionCommentsData = {
        commentList: resultData.comment_list.map((c) => ({
          commentId: c.comment_id,
          typeId: c.type_id,
          typeName: c.type_name,
          beforeStatusId: c.before_status_id,
          beforeStatusName: c.before_status_name,
          afterStatusId: c.after_status_id,
          afterStatusName: c.after_status_name,
          createUserId: c.create_user_id,
          createUserName: c.create_user_name,
          createDatetime: c.create_datetime,
          updateDatetime: c.update_datetime,
          comment: c.comment,
          imageList: c.image_list.map((i) => ({
            fileId: i.file_id,
            fileName: i.file_name,
            fileUrl: i.file_url,
            thumbnailFileUrl: i.thumbnail_file_url,
            fileSize: i.file_size,
            memo: i.memo,
          })),
          fileList: c.file_list.map((f) => ({
            fileId: f.file_id,
            fileName: f.file_name,
            fileUrl: f.file_url,
            fileSize: f.file_size,
          })),
        })),
      };
      dispatch(actions.setConstructionComments(constructionsCommentsData));
    })
    .catch(() => {
      dispatch(actions.setConstructionComments(null));
      return;
    });
  dispatch(actions.setIsLoading2(false));
};
