//import axios from "@providers/axiosProvider";
import axios_ from 'axios';
import * as actions from './actions';
import {
  AdjustmentPlan,
  AdjustmentPlanSummary,
  APIGetAdjustmentPlansRequestParams,
  APIGetAdjustmentPlansResponse,
  APISendReadjustments,
  APISendReadjustmentsRequest,
  APISendReadjustmentsRequestParams,
  ReadjustmentsSubmitData,
} from './types';

export const getAdjustmentPlansAsync = (token: string, month: string) => async (dispatch: any) => {
  dispatch(actions.setIsLoading(true));

  const reqParams: APIGetAdjustmentPlansRequestParams = { token, month };

  await axios_
    .get<APIGetAdjustmentPlansResponse>('/api/guest/plans/adjustment', {
      params: reqParams,
    })
    .then((response) => {
      const result = response.data.result;

      const summary: AdjustmentPlanSummary = {
        month: result.month,
        comment: result.comment,
      };

      const plans: AdjustmentPlan[] = result.data.map(
        (v): AdjustmentPlan => ({
          dailyTaskId: v.plan_id,
          schedulingStatusId: v.scheduling_status_id,
          schedulingStatusName: v.scheduling_status_name,
          startDate: v.start_date,
          startTime: v.start_time,
          endDate: v.end_date,
          endTime: v.end_time,
          propertyId: v.property_id,
          propertyName: v.property_name,
          typeId: v.type_id,
          typeName: v.type_name,
          title: v.title,
          readjustmentRequest: {
            startDate: null,
            startTime: null,
            endDate: null,
            endTime: null,
            comment: '',
          },
          isAnswered: false,
        }),
      );

      dispatch(actions.setPlans(plans));
      dispatch(actions.setSummary(summary));
    })
    .catch((error) => {
      alert(error.response?.data.message ?? '');
      dispatch(actions.setPlans([]));
      dispatch(actions.setSummary(null));
      dispatch(actions.setIsInvalidToken(true));
    });

  dispatch(actions.setIsLoading(false));
};

export const sendReadjustmentsAsync =
  (token: string, month: string, data: ReadjustmentsSubmitData) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const reqParams: APISendReadjustmentsRequestParams = { token };

    const items: APISendReadjustments[] = data.map(
      (v): APISendReadjustments => ({
        daily_task_id: v.dailyTaskId,
        status: v.status,
        start_date: v.startDate,
        start_time: v.startTime,
        end_date: v.endDate,
        end_time: v.endTime,
        comment: v.comment,
      }),
    );

    const requestData: APISendReadjustmentsRequest = {
      month: month,
      daily_task_list: items,
    };

    await axios_
      .post(`/api/guest/plans/adjustment`, requestData, { params: reqParams })
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch((error) => {
        alert(error.response?.data.message ?? '');
      });

    dispatch(actions.setIsSubmitting(false));
  };
