import axios from '@providers/axiosProvider';
import * as actions from './actions';

import {
  APIAddDiagramEquipmentsRequest,
  APIDeleteDiagramEquipmentsRequest,
  APIDeleteDiagramsRequest,
  APIGetDiagramResponse,
  APIGetSelectEquipmentsResponse,
  APIUpdateDiagram,
  APIUpdateDiagramRequest,
  Diagram,
  DiagramEquipmentSubmitData,
  DiagramSubmitData,
  Equipment,
} from './types';

export const getDiagramAsync = (propertyId: number, diagramId: number) => async (dispatch: any) => {
  dispatch(actions.setIsLoading(true));

  await axios
    .get<APIGetDiagramResponse>(`/properties/${propertyId}/diagram/${diagramId}`)
    .then((response) => {
      const v = response.data.result;

      const data: Diagram = {
        diagramId: v.diagram_id,
        diagramName: v.diagram_name,
        propertyId: v.property_id,
        propertyName: v.property_name,
        description: v.description,
        equipmentList: v.equipment_list.map((e) => ({
          equipmentId: e.equipment_id,
          equipmentName: e.equipment_name,
          floorId: e.floor_id,
          floorName: e.floor_name,
          modelNumber: e.model_number,
          diagramSymbol: e.diagram_symbol,
          equipmentTypeName: e.equipment_type_name,
          manufacturer: e.manufacturer,
        })),
      };

      dispatch(actions.setDiagram(data));
    })
    .catch(() => {
      dispatch(actions.setDiagram(null));
    });

  dispatch(actions.setIsLoading(false));
};

export const updateDiagramAsync =
  (propertyId: number, diagramId: number, data: DiagramSubmitData) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const diagram: APIUpdateDiagram = {
      diagram_name: data.name,
      description: data.description,
    };

    const requestData: APIUpdateDiagramRequest = diagram;

    await axios
      .put<APIGetDiagramResponse>(`/properties/${propertyId}/diagram/${diagramId}`, requestData)
      .then(() => {
        dispatch(actions.setIsNavigating(true));
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const deleteDiagramsAsync =
  (propertyId: number, diagramIds: number[]) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const requestData: APIDeleteDiagramsRequest = {
      diagram_list: diagramIds.map((v) => ({
        diagram_id: v,
      })),
    };

    await axios
      .delete(`/properties/${propertyId}/diagram`, {
        data: requestData,
      })
      .then(() => {
        dispatch(actions.setIsNavigating(true));
        dispatch(actions.setIsNavigatingTo(-1));
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const addDiagramEquipmentsAsync =
  (propertyId: number, diagramId: number, data: DiagramEquipmentSubmitData[]) =>
  async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const requestData: APIAddDiagramEquipmentsRequest = {
      equipment_list: data.map((v) => ({
        equipment_id: v.equipmentId,
        diagram_symbol: v.diagramSymbol,
      })),
    };

    await axios
      .post<APIGetDiagramResponse>(
        `/properties/${propertyId}/diagram/${diagramId}/diagram_equipments`,
        requestData,
      )
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const deleteDiagramEquipmentsAsync =
  (propertyId: number, diagramId: number, equipmentIds: number[]) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const requestData: APIDeleteDiagramEquipmentsRequest = {
      equipment_list: equipmentIds.map((v) => ({
        equipment_id: v,
      })),
    };

    await axios
      .delete(`/properties/${propertyId}/diagram/${diagramId}/diagram_equipments`, {
        data: requestData,
      })
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const getSelectEquipmentsAsync = (propertyId: number) => async (dispatch: any) => {
  dispatch(actions.setIsLoading2(true));

  await axios
    .get<APIGetSelectEquipmentsResponse>(`/properties/${propertyId}/diagram/diagram_equipments`)
    .then((response) => {
      const data: Equipment[] = response.data.result.map(
        (e): Equipment => ({
          equipmentId: e.equipment_id,
          equipmentName: e.equipment_name,
          floorId: e.floor_id,
          floorName: e.floor_name,
          modelNumber: e.model_number,
          diagramSymbol: e.diagram_symbol,
          equipmentTypeName: e.equipment_type_name,
          manufacturer: e.manufacturer,
        }),
      );
      dispatch(actions.setSelectEquipments(data));
    })
    .catch(() => {
      dispatch(actions.setSelectEquipments([]));
    });

  dispatch(actions.setIsLoading2(false));
};
