import {
  UserLicenseState,
  UserLicenseAction,
  UserLicenseActionTypes as ActionTypes,
} from './types';

const initialUserLicenseState: UserLicenseState = {
  refresh: {},
  isLoading: false,
  licenseInfo: null,
};

const userLicenseReducer = (
  state: UserLicenseState = initialUserLicenseState,
  action: UserLicenseAction,
): UserLicenseState => {
  switch (action.type) {
    case ActionTypes.INIT:
      return initialUserLicenseState;

    case ActionTypes.REFRESH:
      return { ...state, refresh: {} };

    case ActionTypes.LOADING:
      return { ...state, isLoading: action.payload };

    case ActionTypes.SET_LICENSE_INFO:
      return { ...state, licenseInfo: action.payload };

    default:
      return state;
  }
};

export default userLicenseReducer;
