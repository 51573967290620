import {
  PlanMonthlyTableState,
  PlanMonthlyTableAction,
  PlanMonthlyTableActionTypes as ActionTypes,
} from './types';

/**
 * @summary 月次計画詳細画面で管理されるStateの初期値
 */
const initialTableMonthlyTableState: PlanMonthlyTableState = {
  refresh: {},
  /** Load中か */
  isLoading: false,
  /** 契約リストLoad中か */
  isLoadingContractList: false,
  /** 送信処理中か */
  isSubmitting: false,
  /** 編集中か */
  isEditing: false,
  /** ページ番号 */
  page: 1,
  /** ソート番号 */
  sort: 1,
  /** 詳細検索条件 */
  searchParameter: {},
  /** 月次計画詳細リスト */
  tables: [],
  /** 月次計画サマリ */
  summary: null,
  /** Pager情報 */
  displayParams: null,
  /** 契約リスト */
  contractList: [],
  /** 契約リストPager情報 */
  contractDisplayParams: null,
  /** 選択を確定した契約 */
  contract: null,
};

const tableMonthlyTableReducer = (
  state: PlanMonthlyTableState = initialTableMonthlyTableState,
  action: PlanMonthlyTableAction,
): PlanMonthlyTableState => {
  switch (action.type) {
    case ActionTypes.INIT:
      return initialTableMonthlyTableState;

    case ActionTypes.REFRESH:
      return { ...state, refresh: {} };

    case ActionTypes.LOADING:
      return { ...state, isLoading: action.payload };

    case ActionTypes.LOADING_CONTRACT_LIST:
      return { ...state, isLoadingContractList: action.payload };

    case ActionTypes.SUBMITTING:
      return { ...state, isSubmitting: action.payload };

    case ActionTypes.EDITING:
      return { ...state, isEditing: action.payload };

    case ActionTypes.SET_PAGE:
      return { ...state, page: action.payload };

    case ActionTypes.SET_SORT:
      return { ...state, sort: action.payload };

    case ActionTypes.SET_SEARCH_PARAMETER:
      return { ...state, searchParameter: action.payload };

    case ActionTypes.SET_TABLES:
      return { ...state, tables: action.payload };

    case ActionTypes.SET_SUMMARY:
      return { ...state, summary: action.payload };

    case ActionTypes.SET_DISPLAY_PARAMS:
      return { ...state, displayParams: action.payload };

    case ActionTypes.SET_CONTRACT_LIST:
      return { ...state, contractList: action.payload };

    case ActionTypes.SET_CONTRACT_DISPLAY_PARAMS:
      return { ...state, contractDisplayParams: action.payload };

    case ActionTypes.SET_CONTRACT:
      return { ...state, contract: action.payload };

    default:
      return state;
  }
};

export default tableMonthlyTableReducer;
