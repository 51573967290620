import {
  NotificationState,
  NotificationAction,
  NotificationActionTypes as ActionTypes,
} from './types';

const initialNotificationState: NotificationState = {
  refresh: {},
  isLoading: false,
  isSubmitting: false,
  notifications: {
    /* 建物 */
    is_property_updated: false,
    is_contract_updated: true,
    is_property_information_updated: true,
    is_property_group_user_updated: false,
    /* 定期業務 */
    is_inspection_applied: true,
    is_inspection_approved: true,
    is_inspection_rejected: true,
    is_inspection_comments: true,
    /* 工事 */
    is_construction_applied: true,
    is_construction_approved: true,
    is_construction_rejected: true,
    is_construction_comments: true,
    /* 案件 */
    is_trouble_created: true,
    is_trouble_updated: false,
    is_trouble_applied: true,
    is_trouble_completed: true,
    is_trouble_rejected: true,
    is_trouble_pending: true,
    is_trouble_shooting: true,
    is_trouble_comments: true,
  },
};

const notificationReducer = (
  state: NotificationState = initialNotificationState,
  action: NotificationAction,
): NotificationState => {
  switch (action.type) {
    case ActionTypes.INIT:
      return initialNotificationState;

    case ActionTypes.REFRESH:
      return { ...state, refresh: {} };

    case ActionTypes.LOADING:
      return { ...state, isLoading: action.payload };

    case ActionTypes.SUBMITTING:
      return { ...state, isSubmitting: action.payload };

    case ActionTypes.SET_NOTIFICATIONS:
      return { ...state, notifications: action.payload };

    default:
      return state;
  }
};

export default notificationReducer;
