import type { Action, PayloadAction } from '@reduxjs/toolkit';

export const CANVAS_W = 1920;
export const CANVAS_H = 1080;
export const CANVAS_W_MARGIN = 16;
export const CANVAS_H_MARGIN = 9;
/** 拡大率の最大値 */
export const MAX_SCALE = 5;
/** 拡大率の最小値(1.0より小さい場合は縮小) */
export const MIN_SCALE = 0.4;
/** 拡大縮小は20%ずつ */
export const SCALE_STEP = 0.2;
/** 拡大回数の下限値(-3回 ※縮小回数を負の値とし縮小を3回可能とする) */
export const MIN_SCALE_COUNT = Math.floor((MIN_SCALE - 1) / SCALE_STEP);
/** 拡大回数の上限値(20回) */
export const MAX_SCALE_COUNT = Math.ceil((MAX_SCALE - 1) / SCALE_STEP);

export type FloorMapState = {
  /** 各登録データの変更検知に使用する。変更検知時にフロア、設備、部屋の再取得を行う */
  refresh: Record<string, never>;
  /** 画像の変更検知に使用する。変更検知時にフロア、設備、部屋の表示部の再描画を行う */
  floorMapRedraw: Record<string, never>;
  isLoading: boolean;
  /** 図面画像読み込み中か */
  isImageLoading: boolean;
  isSubmitting: boolean;
  floors: Floor[];
  rooms: Room[];
  equipments: Equipment[];
  floorMapUrl: string | null;
  tab: FloorMapItemTypes;
  filters: FloorMapFilters;
  selectedItem: FloorMapItem | null;
  editModeState: EditModeState | null;
  editItemModeState: EditItemModeState | null;
  /** 画像の初期倍率 */
  initialImageScale: number;
  /** 表示倍率 */
  imageScale: number;
  /** 拡大回数(※負の値の場合は縮小回数) */
  scaleCount: number;
  imageSize: Size;
  refPoint: Point;
  moveState: {
    mousePointOffset: Point;
    refPointOffset: Point;
  } | null;
};

export enum FloorMapActionTypes {
  INIT = 'floorMap/init',
  REFRESH = 'floorMap/refresh',
  FLOOR_MAP_REDRAW = 'floorMap/FLOOR_MAP_REDRAW',
  LOADING = 'floorMap/set/isLoading',
  IMAGE_LOADING = 'floorMap/set/isImageLoading',
  SUBMITTING = 'floorMap/set/isSubmitting',
  SET_FLOORS = 'floorMap/set/floors',
  SET_ROOMS = 'floorMap/set/rooms',
  SET_EQUIPMENTS = 'floorMap/set/equipments',
  SET_FLOOR_MAP_URL = 'floorMap/set/floorMapUrl',
  SET_TAB = 'floorMap/set/tab',
  SET_FILTERS = 'floorMap/set/filters',
  SET_SELECTED_ITEM = 'floorMap/set/selectedItem',
  SET_EDIT_MODE = 'floorMap/set/editMode',
  SET_EDIT_ITEM_MODE = 'floorMap/set/editItemMode',
  SET_EDIT_ITEM_POINTS = 'floorMap/set/editItemMode/points',
  ADD_EDIT_ITEM_EQUIPMENT = 'floorMap/add/editItemMode/equipment',
  ADD_EDIT_ITEM_ROOM = 'floorMap/add/editItemMode/room',
  UP_IMAGE_SCALE = 'floorMap/up/imageScale',
  DOWN_IMAGE_SCALE = 'floorMap/down/imageScale',
  SET_IMAGE_SIZE = 'floorMap/set/imageSize',
  MOVE_MAP_START = 'floorMap/move/map/start',
  MOVE_MAP = 'floorMap/move/map/move',
  MOVE_MAP_END = 'floorMap/move/map/end',
}

export type FloorMapAction =
  | Action<FloorMapActionTypes.INIT>
  | Action<FloorMapActionTypes.REFRESH>
  | Action<FloorMapActionTypes.FLOOR_MAP_REDRAW>
  | PayloadAction<boolean, FloorMapActionTypes.LOADING>
  | PayloadAction<boolean, FloorMapActionTypes.IMAGE_LOADING>
  | PayloadAction<boolean, FloorMapActionTypes.SUBMITTING>
  | PayloadAction<Floor[], FloorMapActionTypes.SET_FLOORS>
  | PayloadAction<Room[], FloorMapActionTypes.SET_ROOMS>
  | PayloadAction<Equipment[], FloorMapActionTypes.SET_EQUIPMENTS>
  | PayloadAction<string | null, FloorMapActionTypes.SET_FLOOR_MAP_URL>
  | PayloadAction<FloorMapFilters, FloorMapActionTypes.SET_FILTERS>
  | PayloadAction<FloorMapItemTypes, FloorMapActionTypes.SET_TAB>
  | PayloadAction<FloorMapItem | null, FloorMapActionTypes.SET_SELECTED_ITEM>
  | PayloadAction<boolean, FloorMapActionTypes.SET_EDIT_MODE>
  | PayloadAction<EditItemModeState | null, FloorMapActionTypes.SET_EDIT_ITEM_MODE>
  | PayloadAction<Point[], FloorMapActionTypes.SET_EDIT_ITEM_POINTS>
  | PayloadAction<EquipmentItem, FloorMapActionTypes.ADD_EDIT_ITEM_EQUIPMENT>
  | PayloadAction<RoomItem, FloorMapActionTypes.ADD_EDIT_ITEM_ROOM>
  | PayloadAction<Point, FloorMapActionTypes.UP_IMAGE_SCALE>
  | PayloadAction<Point, FloorMapActionTypes.DOWN_IMAGE_SCALE>
  | PayloadAction<Size, FloorMapActionTypes.SET_IMAGE_SIZE>
  | PayloadAction<Point, FloorMapActionTypes.MOVE_MAP_START>
  | PayloadAction<Point, FloorMapActionTypes.MOVE_MAP>
  | Action<FloorMapActionTypes.MOVE_MAP_END>;

// -- State Types ---------------------------------------------------------- //

export type Equipment = {
  equipmentId: number;
  equipmentName: string;
  equipmentTypeId: number;
  equipmentTypeName: string;
  diagramId: number;
  diagramName: string;
  modelNumber: string;
  serialNumber: string;
  roomId: number;
  coordinate: Coordinate | null;
};

export type Room = {
  roomId: number;
  roomName: string;
  description: string;
  coordinate: RoomCoordinate;
};

export type FloorMapFilters = {
  equipmentTypeId?: number;
  diagramId?: number;
  modelNumber?: string;
  serialNumber?: string;
  roomId?: number;
  keyword?: string;
};

export type Floor = {
  id: number;
  name: string;
  description: string;
  roomNum: number;
};

export type FloorMapItemTypes = 'ROOM' | 'EQUIPMENT';

export type Point = { left: number; top: number };
export type Size = { width: number; height: number };
export type ViewBox = Coordinate & Size;

export type Coordinate = { x: number; y: number };
/**
 * 部屋の範囲は頂点座標 (x,y) のリストの文字列で与える
 *
 * 例1 - 三角形: 0,0,10,10,20,0
 *
 * 例2 - 四角形: 0,0,10,0,10,10,0,10
 */
export type RoomCoordinate = string;

export type FloorMapItem = {
  id: number;
  type: FloorMapItemTypes;
};

/** 図面編集時に保存する前の編集状態を保持するためのオブジェクト型 */
export type EditModeState = {
  equipments: EquipmentItem[];
  rooms: RoomItem[];
};

export type EquipmentItem = {
  id: number;
  coordinate: Coordinate | null;
};

export type RoomItem = { id: number; coordinate: RoomCoordinate };

/** 座標編集時に保存する前の座標の状態を保持するためのオブジェクト型 */
export type EditItemModeState = {
  type: FloorMapItemTypes;
  id: number;
  points: Point[];
};

// -- API Types ------------------------------------------------------------ //

type APIEquipmentsAndRooms = {
  readonly file_url: string | null;
  readonly room_list: {
    readonly room_id: number;
    readonly room_name: string;
    readonly description: string;
    readonly coordinate: string;
  }[];
  readonly equipment_list: {
    readonly equipment_id: number;
    readonly equipment_name: string;
    readonly equipment_type_id: number;
    readonly equipment_type_name: string;
    readonly diagram_id: number;
    readonly diagram_name: string;
    readonly model_number: string;
    readonly serial_number: string;
    readonly room_id: number;
    readonly manufacturer: string;
    readonly manufacture_date: string;
    readonly install_date: string;
    readonly coordinate_x: number | null;
    readonly coordinate_y: number | null;
  }[];
};

export type APIGetEquipmentsAndRoomsResponse = {
  result: APIEquipmentsAndRooms;
};

export type APIUploadFloorMapRequest = {
  file_name: string;
  file_content: string;
};

export type APILinkFloorMapRequest = {
  equipment_list: {
    equipment_id: number;
    coordinate_x: number | null;
    coordinate_y: number | null;
  }[];
  room_list: { room_id: number; coordinate: string }[];
};
