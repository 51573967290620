import { AppState as State } from '@ducks/index';
import _ from 'lodash';
import { LayoutComponent } from './types';

// 「セレクター（Selector）」関数の定義
// セレクターは、グローバルな状態オブジェクトから特定のデータを取得するために使用される
export const getRefreshId = (state: State) => state.inspection.refresh;

/**
 * @summary  定期業務の連絡タブデータ取得用effect監視のRefreshIdを定義
 */
export const getRefreshIdNotificationTab = (state: State) =>
  state.inspection.refreshNotificationTab;

/**
 * @summary 定期業務に用意したタブがRefreshIdを共有していたことにより、
 * 各タブの各データ処理のuseEffectの依存配列が反応し、無限ループを起こしていた。
 * 無限ループに入らないようにデータ取得のuseEffectを作業結果タブ用の
 * RefreshIdを定義
 */
export const getRefreshIdWorkResultTab = (state: State) => state.inspection.refreshWorkResultTab;

/**
 * @summary  定期業務のコメントタブデータ取得用effect監視のRefreshIdを定義
 */
export const getRefreshIdCommentTab = (state: State) => state.inspection.refreshCommentTab;

/**
 * @summary  定期業務の一覧画面、詳細画面のデータロード中 状態監視用
 */
export const checkIsLoading = (state: State) => state.inspection.isLoading;

/**
 * @summary  定期業務の詳細タブのデータロード中 状態監視用
 */
export const checkIsLoadingTab = (state: State) => state.inspection.isLoadingTab;
export const checkIsSubmitting = (state: State) => state.inspection.isSubmitting;
export const checkIsNavigating = (state: State) => state.inspection.isNavigating;
export const checkIsNavigating2 = (state: State) => state.inspection.isNavigating2;
export const checkIsNavigatingTo = (state: State) => state.inspection.isNavigatingTo;

/**
 * @summary この関数はアプリケーションの状態からinspectionsというデータを取り出すために使用される
 * getInspectionsという名前の関数をエクスポートし、
 * (state: State): はstateという引数を取り、stateはアプリケーションの全体的な状態（Stateオブジェクト）を指す
 * => state.inspection.inspections;: この関数は与えられた全体的な状態オブジェクトから、
 * state.inspection.inspectionsという特定のプロパティを返す。アプリケーションの状態ツリーの中で
 * inspectionオブジェクトの中のinspectionsというフィールドを指す
 * 画面のフックのuseData() 関数内で以下のように利用する
   const inspections = useSelector(inspectionSelectors.getInspections);
 *
 */
export const getInspections = (state: State) => state.inspection.inspections;
export const getInspectionsListInfo = (state: State) => state.inspection.inspectionsListInfo;

export const getTargetInspection = (state: State) => state.inspection.inspection;

export const getPastResults = (state: State) => state.inspection.inspection?.inspection_result_list;

export const getPrevInspection = (state: State) => state.inspection.prevInspection;

export const getInspectionChecklists = (state: State) => state.inspection.inspectionChecklists;

export const getLayoutComponents = (state: State): LayoutComponent[] =>
  state.inspection.inspection != null
    ? _.flattenDeep(
        state.inspection.inspection?.layout.groups.map((g) => g.sections.map((s) => s.components)),
      )
    : [];

export const getLayoutComponentsWithoutDisabled = (state: State): LayoutComponent[] => {
  const components: LayoutComponent[] =
    state.inspection.inspection != null
      ? _.flattenDeep(
          state.inspection.inspection?.layout?.groups
            .filter((g) => !g.disable)
            .map((g) => g.sections.filter((s) => !s.disable).map((s) => s.components)),
        )
      : [];
  return components.filter((c) => !c.disable);
};

export const getPhotos = (state: State) => state.inspection.photos;
export const getDeletePhotos = (state: State) => state.inspection.deletePhotos;
export const getPhotoIds = (state: State) =>
  state.inspection.photos.filter((v) => !v.isDeleteFile).map((v) => v.id);

//
/** @summary 定期業務-基本情報タブ用情報取得 */
export const getInspectionBasic = (state: State) => state.inspection.inspectionBasic;

/** @summary 定期業務-連絡事項情報e取得 */
export const getInspectionsInfo = (state: State) => state.inspection.inspectionInfo;

/** @summary 定期業務-コメント一覧取得 */
export const getInspectionsComments = (state: State) => state.inspection.inspectionComments;

/** @summary Guestアクセス時にAPI付与のトークンが無効だったかどうかを判断 */
export const checkIsInvalidToken = (state: State) => state.inspection.isInvalidToken;
