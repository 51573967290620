import reducer from './reducers';

import * as equipmentSelectors from './selectors';
import * as equipmentActions from './actions';
import * as equipmentOperations from './operations';
import { EquipmentState } from './types';

export { equipmentSelectors, equipmentActions, equipmentOperations };
export type { EquipmentState };

export default reducer;
