import { AppState as State } from '@ducks/index';

export const getRefreshId = (state: State) => state.group.refresh;

export const checkIsLoading = (state: State) => state.group.isLoading;
export const checkIsLoading2 = (state: State) => state.group.isLoading2;
export const checkIsSubmitting = (state: State) => state.group.isSubmitting;
export const checkIsNavigating = (state: State) => state.group.isNavigating;
export const checkIsNavigatingTo = (state: State) => state.group.isNavigatingTo;

export const getGroups = (state: State) => state.group.groups;
export const getGroupsListInfo = (state: State) => state.group.groupsListInfo;

export const getGroup = (state: State) => state.group.group;

export const getSelectMembers = (state: State) => state.group.selectMembers;
export const getSelectMembersListInfo = (state: State) => state.group.selectMembersListInfo;
