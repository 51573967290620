import {
  FloorMapAction,
  FloorMapState,
  FloorMapActionTypes as ActionTypes,
  SCALE_STEP,
  CANVAS_W,
  CANVAS_H,
  CANVAS_W_MARGIN,
  CANVAS_H_MARGIN,
  MAX_SCALE_COUNT,
  MIN_SCALE_COUNT,
} from './types';

const initialFloorMapCanvasState: FloorMapState = {
  refresh: {},
  floorMapRedraw: {},
  isLoading: false,
  isImageLoading: false,
  isSubmitting: false,
  floors: [],
  rooms: [],
  equipments: [],
  floorMapUrl: null,
  tab: 'EQUIPMENT',
  filters: {},
  selectedItem: null,
  editModeState: null,
  editItemModeState: null,
  initialImageScale: 1.0,
  imageScale: 1.0,
  scaleCount: 0,
  imageSize: { width: 0, height: 0 },
  refPoint: { left: 0, top: 0 },
  moveState: null,
};

const floorMapReducer = (
  state: FloorMapState = initialFloorMapCanvasState,
  action: FloorMapAction,
): FloorMapState => {
  switch (action.type) {
    case ActionTypes.INIT:
      return { ...initialFloorMapCanvasState };

    case ActionTypes.REFRESH:
      return { ...state, refresh: {} };

    case ActionTypes.FLOOR_MAP_REDRAW:
      return { ...state, floorMapRedraw: {} };

    case ActionTypes.LOADING:
      return { ...state, isLoading: action.payload };

    case ActionTypes.IMAGE_LOADING:
      return { ...state, isImageLoading: action.payload };

    case ActionTypes.SUBMITTING:
      return { ...state, isLoading: action.payload };

    case ActionTypes.SET_FLOORS:
      return { ...state, floors: action.payload, selectedItem: null };

    case ActionTypes.SET_ROOMS:
      return { ...state, rooms: action.payload, selectedItem: null };

    case ActionTypes.SET_EQUIPMENTS:
      return { ...state, equipments: action.payload, selectedItem: null };

    case ActionTypes.SET_FLOOR_MAP_URL:
      return {
        ...state,
        floorMapUrl: action.payload,
        selectedItem: null,
        initialImageScale: 1.0,
        imageScale: 1.0,
        scaleCount: 0,
        imageSize: { width: 0, height: 0 },
        refPoint: { left: 0, top: 0 },
      };

    case ActionTypes.SET_FILTERS:
      return { ...state, filters: action.payload, selectedItem: null };

    case ActionTypes.SET_TAB:
      return { ...state, tab: action.payload };

    case ActionTypes.SET_SELECTED_ITEM:
      return { ...state, selectedItem: action.payload };

    case ActionTypes.SET_EDIT_MODE:
      return {
        ...state,
        editModeState: action.payload ? { equipments: [], rooms: [] } : null,
        editItemModeState: null,
      };

    case ActionTypes.SET_EDIT_ITEM_MODE:
      if (state.editModeState == null) return state;
      return { ...state, editItemModeState: action.payload };

    case ActionTypes.SET_EDIT_ITEM_POINTS:
      if (state.editItemModeState == null) return state;
      return {
        ...state,
        editItemModeState: { ...state.editItemModeState, points: action.payload },
      };

    case ActionTypes.ADD_EDIT_ITEM_EQUIPMENT: {
      if (state.editModeState == null || state.editItemModeState == null) return state;
      const newEditModeState = { ...state.editModeState };
      const eq = action.payload;

      newEditModeState.equipments = newEditModeState.equipments.filter((v) => v.id !== eq.id);
      newEditModeState.equipments.push(eq);

      return { ...state, editModeState: newEditModeState };
    }

    case ActionTypes.ADD_EDIT_ITEM_ROOM: {
      if (state.editModeState == null || state.editItemModeState == null) return state;
      const newEditModeState = { ...state.editModeState };
      const rm = action.payload;

      newEditModeState.rooms = newEditModeState.rooms.filter((v) => v.id !== rm.id);
      newEditModeState.rooms.push(rm);

      return { ...state, editModeState: newEditModeState };
    }

    case ActionTypes.UP_IMAGE_SCALE: {
      const newScaleCount = state.scaleCount + 1;
      if (MAX_SCALE_COUNT < newScaleCount) return state;

      // 拡大回数から表示倍率を計算する
      const newScale = state.initialImageScale * (newScaleCount * SCALE_STEP + 1);
      let { left, top } = state.refPoint;
      const { left: x, top: y } = action.payload;

      /**
       * マウスホイールのあった点を基点にフロアマップを拡大する
       * つまり、スケール操作の前後でマウスの位置が変わらないと仮定した場合に、その位置にあるフロアマップ画像上の点が
       * スケール操作の前後で同一になるように ViewBox のオフセット位置を調節する
       *
       * 基点の座標を (X, Y)
       * スケール操作による変化を scale: α -> α', ViewBox: (x, y, W, H) -> (x', y', W', H') としたとき、
       *
       *  α' = α + α_step, W' = W * ( α / α'), H' = H * ( α / α')  であり
       * 制約条件は  (X - x) / W = (X - x') / W' かつ (Y - y) / H = (Y - y') / H'  であるから
       *
       *   x' = x + (α_step / α') * (X - x)
       *   y' = y + (α_step / α') * (Y - y)
       *
       */

      left = left + ((newScale - state.imageScale) / newScale) * (x - left);
      top = top + ((newScale - state.imageScale) / newScale) * (y - top);

      return {
        ...state,
        imageScale: newScale,
        scaleCount: newScaleCount,
        refPoint: { left, top },
      };
    }

    case ActionTypes.DOWN_IMAGE_SCALE: {
      const newScaleCount = state.scaleCount - 1;
      if (newScaleCount < MIN_SCALE_COUNT) return state;

      // 縮小回数から表示倍率を計算する
      const newScale = state.initialImageScale * (newScaleCount * SCALE_STEP + 1);
      let { left, top } = state.refPoint;
      const { left: x, top: y } = action.payload;

      // マウスホイールのあった点を基点にフロアマップを縮小する
      left = left + ((newScale - state.imageScale) / newScale) * (x - left);
      top = top + ((newScale - state.imageScale) / newScale) * (y - top);

      return {
        ...state,
        imageScale: newScale,
        scaleCount: newScaleCount,
        refPoint: { left, top },
      };
    }

    // 画像のサイズから拡大率、センタリングのための位置を設定する
    case ActionTypes.SET_IMAGE_SIZE: {
      const { width, height } = action.payload;
      const WIDTH = CANVAS_W - CANVAS_W_MARGIN * 2;
      const HEIGHT = CANVAS_H - CANVAS_H_MARGIN * 2;

      // 画像のサイズが大きい場合にもデフォルトの拡大率の描画エリア内に収まるようにする
      const reductionRatio = Math.min(WIDTH / width, HEIGHT / height, 1.0);
      // 画像をセンタリングするための位置を計算
      const imagePosition = {
        left: CANVAS_W_MARGIN + Math.max(WIDTH - width * reductionRatio, 0) / 2,
        top: CANVAS_H_MARGIN + Math.max(HEIGHT - height * reductionRatio, 0) / 2,
      };

      return {
        ...state,
        initialImageScale: reductionRatio,
        imageScale: reductionRatio,
        imageSize: { width, height },
        // 画像のサイズに応じて拡大率が変更される場合があるためセンタリング位置についても拡大率に応じた調整を行う
        refPoint: {
          left: -imagePosition.left / reductionRatio,
          top: -imagePosition.top / reductionRatio,
        },
        moveState: null,
      };
    }

    case ActionTypes.MOVE_MAP_START: {
      return {
        ...state,
        moveState: {
          mousePointOffset: action.payload,
          refPointOffset: { ...state.refPoint },
        },
      };
    }

    case ActionTypes.MOVE_MAP: {
      const moveState = state.moveState;
      if (moveState == null) return state;

      const newRefPoint = { ...state.refPoint };
      const { left: x, top: y } = action.payload;

      newRefPoint.left = moveState.refPointOffset.left - (x - moveState.mousePointOffset.left);
      newRefPoint.top = moveState.refPointOffset.top - (y - moveState.mousePointOffset.top);

      return { ...state, refPoint: newRefPoint };
    }

    case ActionTypes.MOVE_MAP_END:
      return { ...state, moveState: null };

    default:
      return state;
  }
};

export default floorMapReducer;
