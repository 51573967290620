import React from 'react';
import { Provider as MessageSourceProvider } from 'react-message-source';

import 'moment/locale/ja';
import resources_ja from '@assets/resources/resources_ja';

type Languages = 'ja';

type ResourceProviderProps = {
  children: React.ReactNode;
  lang: Languages;
};

const ResourceProvider = (props: ResourceProviderProps) => {
  let resources;

  switch (props.lang) {
    case 'ja':
    default:
      resources = resources_ja;
      break;
  }

  return <MessageSourceProvider value={resources}>{props.children}</MessageSourceProvider>;
};

export default ResourceProvider;
