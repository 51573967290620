import { UserState, UserAction, UserActionTypes as ActionTypes } from './types';

const initialUserState: UserState = {
  refresh: {},
  isLoading: false,
  isSubmitting: false,
  isNavigating: false,
  isNavigatingTo: null,
  users: [],
  usersListInfo: null,
  user: null,
};

const userReducer = (state: UserState = initialUserState, action: UserAction): UserState => {
  switch (action.type) {
    case ActionTypes.INIT:
      return initialUserState;

    case ActionTypes.REFRESH:
      return { ...state, refresh: {} };

    case ActionTypes.LOADING:
      return { ...state, isLoading: action.payload };

    case ActionTypes.SUBMITTING:
      return { ...state, isSubmitting: action.payload };

    case ActionTypes.NAVIGATING:
      return { ...state, isNavigating: action.payload };

    case ActionTypes.NAVIGATING_TO:
      return { ...state, isNavigatingTo: action.payload };

    case ActionTypes.SET_USERS:
      return { ...state, users: action.payload };

    case ActionTypes.SET_USERS_LIST_INFO:
      return { ...state, usersListInfo: action.payload };

    case ActionTypes.SET_USER:
      return { ...state, user: action.payload };

    default:
      return state;
  }
};

export default userReducer;
