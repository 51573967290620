import { AppState as State } from '@ducks/index';
import { SearchParameter, DailyPlanSchedule, DailyPlanScheduleSummary } from './types';

/**
 * @summary リフレッシュを行うためのstateを返却する
 * @param {State} state
 * @returns {Record<string, never>}
 */
export const getRefreshId = (state: State) => state.plan.dailySchedule.refresh;

/**
 * @summary ローディング状態かを返却する
 * @param {State} state
 * @returns {boolean} ローディング状態か
 */
export const checkIsLoading = (state: State) => state.plan.dailySchedule.isLoading;

/**
 * @summary 送信処理中かを返却する
 * @param {State} state
 * @returns {boolean} 送信処理中か
 */
export const checkIsSubmitting = (state: State) => state.plan.dailySchedule.isSubmitting;

/**
 * @summary ソート番号を返却する
 * @param {State} state
 * @returns {number}
 */
export const getSort: (state: State) => number = (state: State) => state.plan.dailySchedule.sort;

/**
 * @summary 詳細検索条件を返却する
 * @param {State} state
 * @returns {SearchParameter}
 */
export const getSearchParameter: (state: State) => SearchParameter = (state: State) =>
  state.plan.dailySchedule.searchParameter;

/**
 * @summary 日時計画リストを返却する
 * @param {State} state
 * @returns {DailyPlanSchedule[]}
 */
export const getPlans: (state: State) => DailyPlanSchedule[] = (state: State) =>
  state.plan.dailySchedule.plans;

/**
 * @summary 日時計画サマリを返却する
 * @param {State} state
 * @returns {DailyPlanScheduleSummary|null}
 */
export const getSummary: (state: State) => DailyPlanScheduleSummary | null = (state: State) =>
  state.plan.dailySchedule.summary;
