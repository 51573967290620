import { CommonAction, CommonState, CommonActionTypes as ActionTypes } from './types';

const initialCommonState: CommonState = {
  refresh: {},
  isLoading: false,
  isProcessing: false,
  isSubmitting: false,
  selectedTagIds: [],
  /** 建物選択フィールドに表示する建物一覧 */
  propertyList: [],
  /** タグ選択フィールドに表示する建物一覧 */
  propertyListFilteredByTagIds: [],
  selectedPropertyId: null,
  profile: null,
  runtimeError: null,
};

const commonReducer = (
  state: CommonState = initialCommonState,
  action: CommonAction,
): CommonState => {
  switch (action.type) {
    case ActionTypes.REFRESH:
      return { ...state, refresh: {} };

    case ActionTypes.LOADING:
      return { ...state, isLoading: action.payload };

    case ActionTypes.PROCESSING:
      return { ...state, isProcessing: action.payload };

    case ActionTypes.SUBMITTING:
      return { ...state, isSubmitting: action.payload };

    case ActionTypes.SET_SELECTED_TAG_IDS:
      return { ...state, selectedTagIds: action.payload };

    case ActionTypes.SET_PROPERTY_LIST:
      return { ...state, propertyList: action.payload };

    case ActionTypes.SET_PROPERTY_LIST_FILTERED_BY_TAG_IDS:
      return { ...state, propertyListFilteredByTagIds: action.payload };

    case ActionTypes.SET_PROPERTY_ID:
      return { ...state, selectedPropertyId: action.payload };

    case ActionTypes.SET_PROFILE:
      return { ...state, profile: action.payload };

    case ActionTypes.SET_RUNTIME_ERROR:
      return { ...state, runtimeError: action.payload };

    default:
      return state;
  }
};

export default commonReducer;
