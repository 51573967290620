import {
  Construction,
  ConstructionAction as Action,
  ConstructionActionTypes as ActionTypes,
  ConstructionsListData,
  ConstructionsListDisplayStatus,
  ConstructionInfoData,
  ConstructionCommentsData,
} from './types';

export const init = (): Action => ({ type: ActionTypes.INIT });

export const refresh = (): Action => ({ type: ActionTypes.REFRESH });

export const refresh2 = (): Action => ({ type: ActionTypes.REFRESH2 });

export const refresh3 = (): Action => ({ type: ActionTypes.REFRESH3 });

export const setIsLoading = (isLoading: boolean): Action => ({
  type: ActionTypes.LOADING,
  payload: isLoading,
});

export const setIsLoading2 = (isLoading2: boolean): Action => ({
  type: ActionTypes.LOADING2,
  payload: isLoading2,
});

export const setIsSubmitting = (isSubmitting: boolean): Action => ({
  type: ActionTypes.SUBMITTING,
  payload: isSubmitting,
});

export const setIsInvalidToken = (isInvalidToken: boolean): Action => ({
  type: ActionTypes.INVALID,
  payload: isInvalidToken,
});

/** @summary 工事一覧のstate変更 */
export const setConstructionsList = (constructionsAllList: ConstructionsListData[]): Action => ({
  type: ActionTypes.SET_CONSTRUCTIONS_LIST,
  payload: constructionsAllList,
});

/** @summary 工事一覧画面statusのstate変更 */
export const setConstructionsListDisplayStatus = (
  constructionsListDisplayStatus: ConstructionsListDisplayStatus | null,
): Action => ({
  type: ActionTypes.SET_CONSTRUCTIONS_LIST_DISPLAY_STATUS,
  payload: constructionsListDisplayStatus,
});

export const setConstruction = (data: Construction | null): Action => ({
  type: ActionTypes.SET_CONSTRUCTION,
  payload: data,
});

/** @summary 工事-連絡事項情報取得のstate変更 */
export const setConstructionInfo = (constructionInfo: ConstructionInfoData | null): Action => ({
  type: ActionTypes.SET_CONSTRUCTIONS_INFO,
  payload: constructionInfo,
});

export const setConstructionComments = (
  constructionsCommentsData: ConstructionCommentsData | null,
): Action => ({
  type: ActionTypes.SET_CONSTRUCTIONS_COMMENTS,
  payload: constructionsCommentsData,
});
