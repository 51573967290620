import {
  PlanAdjustmentState,
  PlanAdjustmentAction,
  PlanAdjustmentActionTypes as ActionTypes,
} from './types';

const initialPlanAdjustmentState: PlanAdjustmentState = {
  refresh: {},
  isLoading: false,
  isSubmitting: false,
  isInvalidToken: false,
  plans: [],
  summary: {
    month: '2024/04',
    comment: '',
  },
};

const planAdjustmentReducer = (
  state: PlanAdjustmentState = initialPlanAdjustmentState,
  action: PlanAdjustmentAction,
): PlanAdjustmentState => {
  switch (action.type) {
    case ActionTypes.INIT:
      return initialPlanAdjustmentState;

    case ActionTypes.REFRESH:
      return { ...state, refresh: {} };

    case ActionTypes.LOADING:
      return { ...state, isLoading: action.payload };

    case ActionTypes.SUBMITTING:
      return { ...state, isSubmitting: action.payload };

    case ActionTypes.INVALID:
      return { ...state, isInvalidToken: action.payload };

    case ActionTypes.SET_PLANS:
      return { ...state, plans: action.payload };

    case ActionTypes.SET_SUMMARY:
      return { ...state, summary: action.payload };

    default:
      return state;
  }
};

export default planAdjustmentReducer;
