import axios from '@providers/axiosProvider';

import * as actions from './actions';
import { APIGetHomeResponse, HomeData } from './types';

export const getHomeSummaryAsync =
  (propertyId: number | null, tagIdsStr: string | null) => async (dispatch: any) => {
    dispatch(actions.setIsLoading(true));

    const params = { property_id: propertyId, tag: tagIdsStr };

    await axios
      .get<APIGetHomeResponse>(`/home`, { params })
      .then((response) => {
        const res = response.data.result;
        const data: HomeData = {
          /** 本日の定期業務 */
          tasks: res.task.task_list.map((task) => ({
            inspectionResultId: task.inspection_result_id,
            contractId: task.contract_id,
            contractTitle: task.contract_title,
            propertyId: task.property_id,
            propertyName: task.property_name,
            statusId: task.status_id,
            statusName: task.status_name,
            startDatetime: task.start_datetime,
            updateDatetime: task.update_datetime,
            typeId: task.type_id,
            typeName: task.type_name,
            latestComment: task.latest_comment,
            supervisors: task.supervisors.map((supervisor) => ({
              userId: supervisor.user_id,
              userName: supervisor.user_name,
            })),
            workers: task.workers.map((worker) => ({
              userId: worker.user_id,
              userName: worker.user_name,
            })),
            checkUserId: task.check_user_id,
            checkUserName: task.check_user_name,
          })),
          tasksCount: res.task.count,

          /** 査収待ちの定期業務 */
          checkingTasks: res.checking_task.task_list.map((task) => ({
            inspectionResultId: task.inspection_result_id,
            contractId: task.contract_id,
            contractTitle: task.contract_title,
            propertyId: task.property_id,
            propertyName: task.property_name,
            statusId: task.status_id,
            statusName: task.status_name,
            startDatetime: task.start_datetime,
            updateDatetime: task.update_datetime,
            typeId: task.type_id,
            typeName: task.type_name,
            latestComment: task.latest_comment,
            supervisors: task.supervisors.map((supervisor) => ({
              userId: supervisor.user_id,
              userName: supervisor.user_name,
            })),
            workers: task.workers.map((worker) => ({
              userId: worker.user_id,
              userName: worker.user_name,
            })),
            checkUserId: task.check_user_id,
            checkUserName: task.check_user_name,
          })),
          checkingTasksCount: res.checking_task.count,

          /** 未完了の工事 */
          incompleteConstruction: res.incomplete_construction.construction_list.map(
            (construction) => ({
              constructionId: construction.construction_id,
              contractTitle: construction.contract_title,
              propertyId: construction.property_id,
              propertyName: construction.property_name,
              statusId: construction.status_id,
              statusName: construction.status_name,
              startDate: construction.start_date,
              updateDatetime: construction.update_datetime,
              typeId: construction.type_id,
              typeName: construction.type_name,
              latestComment: construction.latest_comment,
              supervisors: construction.supervisors.map((supervisor) => ({
                userId: supervisor.user_id,
                userName: supervisor.user_name,
              })),
              workers: construction.workers.map((worker) => ({
                userId: worker.user_id,
                userName: worker.user_name,
              })),
              reporterId: construction.reporter_id,
              reporterName: construction.reporter_name,
            }),
          ),
          incompleteConstructionCount: res.incomplete_construction.count,

          /** 査収待ちの工事 */
          checkingConstruction: res.checking_construction.construction_list.map((construction) => ({
            constructionId: construction.construction_id,
            contractTitle: construction.contract_title,
            propertyId: construction.property_id,
            propertyName: construction.property_name,
            statusId: construction.status_id,
            statusName: construction.status_name,
            startDate: construction.start_date,
            updateDatetime: construction.update_datetime,
            typeId: construction.type_id,
            typeName: construction.type_name,
            latestComment: construction.latest_comment,
            supervisors: construction.supervisors.map((supervisor) => ({
              userId: supervisor.user_id,
              userName: supervisor.user_name,
            })),
            workers: construction.workers.map((worker) => ({
              userId: worker.user_id,
              userName: worker.user_name,
            })),
            reporterId: construction.reporter_id,
            reporterName: construction.reporter_name,
          })),
          checkingConstructionCount: res.checking_construction.count,

          /** 未完了の案件 */
          incompleteTrouble: res.incomplete_trouble.trouble_list.map((trouble) => ({
            troubleId: trouble.trouble_id,
            title: trouble.title,
            statusId: trouble.status_id,
            statusName: trouble.status_name,
            priorityId: trouble.priority_id,
            priorityName: trouble.priority_name,
            occurrenceDate: trouble.occurrence_date,
            occurrenceTime: trouble.occurrence_date,
            updateDatetime: trouble.update_datetime,
            troubleType: trouble.trouble_type,
            troubleTypeName: trouble.trouble_type_name,
            latestComment: trouble.latest_comment,
            picUserId: trouble.pic_user_id,
            picUserName: trouble.pic_user_name,
            propertyId: trouble.property_id,
            propertyName: trouble.property_name,
          })),
          incompleteTroubleCount: res.incomplete_trouble.count,

          /** 査収待ちの案件 */
          checkingTrouble: res.checking_trouble.trouble_list.map((trouble) => ({
            troubleId: trouble.trouble_id,
            title: trouble.title,
            statusId: trouble.status_id,
            statusName: trouble.status_name,
            priorityId: trouble.priority_id,
            priorityName: trouble.priority_name,
            occurrenceDate: trouble.occurrence_date,
            occurrenceTime: trouble.occurrence_time,
            updateDatetime: trouble.update_datetime,
            troubleType: trouble.trouble_type,
            troubleTypeName: trouble.trouble_type_name,
            latestComment: trouble.latest_comment,
            picUserId: trouble.pic_user_id,
            picUserName: trouble.pic_user_name,
            propertyId: trouble.property_id,
            propertyName: trouble.property_name,
          })),
          checkingTroubleCount: res.checking_trouble.count,
        };
        dispatch(actions.setData(data));
      })
      .catch(() => {});

    dispatch(actions.setIsLoading(false));
  };
