import {
  CommonAction as Action,
  CommonActionTypes as ActionTypes,
  Profile,
  PropertyListWithIsFavorite,
  RuntimeError,
} from './types';

export const refresh = (): Action => ({ type: ActionTypes.REFRESH });

export const setIsLoading = (isLoading: boolean): Action => ({
  type: ActionTypes.LOADING,
  payload: isLoading,
});

export const setIsProcessing = (isProcessing: boolean): Action => ({
  type: ActionTypes.PROCESSING,
  payload: isProcessing,
});

export const setIsSubmitting = (isSubmitting: boolean): Action => ({
  type: ActionTypes.SUBMITTING,
  payload: isSubmitting,
});

/** 建物選択用建物リスト */
export const setPropertyList = (data: PropertyListWithIsFavorite[]): Action => ({
  type: ActionTypes.SET_PROPERTY_LIST,
  payload: data,
});

/** タグ選択用建物リスト */
export const setPropertyListFilteredByTagIds = (data: PropertyListWithIsFavorite[]): Action => ({
  type: ActionTypes.SET_PROPERTY_LIST_FILTERED_BY_TAG_IDS,
  payload: data,
});

export const setSelectedTagIds = (data: number[]): Action => ({
  type: ActionTypes.SET_SELECTED_TAG_IDS,
  payload: data,
});

export const setPropertyId = (id: number | null): Action => ({
  type: ActionTypes.SET_PROPERTY_ID,
  payload: id,
});

export const setProfile = (data: Profile | null): Action => ({
  type: ActionTypes.SET_PROFILE,
  payload: data,
});

export const setRuntimeError = (data: RuntimeError | null): Action => ({
  type: ActionTypes.SET_RUNTIME_ERROR,
  payload: data,
});
