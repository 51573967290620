import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { TagTypeIdNum } from '../tags/types';

/**
 * @summary 連絡先一覧画面で管理されるState
 */
export type AffiliateState = {
  refresh: Record<string, never>;
  /** Load中か */
  isLoading: boolean;
  /** 送信処理中か */
  isSubmitting: boolean;
  /** ページ遷移を行うか */
  isNavigating: boolean;
  /** 遷移先のurl */
  navigatingTo: string | null;
  /** ページ番号 */
  page: number;
  /** ソート番号 */
  sort: number;
  /** 詳細検索条件 */
  searchParameter: SearchParameter;
  /** 検索タグID(連絡先) */
  tagIdList: number[];
  /** 連絡先ユーザリスト */
  userList: User[];
  /** 連絡先ユーザ詳細 */
  userDetail: UserDetail | null;
  /** 連絡先企業リスト */
  companyList: Company[];
  /** 連絡先企業詳細 */
  companyDetail: CompanyDetail | null;
  /** Pager情報 */
  displayParams: DisplayParams;
  /** 自動補完住所 */
  addressAutofill: Address | null;
};

/**
 * @summary 連絡先一覧画面のAction Type
 */
export enum AffiliateActionTypes {
  INIT = 'affiliate/init',
  REFRESH = 'affiliate/refresh',
  LOADING = 'affiliate/set/isLoading',
  SUBMITTING = 'affiliate/set/isSubmitting',
  NAVIGATING = 'affiliate/set/navigating',
  SET_NAVIGATING_TO = 'affiliate/set/navigating_to',
  SET_PAGE = 'affiliate/set/page',
  SET_SORT = 'affiliate/set/sort',
  SET_SEARCH_PARAMETER = 'affiliate/set/search_parameter',
  SET_SEARCH_TAG_ID_LIST = 'affiliate/set/search/tag_id_list',
  SET_USER_LIST = 'affiliate/set/user_list',
  SET_USER = 'affiliate/set/user',
  SET_COMPANY_LIST = 'affiliate/set/company_list',
  SET_COMPANY = 'affiliate/set/company',
  SET_DISPLAY_PARAMS = 'affiliate/set/display_params',
  SET_ADDRESS_AUTOFILL = 'affiliate/set/address_autofill',
}

/**
 * @summary 連絡先一覧画面のAction
 */
export type AffiliateAction =
  /** 初期化 */
  | Action<AffiliateActionTypes.INIT>
  /** リフレッシュ */
  | Action<AffiliateActionTypes.REFRESH>
  /** Loading状態を保持 */
  | PayloadAction<boolean, AffiliateActionTypes.LOADING>
  /** 送信処理中かを保持 */
  | PayloadAction<boolean, AffiliateActionTypes.SUBMITTING>
  /** ページ遷移を行うかを保持 */
  | PayloadAction<boolean, AffiliateActionTypes.NAVIGATING>
  /** 遷移先の詳細ページのIDを保持 */
  | PayloadAction<string | null, AffiliateActionTypes.SET_NAVIGATING_TO>
  /** ページ番号を保持 */
  | PayloadAction<number, AffiliateActionTypes.SET_PAGE>
  /** ソート番号を保持 */
  | PayloadAction<number, AffiliateActionTypes.SET_SORT>
  /** 詳細検索条件を保持 */
  | PayloadAction<SearchParameter, AffiliateActionTypes.SET_SEARCH_PARAMETER>
  /** 検索タグIDを保持 */
  | PayloadAction<number[], AffiliateActionTypes.SET_SEARCH_TAG_ID_LIST>
  /** 連絡先ユーザリストを保持 */
  | PayloadAction<User[], AffiliateActionTypes.SET_USER_LIST>
  /** 連絡先ユーザを保持 */
  | PayloadAction<UserDetail | null, AffiliateActionTypes.SET_USER>
  /** 連絡先企業リストを保持 */
  | PayloadAction<Company[], AffiliateActionTypes.SET_COMPANY_LIST>
  /** 連絡先企業を保持 */
  | PayloadAction<CompanyDetail | null, AffiliateActionTypes.SET_COMPANY>
  /** Pager情報を保持 */
  | PayloadAction<DisplayParams, AffiliateActionTypes.SET_DISPLAY_PARAMS>
  /** 自動補完住所を保持 */
  | PayloadAction<Address | null, AffiliateActionTypes.SET_ADDRESS_AUTOFILL>;

/**
 * @summary 連絡先一覧の詳細検索パラメータ
 */
export type SearchParameter = {
  /** 企業名 */
  affiliate_company_name?: string;
  /** 名 */
  name?: string;
  /** 所属 */
  department?: string;
  /** 役職 */
  position?: string;
  /** 住所 */
  address?: string;
  /** 業種 */
  industries?: string;
  /** 連絡先 - 名 */
  user_name?: string;
  /** 企業 - 名 */
  company_name?: string;
  /** 連絡先 - 住所 */
  user_address?: string;
  /** 企業 - 住所 */
  company_address?: string;
};

/**
 * @summary 連絡先一覧でユーザと企業のいずれかを表すtype
 */
export type AffiliateType =
  /** ユーザ */
  | 'USER'
  /** 企業 */
  | 'COMPANY';

/**
 * @summary 連絡先の編集と新先作成のいずれかを表すmode
 */
export type AffiliateEditMode =
  /** 編集 */
  | 'EDIT'
  /** 新規作成 */
  | 'NEW';

/**
 * @summary 連絡先リストの取得APIで指定できるリクエストパラメータ
 */
export type APIGetAffiliatesRequestParams = {
  keyword?: string;
  page?: number;
  size_per_page?: number;
  sort?: number;
  tag?: string | null;
} & SearchParameter;

/**
 * @summary APIから返却される連絡先ユーザリストのプロパティ
 */
export type APIGetAffiliateUserListResponse = {
  result: {
    /** ユーザリスト */
    affiliate_user_list: User[];
    /** Pager情報 */
    display_params: DisplayParams;
  };
};

/**
 * @summary APIから返却される連絡先(ユーザ)のプロパティ
 */
export type User = {
  /** ユーザID */
  affiliate_user_id: number;
  /** ユーザ名 */
  affiliate_user_name: string;
  /** 企業ID */
  company_id: number;
  /** 企業名 */
  company_name: string;
  /** 部署名 */
  department: string;
  /** 役職 */
  position: string;
  /** 電話番号 */
  tel: string;
  /** 電話番号2 */
  tel2: string;
  /** 携帯電話番号 */
  cell_phone: string;
  /** FAX */
  fax: string;
  /** メールアドレス */
  email_address: string;
  /** URL */
  url: string;
  /** 住所 */
  address: string;
  /** 作成者ID */
  create_user_id: number;
  /** 作成者名 */
  create_user_name: string;
  /** 作成日 */
  create_datetime: string;
  /** 更新者ID */
  update_user_id: number;
  /** 更新者名 */
  update_user_name: string;
  /** 更新日 */
  update_datetime: string;
};

/**
 * @summary APIから返却される連絡先企業リストのプロパティ
 */
export type APIGetAffiliateCompanyListResponse = {
  result: {
    /** ユーザリスト */
    affiliate_company_list: Company[];
    /** Pager情報 */
    display_params: DisplayParams;
  };
};

/**
 * @summary APIから返却される連絡先企業のプロパティ
 */
export type Company = {
  /** 企業ID */
  company_id: number;
  /** 企業名 */
  company_name: string;
  /** URL */
  url: string;
  /** 住所 */
  address: string;
  /** 電話番号 */
  tel: string;
  /** 代表者氏名 */
  representative_name: string;
  /** 業種 */
  industries: string;
  /** 連絡先数（会社に所属する連絡先の件数） */
  member_num: number;
  /** 過去の作業実施数（承認済みデータのみ） */
  past_work_count: number;
  /** 契約件数（協力会社として紐づく契約の件数） */
  contract_count: number;
  /** 更新者名 */
  update_user_name: string;
  /** 更新日 */
  update_datetime: string;
};

/**
 * @summary APIから返却される連絡先(ユーザ)のプロパティ
 */
export type APIGetAffiliateUserResponse = {
  result: UserDetail;
};

/**
 * @summary APIから返却される連絡先(ユーザ)詳細のプロパティ
 */
export type UserDetail = {
  /** 姓 */
  last_name: string;
  /** 名 */
  first_name: string;
  /** 姓(カナ) */
  kana_last: string;
  /** 名(カナ) */
  kana_first: string;
  /** 企業ID */
  company_id: number | null;
  /** 企業名 */
  company_name: string | null;
  /** 部署名 */
  department: string;
  /** 役職 */
  position: string;
  /** 電話番号 */
  tel: string;
  /** 電話番号2 */
  tel2: string;
  /** 携帯電話番号 */
  cell_phone: string;
  /** FAX */
  fax: string;
  /** メールアドレス */
  email_address: string;
  /** URL */
  url: string;
  /** 郵便番号 */
  zip_code: string;
  /** 都道府県ID */
  prefecture_id: number;
  /** 都道府県名 */
  prefecture_name: string;
  /** 市区町村 */
  municipalities: string;
  /** 町域・番地 */
  block_address: string;
  /** ビル名 */
  property_name: string;
  /** タグ情報 */
  tags:
    | {
        /** タグID */
        tag_id: number;
        /** タグ名 */
        tag_name: string;
        /** タグ種別ID */
        type_id: TagTypeIdNum;
        /** タグ種別名 */
        type_name: string;
      }[]
    | null;
  /** 備考 */
  remarks: string;
  /** 作成者ID */
  create_user_id: number;
  /** 作成者名 */
  create_user_name: string;
  /** 作成日 */
  create_datetime: string;
  /** 更新者ID */
  update_user_id: number;
  /** 更新者名 */
  update_user_name: string;
  /** 更新日 */
  update_datetime: string;
};

/**
 * @summary APIから返却される連絡先企業のプロパティ
 */
export type APIGetAffiliateCompanyResponse = {
  result: CompanyDetail;
};

/**
 * @summary APIから返却される連絡先企業詳細のプロパティ
 */
export type CompanyDetail = {
  /** 企業ID */
  company_id: number;
  /** 企業名 */
  company_name: string;
  /** URL */
  url: string;
  /** 郵便番号 */
  zip_code: string;
  /** 都道府県ID */
  prefecture_id: number;
  /** 都道府県名 */
  prefecture_name: string;
  /** 市区町村 */
  municipalities: string;
  /** 町域・番地 */
  block_address: string;
  /** ビル名 */
  property_name: string;
  /** 電話番号 */
  tel: string;
  /** 姓 */
  last_name: string;
  /** 名 */
  first_name: string;
  /** 姓(カナ) */
  kana_last: string;
  /** 名(カナ) */
  kana_first: string;
  /** 業種 */
  industries: string;
  /** 連絡先数（会社に所属する連絡先の件数） */
  member_num: number;
  /** 過去の作業実施数（承認済みデータのみ） */
  past_work_count: number;
  /** 契約件数（協力会社として紐づく契約の件数） */
  contract_count: number;
  /** タグ情報 */
  tags:
    | {
        /** タグID */
        tag_id: number;
        /** タグ名 */
        tag_name: string;
        /** タグ種別ID */
        type_id: number;
        /** タグ種別名 */
        type_name: string;
      }[]
    | null;
  /** 備考 */
  remarks: string;
  /** 更新者名 */
  update_user_name: string;
  /** 更新日 */
  update_datetime: string;
};

/**
 * @summary 合計件数等Pagerの表示で使用する情報
 */
export type DisplayParams = {
  /** 連絡先数 */
  total: number;
  /** 全体ページ数 */
  total_page: number;
  /** 現在のページ */
  current_page: number;
  /** ソート番号 */
  sort: number;
} | null;

/**
 * @summary 連絡先ユーザの編集、登録で使用するフォームデータ
 */
export type AffiliateUserFormData = {
  /** 姓 */
  last_name: string;
  /** 名 */
  first_name: string;
  /** 姓(カナ) */
  kana_last: string;
  /** 名(カナ) */
  kana_first: string;
  /** 企業ID */
  company_id: string | null;
  /** 部署名 */
  department: string;
  /** 役職 */
  position: string;
  /** TEL1 */
  tel: string;
  /** TEL2 */
  tel2: string;
  /** 携帯電話番号 */
  cell_phone: string;
  /** FAX */
  fax: string;
  /** メールアドレス */
  email_address: string;
  /** URL */
  url: string;
  /** 郵便番号 */
  zip_code: string;
  /** 都道府県 */
  prefecture_id: string | number | null;
  /** 市区町村 */
  municipalities: string;
  /** 町域・番地 */
  block_address: string;
  /** ビル名 */
  property_name: string;
  /** タグ情報 */
  tags: {
    /** タグID */
    tag_id: number;
    /** タグ種別 */
    type_id?: number;
  }[];
  /** 備考 */
  remarks: string;
};

/**
 * @summary 連絡先企業の編集、登録で使用するフォームデータ
 */
export type AffiliateCompanyFormData = {
  /** 企業名 */
  company_name: string;
  /** URL */
  url: string;
  /** 郵便番号 */
  zip_code: string;
  /** 都道府県 */
  prefecture_id: string | number | null;
  /** 市区町村 */
  municipalities: string;
  /** 町域・番地 */
  block_address: string;
  /** ビル名 */
  property_name: string;
  /** 電話番号 */
  tel: string;
  /** 代表者姓 */
  last_name: string;
  /** 代表者名 */
  first_name: string;
  /** 代表者姓(カナ) */
  kana_last: string;
  /** 代表者名(カナ) */
  kana_first: string;
  /** 業種 */
  industries: string;
  /** タグ情報 */
  tags: {
    /** タグID */
    tag_id: number;
    /** タグ種別 */
    type_id?: number;
  }[];
  /** 備考 */
  remarks: string;
};

/**
 * @summary 住所情報
 */
export type Address = {
  /** 都道府県名 */
  address1: string;
  /** 市区名 */
  address2: string;
  /** 町村名 */
  address3: string;
  /** 住所コード */
  prefCode: string;
  /** 郵便番号 */
  zipCode: string;
};

/**
 * @summary 住所取得APIから返却される住所のプロパティ
 */
export type APIAddress = {
  /** 都道府県名 */
  readonly address1: string;
  /** 市区名 */
  readonly address2: string;
  /** 町村名 */
  readonly address3: string;
  /** 住所コード */
  readonly prefcode: string;
  /** 郵便番号 */
  readonly zipcode: string;
};

/**
 * @summary 住所取得APIから返却されるプロパティ
 */
export type APIGetAddressResponse = {
  /** メッセージ */
  messages: string;
  /** ステータスコード */
  status: number;
  /** 住所情報 */
  results: APIAddress[];
};

/**
 * @summary 一括登録に使用するCSVフォーマットの取得APIから返却されるレスポンス
 */
export type APIDownloadBulkCreateFormatResponse = {
  result: {
    file_name: string;
    file_content: string;
  };
};
