import {
  DailyPlanSchedule,
  DailyPlanScheduleSummary,
  PlanDailyScheduleAction as Action,
  PlanDailyScheduleActionTypes as ActionTypes,
  SearchParameter,
} from './types';

/**
 * @summary 初期化処理
 * @returns {Action}
 */
export const init = (): Action => ({ type: ActionTypes.INIT });

/**
 * @summary リフレッシュ処理
 * @returns {Action}
 */
export const refresh = (): Action => ({ type: ActionTypes.REFRESH });

/**
 * @summary Loading状態を保持
 * @param {boolean} isLoading
 * @returns {Action}
 */
export const setIsLoading = (isLoading: boolean): Action => ({
  type: ActionTypes.LOADING,
  payload: isLoading,
});

/**
 * @summary 送信処理中かを保持
 * @param {boolean} isSubmitting
 * @returns {Action}
 */
export const setIsSubmitting = (isSubmitting: boolean): Action => ({
  type: ActionTypes.SUBMITTING,
  payload: isSubmitting,
});

/**
 * @summary ソート番号を保持
 * @param {number} sort
 * @returns {Action}
 */
export const setSort = (sort: number): Action => ({
  type: ActionTypes.SET_SORT,
  payload: sort,
});

/**
 * @summary 詳細検索条件を保持
 * @param {SearchParameter} searchParameter
 * @returns {Action}
 */
export const setSearchParameter = (searchParameter: SearchParameter): Action => ({
  type: ActionTypes.SET_SEARCH_PARAMETER,
  payload: searchParameter,
});

/**
 * @summary 日時計画リストを保持
 * @param {DailyPlanSchedule[]} plans
 * @returns {Action}
 */
export const setPlans = (plans: DailyPlanSchedule[]): Action => ({
  type: ActionTypes.SET_PLANS,
  payload: plans,
});

/**
 * @summary 日時計画サマリを保持
 * @param {DailyPlanScheduleSummary | null} summary
 * @returns {Action}
 */
export const setSummary = (summary: DailyPlanScheduleSummary | null): Action => ({
  type: ActionTypes.SET_SUMMARY,
  payload: summary,
});
