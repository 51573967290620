import reducer from './reducers';

import * as tagSelectors from './selectors';
import * as tagOperations from './operations';
import { TagState } from './types';

export type { TagState };
export { tagSelectors, tagOperations };

export default reducer;
