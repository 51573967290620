import { AppState as State } from '@ducks/index';

/** @summary 連絡先リストを返却 */
export const getUserList = (state: State) => state.selectAffiliate.userList;

/** @summary 企業リストを返却 */
export const getCompanyList = (state: State) => state.selectAffiliate.companyList;

/** @summary 建物担当者リストを返却 */
export const getPropertyUserList = (state: State) => state.selectAffiliate.propertyUserList;

/** @summary 選択タブを返却 */
export const getTab = (state: State) => state.selectAffiliate.tab;

/** @summary 建物担当者の取得APIに付与するパラメータを返却 */
export const getPropertyUserParameter = (state: State) => state.selectAffiliate.propertyUserParameter;

/** @summary 選択状態の連絡先|企業|建物担当者を返却 */
export const getSelectedItem = (state: State) => state.selectAffiliate.selectedItem;

/** @summary 選択確定済の連絡先の種類を返却 */
export const getSubmitItemType = (state: State) => state.selectAffiliate.submitItemType;

/** @summary 選択確定済の連絡先を返却 */
export const getSubmitUserItem = (state: State) => state.selectAffiliate.submitUserItem;

/** @summary 選択確定済の企業を返却 */
export const getSubmitCompanyItem = (state: State) => state.selectAffiliate.submitCompanyItem;

/** @summary 選択確定済の建物担当者を返却 */
export const getSubmitPropertyUserItem = (state: State) => state.selectAffiliate.submitPropertyUserItem;
