import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import { AppState as State } from '@ducks/index';
import { OptionItem } from '@uiParts/selectBox';

export const getRefreshId = (state: State) => state.equipment.refresh;

export const checkIsLoading = (state: State) => state.equipment.isLoading;
export const checkIsLoading2 = (state: State) => state.equipment.isLoading2;
export const checkIsSubmitting = (state: State) => state.equipment.isSubmitting;
export const checkIsNavigating = (state: State) => state.equipment.isNavigating;
export const checkIsNavigatingTo = (state: State) => state.equipment.isNavigatingTo;

export const getEquipments = (state: State) => state.equipment.equipments;
export const getEquipmentsListInfo = (state: State) => state.equipment.equipmentsListInfo;

export const getEquipment = (state: State) => state.equipment.equipment;
export const getEquipmentHistory = (state: State) => state.equipment.equipmentHistory;
export const getEquipmentSqsesData = (state: State) => state.equipment.equipmentSqsesData;

export const checkEquipmentHistoryLoaded = (state: State) => state.equipment.equipmentHistoryLoaded;
export const checkEquipmentSqsesDataLoaded = (state: State) =>
  state.equipment.equipmentSqsesDataLoaded;

export const getEquipmentTypeOptions = (state: State) =>
  state.equipment.optionsForEdit?.equipmentTypeOptions ?? [];
export const getDiagramOptions = (state: State) =>
  state.equipment.optionsForEdit?.diagramOptions ?? [];
export const getFloorOptions = (state: State): OptionItem[] =>
  state.equipment.optionsForEdit?.floorOptions.map((v) => _.omit(v, ['roomOptions'])) ?? [];
export const getRoomOptions = (floorId: number | null) =>
  createSelector(
    [(state: State) => state.equipment.optionsForEdit?.floorOptions ?? []],
    (floorOptions) => floorOptions.find((v) => v.value === floorId)?.roomOptions ?? [],
  );
