import type { Action, PayloadAction } from '@reduxjs/toolkit';

import {
  APIInspectionChecklists,
  InspectionChecklistsList,
} from '@ducks/inspectionChecklist/types';

export enum InspectionActionTypes {
  INIT = 'inspection/init',
  REFRESH = 'inspection/refresh',
  REFRESH_NOTIFICATION_TAB = 'inspection/refreshNotificationTab',
  REFRESH_WORK_RESULT_TAB = 'inspection/refreshWorkResultTab',
  REFRESH_COMMENT_TAB = 'inspection/refreshCommentTab',
  LOADING = 'inspection/set/isLoading',
  LOADING_TAB = 'inspection/set/isLoadingTab',
  SUBMITTING = 'inspection/set/isSubmitting',
  NAVIGATING = 'inspection/set/isNavigating',
  NAVIGATING2 = 'inspection/set/isNavigating2',
  NAVIGATING_TO = 'inspection/set/isNavigatingTo',
  SET_INSPECTIONS = 'inspection/set/inspections',
  SET_INSPECTIONS_LIST_INFO = 'inspection/set/inspectionsListInfo',
  SET_INSPECTION = 'inspection/set/inspection',
  SET_PREV_INSPECTION = 'inspection/set/prevInspection',
  SET_INSPECTION_CHECKLISTS = 'inspection/set/inspectionChecklists',
  SET_PHOTOS = 'inspection/set/photos',
  SET_PHOTO = 'inspection/set/photo',
  SET_DELETE_PHOTO = 'inspection/set/deletePhoto',
  APPROVE_SUBMITTING = 'inspection/set/inspections/isApproveSubmitting',
  SET_INSPECTIONS_APPROVED = 'inspection/set/inspections/isApproved',
  // 基本情報タブ
  SET_INSPECTION_BASIC = 'inspection/set/inspectionBasic',
  SET_INSPECTION_INFO = 'inspection/set/inspectionInfo',
  SET_INSPECTION_COMMENTS = 'inspection/set/inspectionComments',
  INVALID = 'inspection/set/isInvalidToken',
}

/**
 * ReduxやRedux Toolkitなどの状態管理ライブラリで使用されるアクションタイプを定義。
 * Reduxでは、アプリケーションの状態（state）を変更するためにアクションを使用する。
 * 各アクションタイプは、特定の状態更新をトリガーするための識別子
 */
export type InspectionAction =
  | Action<InspectionActionTypes.INIT>
  | Action<InspectionActionTypes.REFRESH>
  | Action<InspectionActionTypes.REFRESH_NOTIFICATION_TAB>
  | Action<InspectionActionTypes.REFRESH_WORK_RESULT_TAB>
  | Action<InspectionActionTypes.REFRESH_COMMENT_TAB>
  | PayloadAction<boolean, InspectionActionTypes.LOADING>
  // タブで利用するAPIをロード中のかどうか判定
  | PayloadAction<boolean, InspectionActionTypes.LOADING_TAB>
  | PayloadAction<boolean, InspectionActionTypes.SUBMITTING>
  | PayloadAction<boolean, InspectionActionTypes.NAVIGATING>
  | PayloadAction<boolean, InspectionActionTypes.NAVIGATING2>
  | PayloadAction<number | null, InspectionActionTypes.NAVIGATING_TO>
  | PayloadAction<InspectionsList[], InspectionActionTypes.SET_INSPECTIONS>
  | PayloadAction<InspectionsListInfo | null, InspectionActionTypes.SET_INSPECTIONS_LIST_INFO>
  | PayloadAction<InspectionResponseItem | null, InspectionActionTypes.SET_INSPECTION>
  | PayloadAction<InspectionResponseItem | null, InspectionActionTypes.SET_PREV_INSPECTION>
  | PayloadAction<InspectionChecklistsList[], InspectionActionTypes.SET_INSPECTION_CHECKLISTS>
  | PayloadAction<Photo[], InspectionActionTypes.SET_PHOTOS>
  | PayloadAction<Photo, InspectionActionTypes.SET_PHOTO>
  | PayloadAction<Photo, InspectionActionTypes.SET_DELETE_PHOTO>
  | PayloadAction<{ id: number; isSubmitting: boolean }, InspectionActionTypes.APPROVE_SUBMITTING>
  | PayloadAction<
      { id: number; isApproved: boolean },
      InspectionActionTypes.SET_INSPECTIONS_APPROVED
    >
  // 基本情報タブで利用する
  | PayloadAction<InspectionBasic | null, InspectionActionTypes.SET_INSPECTION_BASIC>
  /** @summary 定期業務-連絡事項情報State取得 */
  | PayloadAction<InspectionInfoData | null, InspectionActionTypes.SET_INSPECTION_INFO>
  /** @summary 定期業務-コメント一覧取得State取得 */
  | PayloadAction<InspectionCommentsData | null, InspectionActionTypes.SET_INSPECTION_COMMENTS>
  /** @summary guest API を呼び出してAPIエラー（トークン期限切れの場合は）画面で無効メッセージ表示する仕掛け */
  | PayloadAction<boolean, InspectionActionTypes.INVALID>;

// -- ReactのState Types ---------------------------------------------------------- //
export type InspectionsListQuery = Partial<{
  title: string;
  /**
   * @summary 定期業務種別
   */
  type: number;
  /**
   * @summary 定期業務区分
   */
  division: number;
  /**
   * @summary 対応状況
   */
  status: number;
  /**
   * @summary 監督者（名前検索）
   */
  supervisor: string;
  /**
   * @summary 作業者（名前検索）
   */
  worker: string;
  /**
   * @summary 承認者（名前検索）
   */
  approval_user: string;
  /**
   * @summary 協力会社（名前検索）
   */
  company: string;
  /**
   * @summary 開始日（From）
   */
  start_since: string;
  /**
   * @summary 開始日（To）
   */
  start_until: string;
  /**
   * @summary 終了日（From）
   */
  end_since: string;
  /**
   * @summary 終了日（To）
   */
  end_until: string;
}>;

/**
 * @summary 定期業務一覧（旧点検結果一覧）画面の表示値
 * Responseを このtype Inspectionにindex.tsxで代入
 * operationsのAPIから取得したレスポンスを以下の型にあてはめて、Reduxのステートに送る。
 * Reduxのペイロードの型は、以下のtypeを受け取るように型定義されている。
 */
export type InspectionItem = {
  propertyId: number;
  propertyName: string;
  inspectionResultId: number;
  inspectionChecklistId: number;
  inspectionChecklistTitle: string;
  inspectionTypeName: string;
  inspectionStatusName: string;
  checkUserId: number;
  checkUserName: string;
  checkDate: string | null;
  scheduledDate: string;
  description: string;
  inspectionType: number;
  status: number;
  approvalDate: string;
  approvedUserId: number;
  // 承認者
  approvedUserName: string;
  updateUserId: number;
  updateUserName: string;
  // 最終更新日
  updateDatetime: string;
  isSubmitted: boolean;
  remarks: string;
  layout: Layout;
  data: Data[];
  inspection_result_list: APIInspectionResults[];
  reports: FileList[];
  // 計画開始日時
  startDatetime: string;
  // 計画名
  contractTitle: string;
  // コメント
  latestComment: string;
  // 担当者(監督者） 複数列ある
  supervisors: Supervisor[];
  // 作業者 複数列ある
  workers: Workers[];
};

/**
 * @description 当初(v1.3)のバージョンでは
 * 「export type Inspection = {」を利用していたが、定期業務一覧に改修が入り、
 *  Inspectionには列が追加となった。だがこのTypeはいろいろなAPIの返却値が持つInspectionのtypeとして
 *  利用していたため、他のAPI実行時にエラーとなった。なのでInspectionのままの形で別名で別途用意した。
 */
export type InspectionResponseItem = {
  propertyId: number;
  propertyName: string;
  inspectionResultId: number;
  inspectionChecklistId: number;
  inspectionChecklistTitle: string;
  inspectionTypeName: string;
  inspectionStatusName: string;
  checkUserId: number;
  checkUserName: string;
  checkDate: string | null;
  scheduledDate: string;
  description: string;
  inspectionType: number;
  status: number;
  approvalDate: string;
  approvedUserId: number;
  // 承認者
  approvedUserName: string;
  updateUserId: number;
  updateUserName: string;
  // 最終更新日
  updateDatetime: string;
  isSubmitted: boolean;
  remarks: string;
  layout: Layout;
  data: Data[];
  inspection_result_list: APIInspectionResults[];
  reports: FileList[];
};

export type InspectionBasic = {
  statusName: string;
  statusId: number;
  // "inspection_result_id": 1,
  inspectionResultId: number;
  // "property_id": 1,
  propertyId: number;
  // "property_name": "ダイキンプラザタワー",
  propertyName: string;
  // "contract_id": 1,
  contractId: number;
  // "contract_title": "日常巡回点検",
  contractTitle: string;
  // "contract_type_id": 1,
  contractTypeId: number;
  // "contract_type_name": "定期業務",
  contractTypeName: string;
  // "inspection_type_id": 1,
  inspectionTypeId: number;
  // "inspection_type_name": "定期",
  inspectionTypeName: string;
  // "inspection_division_id": 1,
  inspectionDivisionId: number;
  // "inspection_division_name": "設備",
  inspectionDivisionName: string;
  // "start_date": "2021/12/11",
  startDate: string;
  // "start_time": "11:00",
  startTime: string;
  // "end_date": "2021/12/11",
  endDate: string;
  // "end_time": "16:00",
  endTime: string;
  // "supervisors": [
  supervisors: SupervisorBasic[];
  //     "company_id": 0,
  //     "company_name": "",
  //     "user_id": 0,
  //     "user_name": "西田 俊哉"
  //   }
  // ],
  // "workers": [
  workers: WorkersBasic[];
  //   {
  //     "company_id": 0,
  //     "company_name": "",
  //     "user_id": 0,
  //     "user_name": "西田 俊哉"
  //   }
  // ],
  // "companies": [
  companies: Companies[];
  //   {
  //     "company_id": 0,
  //     "company_name": "ABC設備"
  //   }
  // ],
  // "adjustment_targets": [
  adjustmentTargetsBasic: AdjustmentTargetsBasic[];
  //   {
  //     "company_id": 0,
  //     "company_name": "",
  //     "user_id": 0,
  //     "user_name": "西田 俊哉"
  //   }
  // ],
  // "inspection_checklist_id": 1,
  inspectionChecklistId: number;
  // "inspection_checklist_title": "11.高圧受変電設備定期点検用",
  inspectionChecklistTitle: string;
  // "has_format": false,
  hasFormat: boolean;
  // "message": "シーズンの変わり目ですので、夏場の高温対策を昨年同様に実施してください。"
  message: number;
};

export type Layout = {
  groups: LayoutGroup[];
};
export type LayoutGroup = { title: string; disable: boolean; sections: LayoutSection[] };
export type LayoutSection = {
  title: string;
  disable: boolean;
  equipment: Equipment | null;
  components: LayoutComponent[];
};
export type LayoutComponent = {
  id: string;
  type: 'Choice' | 'NumberInput' | 'ComboBox' | 'TextBox' | 'TextArea';
  label: string;
  disable: boolean;
  required: boolean;
  config: { label: string; value: string | number; abnormal?: boolean }[];
};
export type Equipment = {
  equipment_id: number;
  equipment_name: string;
};
export type Data = {
  id: string;
  value: string | number;
  is_abnormal: boolean;
  photos: PhotoData[];
};
export type Photo = {
  id: string;
  name: string;
  url: string;
  content: string;
  memo: string;
  isUploaded: boolean;
  isDeleteFile: boolean;
  commit: boolean;
};
export type PhotoData = {
  file_name: string;
  file_url: string;
  memo: string;
  is_delete_file: boolean;
};

export type FileList = {
  fileName: string;
  fileUrl: string;
  fileSize?: number;
  deleteFlag?: boolean;
};
// Pickにより、Inspection型のプロパティで必要なものだけPickupし、
// 新しい型InspectionsList型を作成
export type InspectionsList = Pick<
  InspectionItem,
  | 'propertyId'
  | 'propertyName'
  | 'inspectionResultId'
  | 'inspectionChecklistId'
  | 'inspectionChecklistTitle'
  | 'inspectionTypeName'
  | 'inspectionStatusName'
  | 'checkUserId'
  | 'checkUserName'
  | 'checkDate'
  | 'scheduledDate'
  // 以下追加
  | 'approvedUserName'
  // 最終更新日
  | 'updateDatetime'
  // 計画開始日時
  | 'startDatetime'
  // 計画名
  | 'contractTitle'
  // コメント
  | 'latestComment'
  // 担当者(監督者） 複数列ある
  | 'supervisors'
  // 作業者 複数列ある
  | 'workers'
>;

export type InspectionsListInfo = {
  totalHits: number;
  totalPages: number;
};

// -- API Types ------------------------------------------------------------ //

export type APIDisplayParam = {
  readonly total_page: number;
  readonly total: number;
};
// Supervisor の型を定義
export type Supervisor = {
  user_id: number;
  user_name: string;
};

// Workers の型を定義
export type Workers = {
  user_id: number;
  user_name: string;
};

// 基本情報タブ取得APIで利用
export type Users = {
  user_id: number;
  user_name: string;
};

export type Companies = {
  company_id: number;
  company_name: string;
};

export type SupervisorBasic = Companies & Users;
export type WorkersBasic = Companies & Users;
export type AdjustmentTargetsBasic = Companies & Users;

/**
 * @summary 定期業務一覧（旧点検結果一覧）画面の表示値、ほかでも利用
 */
export type APIInspections = {
  readonly property_id: number;
  readonly property_name: string;
  readonly inspection_result_id: number;
  readonly inspection_checklist_id: number;
  readonly inspection_title: string;
  readonly inspection_status_name: string;
  readonly inspection_type_name: string;
  readonly check_user_id: number;
  // 実施者
  readonly check_user_name: string;
  readonly check_date: string | null;
  readonly scheduled_date: string;
  // 以下新規で追加
  // 計画開始日時
  readonly start_datetime: string;
  // 最終更新日
  readonly update_datetime: string;
  // 計画名
  readonly contract_title: string;
  // コメント
  readonly latest_comment: string;
  // 担当者(監督者） 複数列ある
  readonly supervisors: Supervisor[];
  // 作業者 複数列ある
  readonly workers: Workers[];
  // 承認者
  readonly approval_user_name: string;
};
export type APIInspection = Pick<
  APIInspections,
  | 'property_id'
  | 'property_name'
  | 'inspection_result_id'
  | 'inspection_checklist_id'
  | 'inspection_title'
  | 'inspection_status_name'
  | 'inspection_type_name'
  | 'check_user_id'
  | 'check_user_name'
  | 'check_date'
  | 'scheduled_date'
>;

/**
 * @summary 利用API  作業結果タブ
 */
export type APIInspectionItem = APIInspection & {
  readonly title: string;
  readonly schedule_start_date: string;
  readonly description: string;
  readonly inspection_type: number;
  readonly status: number;
  readonly status_name: string;
  readonly approval_datetime: string;
  readonly approval_user_id: number;
  readonly approval_user_name: string;
  readonly update_user_id: number;
  readonly update_user_name: string;
  readonly update_datetime: string;
  readonly is_submitted: boolean;
  readonly remarks: string;
  readonly layout: Layout;
  readonly data: Data[];
  readonly inspection_result_list: APIInspectionResults[];
  readonly reports: APIFileList[];
};

/**
 * @summary 基本情報タブの情報取得APIから返されるレスポンスに含まれている主要な型
 */
export type APIInspectionBasic = {
  // 定期業務-基本情報タブ 進捗ステップラベル制御用
  readonly status_id: number;
  readonly status_name: string;
  // "inspection_result_id": 1,
  readonly inspection_result_id: number;
  // "property_id": 1,
  readonly property_id: number;
  // "property_name": "ダイキンプラザタワー",
  readonly property_name: string;
  // "contract_id": 1,
  readonly contract_id: number;
  // "contract_title": "日常巡回点検",
  readonly contract_title: string;
  // "contract_type_id": 1,
  readonly contract_type_id: number;
  // "contract_type_name": "定期業務",
  readonly contract_type_name: string;
  // "inspection_type_id": 1,
  readonly inspection_type_id: number;
  // "inspection_type_name": "定期",
  readonly inspection_type_name: string;
  // "inspection_division_id": 1,
  readonly inspection_division_id: number;
  // "inspection_division_name": "設備",
  readonly inspection_division_name: string;
  // "schedule_start_date": "2021/12/11",
  readonly schedule_start_date: string;
  // "schedule_start_time": "11:00",
  readonly schedule_start_time: string;
  // "schedule_end_date": "2021/12/11",
  readonly schedule_end_date: string;
  // "schedule_end_time": "16:00",
  readonly schedule_end_time: string;
  // "supervisors": [
  readonly supervisors: SupervisorBasic[];
  // "workers": [
  readonly workers: WorkersBasic[];
  // "companies": [
  readonly companies: Companies[];
  // "adjustment_targets": [
  readonly adjustment_targets: AdjustmentTargetsBasic[];
  // "inspection_checklist_id": 1,
  readonly inspection_checklist_id: number;
  // "inspection_checklist_title": "11.高圧受変電設備定期点検用",
  readonly inspection_checklist_title: string;
  // "has_format": false,
  readonly has_format: boolean;
  // "message": "シーズンの変わり目ですので、夏場の高温対策を昨年同様に実施してください。"
  readonly message: number;
};

export type APIInspectionResults = {
  readonly inspection_result_id: number;
  readonly check_date: string | null;
};

export type APIFileList = {
  file_name: string;
  file_content: string;
  file_url?: string;
  file_size?: number;
  memo: string;
  is_delete_file?: boolean;
};

export type APIGetInspectionsRequestParams = {
  property_id?: number | null;
  page: number;
  sort: number;
  // ここには一覧画面の検索キーワードが入る(サーバー側ではplanと同じく計画名を検索する)
  keyword?: string;
  // ここには詳細検索画面の検索キーワードが入る
  title?: string;
  status?: number;
  size_per_page?: number;
  // グローバルメニューで設定したタグ情報(カンマ区切り)
  tag?: string;
  //追加 テストコード
  type?: number;
  show_completed: boolean;
  division?: number;
  supervisor?: string;
  worker?: string;
  approval_user?: string;
  company?: string;
  start_since?: string;
  start_until?: string;
  end_since?: string;
  end_until?: string;
};

export type APIGetInspectionsByInspectionResultIdRequestParams = {
  inspection_result_id: number | null;
};

export type APIGetInspectionsResponse = {
  result: {
    inspection_result: APIInspections[];
    display_params: APIDisplayParam;
  };
};

export type APIGetInspectionItemResponse = {
  message: string;
  result: APIInspectionItem;
};

/**
 * @summary 基本情報タブの情報取得APIのレスポンス型
 */
export type APIGetInspectionBasicResponse = {
  message: string;
  result: APIInspectionBasic;
};

export type APIStartInspectionResponse = {
  message: string;
  result: { inspection_result_id: number };
};

export type APISaveInspectionRequest = {
  inspection_checklist_id: number;
  remarks: string;
  is_skip: boolean;
  data: InspectionResultData[];
  reports: APIFileList[];
};

/**
 * @summary 点検完了のAPIに渡すリクエストbodyの型
 */
export type APIInspectionCompleteRequest = {
  comment: string;
};

export type InspectionResultData = {
  id: string;
  value: string | number | null;
  is_abnormal: boolean;
  photos: {
    file_name: string;
    file_content: string;
    is_delete_file: boolean;
    memo: string;
  }[];
};

export type APIExportInspectionReportResponse = {
  message: string;
  result: {
    file_name: string;
    file_content: string;
  };
};

export type APIGetInspectionChecklistsResponse = {
  result: APIInspectionChecklists[];
};

export type APIGetInspectionGuestRequest = { token: string };

// ↓ 新規 一旦まとめる ★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★

// -- State Types ---------------------------------------------------------- //

/**
 * @summary editReportModalで利用
 */
export type InspectionReportSubmitData = {
  description: string;
  reports: (FileList & { deleteFlag: boolean })[];
};

export type CommentListData = {
  commentId: number;
  typeId: number;
  typeName: string;
  createUserId: number;
  createUserName: string;
  createDatetime: string;
  updateDatetime: string;
  comment: string;
  imageList: CommentListImageListData[];
  fileList: CommentListFileListData[];
};

// -- API Types ------------------------------------------------------------ //

/**
 * @summary URL生成
 */
export type InspectionUrl = {
  accessUrl: string;
  expirationDatetime: string;
};
export type InspectionState = {
  refresh: Record<string, never>;
  refreshNotificationTab: Record<string, never>;
  refreshWorkResultTab: Record<string, never>;
  refreshCommentTab: Record<string, never>;
  isLoading: boolean;
  isLoadingTab: boolean;
  isSubmitting: boolean;
  isNavigating: boolean; // edit -> detail (when complete)
  isNavigating2: boolean; // new -> edit (when start new)
  isNavigatingTo: number | null;
  inspections: InspectionsList[];
  inspectionsListInfo: InspectionsListInfo | null;
  inspection: InspectionResponseItem | null;
  prevInspection: InspectionResponseItem | null;
  inspectionChecklists: InspectionChecklistsList[];
  photos: Photo[];
  deletePhotos: Photo[];
  // 以下追加項目
  isInvalidToken: boolean;
  // 用途確認
  inspectionsList: InspectionsListData[];
  inspectionsListDisplayStatus: InspectionsListDisplayStatus | null;
  // タブの基本情報部  selector.tsxにて 、 getInspectionBasic = (state: State) => state.inspection.inspectionBasic ; のように利用
  // このtypeは、reducerで、利用しているのでそこのinitializerに含めて初期化すること。
  inspectionBasic: InspectionBasic | null;
  //
  /** @summary 定期業務-連絡事項情報State取得 */
  inspectionInfo: InspectionInfoData | null;
  /** @summary 定期業務-コメント一覧取得State取得 */
  inspectionComments: InspectionCommentsData | null;
};

export type InspectionsListData = {
  construction_id: number;
  property_id: number;
  property_name: string;
  status_id: number;
  status_name: string;
  start_date: string;
  title: string;
  reporter_id: number;
  reporter_name: string;
  latest_comment: string;
  update_datetime: string;
};

export type InspectionsListDisplayStatus = {
  num_per_page: number;
  current_page: number;
  total_page: number;
  total: number;
  sort: number;
};

/** @summary 定期業務-連絡事項情報 */

export type InspectionInfoData = {
  inspectionResultId: number;
  propertyId: number;
  propertyName: string;
  contractId: number;
  contractTitle: string;
  message: string;
  files: InspectionInfoFilesType[];
};

/**
 * @summary fileをダウンロードする処理の 現在選択中のファイル
 */
export type InspectionInfoFilesType = {
  fileId: number;
  fileName: string;
  fileUrl: string;
  fileSize: number;
};

/** @summary 定期業務コメント一覧取得Stateのtype */
export type InspectionCommentsData = {
  // commentList: CommentListData[];
  commentList: InspectionListData[];
};

export type InspectionListData = {
  commentId: number;
  typeId: number;
  typeName: string;
  createUserId: number;
  createUserName: string;
  createDatetime: string;
  updateDatetime: string;
  beforeStatusId: number;
  beforeStatusName: string;
  afterStatusId: number;
  afterStatusName: string;
  comment: string;
  imageList: CommentListImageListData[];
  fileList: CommentListFileListData[];
};
// ↑ つながってます。
export type CommentListImageListData = {
  fileId: number;
  fileName: string;
  fileUrl: string;
  thumbnailFileUrl: string;
  fileSize: number;
  memo: string;
};
// ↑ つながってます。
export type CommentListFileListData = {
  fileId: number;
  fileName: string;
  fileUrl: string;
  fileSize: number;
};

// /** @summary コメント登録・編集type */
export type UpdateCommentData = {
  comment: string;
  imageList: UpdateImageListData[];
  fileList: UpdateFileListData[];
};

export type UpdateImageListData = {
  fileName: string;
  fileContent: string;
  memo: string;
};

export type UpdateFileListData = {
  fileName: string;
  fileContent: string;
};
export type UpdateImageListState = {
  fileName: string;
  fileUrl: string;
  memo: string;
  fileSize: number;
  thumbnailFileUrl: string;
};

export type UpdateFileListState = {
  fileName: string;
  fileUrl: string;
  fileSize: number;
};

/**
 * 一覧検索での追加パラメータ
 */
export interface APIGetInspectionRequestParamsPage {
  page: number;
  /** 1ページ当たりのデータ数。省略時は「15」。最大値「100」。「0」以下は「15」扱い。「101」以上は「100」扱い。 */
  size_per_page?: number;
}

/**
 * 定期業務検索のための詳細検索条件のlocationのstateタイプ
 */
export interface InspectionAdvancedQuery {
  title?: string;
  /**
   * @summary 定期業務種別
   */
  type?: number;
  /**
   * @summary 定期業務区分
   */
  division?: number;
  /**
   * @summary 対応状況
   */
  status?: number;
  /**
   * @summary 監督者（名前検索）
   */
  supervisor?: string;
  /**
   * @summary 作業者（名前検索）
   */
  worker?: string;
  /**
   * @summary 承認者（名前検索）
   */
  approval_user?: string;
  /**
   * @summary 協力会社（名前検索）
   */
  company?: string;
  /**
   * @summary 開始日（From）
   */
  start_since?: string;
  /**
   * @summary 開始日（To）
   */
  start_until?: string;
  /**
   * @summary 終了日（From）
   */
  end_since?: string;
  /**
   * @summary 終了日（To）
   */
  end_until?: string;
  sort?: string;
  tag?: string;

  showCompleted: boolean;
}

/**
 * 定期業務一覧検索時に使われるlocationのstate
 */
export type InspectionListQuery = InspectionAdvancedQuery & APIGetInspectionRequestParamsPage;

/** 定期業務一覧画面のlocationのstate */
export type InspectionListState = InspectionListQuery &
  InspectionAdvancedQuery & {
    order: boolean;
    pageH: number;
  };
// -- ↓  一旦まとめる ★★★★★ API Types ------------------------------------------------------------ //

/**
 * @summary /api/inspections(定期業務一覧取得のresponseのresult型)
 * @description /api/inspections定期業務一覧取得swagger参照)
 */
export type APIInspectionsList = {
  data: InspectionsListData[];
  display_params: InspectionsListDisplayStatus;
};

export type APICreateInspectionReport = {
  description: string;
  reports: {
    file_name: string;
    file_content: string;
    is_delete_file: boolean;
    memo: string;
  }[];
  files: [];
};

// 承認・差し戻し
export type APIApproveInspection = {
  is_approved: boolean;
  comment: string;
};

export type APIUpdateInspectionReport = APICreateInspectionReport;

/** @summary 定期業務一覧取得パラメーター */
export type APIGetInspectionsListParams = {
  sort: string;
  page: number;
  size_per_page: number;
  keyword: string;
  show_completed: boolean;
  supervisor: string;
  worker: string;
  approval_user: string;
  status: number | null;
  company: string;
  since: string | null;
  until: string | null;
  name: string;
  property_id?: number | null;
};

/** @summary 定期業務-報告書提出URL生成 */
export type APIInspectionUrl = {
  access_url: string;
  expiration_datetime: string;
};

/** @summary 定期業務-連絡事項情報取得type */
export type APIInspectionInfo = {
  inspection_result_id: number;
  property_id: number;
  property_name: string;
  contract_id: number;
  contract_title: string;
  title: string;
  message: string;
  file_list: InspectionInfoFiles[];
};

/** @summary 定期業務-連絡事項添付ファイル登録type */
export type InspectionInfoFiles = {
  file_id: number;
  file_name: string;
  file_url: string;
  file_size: number;
};

/** @summary 定期業務-連絡事項添付ファイル名変更type */
export type InspectionInfoFIlesResponse = {
  file_name: string;
};

/** @summary 伝達事項編集type*/
export type InspectionInfoMessage = {
  message: string;
};

/** @summary 定期業務コメント一覧取得type */
export type InspectionComments = {
  comment_list: CommentList[];
};

export type CommentList = {
  comment_id: number;
  type_id: number;
  type_name: string;
  create_user_id: number;
  create_user_name: string;
  create_datetime: string;
  update_datetime: string;
  before_status_id: number;
  before_status_name: string;
  after_status_id: number;
  after_status_name: string;
  comment: string;
  image_list: CommentListImageList[];
  file_list: CommentListFileList[];
};

export type CommentListImageList = {
  file_id: number;
  file_name: string;
  file_url: string;
  thumbnail_file_url: string;
  file_size: number;
  memo: string;
};
export type CommentListFileList = {
  file_id: number;
  file_name: string;
  file_url: string;
  file_size: number;
};

/** @summary 定期業務 コメント登録APIのtype */
export type RegComment = {
  comment: string;
  image_list: RegImageList[];
  file_list: RegFileList[];
};

/** @summary 定期業務 コメント登録APIのtype の内部オブジェクトtype*/
export type RegImageList = {
  file_name: string;
  file_content: string;
  memo: string;
};

/** @summary 定期業務 コメント登録APIのtype の内部オブジェクトtype */
export type RegFileList = {
  file_name: string;
  file_content: string;
};

/** @summary コメント登録APIのtype */
export type UpdateComment = {
  comment: string;
  image_list: UpdateImageList[];
  file_list: UpdateFileList[];
};

/** @summary コメント登録APIのtypeの内部オブジェクトtype */
export type UpdateImageList = {
  file_name: string;
  file_content: string;
  memo: string;
  is_delete_file: boolean;
};

/** @summary コメント登録APIのtypeの内部オブジェクトtype */
export type UpdateFileList = {
  file_name: string;
  file_content: string;
  is_delete_file: boolean;
};

/** @summary /api/inspections(定期業務一覧取得のresponse型)*/
export type APIGetInspectionsListResponse = { result: APIInspectionsList };

export type APICreateInspectionReportRequest = APICreateInspectionReport;

export type APIUpdateInspectionReportRequest = APIUpdateInspectionReport;

export type APIApproveInspectionRequest = APIApproveInspection;

/**
 * @summary 定期業務－報告書提出先URL生成
 */
export type APIGetInspectionUrlResponse = { result: APIInspectionUrl };

/** @summary /api/inspections/{inspection_id}/information（定期業務-連絡一覧情報取得）*/
export type APIGetInspectionInfoResponse = { result: APIInspectionInfo };

/** @summary /api/inspections/{inspection_id}/information/files{定期業務-連絡事項添付ファイル登録} */
export type APIGetInspectionInfoFilesResponse = { result: InspectionInfoFiles };

/** @summary  /api/inspections/{inspection_id}/information/files/{file_id}(定期業務-連絡事項添付ファイル名変更) */
export type APIPutInspectionInfoFilesRequest = InspectionInfoFIlesResponse;

/** @summary  /api/inspections/{inspection_id}/information/message(伝達事項編集)*/
export type APIPutInspectionInfoMessageRequest = InspectionInfoMessage;

/** @summary  /api/inspections/{inspection_id}/comments（定期業務コメント一覧取得） */
export type APIInspectionsCommentsResponse = { result: InspectionComments };

/** @summary  /api/inspections/{inspection_id}/comments (定期業務コメント登録) */
export type APIPostInspectionsCommentsRequest = RegComment;

export type APIPostInspectionsCommentsResponse = { result: CommentList };
/**
 * @summary  /api/inspections/${inspectionResultId}/comments/${inspectionCommentId} (定期業務コメント編集) */
export type APIPutInspectionsCommentsRequest = UpdateComment;

export type APIPutInspectionsCommentsResponse = { result: CommentList };

// ↑ 一旦まとめる ★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★
