import type { Action, PayloadAction } from '@reduxjs/toolkit';

export type TagState = {
  refresh: Record<string, never>;
  tags: TagDataType[];
  tagSettingList: TagDataTypeForSettings[];
  isLoading: boolean;
  isSubmitting: boolean;
};

export enum TagActionTypes {
  INIT = 'tag/init',
  REFRESH = 'tag/refresh',
  LOADING = 'tag/set/isLoading',
  SUBMITTING = 'tag/set/isSubmitting',
  SET_TAGS = 'tag/set/tags',
  SET_TAG_SETTING_LIST = 'tag/set/tagSettingList',
}

export type TagAction =
  | Action<TagActionTypes.INIT>
  | Action<TagActionTypes.REFRESH>
  | PayloadAction<boolean, TagActionTypes.LOADING>
  | PayloadAction<boolean, TagActionTypes.SUBMITTING>
  | PayloadAction<TagDataType[], TagActionTypes.SET_TAGS>
  | PayloadAction<TagDataTypeForSettings[], TagActionTypes.SET_TAG_SETTING_LIST>;

// -- State Types ---------------------------------------------------------- //

/**
 * @summary タグタイプの種類
 * @description 1. 個人タグ 2. 共有タグ
 */
export type TagTypeIdNum = 1 | 2;

/**
 * @summary 一つのタグに含まれる情報(プロパティ)
 */
export type TagDataType = {
  /** タグのid */
  tag_id: number;
  /** タグの名前 */
  tag_name: string;
  /** タグのid */
  type_id: TagTypeIdNum;
};

export type TagDataTypeForSettings = TagDataType & {
  type_name: string;
  /** タグ作成者の名前 */
  user_name: string;
  /** 登録件数 */
  using_count: string;
};

export type TagFormDataToAdd = {
  tagNameToAdd: string;
  tagTypeIdToAdd: TagTypeIdNum;
};

export type TagFormDataToEdit = {
  tagNameToEdit: string;
};

/**
 * @summary 登録件数(using_count)の参照先
 * @description 1. 建物 2.連絡先 3.企業
 */
export type EditModeType = 1 | 2 | 3;
