import reducer from './reducers';

import * as workSelectors from './selectors';
import * as workActions from './actions';
import * as workOperations from './operations';
import { WorkState } from './types';

export { workSelectors, workActions, workOperations };
export type { WorkState };

export default reducer;
