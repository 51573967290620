import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import {
  InspectionChecklistEditState,
  InspectionChecklistEditAction,
  InspectionChecklistEditActionTypes as ActionTypes,
} from './types';

const initialInspectionChecklistEditState: InspectionChecklistEditState = {
  refresh: {},
  isLoading: false,
  isSubmitting: false,
  isNavigating: false,
  isNavigating2: false,
  isNavigatingTo: null,
  checklist: null,
  layout: { groups: [] },
  publishedLayoutComponentIds: [],
};

const inspectionChecklistEditReducer = (
  state: InspectionChecklistEditState = initialInspectionChecklistEditState,
  action: InspectionChecklistEditAction,
): InspectionChecklistEditState => {
  switch (action.type) {
    case ActionTypes.INIT:
      return initialInspectionChecklistEditState;

    case ActionTypes.REFRESH:
      return { ...state, refresh: {} };

    case ActionTypes.LOADING:
      return { ...state, isLoading: action.payload };

    case ActionTypes.SUBMITTING:
      return { ...state, isSubmitting: action.payload };

    case ActionTypes.NAVIGATING:
      return { ...state, isNavigating: action.payload };

    case ActionTypes.NAVIGATING2:
      return { ...state, isNavigating2: action.payload };

    case ActionTypes.NAVIGATING_TO:
      return { ...state, isNavigatingTo: action.payload };

    case ActionTypes.SET_CHECKLIST:
      return { ...state, checklist: action.payload };

    case ActionTypes.SET_LAYOUT:
      return { ...state, layout: action.payload };

    case ActionTypes.SET_PUBLISHED:
      return { ...state, publishedLayoutComponentIds: action.payload };

    case ActionTypes.ADD_NEW_GROUP: {
      const p = action.payload;
      const newLayout = _.cloneDeep(state.layout);
      newLayout.groups.push({
        title: p.groupName,
        disable: false,
        sections: [
          {
            title: p.sectionName,
            equipment: null,
            disable: false,
            components: [],
          },
        ],
      });
      return { ...state, layout: newLayout };
    }

    case ActionTypes.ADD_NEW_SECTION: {
      const p = action.payload;
      const newLayout = _.cloneDeep(state.layout);
      newLayout.groups[p.groupIndex].sections.push({
        title: p.sectionName,
        equipment: null,
        disable: false,
        components: [],
      });
      return { ...state, layout: newLayout };
    }

    case ActionTypes.ADD_NEW_COMPONENT: {
      const p = action.payload;
      const newLayout = _.cloneDeep(state.layout);
      const component = {
        id: uuidv4(),
        label: p.component.label,
        type: p.component.type,
        required: p.component.required,
        disable: false,
        config: p.component.config ?? [],
      };
      newLayout.groups[p.groupIndex].sections[p.sectionIndex].components.push(component);
      return { ...state, layout: newLayout };
    }

    case ActionTypes.SET_GROUP_NAME: {
      const p = action.payload;
      const newLayout = _.cloneDeep(state.layout);
      newLayout.groups[p.groupIndex].title = p.groupName;
      return { ...state, layout: newLayout };
    }

    case ActionTypes.SET_SECTION_NAME: {
      const p = action.payload;
      const newLayout = _.cloneDeep(state.layout);
      newLayout.groups[p.groupIndex].sections[p.sectionIndex].title = p.sectionName;
      return { ...state, layout: newLayout };
    }

    case ActionTypes.SET_SECTION_EQUIPMENT: {
      const p = action.payload;
      const newLayout = _.cloneDeep(state.layout);
      newLayout.groups[p.groupIndex].sections[p.sectionIndex].equipment = p.equipment;
      return { ...state, layout: newLayout };
    }

    case ActionTypes.SET_COMPONENT: {
      const p = action.payload;
      const newLayout = _.cloneDeep(state.layout);
      const target =
        newLayout.groups[p.groupIndex].sections[p.sectionIndex].components[p.componentIndex];
      const newComponent = { ...target, ...p.component };
      newLayout.groups[p.groupIndex].sections[p.sectionIndex].components[p.componentIndex] =
        newComponent;
      return { ...state, layout: newLayout };
    }

    case ActionTypes.COPY_GROUP: {
      const p = action.payload;
      const newLayout = _.cloneDeep(state.layout);
      const target = newLayout.groups;
      target.push({
        title: target[p.groupIndex].title,
        disable: target[p.groupIndex].disable,
        sections: target[p.groupIndex].sections.map((section) => {
          return {
            title: section.title,
            disable: section.disable,
            equipment: section.equipment,
            components: section.components.map((component) => {
              return {
                id: uuidv4(),
                type: component.type,
                label: component.label,
                disable: component.disable,
                required: component.required,
                config: component.config,
              };
            }),
          };
        }),
      });
      return { ...state, layout: newLayout };
    }

    case ActionTypes.COPY_SECTION: {
      const p = action.payload;
      const newLayout = _.cloneDeep(state.layout);
      const target = newLayout.groups[p.groupIndex].sections;
      target.push({
        title: target[p.sectionIndex].title,
        disable: target[p.sectionIndex].disable,
        equipment: target[p.sectionIndex].equipment,
        components: target[p.sectionIndex].components.map((component) => {
          return {
            id: uuidv4(),
            type: component.type,
            label: component.label,
            disable: component.disable,
            required: component.required,
            config: component.config,
          };
        }),
      });
      return { ...state, layout: newLayout };
    }

    case ActionTypes.COPY_COMPONENT: {
      const p = action.payload;
      const newLayout = _.cloneDeep(state.layout);
      const target = newLayout.groups[p.groupIndex].sections[p.sectionIndex].components;
      target.push({
        id: uuidv4(),
        type: target[p.componentIndex].type,
        label: target[p.componentIndex].label,
        disable: target[p.componentIndex].disable,
        required: target[p.componentIndex].required,
        config: target[p.componentIndex].config,
      });
      return { ...state, layout: newLayout };
    }

    case ActionTypes.CHANGE_GROUP_ORDER: {
      const p = action.payload;
      const newLayout = _.cloneDeep(state.layout);
      const target = newLayout.groups;
      if (p.isUp) {
        target.splice(p.groupIndex - 1, 2, target[p.groupIndex], target[p.groupIndex - 1]);
      } else {
        target.splice(p.groupIndex, 2, target[p.groupIndex + 1], target[p.groupIndex]);
      }
      return { ...state, layout: newLayout };
    }

    case ActionTypes.CHANGE_SECTION_ORDER: {
      const p = action.payload;
      const newLayout = _.cloneDeep(state.layout);
      const target = newLayout.groups[p.groupIndex].sections;
      if (p.isUp) {
        target.splice(p.sectionIndex - 1, 2, target[p.sectionIndex], target[p.sectionIndex - 1]);
      } else {
        target.splice(p.sectionIndex, 2, target[p.sectionIndex + 1], target[p.sectionIndex]);
      }
      return { ...state, layout: newLayout };
    }

    case ActionTypes.CHANGE_COMPONENT_ORDER: {
      const p = action.payload;
      const newLayout = _.cloneDeep(state.layout);
      const target = newLayout.groups[p.groupIndex].sections[p.sectionIndex].components;
      if (p.isUp) {
        target.splice(
          p.componentIndex - 1,
          2,
          target[p.componentIndex],
          target[p.componentIndex - 1],
        );
      } else {
        target.splice(p.componentIndex, 2, target[p.componentIndex + 1], target[p.componentIndex]);
      }
      return { ...state, layout: newLayout };
    }

    case ActionTypes.DISABLE_GROUP: {
      const p = action.payload;
      const newLayout = _.cloneDeep(state.layout);
      newLayout.groups[p.groupIndex].disable = p.disable;
      return { ...state, layout: newLayout };
    }

    case ActionTypes.DISABLE_SECTION: {
      const p = action.payload;
      const newLayout = _.cloneDeep(state.layout);
      newLayout.groups[p.groupIndex].sections[p.sectionIndex].disable = p.disable;
      return { ...state, layout: newLayout };
    }

    case ActionTypes.DISABLE_COMPONENT: {
      const p = action.payload;
      const newLayout = _.cloneDeep(state.layout);
      newLayout.groups[p.groupIndex].sections[p.sectionIndex].components[p.componentIndex].disable =
        p.disable;
      return { ...state, layout: newLayout };
    }

    case ActionTypes.DELETE_GROUP: {
      const p = action.payload;
      const newLayout = _.cloneDeep(state.layout);
      const target = newLayout.groups;
      target.splice(p.groupIndex, 1);
      return { ...state, layout: newLayout };
    }

    case ActionTypes.DELETE_SECTION: {
      const p = action.payload;
      const newLayout = _.cloneDeep(state.layout);
      const target = newLayout.groups[p.groupIndex].sections;
      target.splice(p.sectionIndex, 1);
      return { ...state, layout: newLayout };
    }

    case ActionTypes.DELETE_COMPONENT: {
      const p = action.payload;
      const newLayout = _.cloneDeep(state.layout);
      const target = newLayout.groups[p.groupIndex].sections[p.sectionIndex].components;
      target.splice(p.componentIndex, 1);
      return { ...state, layout: newLayout };
    }

    default:
      return state;
  }
};

export default inspectionChecklistEditReducer;
