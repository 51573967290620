import {
  MonthlyTableTable,
  MonthlyTableTableSummary,
  PlanMonthlyTableAction as Action,
  PlanMonthlyTableActionTypes as ActionTypes,
  SearchParameter,
  DisplayParams,
  Contract,
} from './types';

/**
 * @summary 初期化処理
 * @returns {Action}
 */
export const init = (): Action => ({ type: ActionTypes.INIT });

/**
 * @summary リフレッシュ処理
 * @returns {Action}
 */
export const refresh = (): Action => ({ type: ActionTypes.REFRESH });

/**
 * @summary Loading状態を保持
 * @param {boolean} isLoading
 * @returns {Action}
 */
export const setIsLoading = (isLoading: boolean): Action => ({
  type: ActionTypes.LOADING,
  payload: isLoading,
});

/**
 * @summary 契約リストのLoading状態を保持
 * @param {boolean} isLoadingContractList
 * @returns {Action}
 */
export const setIsLoadingContactList = (isLoadingContractList: boolean): Action => ({
  type: ActionTypes.LOADING_CONTRACT_LIST,
  payload: isLoadingContractList,
});

/**
 * @summary 送信処理中かを保持
 * @param {boolean} isSubmitting
 * @returns {Action}
 */
export const setIsSubmitting = (isSubmitting: boolean): Action => ({
  type: ActionTypes.SUBMITTING,
  payload: isSubmitting,
});

/**
 * @summary 編集中かを保持
 * @param {boolean} isEditing
 * @returns {Action}
 */
export const setIsEditing = (isEditing: boolean): Action => ({
  type: ActionTypes.EDITING,
  payload: isEditing,
});

/**
 * @summary ページ番号を保持
 * @param {number} page
 * @returns {Action}
 */
export const setPage = (page: number): Action => ({
  type: ActionTypes.SET_PAGE,
  payload: page,
});

/**
 * @summary ソート番号を保持
 * @param {number} sort
 * @returns {Action}
 */
export const setSort = (sort: number): Action => ({
  type: ActionTypes.SET_SORT,
  payload: sort,
});

/**
 * @summary 詳細検索条件を保持
 * @param {SearchParameter} searchParameter
 * @returns {Action}
 */
export const setSearchParameter = (searchParameter: SearchParameter): Action => ({
  type: ActionTypes.SET_SEARCH_PARAMETER,
  payload: searchParameter,
});

/**
 * @summary 月次計画詳細リストを保持
 * @param {MonthlyTableTable[]} tables
 * @returns {Action}
 */
export const setTables = (tables: MonthlyTableTable[]): Action => ({
  type: ActionTypes.SET_TABLES,
  payload: tables,
});

/**
 * @summary 月次計画サマリを保持
 * @param {MonthlyTableTableSummary | null} summary
 * @returns {Action}
 */
export const setSummary = (summary: MonthlyTableTableSummary | null): Action => ({
  type: ActionTypes.SET_SUMMARY,
  payload: summary,
});

/**
 * @summary Pager情報を保持
 * @param {DisplayParams} displayParams
 * @returns {Action}
 */
export const setDisplayParams = (displayParams: DisplayParams): Action => ({
  type: ActionTypes.SET_DISPLAY_PARAMS,
  payload: displayParams,
});

/**
 * @summary 契約リストを保持
 * @param {Contract[]} contractList
 * @returns {Action}
 */
export const setContractList = (contractList: Contract[]): Action => ({
  type: ActionTypes.SET_CONTRACT_LIST,
  payload: contractList,
});

/**
 * @summary 契約リストPager情報を保持
 * @param {DisplayParams} contractDisplayParams
 * @returns {Action}
 */
export const setContractDisplayParams = (contractDisplayParams: DisplayParams): Action => ({
  type: ActionTypes.SET_CONTRACT_DISPLAY_PARAMS,
  payload: contractDisplayParams,
});

/**
 * @summary 選択を確定した契約を保持
 * @param {{ id: number; name: string } | null} contract
 * @returns {Action}
 */
export const setContract = (contract: { id: number; name: string } | null): Action => ({
  type: ActionTypes.SET_CONTRACT,
  payload: contract,
});
