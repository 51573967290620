import axios from '@providers/axiosProvider';
import * as actions from './actions';
import {
  APIGetEquipmentsAndRoomsResponse,
  APILinkFloorMapRequest,
  APIUploadFloorMapRequest,
  EditModeState,
  Equipment,
  Room,
} from './types';

import { APIGetFloorsResponse, FloorsList } from '@ducks/property/types';

export const getFloorsAsync = (propertyId: number) => async (dispatch: any) => {
  dispatch(actions.setIsLoading(true));

  await axios
    .get<APIGetFloorsResponse>(`/properties/${propertyId}/floors`)
    .then((response) => {
      const result: FloorsList[] = response.data.result.map((v) => ({
        id: v.floor_id,
        name: v.floor_name,
        description: v.description,
        roomNum: v.room_num,
      }));
      dispatch(actions.setFloors(result));
    })
    .catch(() => {
      dispatch(actions.setFloors([]));
    });

  dispatch(actions.setIsLoading(false));
};

/**
 * @summary フロアの図面、設備、部屋を取得する
 * @description フロアの図面画像、設備、部屋を取得した後にフロア、設備、部屋の再描画を実行させる
 * @param {number} propertyId 建物ID
 * @param {number} floorId フロアID
 */
export const getEquipmentsAndRoomsAsync =
  (propertyId: number, floorId: number) => async (dispatch: any) => {
    dispatch(actions.setIsLoading(true));

    await axios
      .get<APIGetEquipmentsAndRoomsResponse>(`/properties/${propertyId}/floors/${floorId}`)
      .then((response) => {
        const r = response.data.result;

        const floorMapUrl = r.file_url;

        const rooms: Room[] = r.room_list.map((rm) => ({
          roomId: rm.room_id,
          roomName: rm.room_name,
          description: rm.description,
          coordinate: rm.coordinate,
        }));

        const equipments: Equipment[] = r.equipment_list.map((eq) => {
          const coordinate =
            eq.coordinate_x != null && eq.coordinate_y != null
              ? { x: eq.coordinate_x, y: eq.coordinate_y }
              : null;

          return {
            equipmentId: eq.equipment_id,
            equipmentName: eq.equipment_name,
            equipmentTypeId: eq.equipment_type_id,
            equipmentTypeName: eq.equipment_type_name,
            diagramId: eq.diagram_id,
            diagramName: eq.diagram_name,
            modelNumber: eq.model_number,
            serialNumber: eq.serial_number,
            roomId: eq.room_id,
            coordinate: coordinate,
          };
        });

        dispatch(actions.setFloorMapUrl(floorMapUrl));
        dispatch(actions.setEquipments(equipments));
        dispatch(actions.setRooms(rooms));
        /** フロア、設備、部屋の再描画を実行させる */
        dispatch(actions.floorMapRedraw());
      })
      .catch(() => {
        dispatch(actions.setFloorMapUrl(null));
        dispatch(actions.setEquipments([]));
        dispatch(actions.setRooms([]));
        /** フロア、設備、部屋の再描画を実行させる */
        dispatch(actions.floorMapRedraw());
      });

    dispatch(actions.setIsLoading(false));
  };

export const uploadFloorMapAsync =
  (propertyId: number, floorId: number, file: { name: string; content: string }) =>
  async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const requestData: APIUploadFloorMapRequest = {
      file_name: file.name,
      file_content: file.content,
    };

    await axios
      .post(`/properties/${propertyId}/floors/${floorId}/map`, requestData)
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const linkFloorMapAsync =
  (propertyId: number, floorId: number, data: EditModeState) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const requestData: APILinkFloorMapRequest = {
      equipment_list: data.equipments.map((v) => ({
        equipment_id: v.id,
        coordinate_x: v.coordinate?.x ?? null,
        coordinate_y: v.coordinate?.y ?? null,
      })),
      room_list: data.rooms.map((v) => ({ room_id: v.id, coordinate: v.coordinate })),
    };

    await axios
      .put(`/properties/${propertyId}/floors/${floorId}/map/link`, requestData)
      .then(() => {
        dispatch(actions.setEditMode(false));
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };
