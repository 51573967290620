import { AppState as State } from '@ducks/index';
/**
 * @summary ローディング状態かを返却する
 * @param {State} state
 * @returns {boolean} ローディング状態か
 */
export const checkIsLoading = (state: State) => state.selectMultipleAffiliate.isLoading;

/**
 * @summary 連絡先リストを返却
 * @param {State} state
 * @returns {boolean} ローディング状態か
 */
export const getUserList = (state: State) => state.selectMultipleAffiliate.userList;

/**
 * @summary 企業リストを返却
 * @param {State} state
 * @returns {boolean} ローディング状態か
 */
export const getCompanyList = (state: State) => state.selectMultipleAffiliate.companyList;

/**
 * @summary システムユーザリストを返却
 * @param {State} state
 * @returns {boolean} ローディング状態か
 */
export const getSystemUserList = (state: State) => state.selectMultipleAffiliate.systemUserList;

/**
 * @summary 選択タブを返却
 * @param {State} state
 * @returns {boolean} ローディング状態か
 */
export const getTab = (state: State) => state.selectMultipleAffiliate.tab;

/** @summary 選択状態の連絡先リストを返却
 * @param {State} state
 * @returns {boolean} ローディング状態か
 */
export const getSelectItemList = (state: State) => state.selectMultipleAffiliate.selectedItemList;
