import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { Layout } from '@ducks/inspectionChecklistEdit/types';

export type InspectionChecklistState = {
  refresh: Record<string, never>;
  isLoading: boolean;
  isSubmitting: boolean;
  isNavigating: boolean;
  inspectionChecklists: InspectionChecklistsList[];
  inspectionChecklistsListInfo: InspectionChecklistsListInfo | null;
  inspectionChecklist: InspectionChecklist | null;
  inspectionChecklistFormatPlaceholders: InspectionChecklistFormatPlaceholders[];
};

export enum InspectionChecklistActionTypes {
  INIT = 'inspectionChecklist/init',
  REFRESH = 'inspections/checklists/refresh',
  LOADING = 'inspections/checklists/set/isLoading',
  SUBMITTING = 'inspections/checklists/set/isSubmitting',
  NAVIGATING = 'inspections/checklists/set/isNavigating',
  SET_INSPECTION_CHECKLISTS = 'inspections/checklists/set/inspectionChecklists',
  SET_INSPECTION_CHECKLISTS_LIST_INFO = 'inspections/checklists/set/inspectionChecklistsListInfo',
  SET_INSPECTION_CHECKLIST = 'inspections/checklists/set/inspectionChecklist',
  SET_INSPECTION_CHECKLIST_FORMAT_PLACEHOLDERS = 'inspections/checklists/set/inspectionChecklistFormatPlaceholders',
}

export type InspectionChecklistAction =
  | Action<InspectionChecklistActionTypes.INIT>
  | Action<InspectionChecklistActionTypes.REFRESH>
  | PayloadAction<boolean, InspectionChecklistActionTypes.LOADING>
  | PayloadAction<boolean, InspectionChecklistActionTypes.SUBMITTING>
  | PayloadAction<boolean, InspectionChecklistActionTypes.NAVIGATING>
  | PayloadAction<
      InspectionChecklistsList[],
      InspectionChecklistActionTypes.SET_INSPECTION_CHECKLISTS
    >
  | PayloadAction<
      InspectionChecklistsListInfo | null,
      InspectionChecklistActionTypes.SET_INSPECTION_CHECKLISTS_LIST_INFO
    >
  | PayloadAction<
      InspectionChecklist | null,
      InspectionChecklistActionTypes.SET_INSPECTION_CHECKLIST
    >
  | PayloadAction<
      InspectionChecklistFormatPlaceholders[],
      InspectionChecklistActionTypes.SET_INSPECTION_CHECKLIST_FORMAT_PLACEHOLDERS
    >;

// -- State Types ---------------------------------------------------------- //

export type InspectionChecklistsList = {
  propertyId: number;
  propertyName: string;
  inspectionChecklistId: number;
  inspectionTypeName: string;
  publishStatus: number;
  publishStatusName: string;
  title: string;
  description: string;
  createUserName: string;
  inspectionCount: string;
  hasFormat: boolean;
  updateDatetime: string;
};

export type InspectionChecklist = InspectionChecklistsList & {
  inspectionTypeId: number;
  publishStatusId: number;
  propertyId: number;
  propertyName: string;
  createUserId: number;
  createUserName: string;
  lastUpdate: string;
  layoutInformation: Layout;
  hasFormat: boolean;
};

export type InspectionChecklistsListInfo = {
  totalHits: number;
  totalPages: number;
};

export type InspectionChecklistFormatPlaceholders = {
  name: string;
  value: string;
};

// -- API Types ------------------------------------------------------------ //

export type APIDisplayParam = {
  readonly total_page: number;
  readonly total: number;
};

export type APIInspectionChecklists = {
  readonly property_id: number;
  readonly property_name: string;
  readonly inspection_checklist_id: number;
  readonly inspection_type_name: string;
  readonly publish_status: number;
  readonly publish_status_name: string;
  readonly title: string;
  readonly description: string;
  readonly create_user_name: string;
  readonly inspection_count: string;
  readonly has_format: boolean;
  readonly update_datetime: string;
};

export type APIInspectionChecklist = APIInspectionChecklists & {
  readonly inspection_type: number;
  readonly publish_status: number;
  readonly property_id: number;
  readonly property_name: string;
  readonly create_user_id: number;
  readonly create_user_name: string;
  readonly update_datetime: string;
  readonly layout_information: Layout;
  readonly has_format: boolean;
};

export type APIInspectionChecklistFormatPlaceholders = {
  readonly name: string;
  readonly value: string;
};

export type APIGetInspectionChecklistsRequestParams = {
  property_id?: number | null;
  page: number;
  sort: number;
  keyword: string;
  size_per_page?: number;
};

export type APIGetInspectionChecklistsDownloadRequestParams = {
  id?: string;
  is_all: boolean;
  sort: number;
  tag?: string;
  property_id?: number;
  keyword?: string;
  title?: string;
  description?: string;
  inspection_min?: number;
  inspection_max?: number;
  has_format?: boolean;
  status?: number;
};

export type APIGetInspectionChecklistsResponse = {
  result: {
    inspection_list: APIInspectionChecklists[];
    display_params: APIDisplayParam;
  };
};

export type APIGetInspectionChecklistResponse = {
  message: string;
  result: APIInspectionChecklist;
};

export type APIDeleteInspectionChecklistRequest = {
  inspection_checklist: { inspection_checklist_id: number }[];
};

export type APIGetInspectionChecklistFormatPlaceholdersResponse = {
  result: APIInspectionChecklistFormatPlaceholders[];
};

export type APIDownloadInspectionChecklistFormatResponse = {
  result: {
    file_name: string;
    file_content: string;
  };
};
