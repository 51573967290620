import {
  InspectionChecklistState,
  InspectionChecklistAction,
  InspectionChecklistActionTypes as ActionTypes,
} from './types';

const initialInspectionChecklistState: InspectionChecklistState = {
  refresh: {},
  isLoading: false,
  isSubmitting: false,
  isNavigating: false,
  inspectionChecklists: [],
  inspectionChecklistsListInfo: null,
  inspectionChecklist: null,
  inspectionChecklistFormatPlaceholders: [],
};

const inspectionChecklistsReducer = (
  state: InspectionChecklistState = initialInspectionChecklistState,
  action: InspectionChecklistAction,
): InspectionChecklistState => {
  switch (action.type) {
    case ActionTypes.INIT:
      return initialInspectionChecklistState;

    case ActionTypes.REFRESH:
      return { ...state, refresh: {} };

    case ActionTypes.LOADING:
      return { ...state, isLoading: action.payload };

    case ActionTypes.SUBMITTING:
      return { ...state, isSubmitting: action.payload };

    case ActionTypes.NAVIGATING:
      return { ...state, isNavigating: action.payload };

    case ActionTypes.SET_INSPECTION_CHECKLISTS:
      return { ...state, inspectionChecklists: action.payload };

    case ActionTypes.SET_INSPECTION_CHECKLISTS_LIST_INFO:
      return { ...state, inspectionChecklistsListInfo: action.payload };

    case ActionTypes.SET_INSPECTION_CHECKLIST:
      return { ...state, inspectionChecklist: action.payload };

    case ActionTypes.SET_INSPECTION_CHECKLIST_FORMAT_PLACEHOLDERS:
      return { ...state, inspectionChecklistFormatPlaceholders: action.payload };

    default:
      return state;
  }
};

export default inspectionChecklistsReducer;
