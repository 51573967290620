import type { Action, PayloadAction } from '@reduxjs/toolkit';

export type WorkState = {
  refresh: Record<string, never>;
  refresh2: Record<string, never>;
  refresh3: Record<string, never>;
  isLoading: boolean;
  isLoading2: boolean; // sub-loading
  isLoading3: boolean; // sub-loading(table内のLoading)
  isSubmitting: boolean;
  isNavigating: boolean;
  isNavigatingTo: number | null;
  works: WorksList[];
  worksListInfo: WorksListInfo | null;
  worksItemData: WorksItemData | null;
  work: Work | null;
  workHistory: WorkHistory[];
  workInfo: WorkInfo | null;
};

export enum WorkActionTypes {
  INIT = 'work/init',
  REFRESH = 'work/refresh',
  REFRESH2 = 'work/refresh2',
  REFRESH3 = 'work/refresh3',
  LOADING = 'work/set/isLoading',
  LOADING2 = 'work/set/isLoading2',
  LOADING3 = 'work/set/isLoading3',
  SUBMITTING = 'work/set/isSubmitting',
  NAVIGATING = 'work/set/isNavigating',
  NAVIGATING_TO = 'work/set/isNavigatingTo',
  SET_WORKS = 'work/set/works',
  SET_WORKS_LIST_INFO = 'work/set/worksListInfo',
  SET_WORKS_ITEM_DATA = 'work/set/worksItemData',
  SET_WORK = 'work/set/work',
  SET_WORK_HISTORY = 'work/set/workHistory',
  SET_WORK_INFO = 'work/set/workInfo',
}

export type WorkAction =
  | Action<WorkActionTypes.INIT>
  | Action<WorkActionTypes.REFRESH>
  | Action<WorkActionTypes.REFRESH2>
  | Action<WorkActionTypes.REFRESH3>
  | PayloadAction<boolean, WorkActionTypes.LOADING>
  | PayloadAction<boolean, WorkActionTypes.LOADING2>
  | PayloadAction<boolean, WorkActionTypes.LOADING3>
  | PayloadAction<boolean, WorkActionTypes.SUBMITTING>
  | PayloadAction<boolean, WorkActionTypes.NAVIGATING>
  | PayloadAction<number | null, WorkActionTypes.NAVIGATING_TO>
  | PayloadAction<WorksList[], WorkActionTypes.SET_WORKS>
  | PayloadAction<WorksListInfo | null, WorkActionTypes.SET_WORKS_LIST_INFO>
  | PayloadAction<WorksItemData | null, WorkActionTypes.SET_WORKS_ITEM_DATA>
  | PayloadAction<Work | null, WorkActionTypes.SET_WORK>
  | PayloadAction<WorkHistory[], WorkActionTypes.SET_WORK_HISTORY>
  | PayloadAction<WorkInfo | null, WorkActionTypes.SET_WORK_INFO>;

// -- State Types ---------------------------------------------------------- //

export type WorksList = {
  propertyId: number;
  propertyName: string;
  troubleId: number;
  title: string;
  priorityId: number;
  priorityName: string;
  troubleType: number;
  troubleTypeName: string;
  statusId: number;
  statusName: string;
  occurrenceDate: string;
  occurrenceTime: string;
  description: string;
  picUserId: number;
  picUserName: string;
  isDeletedUser: boolean;
  latestComment: string;
  updateUserName: string;
  updateDateTime: string;
  dueDate: string;
};

export type Work = {
  troubleId: number;
  title: string;
  matterId: string;
  troubleType: number;
  troubleTypeName: string;
  dueDate: string;
  priorityId: number;
  priorityName: string;
  statusId: number;
  statusName: string;
  propertyId: number;
  propertyName: string;
  floorId: number;
  floorName: string;
  roomId: number;
  roomName: string;
  diagramId: number;
  diagramName: string;
  equipmentId: number;
  equipmentName: string;
  isDeletedEquipment: false;
  occurrenceDate: string;
  occurrenceTime: string | null;
  picUserId: number;
  picUserName: string;
  isDeletedUser: boolean;
  updateDateTime: string;
  description: string;
  imageList: workImageList[];
  fileList: FileList[];
  inspectionChecklistId: number;
  inspectionResultId: number;
  componentId: string;
  componentName: string;
};

export type FileList = {
  fileName: string;
  fileUrl: string;
  updateDatetime: string;
  updateUserName: string;
  fileSize: number;
};

export type workImageList = {
  fileId: number;
  fileName: string;
  fileSize: number;
  fileUrl: string;
  isMovie: boolean;
  memo: string;
  thumbnailContent: string;
  thumnailUrl: string;
};

export type ImageList = {
  fileName: string;
  fileUrl: string;
  thumbnailUrl: string;
  memo: string;
  isMovie?: boolean;
  fileSize: number;
};

export type WorksListInfo = {
  totalHits: number;
  totalPages: number;
};

/**
 * 案件詳細検索に使用されるlocationのstateタイプ
 */
export interface WorkAdvancedQuery {
  page?: number;
  sort?: string;
  secondSort?: string;
  sizePerPage?: number;
  showCompleted: boolean;
  propertyId?: number;
  tag?: string | null;
  keyword?: string;
  type?: number;
  dueDateSince?: string;
  dueDateUntil?: string;
  priority?: number;
  status?: number;
  picUser?: string;
  approvalUser?: string;
  startSince?: string;
  startUntil?: string;
  endSince?: string;
  endUntil?: string;
  title?: string;
}

/** 案件検索時に使用されるlocationのstate */
export type WorksListQuery = WorkAdvancedQuery;

/**
 * 案件一覧画面のlocationのstate
 */

export type WorkListState = WorksListQuery &
  WorkAdvancedQuery & {
    order: boolean;
    pageH: number;
  };

export type WorksItemData = {
  troubleTypeList: {
    troubleTypeId: number;
    troubleTypeName: string;
  }[];
  troublePriorityList: {
    troublePriorityId: number;
    troublePriorityName: string;
  }[];
  troubleStatusList: {
    troubleStatusId: number;
    troubleStatusName: string;
  }[];
  diagramList: {
    diagramId: number;
    diagramName: string;
  }[];
  equipmentList: {
    equipmentId: number;
    equipmentName: string;
    diagramId: number;
  }[];
  floorList: {
    floorId: number;
    floorName: string;
    roomList: {
      roomId: number;
      roomName: string;
    }[];
  }[];
  userList: {
    userId: number;
    userName: string;
  }[];
};

// 案件登録
export type WorkCreateSubmitData = {
  title: string;
  troubleType: number;
  priorityId: number;
  statusId: number;
  dueDate: string | null;
  diagramId: number | null;
  equipmentId: number | null;
  floorId: number | null;
  roomId: number | null;
  occurrenceDate: string;
  occurrenceTime: string | null;
  picUserId: number;
  description: string;
  imageList: CreateImageList[];
  fileList: CreateFileList[];
};

export type CreateImageList = {
  fileName: string;
  fileContent: string;
  memo: string;
};

export type CreateFileList = {
  fileName: string;
  fileContent: string;
};

// 案件更新
export type WorkUpdateSubmitDate = {
  // trouble_id: number;
  status_id: number;
  title: string;
  trouble_type: number;
  priority_id: number;
  due_date: string | null;
  diagram_id: number | null;
  equipment_id: number | null;
  floor_id: number | null;
  room_id: number | null;
  occurrence_date: string;
  occurrence_time: string | null;
  pic_user_id: number;
  description: string;
  image_list: UpdateImageList[];
  file_list: UpdateFileList[];
};

export type UpdateImageList = {
  file_name: string;
  file_content: string;
  is_delete_file: boolean;
  memo: string;
};

export type UpdateFileList = {
  file_name: string;
  file_content: string;
  is_delete_file: boolean;
};

/** @summary 伝達事項編集type*/
export type WorkInfoMessage = {
  message: string;
};

export type WorkHistory = {
  troubleShootingId: number;
  troubleTypeId: number;
  troubleTypeName: string;
  iconId: number;
  iconName: string;
  responseUserId: number;
  responseUserName: string;
  isDeletedResponseUser: boolean;
  beforeStatus: number;
  beforeStatusName: string;
  afterStatus: number;
  afterStatusName: string;
  responseStartDate: string;
  responseStartTime: string;
  responseEndDate: string;
  responseEndTime: string;
  responseDetail: string;
  updateUserId: number;
  updateUserName: string;
  isDeletedUpdateUser: boolean;
  createDatetime: string;
  updateDatetime: string;
  fileList: FileList[];
  imageList: workImageList[];
};

export type WorkHistorySubmitData = {
  nextStatus: number;
  responseDetail: string;
  responseEndDate: string | null;
  responseEndTime: string | null;
  responseStartDate: string | null;
  responseStartTime: string | null;
  responseUserId: number | null;
  troubleShootingTypeId: number;
  fileList: {
    fileName: string;
    fileContent: string;
    deleteFlag: boolean;
  }[];
  imageList: {
    fileName: string;
    fileContent: string;
    memo: string;
    deleteFlag: boolean;
  }[];
};

// -- API Types ------------------------------------------------------------ //

export type APIDisplayParam = {
  readonly num_per_page: number;
  readonly current_page: number;
  readonly total_page: number;
  readonly total: number;
  readonly sort: number;
};

export type APIWorks = {
  readonly property_id: number;
  readonly property_name: string;
  readonly trouble_id: number;
  readonly title: string;
  readonly priority_id: number;
  readonly priority_name: string;
  readonly trouble_type: number;
  readonly trouble_type_name: string;
  readonly status_id: number;
  readonly status_name: string;
  readonly occurrence_date: string;
  readonly occurrence_time: string;
  readonly description: string;
  readonly pic_user_id: number;
  readonly pic_user_name: string;
  readonly is_deleted_user: boolean;
  readonly latest_comment: string;
  readonly update_user_name: string;
  readonly update_datetime: string;
  readonly due_date: string;
};

export type APIWork = {
  readonly trouble_id: number;
  readonly title: string;
  readonly matter_id: string;
  readonly trouble_type: number;
  readonly trouble_type_name: string;
  readonly due_date: string;
  readonly priority_id: number;
  readonly priority_name: string;
  readonly status_id: number;
  readonly status_name: string;
  readonly property_id: number;
  readonly property_name: string;
  readonly floor_id: number;
  readonly floor_name: string;
  readonly room_id: number;
  readonly room_name: string;
  readonly diagram_id: number;
  readonly diagram_name: string;
  readonly equipment_id: number;
  readonly equipment_name: string;
  readonly is_deleted_equipment: false;
  readonly occurrence_date: string;
  readonly occurrence_time: string;
  readonly pic_user_id: number;
  readonly pic_user_name: string;
  readonly is_deleted_user: boolean;
  readonly update_datetime: string;
  readonly description: string;
  readonly image_list: WorkImageList[];
  readonly file_list: WorkFileList[];
  readonly inspection_checklist_id: number;
  readonly inspection_result_id: number;
  readonly component_id: string;
  readonly component_name: string;
};

export type WorkImageList = {
  file_id: number;
  file_name: string;
  file_size: number;
  file_url: string;
  is_movie: boolean;
  memo: string;
  thumbnail_content: string;
  thumnail_url: string;
};

export type WorkFileList = {
  file_name: string;
  file_url: string;
  update_datetime: string;
  update_user_name: string;
  file_size: number;
};

export type WorkInfo = {
  troubleId: number;
  title: string;
  propertyId: number;
  propertyName: string;
  message: string;
  files: WorkInfoFiles[];
};

export type WorkInfoFiles = {
  fileId: number;
  fileName: string;
  fileUrl: string;
  fileSize: number;
};

export type APIWorksItemData = {
  readonly trouble_type_list: {
    readonly trouble_type_id: number;
    readonly trouble_type_name: string;
  }[];
  readonly trouble_priority_list: {
    readonly trouble_priority_id: number;
    readonly trouble_priority_name: string;
  }[];
  readonly trouble_status_list: {
    readonly trouble_status_id: number;
    readonly trouble_status_name: string;
  }[];
  readonly diagram_list: {
    readonly diagram_id: number;
    readonly diagram_name: string;
  }[];
  readonly equipment_list: {
    readonly equipment_id: number;
    readonly equipment_name: string;
    readonly diagram_id: number;
  }[];
  readonly floor_list: {
    readonly floor_id: number;
    readonly floor_name: string;
    readonly room_list: {
      readonly room_id: number;
      readonly room_name: string;
    }[];
  }[];
  readonly user_list: {
    readonly user_id: number;
    readonly last_name: string;
    readonly first_name: string;
  }[];
};

/** 不具合対応一覧取得（案件詳細：対応履歴一覧） */
export type APIWorkHistory = {
  readonly trouble_shooting_id: number;
  readonly trouble_type_id: number;
  readonly trouble_type_name: string;
  readonly icon_id: number;
  readonly icon_name: string;
  readonly response_user_id: number;
  readonly response_user_name: string;
  readonly is_deleted_response_user: boolean;
  readonly before_status: number;
  readonly before_status_name: string;
  readonly after_status: number;
  readonly after_status_name: string;
  readonly response_start_date: string;
  readonly response_start_time: string;
  readonly response_end_date: string;
  readonly response_end_time: string;
  readonly response_detail: string;
  readonly update_user_id: number;
  readonly update_user_name: string;
  readonly is_deleted_update_user: boolean;
  readonly create_datetime: string;
  readonly update_datetime: string;
  readonly image_list: [
    {
      readonly file_id: number;
      readonly file_name: string;
      readonly file_size: number;
      readonly file_url: string;
      readonly is_movie: boolean;
      readonly memo: string;
      readonly thumbnail_content: string;
      readonly thumnail_url: string;
    },
  ];
  readonly file_list: [
    {
      readonly file_name: string;
      readonly file_url: string;
      readonly update_datetime: string;
      readonly update_user_name: string;
      readonly file_size: number;
    },
  ];
};

export type APIWorkHistoryDisplayParams = {
  num_per_page: number;
  current_page: number;
  total_page: number;
  total: number;
  sort: number;
};

export type APICreateWork = {
  readonly title: string;
  readonly trouble_type: number;
  readonly priority_id: number;
  readonly status_id: number;
  readonly due_date: string | null;
  readonly diagram_id: number | null;
  readonly equipment_id: number | null;
  readonly floor_id: number | null;
  readonly room_id: number | null;
  readonly occurrence_date: string;
  readonly occurrence_time: string | null;
  readonly pic_user_id: number;
  readonly description: string;
  readonly image_list: APICreateWorkImageList[];
  readonly file_list: APICreateWorkFileList[];
};

export type APICreateWorkImageList = {
  readonly file_name: string;
  readonly file_content: string;
  readonly memo: string;
};

export type APICreateWorkFileList = {
  readonly file_name: string;
  readonly file_content: string;
};

export type APIUpdateWork = {
  // readonly trouble_id: number;
  readonly status_id: number;
  readonly title: string;
  readonly trouble_type: number;
  readonly priority_id: number;
  readonly due_date: string | null;
  readonly diagram_id: number | null;
  readonly equipment_id: number | null;
  readonly floor_id: number | null;
  readonly room_id: number | null;
  readonly occurrence_date: string;
  readonly occurrence_time: string | null;
  readonly pic_user_id: number;
  readonly description: string;
  readonly image_list: APIUpdateWorkImageList[];
  readonly file_list: APIUpdateWorkFileList[];
};

export type APIUpdateWorkImageList = {
  readonly file_name: string;
  readonly file_content: string;
  readonly is_delete_file: boolean;
  readonly memo: string;
};

export type APIUpdateWorkFileList = {
  readonly file_name: string;
  readonly file_content: string;
  readonly is_delete_file: boolean;
};

export type APICreateWorkHistory = {
  readonly after_status: number;
  readonly response_detail: string;
  readonly response_end_date: string | null;
  readonly response_end_time: string | null;
  readonly response_start_date: string | null;
  readonly response_start_time: string | null;
  readonly response_user_id: number | null;
  readonly trouble_type_id: number;
  readonly file_list: {
    readonly file_name: string;
    readonly file_content: string;
  }[];
  readonly image_list: {
    readonly file_name: string;
    readonly file_content: string;
    readonly memo: string;
  }[];
};

export type APIUpdateWorkHistory = APICreateWorkHistory & {
  readonly file_list: {
    readonly is_delete_file: boolean;
  }[];
  readonly image_list: {
    readonly is_delete_file: boolean;
  }[];
};

// 不具合承認・差し戻し
export type APIApproveWorkHistory = {
  is_approved: boolean;
  comment: string;
};

/** 不具合取り戻し */
export type APITakeBackWorkHistory = {
  comment: string;
};

export type APIWorkInfo = {
  trouble_id: number;
  title: string;
  property_id: number;
  property_name: string;
  message: string;
  file_list: {
    file_id: number;
    file_name: string;
    file_url: string;
    file_size: number;
  }[];
};

export type APIGetWorksRequestParams = {
  page: number;
  sort: string;
  size_per_page: number;
  keyword?: string;
  show_completed?: boolean;
  property_id?: number;
  tag?: string | null;
  type?: number;
  due_date_since?: string;
  due_date_until?: string;
  priority?: number;
  status?: number;
  pic_user?: string;
  approval_user?: string;
  start_since?: string;
  start_until?: string;
  end_since?: string;
  end_until?: string;
  title?: string;
};
export type APIGetWorksResponse = {
  result: {
    trouble_list: APIWorks[];
    display_params: APIDisplayParam;
  };
};

export type APIGetWorksItemDataResponse = {
  result: APIWorksItemData;
};

export type APIGetWorkResponse = {
  result: APIWork;
};

export type APIGetWorkHistoryResponse = {
  result: {
    trouble_shooting_list: APIWorkHistory[];
    display_params: APIWorkHistoryDisplayParams;
  };
};

export type APICreateWorkRequestParams = {
  property_id: number;
};
export type APICreateWorkRequest = APICreateWork;
export type APICreateWorkResponse = {
  result: { trouble_id: number };
};

export type APIUpdateWorkRequestParams = APICreateWorkRequestParams;
export type APIUpdateWorkRequest = APIUpdateWork;

export type APIDeleteWorksRequest = {
  trouble_list: { trouble_id: number }[];
};

export type APICreateWorkHistoryRequestParams = APICreateWorkRequestParams;
export type APICreateWorkHistoryRequest = APICreateWorkHistory;

export type APIUpdateWorkHistoryRequestParams = APICreateWorkHistoryRequestParams;
export type APIUpdateWorkHistoryRequest = APIUpdateWorkHistory;

export type APIExportWorkReportResponse = {
  message: string;
  result: {
    file_name: string;
    file_content: string;
  };
};

export type APIGetWorkInformationResponse = {
  result: APIWorkInfo;
};

/** @summary  /api/constructions/{construction_id}/information/message(伝達事項編集)*/
export type APIPutWorkInfoMessageRequest = WorkInfoMessage;

export type APIApproveWorkHistoryRequest = APIApproveWorkHistory;

/** @summary  /api/troubles/{trouble_id}/uncompleted 不具合完了取り戻し */
export type APITakeBackWorkHistoryRequest = APITakeBackWorkHistory;
