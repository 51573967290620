import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

import affiliateReducer, { AffiliateState } from './affiliate';
import archiveReducer, { ArchiveState } from './archive';
import commonReducer, { CommonState } from './common';
import constructionReducer, { ConstructionState } from './construction';
import diagramReducer, { DiagramState } from './diagram';
import equipmentReducer, { EquipmentState } from './equipment';
import floorMapReducer, { FloorMapState } from './floorMap';
import groupReducer, { GroupState } from './group';
import homeReducer, { HomeState } from './home';
import notificationReducer, { NotificationState } from './notification';
import inspectionChecklistEditReducer, {
  InspectionChecklistEditState,
} from './inspectionChecklistEdit';
import inspectionChecklistReducer, { InspectionChecklistState } from './inspectionChecklist';
import inspectionReducer, { InspectionState } from './inspection';
import planReducer, { PlanState } from './plan';
import propertyReducer, { PropertyState } from './property';
import TagReducer, { TagState } from './tags';
import userReducer, { UserState } from './user';
import userLicenseReducer, { UserLicenseState } from './userLicense';
import workReducer, { WorkState } from './work';
import selectAffiliateReducer, { SelectAffiliateState } from './selectAffiliate';
import selectMultipleAffiliateReducer, {
  SelectMultipleAffiliateState,
} from './selectMultipleAffiliate';
import checkingReducer, { CheckingState } from './checking';

export type AppState = {
  affiliate: AffiliateState;
  archive: ArchiveState;
  common: CommonState;
  construction: ConstructionState;
  diagram: DiagramState;
  equipment: EquipmentState;
  floorMap: FloorMapState;
  group: GroupState;
  home: HomeState;
  inspection: InspectionState;
  inspectionChecklist: InspectionChecklistState;
  inspectionChecklistEdit: InspectionChecklistEditState;
  notification: NotificationState;
  plan: PlanState;
  property: PropertyState;
  tag: TagState;
  user: UserState;
  userLicense: UserLicenseState;
  work: WorkState;
  selectAffiliate: SelectAffiliateState;
  selectMultipleAffiliate: SelectMultipleAffiliateState;
  checking: CheckingState;
};

const reducer = combineReducers<AppState>({
  affiliate: affiliateReducer,
  archive: archiveReducer,
  common: commonReducer,
  construction: constructionReducer,
  diagram: diagramReducer,
  equipment: equipmentReducer,
  floorMap: floorMapReducer,
  group: groupReducer,
  home: homeReducer,
  inspection: inspectionReducer,
  inspectionChecklist: inspectionChecklistReducer,
  inspectionChecklistEdit: inspectionChecklistEditReducer,
  notification: notificationReducer,
  plan: planReducer,
  property: propertyReducer,
  tag: TagReducer,
  user: userReducer,
  userLicense: userLicenseReducer,
  work: workReducer,
  selectAffiliate: selectAffiliateReducer,
  selectMultipleAffiliate: selectMultipleAffiliateReducer,
  checking: checkingReducer,
});

const middleWares: any[] = [];
if (process.env.NODE_ENV === 'development') {
  const logger = createLogger({ collapsed: true, timestamp: false, diff: true });
  middleWares.push(logger);
}

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), ...middleWares],
});

export default store;
