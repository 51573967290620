import {
  ArchiveAction as Action,
  ArchiveActionTypes as ActionTypes,
  ArchiveList,
  PathInfo,
} from './types';

export const init = (): Action => ({ type: ActionTypes.INIT });

export const refresh = (): Action => ({ type: ActionTypes.REFRESH });

export const setIsLoading = (isLoading: boolean): Action => ({
  type: ActionTypes.LOADING,
  payload: isLoading,
});

export const setIsSubmitting = (isSubmitting: boolean): Action => ({
  type: ActionTypes.SUBMITTING,
  payload: isSubmitting,
});

export const setArchives = (
  archives: ArchiveList[],
  paths: PathInfo[],
  parentArchiveId: number | null,
): Action => ({
  type: ActionTypes.SET_ARCHIVES,
  payload: { parentArchiveId, paths, archives },
});

export const setCheckFilesOverWrite = (files: string[]): Action => ({
  type: ActionTypes.SET_CHECK_FILES_OVERWRITE,
  payload: files,
});
