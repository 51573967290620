import { WorkState, WorkAction, WorkActionTypes as ActionTypes } from './types';

const initialWorkState: WorkState = {
  refresh: {},
  refresh2: {},
  refresh3: {},
  isLoading: false,
  isLoading2: false,
  isLoading3: false,
  isSubmitting: false,
  isNavigating: false,
  isNavigatingTo: null,
  works: [],
  worksListInfo: null,
  worksItemData: null,
  work: null,
  workHistory: [],
  workInfo: null,
};

const workReducer = (state: WorkState = initialWorkState, action: WorkAction): WorkState => {
  switch (action.type) {
    case ActionTypes.INIT:
      return initialWorkState;

    case ActionTypes.REFRESH:
      return { ...state, refresh: {} };

    case ActionTypes.REFRESH2:
      return { ...state, refresh2: {} };

    case ActionTypes.REFRESH3:
      return { ...state, refresh3: {} };

    case ActionTypes.LOADING:
      return { ...state, isLoading: action.payload };

    case ActionTypes.LOADING2:
      return { ...state, isLoading2: action.payload };

    case ActionTypes.LOADING3:
      return { ...state, isLoading3: action.payload };

    case ActionTypes.SUBMITTING:
      return { ...state, isSubmitting: action.payload };

    case ActionTypes.NAVIGATING:
      return { ...state, isNavigating: action.payload };

    case ActionTypes.NAVIGATING_TO:
      return { ...state, isNavigatingTo: action.payload };

    case ActionTypes.SET_WORKS:
      return { ...state, works: action.payload };

    case ActionTypes.SET_WORKS_LIST_INFO:
      return { ...state, worksListInfo: action.payload };

    case ActionTypes.SET_WORKS_ITEM_DATA:
      return { ...state, worksItemData: action.payload };

    case ActionTypes.SET_WORK:
      return { ...state, work: action.payload };

    case ActionTypes.SET_WORK_HISTORY:
      return { ...state, workHistory: action.payload };

    case ActionTypes.SET_WORK_INFO:
      return { ...state, workInfo: action.payload };

    default:
      return state;
  }
};

export default workReducer;
