import {
  UserLicenseAction as Action,
  UserLicenseActionTypes as ActionTypes,
  LicenseInfo,
} from './types';

export const init = (): Action => ({ type: ActionTypes.INIT });

export const refresh = (): Action => ({ type: ActionTypes.REFRESH });

export const setIsLoading = (isLoading: boolean): Action => ({
  type: ActionTypes.LOADING,
  payload: isLoading,
});

export const setLicenseInfo = (data: LicenseInfo | null): Action => ({
  type: ActionTypes.SET_LICENSE_INFO,
  payload: data,
});
