import { EquipmentState, EquipmentAction, EquipmentActionTypes as ActionTypes } from './types';

const initialEquipmentState: EquipmentState = {
  refresh: {},
  isLoading: false,
  isLoading2: false,
  isSubmitting: false,
  isNavigating: false,
  isNavigatingTo: null,
  equipments: [],
  equipmentsListInfo: null,
  equipment: null,
  equipmentHistory: [],
  equipmentHistoryLoaded: false,
  equipmentSqsesData: [],
  equipmentSqsesDataLoaded: false,
  optionsForEdit: null,
};

const equipmentReducer = (
  state: EquipmentState = initialEquipmentState,
  action: EquipmentAction,
): EquipmentState => {
  switch (action.type) {
    case ActionTypes.INIT:
      return initialEquipmentState;

    case ActionTypes.REFRESH:
      return { ...state, refresh: {} };

    case ActionTypes.LOADING:
      return { ...state, isLoading: action.payload };

    case ActionTypes.LOADING2:
      return { ...state, isLoading2: action.payload };

    case ActionTypes.SUBMITTING:
      return { ...state, isSubmitting: action.payload };

    case ActionTypes.NAVIGATING:
      return { ...state, isNavigating: action.payload };

    case ActionTypes.NAVIGATING_TO:
      return { ...state, isNavigatingTo: action.payload };

    case ActionTypes.SET_EQUIPMENTS:
      return { ...state, equipments: action.payload };

    case ActionTypes.SET_EQUIPMENTS_LIST_INFO:
      return { ...state, equipmentsListInfo: action.payload };

    case ActionTypes.SET_EQUIPMENT:
      return { ...state, equipment: action.payload };

    case ActionTypes.SET_EQUIPMENT_HISTORY:
      return { ...state, equipmentHistory: action.payload, equipmentHistoryLoaded: true };

    case ActionTypes.SET_EQUIPMENT_SQSES_DATA:
      return { ...state, equipmentSqsesData: action.payload, equipmentSqsesDataLoaded: true };

    case ActionTypes.SET_OPTIONS_FOR_EDIT:
      return { ...state, optionsForEdit: action.payload };

    default:
      return state;
  }
};

export default equipmentReducer;
