import type { Action, PayloadAction } from '@reduxjs/toolkit';

export type CommonState = {
  refresh: Record<string, never>;
  isLoading: boolean;
  /** ProcessingLoaderの表示フラグ */
  isProcessing: boolean;
  isSubmitting: boolean;
  selectedTagIds: number[];
  /** 建物選択用建物リスト */
  propertyList: PropertyListWithIsFavorite[];
  /** タグ選択用建物リスト */
  propertyListFilteredByTagIds: PropertyListWithIsFavorite[];
  selectedPropertyId: number | null;
  profile: Profile | null;
  runtimeError: RuntimeError | null;
};

export enum CommonActionTypes {
  REFRESH = 'template/refresh',
  LOADING = 'common/set/isLoading',
  PROCESSING = 'common/set/isProcessing',
  SUBMITTING = 'common/set/isSubmitting',
  SET_PROPERTY_LIST = 'common/set/propertyList',
  SET_PROPERTY_LIST_FILTERED_BY_TAG_IDS = 'common/set/propertyListFilteredByTagIds',
  SET_PROPERTY_ID = 'common/set/selectedPropertyId',
  SET_SELECTED_TAG_IDS = 'common/set/selectedTagIds',
  SET_PROFILE = 'common/set/profile',
  SET_RUNTIME_ERROR = 'common/set/runtimeError',
}

export type CommonAction =
  | Action<CommonActionTypes.REFRESH>
  | PayloadAction<boolean, CommonActionTypes.LOADING>
  | PayloadAction<boolean, CommonActionTypes.PROCESSING>
  | PayloadAction<boolean, CommonActionTypes.SUBMITTING>
  | PayloadAction<PropertyListWithIsFavorite[], CommonActionTypes.SET_PROPERTY_LIST>
  | PayloadAction<
      PropertyListWithIsFavorite[],
      CommonActionTypes.SET_PROPERTY_LIST_FILTERED_BY_TAG_IDS
    >
  | PayloadAction<number[], CommonActionTypes.SET_SELECTED_TAG_IDS>
  | PayloadAction<number | null, CommonActionTypes.SET_PROPERTY_ID>
  | PayloadAction<Profile | null, CommonActionTypes.SET_PROFILE>
  | PayloadAction<RuntimeError | null, CommonActionTypes.SET_RUNTIME_ERROR>;

// -- State Types ---------------------------------------------------------- //

export type Property = {
  id: number;
  name: string;
};

export type PropertyListWithIsFavorite = Property & { is_favorite: boolean };

export type Profile = {
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  icon: string | null;
  isSystemAdmin: boolean;
  properties: {
    id: number;
    roleId: number; // 1: 作業者, 2: 管理者
  }[];
};

export type RuntimeError = {
  error: Error;
  componentStack: string;
};

export type TargetMenuType =
  | 'property'
  | 'equipment'
  | 'floor'
  | '/plan/annual'
  | '/plan/monthly'
  | '/plan/daily'
  | 'checking'
  | 'inspection'
  | 'construction'
  | 'work'
  | 'archive'
  | '/inspection/checklist';

export type DestinationPathDataType = {
  [key in TargetMenuType]: {
    individual: string;
    whole: string;
    filtered_by_tag: string;
  };
};

// -- API Types ------------------------------------------------------------ //
type APIProfile = {
  readonly user_id: number;
  readonly first_name: string;
  readonly last_name: string;
  readonly email: string;
  readonly is_system_role: boolean;
  readonly file_url: string | null;
  readonly property_list: { readonly property_id: number; readonly property_role_id: number }[];
};

export type APIGetPropertyListResponse = {
  message: string;
  result: { property_id: number; property_name: string; is_favorite: boolean }[];
};

export type APIPostRegisterAndRemovePropertyFavoriteResponse = {
  message: string;
  result: {
    message: string;
    result: {
      property_id: number;
      property_name: string;
      is_favorite: boolean;
    };
  };
};

export type APIGetProfileResponse = {
  message: string;
  result: APIProfile;
};
