import {
  AnnualPlanTable,
  AnnualPlanTableSummary,
  PlanAnnualTableAction as Action,
  PlanAnnualTableActionTypes as ActionTypes,
  SearchParameter,
  AnnualPlanTableSalesSummary,
} from './types';

/**
 * @summary 初期化処理
 * @returns {Action}
 */
export const init = (): Action => ({ type: ActionTypes.INIT });

/**
 * @summary リフレッシュ処理
 * @returns {Action}
 */
export const refresh = (): Action => ({ type: ActionTypes.REFRESH });

/**
 * @summary Loading状態を保持
 * @param {boolean} isLoading
 * @returns {Action}
 */
export const setIsLoading = (isLoading: boolean): Action => ({
  type: ActionTypes.LOADING,
  payload: isLoading,
});

/**
 * @summary 送信処理中かを保持
 * @param {boolean} isSubmitting
 * @returns {Action}
 */
export const setIsSubmitting = (isSubmitting: boolean): Action => ({
  type: ActionTypes.SUBMITTING,
  payload: isSubmitting,
});

/**
 * @summary ソート番号を保持
 * @param {number} sort
 * @returns {Action}
 */
export const setSort = (sort: number): Action => ({
  type: ActionTypes.SET_SORT,
  payload: sort,
});

/**
 * @summary 詳細検索条件を保持
 * @param {SearchParameter} searchParameter
 * @returns {Action}
 */
export const setSearchParameter = (searchParameter: SearchParameter): Action => ({
  type: ActionTypes.SET_SEARCH_PARAMETER,
  payload: searchParameter,
});

/**
 * @summary 年次販売集計リストを保持
 * @param {AnnualPlanTable[]} tables
 * @returns {Action}
 */
export const setTables = (tables: AnnualPlanTable[]): Action => ({
  type: ActionTypes.SET_TABLES,
  payload: tables,
});

/**
 * @summary 年次計画サマリを保持
 * @param {AnnualPlanTableSummary | null} summary
 * @returns {Action}
 */
export const setSummary = (summary: AnnualPlanTableSummary | null): Action => ({
  type: ActionTypes.SET_SUMMARY,
  payload: summary,
});

/**
 * @summary 年次販売集計サマリを保持
 * @param {AnnualPlanTableSalesSummary | null} salesSummary
 * @returns {Action}
 */
export const setSalesSummary = (salesSummary: AnnualPlanTableSalesSummary | null): Action => ({
  type: ActionTypes.SET_SALES_SUMMARY,
  payload: salesSummary,
});
