import { AppState as State } from '@ducks/index';

export const getRefreshId = (state: State) => state.property.refresh;
export const getRefreshId2 = (state: State) => state.property.refresh2;

export const checkIsLoading = (state: State) => state.property.isLoading;
export const checkIsLoading2 = (state: State) => state.property.isLoading2;
export const checkIsLoading3 = (state: State) => state.property.isLoading3;
export const checkIsSubmitting = (state: State) => state.property.isSubmitting;
export const checkIsNavigating = (state: State) => state.property.isNavigating;
export const checkIsNavigatingTo = (state: State) => state.property.isNavigatingTo;
export const checkIsNeedConfirm = (state: State) => state.property.isNeedConfirm;

export const getProperties = (state: State) => state.property.properties;
export const getPropertiesListInfo = (state: State) => state.property.propertiesListInfo;
export const getProperty = (state: State) => state.property.property;
export const getFloors = (state: State) => state.property.floors;
export const getRooms = (state: State) => state.property.rooms;
export const getMembers = (state: State) => state.property.members;
export const getMembersListInfo = (state: State) => state.property.membersListInfo;
export const getSelectMembers = (state: State) => state.property.selectMembers;
export const getSelectMembersListInfo = (state: State) => state.property.selectMembersListInfo;
export const getTenants = (state: State) => state.property.tenants;
export const getTenantsListInfo = (state: State) => state.property.tenantsListInfo;

export const getAddressAutofill = (state: State) => state.property.addressAutofill;
export const getPropertyInformation = (state: State) => state.property.information;

export const getContracts = (state: State) => state.property.contracts;
export const getContractsListInfo = (state: State) => state.property.contractsListInfo;
export const getContract = (state: State) => state.property.contract;
export const getDocumentFileSize = (state: State) => state.property.documentFileSize;
