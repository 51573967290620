import {
  UserAction as Action,
  UserActionTypes as ActionTypes,
  User,
  UsersList,
  UsersListInfo,
} from './types';

export const init = (): Action => ({ type: ActionTypes.INIT });

export const refresh = (): Action => ({ type: ActionTypes.REFRESH });

export const setIsLoading = (isLoading: boolean): Action => ({
  type: ActionTypes.LOADING,
  payload: isLoading,
});

export const setIsSubmitting = (isSubmitting: boolean): Action => ({
  type: ActionTypes.SUBMITTING,
  payload: isSubmitting,
});

export const setIsNavigating = (isNavigating: boolean): Action => ({
  type: ActionTypes.NAVIGATING,
  payload: isNavigating,
});

export const setIsNavigatingTo = (isNavigatingTo: number | null): Action => ({
  type: ActionTypes.NAVIGATING_TO,
  payload: isNavigatingTo,
});

export const setUsers = (users: UsersList[]): Action => ({
  type: ActionTypes.SET_USERS,
  payload: users,
});

export const setUsersListInfo = (info: UsersListInfo | null): Action => ({
  type: ActionTypes.SET_USERS_LIST_INFO,
  payload: info,
});

export const setUser = (user: User | null): Action => ({
  type: ActionTypes.SET_USER,
  payload: user,
});
