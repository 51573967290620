import type { Action, PayloadAction } from '@reduxjs/toolkit';

/**
 * @summary 連絡先選択モーダルで管理されるState
 */
export type SelectAffiliateState = {
  /** Load中か */
  isLoading: boolean;
  /** 連絡先リスト */
  userList: User[];
  /** 企業リスト */
  companyList: Company[];
  /** 建物担当者リスト */
  propertyUserList: User[];
  /** 選択中のタブ */
  tab: TabType | null;
  /** 建物担当者検索パラメータ */
  propertyUserParameter: APIGetPropertyUserPropertiesRequestParams;
  /** 選択状態の連絡先|企業|建物担当者 */
  selectedItem: SelectedItem;
  /** 選択を確定した連絡先の種類 */
  submitItemType: TabType | null;
  /** 選択を確定した連絡先 */
  submitUserItem: User | null;
  /** 選択を確定した企業 */
  submitCompanyItem: Company | null;
  /** 選択を確定した建物担当者 */
  submitPropertyUserItem: User | null;
};

/**
 * @summary 連絡先選択モーダルのAction Type
 */
export enum SelectAffiliateActionTypes {
  INIT = 'selectAffiliate/init',
  LOADING = 'selectAffiliate/set/isLoading',
  SET_USER_LIST = 'selectAffiliate/set/user_list',
  SET_COMPANY_LIST = 'selectAffiliate/set/company_list',
  SET_PROPERTY_USER_LIST = 'selectAffiliate/set/property_user_list',
  SET_TAB = 'selectAffiliate/set/tab',
  SET_PROPERTY_USER_SEARCH_PARAMETER = 'selectAffiliate/set/property_user_search_parameter',
  SET_SELECTED_ITEM = 'selectAffiliate/set/selected_item',
  SET_SUBMIT_ITEM_TYPE = 'selectAffiliate/set/submit_item_type',
  SET_SUBMIT_USER_ITEM = 'selectAffiliate/set/submit_user_item',
  SET_SUBMIT_COMPANY_ITEM = 'selectAffiliate/set/submit_company_item',
  SET_SUBMIT_PROPERTY_USER_ITEM = 'selectAffiliate/set/submit_property_user_item',
}

/**
 * @summary 連絡先・企業・建物担当者選択モーダルの表示対象のタブタイプ
 */
export type TabType =
  /** 連絡先 */
  | 'USER'
  /** 企業 */
  | 'COMPANY'
  /** 建物担当者 */
  | 'PROPERTY_USER';

/**
 * @summary 選択状態の連絡先|企業|建物担当者
 */
export type SelectedItem = {
  /** id */
  id: number;
  /** 連絡先、企業、建物担当者のいずれか */
  type: TabType;
} | null;

/**
 * @summary 連絡先情報
 */
export type User = {
  /** ユーザID */
  readonly user_id: number;
  /** ユーザ名 */
  readonly user_name: string;
  /** 企業ID */
  readonly company_id: string;
  /** 企業名 */
  readonly company_name: number;
  /** 所属 */
  readonly department: string;
  /** 担当 */
  readonly belong: string;
};

/**
 * @summary APIから返却される連絡先リスト
 */
export type APIGetUserResponse = {
  /** 連絡先リスト */
  result: User[];
};

/**
 * @summary 企業情報
 */
export type Company = {
  /** 企業ID */
  readonly company_id: number;
  /** 企業名 */
  readonly company_name: string;
  /** 住所 */
  readonly address: string;
};

/**
 * @summary APIから返却される企業リスト
 */
export type APIGetCompanyResponse = {
  /** 企業リスト */
  result: Company[];
};

/**
 * @summary 連絡先|企業|建物担当者リスト検索Param
 */
export type APIGetPropertiesRequestParams = {
  /** 検索キーワード */
  keyword: string;
} & APIGetPropertyUserPropertiesRequestParams;

/**
 * @summary 建物担当者リスト検索Param
 */
export type APIGetPropertyUserPropertiesRequestParams = {
  /** 建物ID */
  property_id?: number;
  /** 管理者のみか */
  is_admin?: boolean;
};

/**
 * @summary 連絡先選択モーダルのAction
 */
export type SelectAffiliateAction =
  /** 初期化 */
  | Action<SelectAffiliateActionTypes.INIT>
  /** Loading状態を保持 */
  | PayloadAction<boolean, SelectAffiliateActionTypes.LOADING>
  /** APIから取得したユーザを保持 */
  | PayloadAction<User[], SelectAffiliateActionTypes.SET_USER_LIST>
  /** APIから取得した企業を保持 */
  | PayloadAction<Company[], SelectAffiliateActionTypes.SET_COMPANY_LIST>
  /** APIから取得した建物担当者を保持 */
  | PayloadAction<User[], SelectAffiliateActionTypes.SET_PROPERTY_USER_LIST>
  /** タブの切り替え */
  | PayloadAction<TabType, SelectAffiliateActionTypes.SET_TAB>
  /** 建物担当者でのみ使用する検索パラメータを保持 */
  | PayloadAction<
      APIGetPropertyUserPropertiesRequestParams,
      SelectAffiliateActionTypes.SET_PROPERTY_USER_SEARCH_PARAMETER
    >
  /** 選択した状態の連絡先を保持 */
  | PayloadAction<SelectedItem, SelectAffiliateActionTypes.SET_SELECTED_ITEM>
  /** 選択確定したSelectAffiliateTabTypeを保持 */
  | PayloadAction<TabType, SelectAffiliateActionTypes.SET_SUBMIT_ITEM_TYPE>
  /** 選択確定した状態の連絡先を保持 */
  | PayloadAction<User | null, SelectAffiliateActionTypes.SET_SUBMIT_USER_ITEM>
  /** 選択確定した状態の企業を保持 */
  | PayloadAction<Company | null, SelectAffiliateActionTypes.SET_SUBMIT_COMPANY_ITEM>
  /** 選択確定した状態の建物担当者を保持 */
  | PayloadAction<User | null, SelectAffiliateActionTypes.SET_SUBMIT_PROPERTY_USER_ITEM>;
