import {
  FloorMapAction as Action,
  FloorMapActionTypes as ActionTypes,
  EditItemModeState,
  Equipment,
  EquipmentItem,
  Floor,
  FloorMapFilters,
  FloorMapItem,
  FloorMapItemTypes,
  Point,
  Room,
  RoomItem,
  Size,
} from './types';

export const init = (): Action => ({
  type: ActionTypes.INIT,
});

/**
 * @summary refresh()の実行を契機に画面のリフレッシュを行う
 * @description フロア、設備、部屋の再取得を行う
 */
export const refresh = (): Action => ({ type: ActionTypes.REFRESH });

/**
 * @summary floorMapRedraw()の実行を契機にフロア、設備、部屋の表示部の再描画を行う
 * @description フロア画像の変更を契機に要実行
 */
export const floorMapRedraw = (): Action => ({ type: ActionTypes.FLOOR_MAP_REDRAW });

export const setIsLoading = (isLoading: boolean): Action => ({
  type: ActionTypes.LOADING,
  payload: isLoading,
});

/** 図面画像の読み込み中かをセットする */
export const setIsImageLoading = (isImageLoading: boolean): Action => ({
  type: ActionTypes.IMAGE_LOADING,
  payload: isImageLoading,
});

export const setIsSubmitting = (isSubmitting: boolean): Action => ({
  type: ActionTypes.SUBMITTING,
  payload: isSubmitting,
});

/** API で取得したリソースをセットする */
export const setFloors = (data: Floor[]): Action => ({
  type: ActionTypes.SET_FLOORS,
  payload: data,
});

/** API で取得したリソースをセットする */
export const setRooms = (data: Room[]): Action => ({
  type: ActionTypes.SET_ROOMS,
  payload: data,
});

/** API で取得したリソースをセットする */
export const setEquipments = (data: Equipment[]): Action => ({
  type: ActionTypes.SET_EQUIPMENTS,
  payload: data,
});

/** API で取得したリソースをセットする */
export const setFloorMapUrl = (url: string | null): Action => ({
  type: ActionTypes.SET_FLOOR_MAP_URL,
  payload: url,
});

/** API で取得したリソースをセットする */
export const setFilters = (data: FloorMapFilters): Action => ({
  type: ActionTypes.SET_FILTERS,
  payload: data,
});

/** タブの切り替えを行う */
export const setTab = (tab: FloorMapItemTypes): Action => ({
  type: ActionTypes.SET_TAB,
  payload: tab,
});

/** アイテムを選択してフォーカスする */
export const setSelectedItem = (item: FloorMapItem | null): Action => ({
  type: ActionTypes.SET_SELECTED_ITEM,
  payload: item,
});

/**
 * 編集モードの切り替えを行う
 * @param mode true -> 編集モード, false -> 閲覧モード
 */
export const setEditMode = (mode: boolean): Action => ({
  type: ActionTypes.SET_EDIT_MODE,
  payload: mode,
});

/** アイテム編集モードの切り替えを行う */
export const setEditItemMode = (data: EditItemModeState | null): Action => ({
  type: ActionTypes.SET_EDIT_ITEM_MODE,
  payload: data,
});

/** アイテム編集モードで編集中の状態を管理する */
export const setEditItemPoints = (data: Point[]): Action => ({
  type: ActionTypes.SET_EDIT_ITEM_POINTS,
  payload: data,
});

/** 新規に定義された設備の座標を保存する */
export const addEditItemEquipment = (data: EquipmentItem): Action => ({
  type: ActionTypes.ADD_EDIT_ITEM_EQUIPMENT,
  payload: data,
});

/** 新規に定義された部屋の座標を保存する */
export const addEditItemRoom = (data: RoomItem): Action => ({
  type: ActionTypes.ADD_EDIT_ITEM_ROOM,
  payload: data,
});

/** フロアマップの拡大率を¹段階上げる */
export const upImageScale = (point: Point): Action => ({
  type: ActionTypes.UP_IMAGE_SCALE,
  payload: point,
});

/** フロアマップの拡大率を¹段階下げる */
export const downImageScale = (point: Point): Action => ({
  type: ActionTypes.DOWN_IMAGE_SCALE,
  payload: point,
});

/** 画像のサイズを計測してセットする */
export const setImageSize = (size: Size): Action => ({
  type: ActionTypes.SET_IMAGE_SIZE,
  payload: size,
});

/** フロアマップの表示位置を操作する（操作開始） */
export const moveMapStart = (point: Point): Action => ({
  type: ActionTypes.MOVE_MAP_START,
  payload: point,
});

/** フロアマップの表示位置を操作する */
export const moveMap = (point: Point): Action => ({
  type: ActionTypes.MOVE_MAP,
  payload: point,
});

/** フロアマップの表示位置を操作する（操作終了） */
export const moveMapEnd = (): Action => ({
  type: ActionTypes.MOVE_MAP_END,
});
