import React from 'react';
import { Suspense } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import { useAuth0 } from '@auth0/auth0-react';
import { auth0 } from '@providers/auth0AccessToken';
import { PrivateRoute } from '@utils/privateRoute';
import Loader from '@uiParts/loader';
import '@assets/styles/main.scss';

import * as Page from './pages';
import RuntimeErrorFallback, { useRuntimeError } from '@pages/common/runtimeErrorFallback';

const App = () => {
  const { runtimeErrorHandler } = useRuntimeError();

  const { getAccessTokenSilently, isLoading: isAuthenticateLoading, isAuthenticated } = useAuth0();
  auth0.setAccessTokenSilently(getAccessTokenSilently);
  const location = useLocation();
  const isOnRootPage = location.pathname === '/';

  if (isAuthenticateLoading) return <></>;
  if (isOnRootPage && !isAuthenticateLoading && isAuthenticated)
    return <Navigate replace to="/home" />;
  if (isOnRootPage && !isAuthenticateLoading && !isAuthenticated)
    return <Navigate replace to="/login" />;

  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary FallbackComponent={RuntimeErrorFallback} onError={runtimeErrorHandler}>
        <Routes>
          <Route index element={<></>} />
          <Route path="/login" element={<Page.Login />} />

          {/* Guest */}
          <Route
            path="/guest/inspection/:inspectionId"
            element={<Page.InspectionDetail guestMode={true} />}
          />
          <Route
            path="/guest/inspection/:inspectionId/edit"
            element={<Page.InspectionEdit guestMode={true} mode="EDIT" />}
          />
          <Route
            path="/guest/construction/:constructionId"
            element={<Page.ConstructionDetail guestMode={true} />}
          />
          <Route
            path="/guest/property/:propertyId"
            element={<Page.PropertyDetail guestMode={true} />}
          />
          <Route path="/guest/plan/adjustment" element={<Page.PlanAdjustment />} />

          <Route
            path="*"
            element={
              <PrivateRoute>
                <Page.CommonLayout>
                  <Suspense fallback={<Loader />}>
                    <AppRouteMain />
                  </Suspense>
                </Page.CommonLayout>
              </PrivateRoute>
            }
          />
        </Routes>
      </ErrorBoundary>
    </Suspense>
  );
};

export default App;

const AppRouteMain = () => {
  const queryParams = new URLSearchParams(location.search);
  const isFilteredByTagIds = queryParams.has('tag_id');

  return (
    <Routes>
      <Route path="/error" element={<Page.CommonError />} />
      {/* Home */}
      <Route
        path="/home"
        element={<Page.Home mode={isFilteredByTagIds ? 'FILTERED_BY_TAG_IDS' : 'WHOLE'} />}
      />
      <Route path="/property/:propertyId/home" element={<Page.Home mode="INDIVIDUAL" />} />
      <Route path="/property" element={<Page.PropertyList />} />
      {/* Equipment */}
      <Route
        path="/equipment"
        element={<Page.EquipmentList mode={isFilteredByTagIds ? 'FILTERED_BY_TAG_IDS' : 'WHOLE'} />}
      />
      <Route
        path="/property/:propertyId/equipment"
        element={<Page.EquipmentList mode="INDIVIDUAL" />}
      />
      <Route
        path="/property/:propertyId/equipment/new"
        element={<Page.EquipmentEdit mode="NEW" />}
      />
      <Route
        path="/property/:propertyId/equipment/:equipmentId"
        element={<Page.EquipmentDetail />}
      />
      <Route
        path="/property/:propertyId/equipment/:equipmentId/edit"
        element={<Page.EquipmentEdit mode="EDIT" />}
      />
      <Route
        path="/property/:propertyId/equipment/diagram/:diagramId"
        element={<Page.EquipmentDiagramDetail />}
      />
      <Route
        path="/property/:propertyId/equipment/diagram/:diagramId/edit"
        element={<Page.EquipmentDiagramEdit />}
      />
      {/* Floor */}
      <Route path="/property/:propertyId/floor" element={<Page.FloorDetail />} />
      <Route path="/property/:propertyId/floor/:floorId" element={<Page.FloorDetail />} />
      {/** checking */}
      <Route
        path="/checking"
        element={<Page.ChecklistList mode={isFilteredByTagIds ? 'FILTERED_BY_TAG_IDS' : 'WHOLE'} />}
      />
      <Route
        path="/property/:propertyId/checking"
        element={<Page.ChecklistList mode="INDIVIDUAL" />}
      />
      f{/* Inspection */}
      <Route
        path="/inspection"
        element={
          <Page.InspectionList mode={isFilteredByTagIds ? 'FILTERED_BY_TAG_IDS' : 'WHOLE'} />
        }
      />
      <Route
        path="/property/:propertyId/inspection"
        element={<Page.InspectionList mode="INDIVIDUAL" />}
      />
      <Route path="/inspection/checklist" element={<Page.InspectionChecklistList />} />
      <Route
        path="/property/:propertyId/inspection/checklist"
        element={<Page.InspectionChecklistList />}
      />
      <Route
        path="/property/:propertyId/inspection/checklist/new"
        element={<Page.InspectionChecklistEdit mode="NEW" />}
      />
      <Route
        path="/property/:propertyId/inspection/checklist/:inspectionChecklistId"
        element={<Page.InspectionChecklistDetail />}
      />
      <Route
        path="/property/:propertyId/inspection/checklist/:inspectionChecklistId/edit"
        element={<Page.InspectionChecklistEdit mode="EDIT" />}
      />
      <Route
        path="/property/:propertyId/inspection/checklist/:inspectionChecklistId/format"
        element={<Page.InspectionChecklistFormat />}
      />
      <Route path="/property/:propertyId/inspection/approve" element={<Page.InspectionApprove />} />
      <Route
        path="/property/:propertyId/inspection/new"
        element={<Page.InspectionEdit mode="NEW" />}
      />
      <Route
        path="/property/:propertyId/inspection/:inspectionId"
        element={<Page.InspectionDetail />}
      />
      <Route
        path="/property/:propertyId/inspection/:inspectionId/edit"
        element={<Page.InspectionEdit mode="EDIT" />}
      />
      {/* Work */}
      <Route
        path="/work"
        element={<Page.WorkList mode={isFilteredByTagIds ? 'FILTERED_BY_TAG_IDS' : 'WHOLE'} />}
      />
      <Route path="/property/:propertyId/work" element={<Page.WorkList mode="INDIVIDUAL" />} />
      <Route path="/property/:propertyId/work/new" element={<Page.WorkEdit mode="NEW" />} />
      <Route path="/property/:propertyId/work/:workId" element={<Page.WorkDetail />} />
      <Route
        path="/property/:propertyId/work/:workId/edit"
        element={<Page.WorkEdit mode="EDIT" />}
      />
      {/* Construction */}
      <Route
        path="/construction"
        element={
          <Page.ConstructionList mode={isFilteredByTagIds ? 'FILTERED_BY_TAG_IDS' : 'WHOLE'} />
        }
      />
      <Route
        path="/property/:propertyId/construction"
        element={<Page.ConstructionList mode="INDIVIDUAL" />}
      />
      <Route
        path="/property/:propertyId/construction/:constructionId"
        element={<Page.ConstructionDetail />}
      />
      {/* Plan */}
      <Route path="/plan/annual" element={<Page.PlanAnnualSchedule />} />
      <Route path="/plan/annual/:year" element={<Page.PlanAnnualSchedule />} />
      <Route path="/property/:propertyId/plan/annual" element={<Page.PlanAnnualSchedule />} />
      <Route path="/property/:propertyId/plan/annual/:year" element={<Page.PlanAnnualSchedule />} />
      <Route path="/table/annual" element={<Page.PlanAnnualTable />} />
      <Route path="/table/annual/:year" element={<Page.PlanAnnualTable />} />
      <Route path="/property/:propertyId/table/annual" element={<Page.PlanAnnualTable />} />
      <Route path="/property/:propertyId/table/annual/:year" element={<Page.PlanAnnualTable />} />
      <Route path="/plan/monthly" element={<Page.PlanMonthlySchedule />} />
      <Route path="/plan/monthly/:year/:month" element={<Page.PlanMonthlySchedule />} />
      <Route path="/property/:propertyId/plan/monthly" element={<Page.PlanMonthlySchedule />} />
      <Route
        path="/property/:propertyId/plan/monthly/:year/:month"
        element={<Page.PlanMonthlySchedule />}
      />
      <Route path="/table/monthly" element={<Page.PlanMonthlyTable />} />
      <Route path="/table/monthly/:year/:month" element={<Page.PlanMonthlyTable />} />
      <Route path="/property/:propertyId/table/monthly" element={<Page.PlanMonthlyTable />} />
      <Route
        path="/property/:propertyId/table/monthly/:year/:month"
        element={<Page.PlanMonthlyTable />}
      />
      <Route path="/plan/daily" element={<Page.PlanDailySchedule />} />
      <Route path="/plan/daily/:year/:month/:day" element={<Page.PlanDailySchedule />} />
      <Route path="/property/:propertyId/plan/daily" element={<Page.PlanDailySchedule />} />
      <Route
        path="/property/:propertyId/plan/daily/:year/:month/:day"
        element={<Page.PlanDailySchedule />}
      />
      {/* Archive */}
      <Route path="/property/:propertyId/archive" element={<Page.ArchiveList />} />
      <Route path="/property/:propertyId/archive/:archiveId" element={<Page.ArchiveList />} />
      {/* User */}
      <Route path="/admin/user" element={<Page.UserList />} />
      <Route path="/admin/user/new" element={<Page.UserEdit mode="NEW" />} />
      <Route path="/admin/user/:userId/edit" element={<Page.UserEdit mode="EDIT" />} />
      <Route path="/admin/user/:userId" element={<Page.UserDetail mode="USER" />} />
      <Route path="/user/:userId" element={<Page.UserDetail mode="USER" />} />
      <Route path="/profile" element={<Page.UserDetail mode="MYSELF" />} />
      <Route path="/profile/edit" element={<Page.UserEdit mode="MYSELF" />} />
      <Route path="/notifications" element={<Page.Notifications mode="MYSELF" />} />
      {/* Group */}
      <Route path="/admin/group" element={<Page.GroupList />} />
      <Route path="/admin/group/new" element={<Page.GroupEdit mode="NEW" />} />
      <Route path="/admin/group/:groupId/edit" element={<Page.GroupEdit mode="EDIT" />} />
      <Route path="/admin/group/:groupId" element={<Page.GroupDetail />} />
      <Route path="/group/:groupId" element={<Page.GroupDetail />} />
      {/* Property */}
      <Route path="/admin/property" element={<Page.PropertyList />} />
      <Route path="/property/new" element={<Page.PropertyEdit mode="NEW" />} />
      <Route path="/property/:propertyId" element={<Page.PropertyDetail />} />
      <Route path="/property/:propertyId/edit" element={<Page.PropertyEdit mode="EDIT" />} />
      {/* Others */}
      <Route path="/affiliate/user" element={<Page.Affiliate type="USER" />} />
      <Route path="/affiliate/user/detail/:userId" element={<Page.AffiliateDetail type="USER" />} />
      <Route path="/affiliate/user/new" element={<Page.AffiliateEdit type="USER" mode="NEW" />} />
      <Route
        path="/affiliate/user/edit/:userId"
        element={<Page.AffiliateEdit type="USER" mode="EDIT" />}
      />
      <Route path="/affiliate/company" element={<Page.Affiliate type="COMPANY" />} />
      <Route
        path="/affiliate/company/detail/:companyId"
        element={<Page.AffiliateDetail type="COMPANY" />}
      />
      <Route
        path="/affiliate/company/new"
        element={<Page.AffiliateEdit type="COMPANY" mode="NEW" />}
      />
      <Route
        path="/affiliate/company/edit/:companyId"
        element={<Page.AffiliateEdit type="COMPANY" mode="EDIT" />}
      />
      <Route path="/info/userLicense" element={<Page.InfoUserLicense />} />
      <Route path="/info/license" element={<Page.InfoLicense />} />
      <Route path="*" element={<Page.NotFound />} />
    </Routes>
  );
};
