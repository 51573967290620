import type { Action, PayloadAction } from '@reduxjs/toolkit';

export type HomeMode = 'INDIVIDUAL' | 'FILTERED_BY_TAG_IDS' | 'WHOLE';

export type HomeState = {
  isLoading: boolean;

  /** 本日の定期業務 */
  tasks: Task[];
  tasksCount: number;

  /** 査収待ちの定期業務 */
  checkingTasks: Task[];
  checkingTasksCount: number;

  /** 未完了の工事 */
  incompleteConstruction: Construction[];
  incompleteConstructionCount: number;

  /** 査収待ちの工事 */
  checkingConstruction: Construction[];
  checkingConstructionCount: number;

  /** 未完了の案件 */
  incompleteTrouble: Trouble[];
  incompleteTroubleCount: number;

  /** 査収待ちの案件 */
  checkingTrouble: Trouble[];
  checkingTroubleCount: number;
};

export enum HomeActionTypes {
  INIT = 'home/init',
  SET_LOADING = 'home/set/isLoading',
  SET_DATA = 'home/set/data',
}

export type HomeAction =
  | Action<HomeActionTypes.INIT>
  | PayloadAction<boolean, HomeActionTypes.SET_LOADING>
  | PayloadAction<HomeData, HomeActionTypes.SET_DATA>;

/**
 * @summary ホーム画面に表示するためのデータタイプ
 * @description HomeState から isLoading を省いたキーのtype
 */
export type HomeData = Pick<
  HomeState,
  | 'tasks'
  | 'tasksCount'
  | 'checkingTasks'
  | 'checkingTasksCount'
  | 'incompleteConstruction'
  | 'incompleteConstructionCount'
  | 'checkingConstruction'
  | 'checkingConstructionCount'
  | 'incompleteTrouble'
  | 'incompleteTroubleCount'
  | 'checkingTrouble'
  | 'checkingTroubleCount'
>;

// -- State Types ---------------------------------------------------------- //

export type Task = {
  inspectionResultId: number;
  contractId: number;
  contractTitle: string;
  propertyId: number;
  propertyName: string;
  statusId: number;
  statusName: string;
  startDatetime: string;
  updateDatetime: string;
  typeId: number;
  typeName: string;
  latestComment: string;
  supervisors: {
    userId: number;
    userName: string;
  }[];
  workers: {
    userId: number;
    userName: string;
  }[];
  checkUserId: number;
  checkUserName: string;
};

type Construction = {
  constructionId: number;
  contractTitle: string;
  propertyId: number;
  propertyName: string;
  statusId: number;
  statusName: string;
  startDate: string;
  updateDatetime: string;
  typeId: number;
  typeName: string;
  latestComment: string;
  supervisors: {
    userId: number;
    userName: string;
  }[];
  workers: {
    userId: number;
    userName: string;
  }[];
  reporterId: number;
  reporterName: string;
};

type Trouble = {
  troubleId: number;
  title: string;
  statusId: number;
  statusName: string;
  priorityId: number;
  priorityName: string;
  occurrenceDate: string;
  occurrenceTime: string;
  updateDatetime: string;
  troubleType: number;
  troubleTypeName: string;
  latestComment: string;
  picUserId: number;
  picUserName: string;
  propertyId: number;
  propertyName: string;
};

// -- API Types ------------------------------------------------------------ //

export type APIGetHomeResponse = {
  message: string;
  result: {
    /** 本日の定期業務 */
    task: {
      task_list: APITaskList[];
      count: number;
    };
    /** 査収待ちの定期業務 */
    checking_task: {
      task_list: APITaskList[];
      count: number;
    };
    /** 未完了の工事 */
    incomplete_construction: {
      construction_list: APIConstructionList[];
      count: number;
    };
    /** 査収待ちの工事 */
    checking_construction: {
      construction_list: APIConstructionList[];
      count: number;
    };
    /** 未完了の案件 */
    incomplete_trouble: {
      trouble_list: APITroubleList[];
      count: number;
    };
    /** 査収待ちの案件 */
    checking_trouble: {
      trouble_list: APITroubleList[];
      count: number;
    };
  };
};

type APITaskList = {
  inspection_result_id: number;
  contract_id: number;
  contract_title: string;
  property_id: number;
  property_name: string;
  status_id: number;
  status_name: string;
  start_datetime: string;
  update_datetime: string;
  type_id: number;
  type_name: string;
  latest_comment: string;
  supervisors: {
    user_id: number;
    user_name: string;
  }[];
  workers: {
    user_id: number;
    user_name: string;
  }[];
  check_user_id: number;
  check_user_name: string;
};

type APIConstructionList = {
  construction_id: number;
  contract_title: string;
  property_id: number;
  property_name: string;
  status_id: number;
  status_name: string;
  start_date: string;
  update_datetime: string;
  type_id: number;
  type_name: string;
  latest_comment: string;
  supervisors: {
    user_id: number;
    user_name: string;
  }[];
  workers: {
    user_id: number;
    user_name: string;
  }[];
  reporter_id: number;
  reporter_name: string;
};

type APITroubleList = {
  trouble_id: number;
  title: string;
  status_id: number;
  status_name: string;
  priority_id: number;
  priority_name: string;
  occurrence_date: string;
  occurrence_time: string;
  update_datetime: string;
  trouble_type: number;
  trouble_type_name: string;
  latest_comment: string;
  pic_user_id: number;
  pic_user_name: string;
  property_id: number;
  property_name: string;
};
