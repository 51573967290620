import { AppState as State } from '@ducks/index';

export const getRefreshId = (state: State) => state.archive.refresh;

export const checkIsLoading = (state: State) => state.archive.isLoading;
export const checkIsSubmitting = (state: State) => state.archive.isSubmitting;

export const getArchives = (state: State) => state.archive.archives;
export const getPaths = (state: State) => state.archive.paths;
export const getParentArchiveId = (state: State) => state.archive.parentArchiveId;

export const getCheckFilesOverWhite = (state: State) => state.archive.checkFilesOverWrite;
