import { AppState as State } from '@ducks/index';

export const checkIsLoading = (state: State) => state.home.isLoading;

/** 本日の定期業務 */
export const getTasks = (state: State) => state.home.tasks;
export const getTasksCount = (state: State) => state.home.tasksCount;

/** 査収待ちの定期業務 */
export const getCheckingTasks = (state: State) => state.home.checkingTasks;
export const getCheckingTasksCount = (state: State) => state.home.checkingTasksCount;

/** 未完了の工事 */
export const getIncompleteConstruction = (state: State) => state.home.incompleteConstruction;
export const getIncompleteConstructionCount = (state: State) =>
  state.home.incompleteConstructionCount;

/** 査収待ちの工事 */
export const getCheckingConstruction = (state: State) => state.home.checkingConstruction;
export const getCheckingConstructionCount = (state: State) => state.home.checkingConstructionCount;

/** 未完了の案件 */
export const getIncompleteTrouble = (state: State) => state.home.incompleteTrouble;
export const getIncompleteTroubleCount = (state: State) => state.home.incompleteTroubleCount;

/** 査収待ちの案件 */
export const getCheckingTrouble = (state: State) => state.home.checkingTrouble;
export const getCheckingTroubleCount = (state: State) => state.home.checkingTroubleCount;
