import { APIGetCheckingRequestParams, APIGetCheckingResponse, CheckingListState } from './types';
import * as actions from './actions';
import axios from '@providers/axiosProvider';
import { LIST_SIZE_PER_PAGE } from '~/utils/constants';

export const getCheckingListAsync =
  (params: APIGetCheckingRequestParams) => async (dispatch: any) => {
    dispatch(actions.setIsLoading(true));

    const reqParams: APIGetCheckingRequestParams = {
      ...params,
      size_per_page: LIST_SIZE_PER_PAGE,
    };

    await axios
      .get<APIGetCheckingResponse>('/checking', {
        params: reqParams,
      })
      .then((response) => {
        const { checking_list: result, display_params: pageInfo } = response.data.result;
        const checkingList: CheckingListState = {
          checkingList: result.map((checkingData) => ({
            categoryId: checkingData.category_id,
            categoryName: checkingData.category_name,
            startDate: checkingData.start_date,
            updateDate: checkingData.update_date,
            typeId: checkingData.type_id,
            typeName: checkingData.type_name,
            checkingId: checkingData.checking_id,
            title: checkingData.title,
            abnormalNum: checkingData.abnormal_num,
            inspectionComment: checkingData.inspection_comment,
            lastPicUserId: checkingData.last_pic_user_id,
            lastPicUserName: checkingData.last_pic_user_name,
            propertyId: checkingData.property_id,
            propertyName: checkingData.property_name,
          })),
          displayParams: {
            numPerPage: pageInfo.num_per_page,
            currentPage: pageInfo.current_page,
            totalPage: pageInfo.total_page,
            total: pageInfo.total,
            sort: pageInfo.sort,
          },
        };
        dispatch(actions.setCheckingList(checkingList));
      })
      .catch(() => {
        dispatch(actions.setCheckingList(null));
      });
    dispatch(actions.setIsLoading(false));
  };
