import {
  TagAction as Action,
  TagActionTypes as ActionTypes,
  TagDataType,
  TagDataTypeForSettings,
} from './types';

export const refresh = (): Action => ({
  type: ActionTypes.REFRESH,
});

export const setIsLoading = (isLoading: boolean): Action => ({
  type: ActionTypes.LOADING,
  payload: isLoading,
});

export const setTags = (tags: TagDataType[]): Action => ({
  type: ActionTypes.SET_TAGS,
  payload: tags,
});

export const setTagSettingList = (tags: TagDataTypeForSettings[]): Action => ({
  type: ActionTypes.SET_TAG_SETTING_LIST,
  payload: tags,
});

export const setIsSubmitting = (isSubmitting: boolean): Action => ({
  type: ActionTypes.SUBMITTING,
  payload: isSubmitting,
});
