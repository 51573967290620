import axios from '@providers/axiosProvider';

import * as actions from './actions';
import {
  ArchiveList,
  APIGetArchivesResponse,
  PathInfo,
  APIPostUploadFilesResponse,
  APIPostUploadFilesRequestParams,
  APICreateDirectoryRequest,
  APICreateDirectoryResponse,
  APIRenameArchiveRequest,
  APIDeleteArchiveRequest,
  APIDownloadArchiveResponse,
  APIGetArchivesRequestParams,
  GetArchiveResult,
  APIPatchUploadFilesResponse,
} from './types';
import { AxiosResponse } from 'axios';

/**
 * 書庫ファイルリストの取得apiからのresponseを加工して返却
 * @param response 書庫ファイルリストの取得apiからのresponse
 * @returns {GetArchiveResult}
 */
const getArchivesResultData = (
  response: AxiosResponse<APIGetArchivesResponse, any>,
): GetArchiveResult => {
  const {
    parent_archive_id: parentArchiveId,
    path: resPaths,
    archive_list: resArchives,
  } = response.data.result;

  const paths: PathInfo[] = resPaths.map(
    (p): PathInfo => ({
      propertyId: p.property_id,
      archiveId: p.archive_id,
      title: p.title,
    }),
  );
  const archives: ArchiveList[] = resArchives.map(
    (v): ArchiveList => ({
      propertyId: v.property_id,
      archiveId: v.archive_id,
      archiveTypeId: v.archive_type_id,
      archiveTypeName: v.archive_type_name,
      fileId: v.file_id,
      fileUrl: v.file_url,
      title: v.title,
      fileSize: v.file_size,
      createUserName: v.create_user_name,
      createDatetime: v.create_datetime,
      updateUserName: v.update_user_name,
      updateDatetime: v.update_datetime,
      lastAccessUserName: v.last_access_user_name,
      lastAccessDatetime: v.last_access_datetime,
    }),
  );

  return { archives, paths, parentArchiveId };
};

/**
 * @summary 書庫ファイル・ディレクトリーのデータ取得
 * @param propertyId 建物ID
 * @param archiveId 書庫ID
 * @param params api共通パラメータ
 */
export const getArchivesAsync =
  (propertyId: number, archiveId: number, params: APIGetArchivesRequestParams) =>
  async (dispatch: any) => {
    dispatch(actions.setIsLoading(true));

    const reqParams: APIGetArchivesRequestParams = {
      ...params,
    };

    await axios
      .get<APIGetArchivesResponse>(`/properties/${propertyId}/archives/${archiveId}`, {
        params: reqParams,
      })
      .then((response) => {
        const { archives, paths, parentArchiveId } = getArchivesResultData(response);
        dispatch(actions.setArchives(archives, paths, parentArchiveId));
      })
      .catch(() => {
        dispatch(actions.setArchives([], [], null));
      });

    dispatch(actions.setIsLoading(false));
  };

/**
 * @summary apiより書庫ファイルのリスト取得
 * @description reduxに保存せず返却
 * @param propertyId 建物ID
 * @param archiveId 書庫ID
 * @param params api共通パラメータ
 * @returns {Promise<null | GetArchiveResult>} 書庫ファイルリスト
 */
export const getArchivesData = async (
  propertyId: number,
  archiveId: number,
  params: APIGetArchivesRequestParams,
): Promise<null | GetArchiveResult> => {
  const reqParams: APIGetArchivesRequestParams = {
    ...params,
  };

  const result = await axios
    .get<APIGetArchivesResponse>(`/properties/${propertyId}/archives/${archiveId}`, {
      params: reqParams,
    })
    .then((response) => {
      return getArchivesResultData(response);
    })
    .catch(() => {
      return null;
    });
  return result;
};

/**
 * @summary 書庫ファイル・ディレクトリーの移動apiを行う関数
 * @description 失敗時はエラー画面に遷移する
 * @param propertyId 移動予定の書庫ファイル・ディレクトリーの建物ID
 * @param archiveId 移動予定の書庫ファイル・ディレクトリーの書庫ID
 * @param destinationArchiveId 移動先の書庫ディレクトリーの書庫ID
 */
export const moveArchivesData =
  (propertyId: number, archiveId: number, destinationArchiveId: number) =>
  async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const requestData = {
      destination_archive_id: destinationArchiveId,
    };

    await axios
      .patch<APIPatchUploadFilesResponse>(
        `/properties/${propertyId}/archives/${archiveId}/move`,
        requestData,
      )
      .then((response) => {
        if (response.status === 202) {
          dispatch(actions.refresh());
        }
      })
      .catch(() => {});

    dispatch(actions.refresh());
    dispatch(actions.setIsSubmitting(false));
  };

export const uploadFilesAsync =
  (propertyId: number, archiveId: number, formData: FormData, overwrite: boolean) =>
  async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const params: APIPostUploadFilesRequestParams = {
      is_overwrite: overwrite,
    };

    await axios
      .post<APIPostUploadFilesResponse>(
        `/properties/${propertyId}/archives/${archiveId}/files`,
        formData,
        { params, headers: { 'content-type': 'multipart/form-data' } },
      )
      .then((response) => {
        if (response.status === 202) {
          const files = response.data.result.file_list.map((v) => v.file_name);
          dispatch(actions.setCheckFilesOverWrite(files));
        } else if (response.status === 201) {
          dispatch(actions.refresh());
          dispatch(actions.setCheckFilesOverWrite([]));
        }
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const createDirectoryAsync =
  (propertyId: number, archiveId: number, folderName: string) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const requestData: APICreateDirectoryRequest = {
      title: folderName,
    };

    await axios
      .post<APICreateDirectoryResponse>(
        `/properties/${propertyId}/archives/${archiveId}/directories`,
        requestData,
      )
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const renameArchiveAsync =
  (propertyId: number, archiveId: number, name: string) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const requestData: APIRenameArchiveRequest = {
      title: name,
    };

    await axios
      .put(`/properties/${propertyId}/archives/${archiveId}`, requestData)
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const deleteArchiveAsync =
  (propertyId: number, archiveId: number, targetArchiveId: number) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const requestData: APIDeleteArchiveRequest = {
      archive_list: [{ archive_id: targetArchiveId }],
    };

    await axios
      .post(`/properties/${propertyId}/archives/${archiveId}/delete`, requestData)
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const downloadArchiveAsync = async (propertyId: number, targetArchiveId: number) => {
  const params = {
    archive_list: targetArchiveId,
  };

  const result = await axios
    .get<APIDownloadArchiveResponse>(`/properties/${propertyId}/archives/files`, { params })
    .then((response) => {
      return response.data.result;
    })
    .catch(() => {
      return null;
    });

  return result;
};
