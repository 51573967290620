import React, { FC } from 'react';
import Modal from '@projects/modal';
import Button from '@uiParts/button';
import './style.scss';

export type ConfirmDialogProps = {
  /** 確認メッセージ */
  message: string;
  /** キャンセル処理 */
  handleCancel: () => void;
  /** OK処理 */
  handleOK: () => void;
};

const ConfirmDialog: FC<ConfirmDialogProps> = (props: ConfirmDialogProps) => {
  return (
    <Modal
      className="confirm-dialog-container"
      title="確認"
      show={true}
      onClose={props.handleCancel}
      bgClose={true}
      footer={
        <div className="button-area">
          <Button type="default" color="white" onClick={props.handleCancel}>
            いいえ
          </Button>
          <Button type="default" color="primary" onClick={props.handleOK}>
            はい
          </Button>
        </div>
      }
    >
      {props.message}
    </Modal>
  );
};

export default ConfirmDialog;
