import axios from '@providers/axiosProvider';
import * as actions from './actions';

import {
  APICreateUser,
  APICreateUserRequest,
  APICreateUserResponse,
  APIGetUserResponse,
  APIGetUsersRequestParams,
  APIGetUsersResponse,
  APIUpdateUser,
  APIUpdateUserRequest,
  User,
  UserSubmitData,
  UsersList,
  UsersListInfo,
  APIDeleteUsersRequest,
  APIGetProfileResponse,
  APIUpdateProfile,
  APIUpdateProfileRequest,
} from './types';

import { LIST_SIZE_PER_PAGE } from '@utils/constants';

export const getUsersAsync = (params: APIGetUsersRequestParams) => async (dispatch: any) => {
  dispatch(actions.setIsLoading(true));

  const reqParams: APIGetUsersRequestParams = {
    ...params,
    size_per_page: LIST_SIZE_PER_PAGE,
  };

  await axios
    .get<APIGetUsersResponse>('/users', {
      params: reqParams,
    })
    .then((response) => {
      const { user_list: result, display_params: info } = response.data.result;

      const users: UsersList[] = result.map(
        (user): UsersList => ({
          userId: user.user_id,
          lastName: user.last_name,
          firstName: user.first_name,
          email: user.email,
          location: user.location,
          belong: user.belong,
          position: user.position,
          isSystemRole: user.is_system_role,
          status: user.status,
        }),
      );

      const usersListInfo: UsersListInfo = {
        totalHits: info.total,
        totalPages: info.total_page,
      };

      dispatch(actions.setUsers(users));
      dispatch(actions.setUsersListInfo(usersListInfo));
    })
    .catch(() => {
      dispatch(actions.setUsers([]));
      dispatch(actions.setUsersListInfo(null));
    });

  dispatch(actions.setIsLoading(false));
};

export const getUserAsync = (userId: number) => async (dispatch: any) => {
  dispatch(actions.setIsLoading(true));

  await axios
    .get<APIGetUserResponse>(`/users/${userId}`)
    .then((response) => {
      const v = response.data.result;

      const user: User = {
        userId: v.user_id,
        email: v.email,
        lastName: v.last_name,
        firstName: v.first_name,
        kanaLast: v.kana_last,
        kanaFirst: v.kana_first,
        birth: v.birth,
        location: v.location,
        position: v.position,
        belong: v.belong,
        phone: v.phone,
        cellPhone: v.cell_phone,
        remarks: v.remarks,
        status: v.status,
        isSystemRole: v.is_system_role,
        fileId: v.file_id,
        fileUrl: v.file_url,
      };

      dispatch(actions.setUser(user));
    })
    .catch(() => {
      dispatch(actions.setUser(null));
    });

  dispatch(actions.setIsLoading(false));
};

export const createUserAsync = (data: UserSubmitData) => async (dispatch: any) => {
  dispatch(actions.setIsSubmitting(true));

  const user: APICreateUser = {
    email: data.email,
    last_name: data.lastName,
    first_name: data.firstName,
    kana_last: data.kanaLast,
    kana_first: data.kanaFirst,
    birth: data.birth,
    location: data.location,
    position: data.position,
    belong: data.belong,
    phone: data.phone,
    cell_phone: data.cellPhone,
    remarks: data.remarks,
    is_system_role: data.isSystemRole,
    file_name: data.fileName,
    file_content: data.fileContent,
  };

  const requestData: APICreateUserRequest = user;

  await axios
    .post<APICreateUserResponse>('/users', requestData)
    .then((response) => {
      const userId = response.data.result.user_id;
      dispatch(actions.setIsNavigating(true));
      dispatch(actions.setIsNavigatingTo(userId));
    })
    .catch(() => {});

  dispatch(actions.setIsSubmitting(false));
};

export const updateUserAsync = (userId: number, data: UserSubmitData) => async (dispatch: any) => {
  dispatch(actions.setIsSubmitting(true));

  const user: APIUpdateUser = {
    last_name: data.lastName,
    first_name: data.firstName,
    kana_last: data.kanaLast,
    kana_first: data.kanaFirst,
    birth: data.birth,
    location: data.location,
    position: data.position,
    belong: data.belong,
    phone: data.phone,
    cell_phone: data.cellPhone,
    remarks: data.remarks,
    is_system_role: data.isSystemRole,
    status: data.status ?? 1,
    file_name: data.fileName,
    file_content: data.fileContent,
  };

  const requestData: APIUpdateUserRequest = user;

  await axios
    .put(`/users/${userId}`, requestData)
    .then(() => {
      dispatch(actions.setIsNavigating(true));
    })
    .catch(() => {});

  dispatch(actions.setIsSubmitting(false));
};

export const deleteUsersAsync = (userIds: number[]) => async (dispatch: any) => {
  dispatch(actions.setIsSubmitting(true));

  const requestData: APIDeleteUsersRequest = {
    user_list: userIds.map((v) => ({
      user_id: v,
    })),
  };

  await axios
    .delete(`/users`, {
      data: requestData,
    })
    .then(() => {
      dispatch(actions.setIsNavigating(true));
      dispatch(actions.setIsNavigatingTo(-1));
    })
    .catch(() => {});

  dispatch(actions.setIsSubmitting(false));
};

export const deleteUserImageAsync = (userId: number, fileId: number) => async (dispatch: any) => {
  dispatch(actions.setIsSubmitting(true));

  await axios
    .delete(`/users/${userId}/profile_picture/${fileId}`)
    .then(() => {})
    .catch(() => {});

  dispatch(actions.setIsSubmitting(false));
};

export const getProfileAsync = () => async (dispatch: any) => {
  dispatch(actions.setIsLoading(true));

  await axios
    .get<APIGetProfileResponse>('/users/profile')
    .then((response) => {
      const v = response.data.result;

      const profile: User = {
        userId: v.user_id,
        email: v.email,
        lastName: v.last_name,
        firstName: v.first_name,
        kanaLast: v.kana_last,
        kanaFirst: v.kana_first,
        birth: v.birth,
        position: v.position,
        belong: v.belong,
        location: v.location,
        phone: v.phone,
        cellPhone: v.cell_phone,
        remarks: v.remarks,
        isSystemRole: v.is_system_role,
        fileId: v.file_id,
        fileUrl: v.file_url,
      };

      dispatch(actions.setUser(profile));
    })
    .catch(() => {
      dispatch(actions.setUser(null));
    });

  dispatch(actions.setIsLoading(false));
};

export const updateProfileAsync = (data: UserSubmitData) => async (dispatch: any) => {
  dispatch(actions.setIsSubmitting(true));

  const profile: APIUpdateProfile = {
    last_name: data.lastName,
    first_name: data.firstName,
    kana_last: data.kanaLast,
    kana_first: data.kanaFirst,
    birth: data.birth,
    location: data.location,
    position: data.position,
    belong: data.belong,
    phone: data.phone,
    cell_phone: data.cellPhone,
    remarks: data.remarks,
    file_name: data.fileName,
    file_content: data.fileContent,
  };

  const requestData: APIUpdateProfileRequest = profile;

  await axios
    .put('/users/profile/edit', requestData)
    .then(() => {
      dispatch(actions.setIsNavigating(true));
    })
    .catch(() => {});

  dispatch(actions.setIsSubmitting(false));
};
