import { AppState as State } from '@ducks/index';

export const getRefreshId = (state: State) => state.diagram.refresh;

export const checkIsLoading = (state: State) => state.diagram.isLoading;
export const checkIsLoading2 = (state: State) => state.diagram.isLoading2;
export const checkIsSubmitting = (state: State) => state.diagram.isSubmitting;
export const checkIsNavigating = (state: State) => state.diagram.isNavigating;
export const checkIsNavigatingTo = (state: State) => state.diagram.isNavigatingTo;

export const getDiagram = (state: State) => state.diagram.diagram;
export const getSelectEquipments = (state: State) => state.diagram.selectEquipments;
