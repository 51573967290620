import type { Action, PayloadAction } from '@reduxjs/toolkit';

/**
 * @summary 日時計画画面で管理されるState
 */
export type PlanDailyScheduleState = {
  refresh: Record<string, never>;
  /** Load中か */
  isLoading: boolean;
  /** 送信処理中か */
  isSubmitting: boolean;
  /** ソート番号 */
  sort: number;
  /** 詳細検索条件 */
  searchParameter: SearchParameter;
  /** 日時計画リスト */
  plans: DailyPlanSchedule[];
  /** 日時計画サマリ */
  summary: DailyPlanScheduleSummary | null;
};

/**
 * @summary 日時計画画面のAction Type
 */
export enum PlanDailyScheduleActionTypes {
  INIT = 'plan/daily_schedule/init',
  REFRESH = 'plan/daily_schedule/refresh',
  LOADING = 'plan/daily_schedule/set/isLoading',
  SUBMITTING = 'plan/daily_schedule/set/isSubmitting',
  SET_SORT = 'plan/daily_schedule/set/sort',
  SET_SEARCH_PARAMETER = 'plan/daily_schedule/set/search_parameter',
  SET_PLANS = 'plan/daily_schedule/set/plans',
  SET_SUMMARY = 'plan/daily_schedule/set/summary',
}

/**
 * @summary 日時計画画面のAction
 */
export type PlanDailyScheduleAction =
  /** 初期化 */
  | Action<PlanDailyScheduleActionTypes.INIT>
  /** リフレッシュ */
  | Action<PlanDailyScheduleActionTypes.REFRESH>
  /** Loading状態を保持 */
  | PayloadAction<boolean, PlanDailyScheduleActionTypes.LOADING>
  /** 送信処理中かを保持 */
  | PayloadAction<boolean, PlanDailyScheduleActionTypes.SUBMITTING>
  /** ページ番号を保持 */
  | PayloadAction<number, PlanDailyScheduleActionTypes.SET_SORT>
  /** 詳細検索条件を保持 */
  | PayloadAction<SearchParameter, PlanDailyScheduleActionTypes.SET_SEARCH_PARAMETER>
  /** 日時計画リストを保持 */
  | PayloadAction<DailyPlanSchedule[], PlanDailyScheduleActionTypes.SET_PLANS>
  /** 日時計画サマリを保持 */
  | PayloadAction<DailyPlanScheduleSummary | null, PlanDailyScheduleActionTypes.SET_SUMMARY>;

/**
 * @summary 日時計画の詳細検索パラメータ
 */
export type SearchParameter = {
  /** 計画名 */
  title?: string;
  /** 契約種別 */
  contract_type?: string;
  /** 定期業務種別 */
  inspection_type?: string;
  /** 定期業務区分 */
  inspection_division?: string;
  /** 対応状況 */
  status?: string;
  /** 協力会社名 */
  company?: string;
};

/**
 * @summary APIから返却される日時計画リストのプロパティ
 */
export type DailyPlanSchedule = {
  /** 建物ID */
  property_id: number;
  /** 建物名 */
  property_name: string;
  /** 計画ID */
  plan_id: number;
  /** 計画名 */
  title: string;
  /** 契約ID */
  contract_id: number;
  /** 点検結果ID */
  inspection_result_id: number;
  /** 工事ID */
  construction_id: number;
  /** 開始日 */
  start_date: string;
  /** 開始時間 */
  start_time: string;
  /** 終了日 */
  end_date: string;
  /** 終了時間 */
  end_time: string;
  /** 対応状況 */
  status_id: number;
};

/**
 * @summary APIから返却される日時計画サマリのプロパティ
 */
export type DailyPlanScheduleSummary = {
  /** 未確定件数 */
  unfixed_num: number;
  /** 確定件数 */
  fixed_num: number;
  /** 対応中件数 */
  in_progress_num: number;
  /** 査収待ち件数 */
  checking_num: number;
  /** 査収完了件数 */
  completed_num: number;
  /** 保留件数 */
  pending_num: number;
};

/**
 * @summary APIから返却される日時計画のresponse.data.result
 */
export type APIDailyPlan = {
  /** 年 */
  readonly year: number;
  /** 月 */
  readonly month: number;
  /** 日 */
  readonly day: number;
  /** サマリ */
  readonly summary: DailyPlanScheduleSummary;
  /** 日時計画 */
  readonly items: DailyPlanSchedule[];
};

export type APIGetDailyPlansRequestParams = {
  /** 建物ID */
  property_id?: number;
  /** 日付(yyyy/mm/dd) */
  date?: string;
  /** ソート番号 */
  sort?: number;
  /** キーワード */
  keyword?: string;
  /** タグID */
  tag?: string;
} & SearchParameter;

/**
 * @summary APIから返却される日時計画のresponse.data.result
 */
export type APIGetDailyPlansResponse = {
  result: APIDailyPlan;
};
