import type { Action, PayloadAction } from '@reduxjs/toolkit';

/**
 * @summary 年次計画画面で管理されるState
 */
export type PlanAnnualScheduleState = {
  refresh: Record<string, never>;
  /** Load中か */
  isLoading: boolean;
  /** 送信処理中か */
  isSubmitting: boolean;
  /** ソート番号 */
  sort: number;
  /** 詳細検索条件 */
  searchParameter: SearchParameter;
  /** 年次計画リスト */
  plans: AnnualPlanSchedule[];
  /** 年次計画サマリ */
  summary: AnnualPlanScheduleSummary | null;
};

/**
 * @summary 年次計画画面のAction Type
 */
export enum PlanAnnualScheduleActionTypes {
  INIT = 'plan/annual_schedule/init',
  REFRESH = 'plan/annual_schedule/refresh',
  LOADING = 'plan/annual_schedule/set/isLoading',
  SUBMITTING = 'plan/annual_schedule/set/isSubmitting',
  SET_SORT = 'plan/annual_schedule/set/sort',
  SET_SEARCH_PARAMETER = 'plan/annual_schedule/set/search_parameter',
  SET_PLANS = 'plan/annual_schedule/set/plans',
  SET_SUMMARY = 'plan/annual_schedule/set/summary',
}

/**
 * @summary 年次計画画面のAction
 */
export type PlanAnnualScheduleAction =
  /** 初期化 */
  | Action<PlanAnnualScheduleActionTypes.INIT>
  /** リフレッシュ */
  | Action<PlanAnnualScheduleActionTypes.REFRESH>
  /** Loading状態を保持 */
  | PayloadAction<boolean, PlanAnnualScheduleActionTypes.LOADING>
  /** 送信処理中かを保持 */
  | PayloadAction<boolean, PlanAnnualScheduleActionTypes.SUBMITTING>
  /** ページ番号を保持 */
  | PayloadAction<number, PlanAnnualScheduleActionTypes.SET_SORT>
  /** 詳細検索条件を保持 */
  | PayloadAction<SearchParameter, PlanAnnualScheduleActionTypes.SET_SEARCH_PARAMETER>
  /** 年次計画リストを保持 */
  | PayloadAction<AnnualPlanSchedule[], PlanAnnualScheduleActionTypes.SET_PLANS>
  /** 年次計画サマリを保持 */
  | PayloadAction<AnnualPlanScheduleSummary | null, PlanAnnualScheduleActionTypes.SET_SUMMARY>;

/**
 * @summary 年次計画の詳細検索パラメータ
 */
export type SearchParameter = {
  /** 計画名 */
  title?: string;
  /** 契約種別 */
  contract_type?: string;
  /** 定期業務種別 */
  inspection_type?: string;
  /** 定期業務区分 */
  inspection_division?: string;
  /** 対応状況 */
  status?: string;
  /** 協力会社名 */
  company?: string;
};

/**
 * @summary APIから返却される年次計画リストのプロパティ
 */
export type AnnualPlanSchedule = {
  /** 建物ID */
  property_id: number;
  /** 建物名 */
  property_name: string;
  /** 契約ID */
  contract_id: number;
  /** 計画名 */
  title: string;
  /** 契約種別ID */
  contract_type_id: number;
  /** 契約種別 */
  contract_type_name: string;
  /** 定期業務種別ID */
  inspection_type_id: number;
  /** 定期業務種別 */
  inspection_type_name: string;
  /** 定期業務区分ID */
  inspection_division_id: number;
  /** 定期業務区分 */
  inspection_division_name: string;
  /** 周期ID */
  repeat_type_id: number;
  /** 周期 */
  repeat_type_name: string;
  /** 周期のカスタム設定 */
  custom_setting: string;
  /** 月次計画 */
  monthly: {
    /** 月 */
    month: number;
    /** 対応状況 */
    status_id: number;
    /** 計画数 */
    plan_num: number;
  }[];
};

/**
 * @summary APIから返却される年次計画サマリのプロパティ
 */
export type AnnualPlanScheduleSummary = {
  /** 未確定件数 */
  unfixed_num: number;
  /** 確定件数 */
  fixed_num: number;
  /** 対応中件数 */
  in_progress_num: number;
  /** 査収待ち件数 */
  checking_num: number;
  /** 査収完了件数 */
  completed_num: number;
  /** 保留件数 */
  pending_num: number;
};

/**
 * @summary APIから返却される年次計画のresponse.data.result
 */
export type APIAnnualPlan = {
  /** 年 */
  readonly year: number;
  /** サマリ */
  readonly summary: AnnualPlanScheduleSummary;
  /** 年次計画 */
  readonly items: AnnualPlanSchedule[];
};

export type APIGetAnnualPlansRequestParams = {
  /** 建物ID */
  property_id?: number;
  /** 年 */
  year?: number;
  /** ソート番号 */
  sort?: number;
  /** キーワード */
  keyword?: string;
  /** タグID */
  tag?: string;
} & SearchParameter;

/**
 * @summary APIから返却される年次計画のresponse.data.result
 */
export type APIGetAnnualPlansResponse = {
  result: APIAnnualPlan;
};
