import reducer from './reducers';

import * as inspectionSelectors from './selectors';
import * as inspectionActions from './actions';
import * as inspectionOperations from './operations';
import { InspectionState } from './types';

export { inspectionSelectors, inspectionActions, inspectionOperations };
export type { InspectionState };

export default reducer;
