import { lazy } from 'react';

export const Login = lazy(() => import('./components/pages/login'));

export const CommonLayout = lazy(() => import('./components/pages/common/commonLayout'));
export const CommonError = lazy(() => import('./components/pages/common/commonError'));
export const NotFound = lazy(() => import('./components/pages/common/notFound'));

export const Home = lazy(() => import('./components/pages/home'));

export const EquipmentList = lazy(() => import('./components/pages/equipment/list'));
export const EquipmentDetail = lazy(() => import('./components/pages/equipment/detail'));
export const EquipmentEdit = lazy(() => import('./components/pages/equipment/edit'));
export const EquipmentDiagramDetail = lazy(() => import('./components/pages/diagram/detail'));
export const EquipmentDiagramEdit = lazy(() => import('./components/pages/diagram/edit'));

export const FloorDetail = lazy(() => import('./components/pages/floor/detail'));

export const ChecklistList = lazy(() => import('./components/pages/checking/list'));

export const InspectionList = lazy(() => import('./components/pages/inspection/list'));
export const InspectionApprove = lazy(() => import('./components/pages/inspection/approve'));
export const InspectionDetail = lazy(() => import('./components/pages/inspection/tabDetail'));

export const InspectionEdit = lazy(() => import('./components/pages/inspection/edit'));
export const InspectionChecklistList = lazy(
  () => import('./components/pages/inspection/checklist/list'),
);
export const InspectionChecklistDetail = lazy(
  () => import('./components/pages/inspection/checklist/detail'),
);
export const InspectionChecklistEdit = lazy(
  () => import('./components/pages/inspection/checklist/edit'),
);
export const InspectionChecklistFormat = lazy(
  () => import('./components/pages/inspection/checklist/format'),
);

export const WorkList = lazy(() => import('./components/pages/work/list'));
export const WorkDetail = lazy(() => import('./components/pages/work/detail'));
export const WorkEdit = lazy(() => import('./components/pages/work/edit'));

export const ConstructionList = lazy(() => import('./components/pages/construction/list'));
export const ConstructionDetail = lazy(() => import('./components/pages/construction/detail'));

export const PlanAdjustment = lazy(() => import('./components/pages/plan/adjustment'));
export const PlanAnnualSchedule = lazy(() => import('./components/pages/plan/annualSchedule'));
export const PlanAnnualTable = lazy(() => import('./components/pages/plan/annualTable'));
export const PlanMonthlySchedule = lazy(() => import('./components/pages/plan/monthlySchedule'));
export const PlanMonthlyTable = lazy(() => import('./components/pages/plan/monthlyTable'));
export const PlanDailySchedule = lazy(() => import('./components/pages/plan/dailySchedule'));

export const ArchiveList = lazy(() => import('./components/pages/archive/list'));

export const Affiliate = lazy(() => import('./components/pages/affiliate/list'));
export const AffiliateDetail = lazy(() => import('./components/pages/affiliate/detail'));
export const AffiliateEdit = lazy(() => import('./components/pages/affiliate/edit'));

export const UserList = lazy(() => import('./components/pages/user/list'));
export const UserDetail = lazy(() => import('./components/pages/user/detail'));
export const UserEdit = lazy(() => import('./components/pages/user/edit'));
export const Notifications = lazy(() => import('./components/pages/user/notifications'));

export const GroupList = lazy(() => import('./components/pages/group/list'));
export const GroupDetail = lazy(() => import('./components/pages/group/detail'));
export const GroupEdit = lazy(() => import('./components/pages/group/edit'));

export const PropertyList = lazy(() => import('./components/pages/property/list'));
export const PropertyDetail = lazy(() => import('./components/pages/property/detail'));
export const PropertyEdit = lazy(() => import('./components/pages/property/edit'));

export const InfoUserLicense = lazy(() => import('./components/pages/info/userLicense'));

export const InfoLicense = lazy(() => import('./components/pages/info/license'));
