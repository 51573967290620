import type { Action, PayloadAction } from '@reduxjs/toolkit';

export type ConstructionState = {
  refresh: Record<string, never>;
  refresh2: Record<string, never>;
  refresh3: Record<string, never>;
  isLoading: boolean;
  isLoading2: boolean;
  isSubmitting: boolean;
  isInvalidToken: boolean;
  construction: Construction | null;
  constructionsList: ConstructionsListData[];
  constructionsListDisplayStatus: ConstructionsListDisplayStatus | null;
  constructionInfo: ConstructionInfoData | null;
  constructionComments: ConstructionCommentsData | null;
};

export enum ConstructionActionTypes {
  INIT = 'construction/init',
  REFRESH = 'construction/refresh',
  REFRESH2 = 'construction/refresh2',
  REFRESH3 = 'construction/refresh3',
  LOADING = 'construction/set/isLoading',
  LOADING2 = 'construction/set/isLoading2',
  SUBMITTING = 'construction/set/isSubmitting',
  INVALID = 'construction/set/isInvalidToken',
  SET_CONSTRUCTION = 'construction/set/construction',
  SET_CONSTRUCTIONS_LIST = 'construction/set/constructionsList',
  SET_CONSTRUCTIONS_LIST_DISPLAY_STATUS = 'construction/set/constructionsListDisplayStatus',
  SET_CONSTRUCTIONS_INFO = 'construction/set/constructionInfo',
  SET_CONSTRUCTIONS_COMMENTS = 'construction/set/constructionComments',
}

export type ConstructionAction =
  | Action<ConstructionActionTypes.INIT>
  | Action<ConstructionActionTypes.REFRESH>
  | Action<ConstructionActionTypes.REFRESH2>
  | Action<ConstructionActionTypes.REFRESH3>
  | PayloadAction<boolean, ConstructionActionTypes.LOADING>
  | PayloadAction<boolean, ConstructionActionTypes.LOADING2>
  | PayloadAction<boolean, ConstructionActionTypes.SUBMITTING>
  | PayloadAction<boolean, ConstructionActionTypes.INVALID>
  | PayloadAction<ConstructionsListData[], ConstructionActionTypes.SET_CONSTRUCTIONS_LIST>
  | PayloadAction<
      ConstructionsListDisplayStatus | null,
      ConstructionActionTypes.SET_CONSTRUCTIONS_LIST_DISPLAY_STATUS
    >
  | PayloadAction<Construction | null, ConstructionActionTypes.SET_CONSTRUCTION>
  | PayloadAction<ConstructionInfoData | null, ConstructionActionTypes.SET_CONSTRUCTIONS_INFO>
  | PayloadAction<
      ConstructionCommentsData | null,
      ConstructionActionTypes.SET_CONSTRUCTIONS_COMMENTS
    >;

// -- State Types ---------------------------------------------------------- //

/**
 * @summary /api/constructions(工事一覧取得のresponseのresult(data内)型)
 * @description /api/constructions(工事一覧取得swagger参照)
 */
export type ConstructionsListData = {
  construction_id: number;
  property_id: number;
  property_name: string;
  status_id: number;
  status_name: string;
  start_date: string;
  title: string;
  reporter_id: number;
  reporter_name: string;
  latest_comment: string;
  update_datetime: string;
};

export type ConstructionsListDisplayStatus = {
  num_per_page: number;
  current_page: number;
  total_page: number;
  total: number;
  sort: number;
};

export type Construction = {
  constructionId: number;
  title: string;
  propertyId: number;
  propertyName: string;
  operators: {
    companyId: number;
    companyName: string;
    userId: number;
    userName: string;
    emailAddress: string;
    isRepresentative: boolean;
  }[];
  workers: {
    companyId: number;
    companyName: string;
    userId: number;
    userName: string;
    emailAddress: string;
    isRepresentative: boolean;
  }[];
  statusId: number;
  statusName: string;
  remarks: string;
  description: string;
  reporterId: number;
  reporterName: string;
  updateDatetime: string;
  files: FileList[];
  reports: ReportsList[];
};

export type FileList = {
  fileName: string;
  memo: string;
  fileUrl: string;
};

export type ReportsList = {
  fileName: string;
  memo: string;
  fileUrl: string;
  fileSize: number;
};

export type ConstructionReportSubmitData = {
  description: string;
  reports: (FileList & { deleteFlag: boolean })[];
};

export type ConstructionUrl = {
  accessUrl: string;
  expirationDatetime: string;
};

export type ConstructionInfoData = {
  constructionId: number;
  title: string;
  propertyId: number;
  propertyName: string;
  message: string;
  fileList: ConstructionInfoFilesType[];
};

export type ConstructionInfoFilesType = {
  fileId: number;
  fileName: string;
  fileUrl: string;
  fileSize: number;
};

/** @summary 工事コメント一覧取得Stateのtype */
export type ConstructionCommentsData = {
  commentList: CommentListData[];
};

export type CommentListData = {
  commentId: number;
  typeId: number;
  typeName: string;
  beforeStatusId: number;
  beforeStatusName: string;
  afterStatusId: number;
  afterStatusName: string;
  createUserId: number;
  createUserName: string;
  createDatetime: string;
  updateDatetime: string;
  comment: string;
  imageList: CommentListImageListData[];
  fileList: CommentListFileListData[];
};

export type CommentListImageListData = {
  fileId: number;
  fileName: string;
  fileUrl: string;
  thumbnailFileUrl: string;
  fileSize: number;
  memo: string;
};
export type CommentListFileListData = {
  fileId: number;
  fileName: string;
  fileUrl: string;
  fileSize: number;
};

// /** @summary コメント登録・編集type */
export type UpdateCommentData = {
  comment: string;
  imageList: UpdateImageListData[];
  fileList: UpdateFileListData[];
};

export type UpdateImageListData = {
  fileName: string;
  fileContent: string;
  memo: string;
};

export type UpdateFileListData = {
  fileName: string;
  fileContent: string;
};
export type UpdateImageListState = {
  fileName: string;
  fileUrl: string;
  memo: string;
  fileSize: number;
  thumbnailFileUrl: string;
};

export type UpdateFileListState = {
  fileName: string;
  fileUrl: string;
  fileSize: number;
};

/**
 * 工事検索のための詳細検索条件のlocationのstateタイプ
 */
export interface ConstructionAdvancedQuery {
  sort?: string;
  page?: number;
  sizePerPage?: number;
  keyword?: string;
  showCompleted: boolean;
  supervisor?: string;
  worker?: string;
  approvalUser?: string;
  status?: string;
  company?: string;
  startSince?: string;
  startUntil?: string;
  endSince?: string;
  endUntil?: string;
  name?: string;
  propertyId?: number | null;
}

/**
 * 工事一覧検索時に使われるlocationのstate
 */
export type ConstructionStateQuery = ConstructionAdvancedQuery;

/** 工事一覧画面のlocationのstate */
export type ConstructionListState = ConstructionStateQuery &
  ConstructionAdvancedQuery & {
    order: boolean;
    pageH: number;
  };

// -- API Types ------------------------------------------------------------ //

/**
 * @summary /api/constructions(工事一覧取得のresponseのresult型)
 * @description /api/constructions(工事一覧取得swagger参照)
 */
export type APIConstructionsList = {
  data: ConstructionsListData[];
  display_params: ConstructionsListDisplayStatus;
};

export type APIConstruction = {
  readonly construction_id: number;
  readonly title: string;
  readonly property_id: number;
  readonly property_name: string;
  readonly operators: {
    readonly company_id: number;
    readonly company_name: string;
    readonly user_id: number;
    readonly user_name: string;
    readonly email_address: string;
    readonly is_representative: boolean;
  }[];
  readonly worker: {
    readonly company_id: number;
    readonly company_name: string;
    readonly user_id: number;
    readonly user_name: string;
    readonly email_address: string;
    readonly is_representative: boolean;
  }[];
  readonly status_id: number;
  readonly status_name: string;
  readonly remarks: string;
  readonly description: string;
  readonly reporter_id: number;
  readonly reporter_name: string;
  readonly upload_datetime: string;
  readonly files: [
    {
      readonly file_name: string;
      readonly memo: string;
      readonly file_url: string;
    },
  ];
  readonly reports: [
    {
      readonly file_name: string;
      readonly memo: string;
      readonly file_url: string;
      readonly file_size: number;
    },
  ];
};

export type APICreateConstructionReport = {
  description: string;
  reports: {
    file_name: string;
    file_content: string;
    is_delete_file: boolean;
    memo: string;
  }[];
  files: [];
};

// 工事承認・差し戻し
export type APIApproveConstruction = {
  is_approved: boolean;
  comment: string;
};

export type APIUpdateConstructionReport = APICreateConstructionReport;

/** @summary 工事一覧取得パラメーター */
export type APIGetConstructionsListParams = {
  sort: string;
  page: number;
  size_per_page?: number;
  tag: string | null;
  keyword: string;
  show_completed: boolean;
  supervisor: string;
  worker: string;
  approval_user: string;
  status: number | null;
  company: string;
  start_since: string | null;
  start_until: string | null;
  end_since: string | null;
  end_until: string | null;
  name: string;
  property_id?: number | null;
};

/** @summary 工事-報告書提出URL生成 */
export type APIConstructionUrl = {
  access_url: string;
  expiration_datetime: string;
};

/** @summary 工事-連絡事項情報取得type */
export type APIConstructionInfo = {
  construction_id: number;
  title: string;
  property_id: number;
  property_name: string;
  message: string;
  file_list: ConstructionInfoFiles[];
};

/** @summary 工事-連絡事項添付ファイル登録type */
export type ConstructionInfoFiles = {
  file_id: number;
  file_name: string;
  file_url: string;
  file_size: number;
};

/** @summary 工事-連絡事項添付ファイル名変更type */
export type ConstructionInfoFIlesResponse = {
  file_name: string;
};

/** @summary 伝達事項編集type*/
export type ConstructionInfoMessage = {
  message: string;
};

/** @summary 工事コメント一覧取得type */
export type ConstructionComments = {
  comment_list: CommentList[];
};

export type CommentList = {
  comment_id: number;
  type_id: number;
  type_name: string;
  before_status_id: number;
  before_status_name: string;
  after_status_id: number;
  after_status_name: string;
  create_user_id: number;
  create_user_name: string;
  create_datetime: string;
  update_datetime: string;
  comment: string;
  image_list: CommentListImageList[];
  file_list: CommentListFileList[];
};

export type CommentListImageList = {
  file_id: number;
  file_name: string;
  file_url: string;
  thumbnail_file_url: string;
  file_size: number;
  memo: string;
};
export type CommentListFileList = {
  file_id: number;
  file_name: string;
  file_url: string;
  file_size: number;
};

/** @summary コメント登録APIのtype */
export type RegComment = {
  comment: string;
  image_list: RegImageList[];
  file_list: RegFileList[];
};

export type RegImageList = {
  file_name: string;
  file_content: string;
  memo: string;
};

export type RegFileList = {
  file_name: string;
  file_content: string;
};

/** @summary コメント編集APIのtype */
export type UpdateComment = {
  comment: string;
  image_list: UpdateImageList[];
  file_list: UpdateFileList[];
};

export type UpdateImageList = {
  file_name: string;
  file_content: string;
  memo: string;
  is_delete_file: boolean;
};

export type UpdateFileList = {
  file_name: string;
  file_content: string;
  is_delete_file: boolean;
};

export type submitConstruction = {
  comment: string;
};

/** @summary /api/constructions(工事一覧取得のresponse型)*/
export type APIGetConstructionsListResponse = { result: APIConstructionsList };

export type APIGetConstructionResponse = { result: APIConstruction };

export type APICreateConstructionReportRequest = APICreateConstructionReport;

export type APIUpdateConstructionReportRequest = APIUpdateConstructionReport;

export type APIApproveConstructionRequest = APIApproveConstruction;

export type APIGetConstructionGuestRequest = { token: string };

export type APIGetConstructionUrlResponse = { result: APIConstructionUrl };

/** @summary /api/constructions/{construction_id}/information（工事-連絡一覧情報取得）*/
export type APIGetConstructionInfoResponse = { result: APIConstructionInfo };

/** @summary /api/constructions/{construction_id}/information/files{工事-連絡事項添付ファイル登録} */
export type APIGetConstructionInfoFilesResponse = { result: ConstructionInfoFiles };

/** @summary  /api/constructions/{construction_id}/information/files/{file_id}(工事-連絡事項添付ファイル名変更) */
export type APIPutConstructionInfoFilesRequest = ConstructionInfoFIlesResponse;

/** @summary  /api/constructions/{construction_id}/information/message(伝達事項編集)*/
export type APIPutConstructionInfoMessageRequest = ConstructionInfoMessage;

/** @summary  /api/constructions/{construction_id}/comments（工事コメント一覧取得） */
export type APIConstructionsCommentsResponse = { result: ConstructionComments };

/** @summary  /api/constructions/{construction_id}/comments (工事コメント登録) */
export type APIPostConstructionsCommentsRequest = RegComment;

export type APIPostConstructionsCommentsResponse = { result: CommentList };

/** @summary  /api/constructions/{construction_id}/comments (工事コメント編集) */
export type APIPutConstructionsCommentsRequest = UpdateComment;

export type APIPutConstructionsCommentsResponse = { result: CommentList };

/** @summary /api/constructions/{construction_id}/submit 報告書提出 */
export type APISubmitConstructionRequest = submitConstruction;
