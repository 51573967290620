import { combineReducers } from 'redux';

import planAnnualScheduleReducer, { PlanAnnualScheduleState } from './annualSchedule';
import planAnnualTableReducer, { PlanAnnualTableState } from './annualTable';
import planMonthlyScheduleReducer, { PlanMonthlyScheduleState } from './monthlySchedule';
import planMonthlyTableReducer, { PlanMonthlyTableState } from './monthlyTable';
import planDailyScheduleReducer, { PlanDailyScheduleState } from './dailySchedule';
import planAdjustmentReducer, { PlanAdjustmentState } from './adjustment';
import { PlanMonthlyMailState } from './monthlyMail/types';
import planMonthlyMailReducer from './monthlyMail/reducers';

export type PlanState = {
  annualSchedule: PlanAnnualScheduleState;
  annualTable: PlanAnnualTableState;
  monthlySchedule: PlanMonthlyScheduleState;
  monthlyTable: PlanMonthlyTableState;
  monthlyMail: PlanMonthlyMailState;
  dailySchedule: PlanDailyScheduleState;
  adjustment: PlanAdjustmentState;
};

const reducer = combineReducers<PlanState>({
  annualSchedule: planAnnualScheduleReducer,
  annualTable: planAnnualTableReducer,
  monthlySchedule: planMonthlyScheduleReducer,
  monthlyTable: planMonthlyTableReducer,
  monthlyMail: planMonthlyMailReducer,
  dailySchedule: planDailyScheduleReducer,
  adjustment: planAdjustmentReducer,
});

export default reducer;
