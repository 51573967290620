import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as StoreProvider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import Auth0Provider from '@providers/auth0ProviderWithHistory';
import ResourceProvider from '@providers/resourceProvider';
import { AxiosProvider } from '@providers/axiosProvider';

import store from './ducks';
import App from './app';

import 'react-calendar/dist/Calendar.css';

import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV !== 'development') {
  console.log = () => {};
  console.debug = () => {};
}

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <ResourceProvider lang="ja">
        <HelmetProvider>
          <BrowserRouter>
            <Auth0Provider>
              <AxiosProvider>
                <App />
              </AxiosProvider>
            </Auth0Provider>
          </BrowserRouter>
        </HelmetProvider>
      </ResourceProvider>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('app'),
);

reportWebVitals();
