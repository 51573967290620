import axios from '@providers/axiosProvider';
import * as actions from './actions';
import {
  APIGetAnnualPlansRequestParams,
  APIGetAnnualPlansResponse,
  SearchParameter,
} from './types';

/**
 * @summary 検索パラメータを元に年次計画のリストを取得
 * @param {number | null} propertyId 建物ID
 * @param {number} year 年
 * @param {number} sort ソート番号
 * @param {string} keyword 検索キーワード
 * @param {SearchParameter} params 詳細検索条件
 * @param {number[]} tagIds 選択タグIDリスト
 * @returns {void}
 */
export const getPlansAnnualAsync =
  (
    propertyId: number | null,
    year: number,
    sort: number,
    keyword: string,
    params: SearchParameter,
    tagIds: number[],
  ) =>
  async (dispatch: any) => {
    dispatch(actions.setIsLoading(true));

    const reqParams: APIGetAnnualPlansRequestParams = {
      ...params,
      year: year,
      sort: sort,
    };

    if (propertyId != null) reqParams.property_id = propertyId;
    if (keyword) reqParams.keyword = keyword;
    if (tagIds.length) reqParams.tag = tagIds.join(',');
    if (params.title) reqParams.title = params.title;
    if (params.contract_type) reqParams.contract_type = params.contract_type;
    if (params.inspection_type) reqParams.inspection_type = params.inspection_type;
    if (params.inspection_division) reqParams.inspection_division = params.inspection_division;
    if (params.company) reqParams.company = params.company;

    await axios
      .get<APIGetAnnualPlansResponse>('/plans/annual', {
        params: reqParams,
      })
      .then((response) => {
        const { items, summary } = response.data.result;
        dispatch(actions.setPlans(items));
        dispatch(actions.setSummary(summary));
      })
      .catch(() => {
        dispatch(actions.setPlans([]));
        dispatch(actions.setSummary(null));
      });

    dispatch(actions.setIsLoading(false));
  };

/**
 * @summary 検索パラメータを元に年次計画のリストを出力
 * @param {number | null} propertyId 建物ID
 * @param {number} year 年
 * @param {number} sort ソート番号
 * @param {string} keyword 検索キーワード
 * @param {SearchParameter} params 詳細検索条件
 * @param {number[]} tagIds 選択タグIDリスト
 * @returns {void}
 */
export const downloadPlanAnnualScheduleAsync =
  (
    propertyId: number | null,
    year: number,
    sort: number,
    keyword: string,
    params: SearchParameter,
    tagIds: number[],
  ) =>
  async () => {
    const reqParams: APIGetAnnualPlansRequestParams = {
      ...params,
      year: year,
      sort: sort,
    };

    if (propertyId != null) reqParams.property_id = propertyId;
    if (keyword) reqParams.keyword = keyword;
    if (tagIds.length) reqParams.tag = tagIds.join(',');
    if (params.title) reqParams.title = params.title;
    if (params.contract_type) reqParams.contract_type = params.contract_type;
    if (params.inspection_type) reqParams.inspection_type = params.inspection_type;
    if (params.inspection_division) reqParams.inspection_division = params.inspection_division;
    if (params.company) reqParams.company = params.company;

    return await axios
      .get('/plans/annual/export', {
        params: reqParams,
      })
      .then((response) => {
        return response.data.result;
      })
      .catch((e) => {
        alert(e.message);
        return null;
      });
  };
