import {
  SelectMultipleAffiliateAction,
  SelectMultipleAffiliateState,
  SelectMultipleAffiliateActionTypes as ActionTypes,
} from './types';

const initialSelectMultipleAffiliateState: SelectMultipleAffiliateState = {
  isLoading: false,
  userList: [],
  companyList: [],
  systemUserList: [],
  tab: null,
  selectedItemList: [],
};

const selectMultipleAffiliateReducer = (
  state: SelectMultipleAffiliateState = initialSelectMultipleAffiliateState,
  action: SelectMultipleAffiliateAction,
): SelectMultipleAffiliateState => {
  switch (action.type) {
    case ActionTypes.INIT:
      return initialSelectMultipleAffiliateState;

    case ActionTypes.LOADING:
      return { ...state, isLoading: action.payload };

    case ActionTypes.SET_USER_LIST:
      return { ...state, userList: action.payload };

    case ActionTypes.SET_COMPANY_LIST:
      return { ...state, companyList: action.payload };

    case ActionTypes.SET_SYSTEM_USER_LIST:
      return { ...state, systemUserList: action.payload };

    case ActionTypes.SET_TAB:
      return { ...state, tab: action.payload };

    case ActionTypes.SET_SELECTED_ITEM_LIST:
      return { ...state, selectedItemList: action.payload };

    default:
      return state;
  }
};

export default selectMultipleAffiliateReducer;
