import { InspectionState, InspectionAction, InspectionActionTypes as ActionTypes } from './types';
// InspectionStateに定義した際には、そのプロパティを初期化すること
const initialInspectionsState: InspectionState = {
  refresh: {},
  refreshNotificationTab: {},
  refreshWorkResultTab: {},
  refreshCommentTab: {},
  isLoading: false,
  isLoadingTab: false,
  isSubmitting: false,
  isNavigating: false,
  isNavigating2: false,
  isNavigatingTo: null,
  inspections: [],
  inspectionsListInfo: null,
  inspection: null,
  prevInspection: null,
  inspectionChecklists: [],
  photos: [],
  deletePhotos: [],

  // 以下タブ追加対応で追加した項目
  isInvalidToken: false,
  // 用途確認
  inspectionsList: [],
  inspectionsListDisplayStatus: null,
  // 基本情報タブ用
  inspectionBasic: null,
  /** @summary 定期業務-連絡事項情報State取得 */
  inspectionInfo: null,
  /** @summary 定期業務-コメント一覧取得 */
  inspectionComments: null,
};
/**
 *
 *
 * @param {InspectionState} [state=initialInspectionsState]
 * @param {InspectionAction} action
 * @return {*}  {InspectionState}
 */
const inspectionsReducer = (
  // 上記で定義したオブジェくと
  state: InspectionState = initialInspectionsState,
  action: InspectionAction,
): InspectionState => {
  switch (action.type) {
    case ActionTypes.INIT:
      return initialInspectionsState;

    case ActionTypes.REFRESH:
      return { ...state, refresh: {} };

    case ActionTypes.REFRESH_NOTIFICATION_TAB:
      return { ...state, refreshNotificationTab: {} };

    case ActionTypes.REFRESH_WORK_RESULT_TAB:
      return { ...state, refreshWorkResultTab: {} };

    case ActionTypes.REFRESH_COMMENT_TAB:
      return { ...state, refreshCommentTab: {} };

    case ActionTypes.LOADING:
      return { ...state, isLoading: action.payload };

    case ActionTypes.LOADING_TAB:
      return { ...state, isLoadingTab: action.payload };

    case ActionTypes.SUBMITTING:
      return { ...state, isSubmitting: action.payload };

    case ActionTypes.INVALID:
      return { ...state, isInvalidToken: action.payload };

    case ActionTypes.NAVIGATING:
      return { ...state, isNavigating: action.payload };

    case ActionTypes.NAVIGATING2:
      return { ...state, isNavigating2: action.payload };

    case ActionTypes.NAVIGATING_TO:
      return { ...state, isNavigatingTo: action.payload };

    case ActionTypes.SET_INSPECTIONS:
      return { ...state, inspections: action.payload };

    case ActionTypes.SET_INSPECTIONS_LIST_INFO:
      return { ...state, inspectionsListInfo: action.payload };

    case ActionTypes.SET_INSPECTION:
      return { ...state, inspection: action.payload };

    case ActionTypes.SET_PREV_INSPECTION:
      return { ...state, prevInspection: action.payload };

    case ActionTypes.SET_INSPECTION_CHECKLISTS:
      return { ...state, inspectionChecklists: action.payload };

    case ActionTypes.SET_PHOTOS:
      return { ...state, photos: action.payload };

    case ActionTypes.SET_PHOTO: {
      const photo = action.payload;
      const newPhotos = state.photos.filter((v) => v.id !== photo.id);
      newPhotos.push(photo);

      return { ...state, photos: newPhotos };
    }

    case ActionTypes.SET_DELETE_PHOTO: {
      const photo = action.payload;
      const newDeletePhotos = state.deletePhotos.filter((v) => v.id !== photo.id);
      newDeletePhotos.push(photo);

      return { ...state, deletePhotos: newDeletePhotos };
    }

    // 基本情報タブ

    case ActionTypes.SET_INSPECTION_BASIC:
      return { ...state, inspectionBasic: action.payload };

    /** @summary 定期業務-連絡事項情報State取得 */
    case ActionTypes.SET_INSPECTION_INFO:
      return { ...state, inspectionInfo: action.payload };

    /** @summary 定期業務-コメント一覧取得 */
    case ActionTypes.SET_INSPECTION_COMMENTS:
      return { ...state, inspectionComments: action.payload };

    default:
      return state;
  }
};

export default inspectionsReducer;
