import { AppState as State } from '@ducks/index';
import {
  SearchParameter,
  AnnualPlanTable,
  AnnualPlanTableSummary,
  AnnualPlanTableSalesSummary,
} from './types';

/**
 * @summary リフレッシュを行うためのstateを返却する
 * @param {State} state
 * @returns {Record<string, never>}
 */
export const getRefreshId = (state: State) => state.plan.annualTable.refresh;

/**
 * @summary ローディング状態かを返却する
 * @param {State} state
 * @returns {boolean} ローディング状態か
 */
export const checkIsLoading = (state: State) => state.plan.annualTable.isLoading;

/**
 * @summary 送信処理中かを返却する
 * @param {State} state
 * @returns {boolean} 送信処理中か
 */
export const checkIsSubmitting = (state: State) => state.plan.annualTable.isSubmitting;

/**
 * @summary ソート番号を返却する
 * @param {State} state
 * @returns {number}
 */
export const getSort: (state: State) => number = (state: State) => state.plan.annualTable.sort;

/**
 * @summary 詳細検索条件を返却する
 * @param {State} state
 * @returns {SearchParameter}
 */
export const getSearchParameter: (state: State) => SearchParameter = (state: State) =>
  state.plan.annualTable.searchParameter;

/**
 * @summary 年次販売集計リストを返却する
 * @param {State} state
 * @returns {AnnualPlanTable[]}
 */
export const getTables: (state: State) => AnnualPlanTable[] = (state: State) =>
  state.plan.annualTable.tables;

/**
 * @summary 年次計画サマリを返却する
 * @param {State} state
 * @returns {AnnualPlanTableSummary|null}
 */
export const getSummary: (state: State) => AnnualPlanTableSummary | null = (state: State) =>
  state.plan.annualTable.summary;

/**
 * @summary 年次販売集計サマリを返却する
 * @param {State} state
 * @returns {AnnualPlanTableSalesSummary|null}
 */
export const getSalesSummary: (state: State) => AnnualPlanTableSalesSummary | null = (
  state: State,
) => state.plan.annualTable.salesSummary;
