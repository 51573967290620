import React from 'react';
import { useMessageSource } from 'react-message-source';
import { useRuntimeError, useData } from './hooks';
import './style.scss';

const RuntimeErrorFallback = () => {
  const { getMessage } = useMessageSource();
  const { info } = useData();

  return (
    <>
      <div className="runtime-error">
        <h3>{getMessage('common.runtimeError.0001')}</h3>
        <p>{getMessage('common.runtimeError.0002')}</p>
        <p>{getMessage('common.runtimeError.0003')}</p>
        {info && (
          <div className="error-detail">
            <div className="title">{info?.error.message}</div>
            <div className="component-stack">{info?.componentStack.slice(1)}</div>
          </div>
        )}
        <div className="link">
          <a href="/home">{getMessage('common.runtimeError.1001')}</a>
        </div>
      </div>
    </>
  );
};

export default RuntimeErrorFallback;
export { useRuntimeError };
