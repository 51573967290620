import { PropertyState, PropertyAction, PropertyActionTypes as ActionTypes } from './types';

const initialPropertyState: PropertyState = {
  refresh: {},
  refresh2: {},
  isLoading: false,
  isLoading2: false,
  isLoading3: false,
  isSubmitting: false,
  isNavigating: false,
  isNavigatingTo: null,
  isNeedConfirm: false,
  properties: [],
  propertiesListInfo: null,
  property: null,
  floors: [],
  rooms: [],
  members: [],
  membersListInfo: null,
  selectMembers: [],
  selectMembersListInfo: null,
  tenants: [],
  tenantsListInfo: null,
  addressAutofill: null,
  information: null,
  contracts: [],
  contractsListInfo: null,
  contract: null,
  // 計算した関連ドキュメント(ローカルファイル)のファイルサイズ合計を設定する
  documentFileSize: 0,
};

const templateReducer = (
  state: PropertyState = initialPropertyState,
  action: PropertyAction,
): PropertyState => {
  switch (action.type) {
    case ActionTypes.INIT:
      return initialPropertyState;

    case ActionTypes.REFRESH:
      return { ...state, refresh: {} };

    case ActionTypes.REFRESH2:
      return { ...state, refresh2: {} };

    case ActionTypes.LOADING:
      return { ...state, isLoading: action.payload };

    case ActionTypes.LOADING2:
      return { ...state, isLoading2: action.payload };

    case ActionTypes.LOADING3:
      return { ...state, isLoading3: action.payload };

    case ActionTypes.SUBMITTING:
      return { ...state, isSubmitting: action.payload };

    case ActionTypes.NAVIGATING:
      return { ...state, isNavigating: action.payload };

    case ActionTypes.NAVIGATING_TO:
      return { ...state, isNavigatingTo: action.payload };

    case ActionTypes.NEED_CONFIRM:
      return { ...state, isNeedConfirm: action.payload };

    case ActionTypes.SET_PROPERTIES:
      return { ...state, properties: action.payload };

    case ActionTypes.SET_PROPERTIES_LIST_INFO:
      return { ...state, propertiesListInfo: action.payload };

    case ActionTypes.SET_PROPERTY:
      return { ...state, property: action.payload };

    case ActionTypes.SET_FLOORS:
      return { ...state, floors: action.payload };

    case ActionTypes.SET_ROOMS:
      return { ...state, rooms: action.payload };

    case ActionTypes.SET_MEMBERS:
      return { ...state, members: action.payload };

    case ActionTypes.SET_MEMBERS_LIST_INFO:
      return { ...state, membersListInfo: action.payload };

    case ActionTypes.SET_SELECT_MEMBERS:
      return { ...state, selectMembers: action.payload };

    case ActionTypes.SET_SELECT_MEMBERS_LIST_INFO:
      return { ...state, selectMembersListInfo: action.payload };

    case ActionTypes.SET_TENANTS:
      return { ...state, tenants: action.payload };

    case ActionTypes.SET_TENANTS_LIST_INFO:
      return { ...state, tenantsListInfo: action.payload };

    case ActionTypes.SET_ADDRESS_AUTOFILL:
      return { ...state, addressAutofill: action.payload };

    case ActionTypes.SET_INFORMATION:
      return { ...state, information: action.payload };

    case ActionTypes.SET_CONTRACTS:
      return { ...state, contracts: action.payload };

    case ActionTypes.SET_CONTRACTS_LIST_INFO:
      return { ...state, contractsListInfo: action.payload };

    case ActionTypes.SET_CONTRACT:
      return { ...state, contract: action.payload };

    case ActionTypes.SET_DOCUMENT_FILE_SIZE:
      return { ...state, documentFileSize: action.payload };

    default:
      return state;
  }
};

export default templateReducer;
