import { AppState as State } from '@ducks/index';
import {
  SearchParameter,
  User,
  UserDetail,
  DisplayParams,
  Address,
  Company,
  CompanyDetail,
} from './types';

/**
 * @summary リフレッシュを行うためのstateを返却する
 * @param {State} state
 * @returns {Record<string, never>}
 */
export const getRefreshId = (state: State) => state.affiliate.refresh;

/**
 * @summary ローディング状態かを返却する
 * @param {State} state
 * @returns {boolean} ローディング状態か
 */
export const checkIsLoading = (state: State) => state.affiliate.isLoading;

/**
 * @summary 送信処理中かを返却する
 * @param {State} state
 * @returns {boolean} 送信処理中か
 */
export const checkIsSubmitting = (state: State) => state.affiliate.isSubmitting;

/**
 * @summary ページ遷移を行うかを返却する
 * @param {State} state
 * @returns {boolean} ページ遷移を行うか
 */
export const checkIsNavigating = (state: State) => state.affiliate.isNavigating;

/**
 * @summary 遷移先のurlを返却する
 * @param {State} state
 * @returns {string|null} 遷移先のurl
 */
export const getNavigatingTo = (state: State) => state.affiliate.navigatingTo;

/**
 * @summary ページ数を返却する
 * @param {State} state
 * @returns {number}
 */
export const getPage = (state: State) => state.affiliate.page;

/**
 * @summary ソート番号を返却する
 * @param {State} state
 * @returns {number}
 */
export const getSort = (state: State) => state.affiliate.sort;

/**
 * @summary 詳細検索条件を返却する
 * @param {State} state
 * @returns {SearchParameter}
 */
export const getSearchParameter: (state: State) => SearchParameter = (state: State) =>
  state.affiliate.searchParameter;

/**
 * @summary 検索タグIDを返却する
 * @param {State} state
 * @returns {number[]}
 */
export const getTagIdList = (state: State) => state.affiliate.tagIdList;

/**
 * @summary 連絡先ユーザリストを返却する
 * @param {State} state
 * @returns {User[]}
 */
export const getUserList: (state: State) => User[] = (state: State) => state.affiliate.userList;

/**
 * @summary 連絡先ユーザを返却する
 * @param {State} state
 * @returns {UserDetail}
 */
export const getUserDetail: (state: State) => UserDetail | null = (state: State) =>
  state.affiliate.userDetail;

/**
 * @summary 連絡先企業リストを返却する
 * @param {State} state
 * @returns {Company[]}
 */
export const getCompanyList: (state: State) => Company[] = (state: State) =>
  state.affiliate.companyList;

/**
 * @summary 連絡先企業を返却する
 * @param {State} state
 * @returns {CompanyDetail}
 */
export const getCompanyDetail: (state: State) => CompanyDetail | null = (state: State) =>
  state.affiliate.companyDetail;

/**
 * @summary Pager情報を返却する
 * @param {State} state
 * @returns {DisplayParams}
 */
export const getDisplayParams: (state: State) => DisplayParams = (state: State) =>
  state.affiliate.displayParams;

/**
 * @summary 自動補完住所を返却する
 * @param {State} state
 * @returns {Address|null}
 */
export const getAddressAutofill: (state: State) => Address | null = (state: State) =>
  state.affiliate.addressAutofill;
