import axios from '@providers/axiosProvider';
import * as actions from './actions';

import {
  APIGetInspectionChecklistsResponse,
  APIGetInspectionChecklistResponse,
  APIGetInspectionChecklistsRequestParams,
  InspectionChecklistsList,
  InspectionChecklistsListInfo,
  InspectionChecklist,
  APIDeleteInspectionChecklistRequest,
  APIGetInspectionChecklistFormatPlaceholdersResponse,
  InspectionChecklistFormatPlaceholders,
  APIDownloadInspectionChecklistFormatResponse,
  APIGetInspectionChecklistsDownloadRequestParams,
} from './types';

import { LIST_SIZE_PER_PAGE } from '@utils/constants';
import { commonActions } from '@ducks/common';

export const getInspectionChecklistsAsync =
  (propertyId: number, params: APIGetInspectionChecklistsRequestParams) =>
  async (dispatch: any) => {
    dispatch(actions.setIsLoading(true));

    const reqParams: APIGetInspectionChecklistsRequestParams = {
      ...params,
      size_per_page: LIST_SIZE_PER_PAGE,
    };

    if (propertyId != 0) reqParams['property_id'] = propertyId;

    await axios
      .get<APIGetInspectionChecklistsResponse>(`/inspections/layout`, {
        params: reqParams,
      })
      .then((response) => {
        const { inspection_list: resInspections, display_params: info } = response.data.result;
        const inspectionChecklists: InspectionChecklistsList[] = resInspections.map(
          (inspection): InspectionChecklistsList => ({
            propertyId: inspection.property_id,
            propertyName: inspection.property_name,
            inspectionChecklistId: inspection.inspection_checklist_id,
            inspectionTypeName: inspection.inspection_type_name,
            publishStatus: inspection.publish_status,
            publishStatusName: inspection.publish_status_name,
            title: inspection.title,
            description: inspection.description,
            createUserName: inspection.create_user_name,
            inspectionCount: inspection.inspection_count,
            hasFormat: inspection.has_format,
            updateDatetime: inspection.update_datetime,
          }),
        );

        const inspectionChecklistsListInfo: InspectionChecklistsListInfo = {
          totalHits: info.total,
          totalPages: info.total_page,
        };

        dispatch(actions.setInspectionChecklists(inspectionChecklists));
        dispatch(actions.setInspectionChecklistsListInfo(inspectionChecklistsListInfo));
      })
      .catch(() => {
        dispatch(actions.setInspectionChecklists([]));
        dispatch(actions.setInspectionChecklistsListInfo(null));
      });

    dispatch(actions.setIsLoading(false));
  };

/**
 * @summary チェック状態もしくは検索パラメータを元に点検票を出力
 * @param {APIGetInspectionChecklistsDownloadRequestParams} reqParams すべてをダウンロードするか
 */
export const downloadInspectionChecklistsAsync =
  (params: APIGetInspectionChecklistsDownloadRequestParams) => async () => {
    return await axios
      .get('/inspections/layout/download', {
        params: params,
      })
      .then((response) => {
        return response.data.result;
      })
      .catch((e) => {
        alert(e.message);
        return null;
      });
  };

/**
 * 点検表一括登録(CSV)
 * @param {number} propertyId 建物ID
 * @param {FormData} formData 点検表一括登録用のファイル
 * @return {Promise<boolean>} 登録成否
 */
export const uploadCsvForAddInspectionChecklists =
  (propertyId: number, formData: FormData): ((dispatch: any) => Promise<boolean>) =>
  async (dispatch: any): Promise<boolean> => {
    dispatch(commonActions.setIsProcessing(true));
    dispatch(actions.setIsSubmitting(true));

    return await axios
      .post(`inspections/layout/bulk_create?property_id=${propertyId}`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      })
      .then(() => {
        dispatch(actions.refresh());
        return true;
      })
      .catch(() => {
        return false;
      })
      .finally(() => {
        dispatch(commonActions.setIsProcessing(false));
        dispatch(actions.setIsSubmitting(false));
      });
  };

export const getInspectionChecklistAsync =
  (inspectionChecklistId: number, isEdit: boolean) => async (dispatch: any) => {
    dispatch(actions.setIsLoading(true));

    const params = { is_edit: isEdit };

    await axios
      .get<APIGetInspectionChecklistResponse>(`/inspections/layout/${inspectionChecklistId}`, {
        params: params,
      })
      .then((response) => {
        const checklist = response.data.result;

        const data: InspectionChecklist = {
          inspectionChecklistId: checklist.inspection_checklist_id,
          title: checklist.title,
          description: checklist.description,
          inspectionTypeName: checklist.inspection_type_name,
          publishStatus: checklist.publish_status,
          publishStatusName: checklist.publish_status_name,
          inspectionTypeId: checklist.inspection_type,
          publishStatusId: checklist.publish_status,
          propertyId: checklist.property_id,
          propertyName: checklist.property_name,
          createUserId: checklist.create_user_id,
          createUserName: checklist.create_user_name,
          lastUpdate: checklist.update_datetime,
          layoutInformation: checklist.layout_information,
          inspectionCount: checklist.inspection_count,
          hasFormat: checklist.has_format,
          updateDatetime: checklist.update_datetime,
        };

        dispatch(actions.setInspectionChecklist(data));
      })
      .catch(() => {
        dispatch(actions.setInspectionChecklist(null));
      });

    dispatch(actions.setIsLoading(false));
  };

export const deleteInspectionChecklistAsync = (checklistIds: number[]) => async (dispatch: any) => {
  dispatch(actions.setIsSubmitting(true));

  const requestData: APIDeleteInspectionChecklistRequest = {
    inspection_checklist: checklistIds.map((v) => ({ inspection_checklist_id: v })),
  };

  await axios
    .post(`/inspections/layout/delete`, requestData)
    .then(() => {
      dispatch(actions.setIsNavigating(true));
    })
    .catch(() => {});

  dispatch(actions.setIsSubmitting(false));
};

export const getInspectionChecklistFormatPlaceholdersAsync =
  (inspectionChecklistId: number) => async (dispatch: any) => {
    dispatch(actions.setIsLoading(true));

    await axios
      .get<APIGetInspectionChecklistFormatPlaceholdersResponse>(
        `/inspections/layout/${inspectionChecklistId}/format/placeholders`,
      )
      .then((response) => {
        const result = response.data.result;

        const data: InspectionChecklistFormatPlaceholders[] = result.map((v) => ({
          name: v.name,
          value: v.value,
        }));

        dispatch(actions.setInspectionChecklistFormatPlaceholders(data));
      })
      .catch(() => {
        dispatch(actions.setInspectionChecklistFormatPlaceholders([]));
      });

    dispatch(actions.setIsLoading(false));
  };

export const uploadInspectionChecklistFormatAsync =
  (inspectionChecklistId: number, formData: FormData) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .post(`/inspections/layout/${inspectionChecklistId}/format`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      })
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const downloadInspectionChecklistFormatAsync = async (inspectionChecklistId: number) => {
  const result = await axios
    .get<APIDownloadInspectionChecklistFormatResponse>(
      `/inspections/layout/${inspectionChecklistId}/download`,
    )
    .then((response) => {
      return {
        fileName: response.data.result.file_name,
        fileContent: response.data.result.file_content,
      };
    })
    .catch(() => {
      return null;
    });

  return result;
};

export const deleteInspectionChecklistFormatAsync =
  (inspectionChecklistId: number) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .delete(`/inspections/layout/${inspectionChecklistId}/format`)
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };
