import React, { useEffect } from 'react';
import { Navigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

export const PrivateRoute = ({ children }: { children: any }) => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const location = useLocation();

  useEffect(() => {
    if (isLoading || isAuthenticated) return;
    loginWithRedirect({ appState: { returnTo: location.pathname } });
  }, [isLoading, isAuthenticated, loginWithRedirect, location.pathname]);

  return isAuthenticated ? children : isLoading ? <></> : <Navigate to="/login" />;
};
