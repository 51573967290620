import axios from '@providers/axiosProvider';
import * as actions from './actions';

import {
  APICreateEquipment,
  APICreateEquipmentDiagram,
  APICreateEquipmentRequest,
  APIDeleteEquipmentsRequest,
  APIDownloadExcelFormatResponse,
  APIExportCsvFormatResponse,
  APIGetEquipmentHistoryResponse,
  APIGetEquipmentOptionsForEditResponse,
  APIGetEquipmentResponse,
  APIGetEquipmentSqsesDataResponse,
  APIGetEquipmentsRequestParams,
  APIGetEquipmentsResponse,
  APIUpdateEquipment,
  APIUpdateEquipmentDiagram,
  APIUpdateEquipmentRequest,
  Equipment,
  EquipmentHistory,
  EquipmentSqsesData,
  EquipmentSubmitData,
  EquipmentsList,
  EquipmentsListInfo,
  OptionsForEdit,
  APICreateEquipmentResponse,
  APIDownloadEquipmentRequestParam,
} from './types';

import { LIST_SIZE_PER_PAGE } from '@utils/constants';
import { commonActions } from '@ducks/common';

export const getEquipmentsAsync =
  (params?: APIGetEquipmentsRequestParams) => async (dispatch: any) => {
    dispatch(actions.setIsLoading(true));

    const reqParams: APIGetEquipmentsRequestParams = {
      ...params,
      size_per_page: LIST_SIZE_PER_PAGE,
    };

    await axios
      .get<APIGetEquipmentsResponse>('/properties/equipments', {
        params: reqParams,
      })
      .then((response) => {
        const { equipment_list: result, display_params: info } = response.data.result;

        const equipments: EquipmentsList[] = result.map(
          (equipment): EquipmentsList => ({
            propertyId: equipment.property_id,
            propertyName: equipment.property_name,
            equipmentId: equipment.equipment_id,
            equipmentName: equipment.equipment_name,
            equipmentTypeId: equipment.equipment_type_id,
            equipmentTypeName: equipment.equipment_type_name,
            modelNumber: equipment.model_number,
            // serialNumber: equipment.serial_number,
            manufacturer: equipment.manufacturer,
            manufactureDate: equipment.manufacture_date,
            floorId: equipment.floor_id,
            floorName: equipment.floor_name,
            diagramId: equipment.diagram_id,
            diagramName: equipment.diagram_name,
            roomName: equipment.room_name,
            installYearMonth: equipment.install_year_month,
            usefulYear: equipment.useful_year,
            isUnused: equipment.is_unused,
            elapsedYear: equipment.elapsed_year,
            inInspection: equipment.in_inspection,
            inTrouble: equipment.in_trouble,
            isAbnormal: equipment.is_abnormal,
          }),
        );

        const equipmentsListInfo: EquipmentsListInfo = {
          totalHits: info.total,
          totalPages: info.total_page,
        };

        dispatch(actions.setEquipments(equipments));
        dispatch(actions.setEquipmentsListInfo(equipmentsListInfo));
      })
      .catch(() => {
        dispatch(actions.setEquipments([]));
        dispatch(actions.setEquipmentsListInfo(null));
      });

    dispatch(actions.setIsLoading(false));
  };

export const getEquipmentAsync =
  (propertyId: number, equipmentId: number) => async (dispatch: any) => {
    dispatch(actions.setIsLoading(true));

    await axios
      .get<APIGetEquipmentResponse>(`/properties/${propertyId}/equipments/${equipmentId}`)
      .then((response) => {
        const { equipment: v, equipment_master: w } = response.data.result;

        const equipment: Equipment = {
          propertyId: v.property_id,
          propertyName: v.property_name,
          diagramId: v.diagram_id,
          diagramName: v.diagram_name,
          equipmentId: v.equipment_id,
          equipmentName: v.equipment_name,
          floorId: v.floor_id,
          floorName: v.floor_name,
          roomId: v.room_id,
          roomName: v.room_name,
          equipmentTypeName: v.equipment_type_name,
          modelNumber: v.model_number,
          serialNumber: v.serial_number,
          manufacturer: v.manufacturer,
          manufactureDate: v.manufacture_date ?? '',
          installDate: v.install_date ?? '',
          diagramSymbol: v.diagram_symbol,
          equipmentTypeId: v.equipment_type_id,
          remarks: v.remarks,
          imageId: v.file_id,
          imageUrl: v.file_url ?? '',
          // v1.4.0で追加
          usefulYear: v.useful_year,
          elapsedYear: v.elapsed_year,
          previousEquipmentId: v.previous_equipment_id,
          previousEquipmentName: v.previous_equipment_name,
          successorEquipmentId: v.successor_equipment_id,
          successorEquipmentName: v.successor_equipment_name,
          isUnused: v.is_unused,
          documents: v.documents,
          imageList: v.image_list,
          model:
            w.mst_equipment_std_id != null
              ? {
                  mstEquipmentStdId: w.mst_equipment_std_id,
                  modelNumber: w.model_number,
                  modelName: w.model_name,
                  equipmentTypeName: w.equipment_type_name,
                  manufacturer: w.manufacturer,
                  releaseDate: w.release_date ?? '',
                  manualUrl: w.manual_url,
                  detail: w.detail.map((m) => ({
                    stdName: m.std_name,
                    stdValue: m.std_value,
                  })),
                }
              : null,
          isGpf: v.is_gpf,
          gpfData: v.gpf_data
            ? {
                onOff: v.gpf_data.on_off,
                operatingMode: v.gpf_data.operating_mode_name,
                setPoint: v.gpf_data.set_point,
                fanSpeed: v.gpf_data.fan_speed_name,
                equipmentError: v.gpf_data.equipment_error,
                equipmentErrorCode: v.gpf_data.equipment_error_code,
                equipmentErrorLevel: v.gpf_data.equipment_error_level_name,
              }
            : null,
          isKirei: v.is_kirei,
          kireiData: v.kirei_data
            ? {
                image: v.kirei_data.image,
                dirtRate: v.kirei_data.dirt_rate,
                timestamp: v.kirei_data.timestamp,
              }
            : null,
          isSqses: v.is_sqses,
        };

        dispatch(actions.setEquipment(equipment));
      })
      .catch(() => {
        dispatch(actions.setEquipment(null));
      });

    dispatch(actions.setIsLoading(false));
  };

export const getEquipmentHistoryAsync =
  (propertyId: number, equipmentId: number) => async (dispatch: any) => {
    dispatch(actions.setIsLoading2(true));

    await axios
      .get<APIGetEquipmentHistoryResponse>(
        `/properties/${propertyId}/equipments/troubles/equipment_troubles/${equipmentId}`,
      )
      .then((response) => {
        const result = response.data.result.trouble_list;
        const equipmentHistory: EquipmentHistory[] = result.map((v) => ({
          troubleId: v.trouble_id,
          title: v.title,
          priorityId: v.priority_id,
          priorityName: v.priority_name,
          troubleType: v.trouble_type,
          troubleTypeName: v.trouble_type_name,
          statusId: v.status_id,
          statusName: v.status_name,
          diagramId: v.diagram_id,
          diagramName: v.diagram_name,
          equipmentId: v.equipment_id,
          equipmentName: v.equipment_name,
          floorId: v.floor_id,
          floorName: v.floor_name,
          roomId: v.room_id,
          roomName: v.room_name,
          occurrenceDate: v.occurrence_date,
          occurrenceTime: v.occurence_time,
          description: v.description,
          picUserId: v.pic_user_id,
          picUserName: v.pic_user_name,
          isDeletedUser: v.is_deleted_user,
          updateDatetime: v.update_datetime,
        }));
        dispatch(actions.setEquipmentHistory(equipmentHistory));
      })
      .catch(() => {
        dispatch(actions.setEquipmentHistory([]));
      });

    dispatch(actions.setIsLoading2(false));
  };

export const getEquipmentSqsesDataAsync =
  (propertyId: number, equipmentId: number) => async (dispatch: any) => {
    dispatch(actions.setIsLoading2(true));

    await axios
      .get<APIGetEquipmentSqsesDataResponse>(
        `/properties/${propertyId}/equipments/${equipmentId}/sqses`,
      )
      .then((response) => {
        const result = response.data.result.maintenance_list;
        const sqsesData: EquipmentSqsesData[] = result.map((v) => ({
          sqsesId: v.sqses_id,
          drno: v.drno,
          occurrenceDate: v.occurrence_date ?? '',
          keitomei: v.keitomei,
          modelNumber: v.model_number,
          serialNumber: v.serial_number,
          sqsesFlag: v.sqses_flag,
          kashoDetail: v.kasho_detail,
          jokyoDetail: v.jokyo_detail,
          ijocd: v.ijocd,
          sgnaiyo: v.sgnaiyo,
        }));
        dispatch(actions.setEquipmentSqsesData(sqsesData));
      })
      .catch(() => {
        dispatch(actions.setEquipmentSqsesData([]));
      });

    dispatch(actions.setIsLoading2(false));
  };

export const getEquipmentOptionsForEditAsync = (propertyId: number) => async (dispatch: any) => {
  dispatch(actions.setIsLoading2(true));

  await axios
    .get<APIGetEquipmentOptionsForEditResponse>(`/properties/${propertyId}/equipments/items`)
    .then((response) => {
      const result = response.data.result;
      const optionsForEdit: OptionsForEdit = {
        equipmentTypeOptions: result.equipment_type_list.map((t) => ({
          value: t.mst_equipment_type_id,
          title: t.mst_equipment_type_name,
        })),
        diagramOptions: result.diagram_list.map((d) => ({
          value: d.diagram_id,
          title: d.diagram_name,
        })),
        floorOptions: result.floor_list.map((f) => ({
          value: f.floor_id,
          title: f.floor_name,
          roomOptions: f.room_list.map((r) => ({ value: r.room_id, title: r.room_name })),
        })),
      };
      dispatch(actions.setOptionsForEdit(optionsForEdit));
    })
    .catch(() => {
      dispatch(actions.setOptionsForEdit(null));
    });

  dispatch(actions.setIsLoading2(false));
};

export const createEquipmentAsync =
  (propertyId: number, data: EquipmentSubmitData) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const equipment: APICreateEquipment = {
      floor_id: data.floorId,
      room_id: data.roomId,
      equipment_name: data.equipmentName,
      model_number: data.modelNumber,
      serial_number: data.serialNumber,
      equipment_type_id: data.equipmentTypeId,
      diagram_symbol: data.diagramSymbol,
      manufacturer: data.manufacturer,
      manufacture_date: data.manufactureDate,
      install_date: data.installDate,
      remarks: data.remarks,
      image_list: data.imageList,
      file_name: data.fileName,
      file_content: data.fileContent,
      useful_year: data.usefulYear,
      previous_equipment_id: data.previousEquipmentId,
      successor_equipment_id: data.successorEquipmentId,
      is_unused: data.isUnused,
      documents: data.documents,
    };

    const diagram: APICreateEquipmentDiagram = {
      diagram_id: null,
      diagram_name: '',
      description: '',
      is_set: false,
    };

    if (data.createNewDiagramFlag) {
      diagram.diagram_name = data.diagramName;
      diagram.description = data.diagramDescription;
      diagram.is_set = true;
    } else if (data.diagramId != null) {
      diagram.diagram_id = data.diagramId;
      diagram.is_set = true;
    }

    const requestData: APICreateEquipmentRequest = {
      equipment,
      diagram,
    };

    await axios
      .post<APICreateEquipmentResponse>(`/properties/${propertyId}/equipments`, requestData)
      .then((response) => {
        dispatch(actions.setIsNavigating(true));
        dispatch(actions.setIsNavigatingTo(response.data.result.equipment_id));
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const updateEquipmentAsync =
  (propertyId: number, equipmentId: number, data: EquipmentSubmitData) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const equipment: APIUpdateEquipment = {
      floor_id: data.floorId,
      room_id: data.roomId,
      equipment_name: data.equipmentName,
      model_number: data.modelNumber,
      serial_number: data.serialNumber,
      equipment_type_id: data.equipmentTypeId,
      diagram_symbol: data.diagramSymbol,
      manufacturer: data.manufacturer,
      manufacture_date: data.manufactureDate,
      install_date: data.installDate,
      remarks: data.remarks,
      file_name: data.fileName,
      file_content: data.fileContent,
      useful_year: data.usefulYear,
      previous_equipment_id: data.previousEquipmentId,
      successor_equipment_id: data.successorEquipmentId,
      is_unused: data.isUnused,
      documents: data.documents,
      image_list: data.imageList,
    };

    const diagram: APIUpdateEquipmentDiagram = {
      diagram_id: null,
      diagram_name: '',
      description: '',
      is_set: false,
    };

    if (data.createNewDiagramFlag) {
      diagram.diagram_name = data.diagramName;
      diagram.description = data.diagramDescription;
      diagram.is_set = true;
    } else if (data.diagramId != null) {
      diagram.diagram_id = data.diagramId;
      diagram.is_set = true;
    }

    const requestData: APIUpdateEquipmentRequest = {
      property_id: propertyId,
      equipment,
      diagram,
    };

    await axios
      .put(`/properties/${propertyId}/equipments/${equipmentId}`, requestData)
      .then(() => {
        dispatch(actions.setIsNavigating(true));
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const deleteEquipmentsAsync =
  (equipmentsToDelete: { propertyId: number; equipmentId: number }[]) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const requestData: APIDeleteEquipmentsRequest = {
      equipment_list: equipmentsToDelete.map((v) => ({
        property_id: v.propertyId,
        equipment_id: v.equipmentId,
      })),
    };

    await axios
      .post(`/properties/equipments/delete`, requestData)
      .then(() => {
        dispatch(actions.setIsNavigating(true));
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const deleteEquipmentImageAsync =
  (propertyId: number, equipmentId: number, fileId: number) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .delete(`/properties/${propertyId}/equipments/${equipmentId}/picture/${fileId}`)
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

/** 一括登録のためのテンプレートCSVファイルをダウンロード */
export const downloadEquipmentsFormatForAddByCsv = async (propertyId: number) => {
  const result = await axios
    .get<APIDownloadExcelFormatResponse>(`/properties/${propertyId}/equipments/download/format`)
    .then((response) => {
      return {
        fileName: response.data.result.file_name,
        fileContent: response.data.result.file_content,
      };
    })
    .catch(() => {
      return null;
    });

  return result;
};

// 設備一覧ダウンロード(CSV)
export const exportEquipmentsListAsync = async (reqParams: APIDownloadEquipmentRequestParam) => {
  const result = await axios
    .get<APIExportCsvFormatResponse>(`/properties/equipments/download`, {
      params: {
        ...reqParams,
      },
    })
    .then((response) => {
      return {
        fileName: response.data.result.file_name,
        fileContent: response.data.result.file_content,
      };
    })
    .catch(() => {
      return null;
    });

  return result;
};

/**
 * 設備一括登録(CSV)
 * @param {number} propertyId 建物ID
 * @param {FormData} formData 設備一括登録用のファイル
 * @return {Promise<boolean>} 登録成否
 */
export const uploadCsvForAddEquipments =
  (propertyId: number, formData: FormData): ((dispatch: any) => Promise<boolean>) =>
  async (dispatch: any): Promise<boolean> => {
    dispatch(commonActions.setIsProcessing(true));
    dispatch(actions.setIsSubmitting(true));

    return await axios
      .post(`/properties/${propertyId}/equipments/upload`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      })
      .then(() => {
        dispatch(actions.refresh());
        return true;
      })
      .catch(() => {
        return false;
      })
      .finally(() => {
        dispatch(commonActions.setIsProcessing(false));
        dispatch(actions.setIsSubmitting(false));
      });
  };
