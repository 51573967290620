import { AppState as State } from '@ducks/index';

export const getRefreshId = (state: State) => state.plan.adjustment.refresh;

export const checkIsLoading = (state: State) => state.plan.adjustment.isLoading;
export const checkIsSubmitting = (state: State) => state.plan.adjustment.isSubmitting;
export const checkIsInvalidToken = (state: State) => state.plan.adjustment.isInvalidToken;

export const getPlans = (state: State) => state.plan.adjustment.plans;
export const getSummary = (state: State) => state.plan.adjustment.summary;

export const checkIsAllAnswered = (state: State) =>
  state.plan.adjustment.plans.filter((v) => !v.isAnswered).length === 0;
