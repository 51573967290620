import { useDispatch, useSelector } from 'react-redux';
import { commonActions, commonSelectors } from '~/ducks/common';

export const useRuntimeError = () => {
  const dispatch = useDispatch();

  const runtimeErrorHandler = (error: Error, info: { componentStack: string }) => {
    dispatch(
      commonActions.setRuntimeError({
        error: error,
        componentStack: info.componentStack,
      }),
    );
  };

  return { runtimeErrorHandler };
};

export const useData = () => {
  const info = useSelector(commonSelectors.getRuntimeError);
  return { info };
};
