import {
  Group,
  GroupAction as Action,
  GroupActionTypes as ActionTypes,
  GroupsList,
  GroupsListInfo,
  SelectMembersList,
  SelectMembersListInfo,
} from './types';

export const init = (): Action => ({ type: ActionTypes.INIT });

export const refresh = (): Action => ({ type: ActionTypes.REFRESH });

export const setIsLoading = (isLoading: boolean): Action => ({
  type: ActionTypes.LOADING,
  payload: isLoading,
});

export const setIsLoading2 = (isLoading2: boolean): Action => ({
  type: ActionTypes.LOADING2,
  payload: isLoading2,
});

export const setIsSubmitting = (isSubmitting: boolean): Action => ({
  type: ActionTypes.SUBMITTING,
  payload: isSubmitting,
});

export const setIsNavigating = (isNavigating: boolean): Action => ({
  type: ActionTypes.NAVIGATING,
  payload: isNavigating,
});

export const setIsNavigatingTo = (isNavigatingTo: number | null): Action => ({
  type: ActionTypes.NAVIGATING_TO,
  payload: isNavigatingTo,
});

export const setGroups = (groups: GroupsList[]): Action => ({
  type: ActionTypes.SET_GROUPS,
  payload: groups,
});

export const setGroupsListInfo = (info: GroupsListInfo | null): Action => ({
  type: ActionTypes.SET_GROUPS_LIST_INFO,
  payload: info,
});

export const setGroup = (group: Group | null): Action => ({
  type: ActionTypes.SET_GROUP,
  payload: group,
});

export const setSelectMembers = (selectMembers: SelectMembersList[]): Action => ({
  type: ActionTypes.SET_SELECT_MEMBERS,
  payload: selectMembers,
});

export const setSelectMembersListInfo = (info: SelectMembersListInfo | null): Action => ({
  type: ActionTypes.SET_SELECT_MEMBERS_LIST_INFO,
  payload: info,
});
