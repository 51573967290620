import type { Action, PayloadAction } from '@reduxjs/toolkit';

export type UserState = {
  refresh: Record<string, never>;
  isLoading: boolean;
  isSubmitting: boolean;
  isNavigating: boolean;
  isNavigatingTo: number | null;
  users: UsersList[];
  usersListInfo: UsersListInfo | null;
  user: User | null;
};

export enum UserActionTypes {
  INIT = 'user/init',
  REFRESH = 'user/refresh',
  LOADING = 'user/set/isLoading',
  SUBMITTING = 'user/set/isSubmitting',
  NAVIGATING = 'user/set/isNavigating',
  NAVIGATING_TO = 'user/set/isNavigatingTo',
  SET_USERS = 'user/set/users',
  SET_USERS_LIST_INFO = 'user/set/usersListInfo',
  SET_USER = 'user/set/user',
}

export type UserAction =
  | Action<UserActionTypes.INIT>
  | Action<UserActionTypes.REFRESH>
  | PayloadAction<boolean, UserActionTypes.LOADING>
  | PayloadAction<boolean, UserActionTypes.SUBMITTING>
  | PayloadAction<boolean, UserActionTypes.NAVIGATING>
  | PayloadAction<number | null, UserActionTypes.NAVIGATING_TO>
  | PayloadAction<UsersList[], UserActionTypes.SET_USERS>
  | PayloadAction<UsersListInfo | null, UserActionTypes.SET_USERS_LIST_INFO>
  | PayloadAction<User | null, UserActionTypes.SET_USER>;

// -- State Types ---------------------------------------------------------- //

export type UsersList = {
  belong: string;
  email: string;
  firstName: string;
  isSystemRole: boolean;
  lastName: string;
  location: string;
  position: string;
  status?: 1 | 2; // 1：有効、2：無効
  userId: number;
};

export type User = UsersList & {
  birth: string;
  cellPhone: string;
  kanaFirst: string;
  kanaLast: string;
  phone: string;
  remarks: string;
  fileId: number | null;
  fileUrl: string | null;
};

export type UsersListInfo = {
  totalHits: number;
  totalPages: number;
};

export type UserSubmitData = {
  belong: string;
  birth: string;
  cellPhone: string;
  email: string;
  firstName: string;
  isSystemRole: boolean;
  kanaFirst: string;
  kanaLast: string;
  lastName: string;
  location: string;
  phone: string;
  position: string;
  remarks: string;
  status?: 1 | 2;
  fileName?: string;
  fileContent?: string;
};

// -- API Types ------------------------------------------------------------ //

export type APIDisplayParam = {
  readonly total: number;
  readonly total_page: number;
};

export type APIUsers = {
  readonly user_id: number;
  readonly email: string;
  readonly last_name: string;
  readonly first_name: string;
  readonly position: string;
  readonly belong: string;
  readonly location: string;
  readonly status: 1 | 2;
  readonly is_system_role: boolean;
};

export type APIUser = APIUsers & {
  readonly kana_last: string;
  readonly kana_first: string;
  readonly birth: string;
  readonly phone: string;
  readonly cell_phone: string;
  readonly remarks: string;
  readonly file_id: number | null;
  readonly file_url: string | null;
};

export type APICreateUser = {
  readonly belong: string;
  readonly birth: string;
  readonly cell_phone: string;
  readonly email: string;
  readonly first_name: string;
  readonly is_system_role: boolean;
  readonly kana_first: string;
  readonly kana_last: string;
  readonly last_name: string;
  readonly location: string;
  readonly phone: string;
  readonly position: string;
  readonly remarks: string;
  readonly file_name?: string;
  readonly file_content?: string;
};

export type APIUpdateUser = {
  readonly belong: string;
  readonly birth: string;
  readonly cell_phone: string;
  readonly first_name: string;
  readonly is_system_role: boolean;
  readonly kana_first: string;
  readonly kana_last: string;
  readonly last_name: string;
  readonly location: string;
  readonly phone: string;
  readonly position: string;
  readonly remarks: string;
  readonly status: number;
  readonly file_name?: string;
  readonly file_content?: string;
};

export type APIProfile = {
  readonly user_id: number;
  readonly email: string;
  readonly last_name: string;
  readonly first_name: string;
  readonly kana_last: string;
  readonly kana_first: string;
  readonly birth: string;
  readonly position: string;
  readonly belong: string;
  readonly location: string;
  readonly phone: string;
  readonly cell_phone: string;
  readonly remarks: string;
  readonly status: number;
  readonly is_system_role: boolean;
  readonly file_id: number;
  readonly file_url: string;
};

export type APIUpdateProfile = {
  readonly belong: string;
  readonly birth: string;
  readonly cell_phone: string;
  readonly first_name: string;
  readonly kana_first: string;
  readonly kana_last: string;
  readonly last_name: string;
  readonly location: string;
  readonly phone: string;
  readonly position: string;
  readonly remarks: string;
  readonly file_name?: string;
  readonly file_content?: string;
};

export type APIGetUsersRequestParams = {
  keyword?: string;
  page?: number;
  size_per_page?: number;
  sort?: number;
};
export type APIGetUsersResponse = {
  result: {
    user_list: APIUsers[];
    display_params: APIDisplayParam;
  };
};

export type APIGetUserResponse = {
  result: APIUser;
};

export type APICreateUserRequest = APICreateUser;
export type APICreateUserResponse = {
  result: { user_id: number };
};

export type APIUpdateUserRequest = APIUpdateUser;

export type APIDeleteUsersRequest = {
  user_list: { user_id: number }[];
};

export type APIGetProfileResponse = {
  result: APIProfile;
};

export type APIUpdateProfileRequest = APIUpdateProfile;
