import type { Action, PayloadAction } from '@reduxjs/toolkit';

/**
 * @summary 建物に設定されるファイルの種類
 * @description 1: ローカル添付ファイル、2: 書庫リンク、3: URL手入力
 */
export type PropertyDocumentType = 1 | 2 | 3;

/**
 * @summary 建物のステータス
 * @description 1：有効、2：無効
 */
export type PropertyStatus = 1 | 2;

export type PropertyState = {
  refresh: Record<string, never>;
  refresh2: Record<string, never>;
  isLoading: boolean;
  isLoading2: boolean;
  isLoading3: boolean;
  isSubmitting: boolean;
  isNavigating: boolean;
  isNavigatingTo: number | null;
  isNeedConfirm: boolean;
  properties: PropertiesList[];
  propertiesListInfo: PropertiesListInfo | null;
  property: Property | null;
  floors: FloorsList[];
  rooms: RoomsList[];
  members: MembersList[];
  membersListInfo: MembersListInfo | null;
  selectMembers: SelectMembersList[];
  selectMembersListInfo: SelectMembersListInfo | null;
  tenants: TenantsList[];
  tenantsListInfo: TenantsListInfo | null;
  addressAutofill: Address | null;
  information: Information | null;
  contracts: ContractsList[];
  contractsListInfo: ContractsListInfo | null;
  contract: Contract | null;
  // 建物編集画面、関連ドキュメント(ローカルファイルの)ファイルサイズ合計
  documentFileSize: number;
};

export enum PropertyActionTypes {
  INIT = 'property/init',
  REFRESH = 'property/refresh',
  REFRESH2 = 'property/refresh2',
  LOADING = 'property/set/isLoading',
  LOADING2 = 'property/set/isLoading2',
  LOADING3 = 'property/set/isLoading3',
  SUBMITTING = 'property/set/isSubmitting',
  NAVIGATING = 'property/set/isNavigating',
  NAVIGATING_TO = 'property/set/isNavigatingTo',
  NEED_CONFIRM = 'property/set/isNeedConfirm',
  SET_PROPERTIES = 'property/set/properties',
  SET_PROPERTIES_LIST_INFO = 'property/set/propertiesListInfo',
  SET_PROPERTY = 'property/set/property',
  SET_FLOORS = 'property/set/floors',
  SET_ROOMS = 'property/set/room',
  SET_MEMBERS = 'property/set/members',
  SET_MEMBERS_LIST_INFO = 'property/set/membersListInfo',
  SET_SELECT_MEMBERS = 'property/set/selectMembers',
  SET_SELECT_MEMBERS_LIST_INFO = 'property/set/selectMembersListInfo',
  SET_TENANTS = 'property/set/tenants',
  SET_TENANTS_LIST_INFO = 'property/set/tenantsListInfo',
  SET_ADDRESS_AUTOFILL = 'property/set/addressAutofill',
  SET_INFORMATION = 'property/set/information',
  SET_CONTRACTS = 'property/set/contracts',
  SET_CONTRACTS_LIST_INFO = 'property/set/contractsListInfo',
  SET_CONTRACT = 'property/set/contract',
  SET_DOCUMENT_FILE_SIZE = 'property/set/documentFileSize',
}

export type PropertyAction =
  | Action<PropertyActionTypes.INIT>
  | Action<PropertyActionTypes.REFRESH>
  | Action<PropertyActionTypes.REFRESH2>
  | PayloadAction<boolean, PropertyActionTypes.LOADING>
  | PayloadAction<boolean, PropertyActionTypes.LOADING2>
  | PayloadAction<boolean, PropertyActionTypes.LOADING3>
  | PayloadAction<boolean, PropertyActionTypes.SUBMITTING>
  | PayloadAction<boolean, PropertyActionTypes.NAVIGATING>
  | PayloadAction<number | null, PropertyActionTypes.NAVIGATING_TO>
  | PayloadAction<boolean, PropertyActionTypes.NEED_CONFIRM>
  | PayloadAction<PropertiesList[], PropertyActionTypes.SET_PROPERTIES>
  | PayloadAction<PropertiesListInfo | null, PropertyActionTypes.SET_PROPERTIES_LIST_INFO>
  | PayloadAction<Property | null, PropertyActionTypes.SET_PROPERTY>
  | PayloadAction<FloorsList[], PropertyActionTypes.SET_FLOORS>
  | PayloadAction<RoomsList[], PropertyActionTypes.SET_ROOMS>
  | PayloadAction<MembersList[], PropertyActionTypes.SET_MEMBERS>
  | PayloadAction<MembersListInfo | null, PropertyActionTypes.SET_MEMBERS_LIST_INFO>
  | PayloadAction<SelectMembersList[], PropertyActionTypes.SET_SELECT_MEMBERS>
  | PayloadAction<SelectMembersListInfo | null, PropertyActionTypes.SET_SELECT_MEMBERS_LIST_INFO>
  | PayloadAction<TenantsList[], PropertyActionTypes.SET_TENANTS>
  | PayloadAction<TenantsListInfo | null, PropertyActionTypes.SET_TENANTS_LIST_INFO>
  | PayloadAction<Address | null, PropertyActionTypes.SET_ADDRESS_AUTOFILL>
  | PayloadAction<Information | null, PropertyActionTypes.SET_INFORMATION>
  | PayloadAction<ContractsList[], PropertyActionTypes.SET_CONTRACTS>
  | PayloadAction<ContractsListInfo | null, PropertyActionTypes.SET_CONTRACTS_LIST_INFO>
  | PayloadAction<Contract | null, PropertyActionTypes.SET_CONTRACT>
  | PayloadAction<number, PropertyActionTypes.SET_DOCUMENT_FILE_SIZE>;

// -- State Types ---------------------------------------------------------- //

/**
 * 建物一覧
 */
export type PropertiesList = {
  /** ID */
  id: number;
  /** 名前 */
  name: string;
  /** 郵便番号 */
  zipCode: string;
  /** 住所 */
  address: string;
  /** オーナー */
  owner: string;
  /** 契約 */
  contact: string;
  /** 契約数 */
  contractNum: number;
  /** 異常のある設備数 */
  abnormalEquipmentNum: number;
  /** 未完了の工事数 */
  incompleteConstructionNum: number;
  /** 未完了の案件数 */
  incompleteWorkNum: number;
  /** URL */
  url: string;
  /** 階数(規模) */
  scale: string;
  /** 駐車場 */
  parking: string;
  /** 更新者 */
  updateUserName: string;
  /** 更新日 */
  updateDatetime: string;
};
export type PropertyImage = {
  /** ファイルID */
  file_id?: number;
  /** ファイル名 */
  file_name: string | null;
  /** 画像をBase64エンコードした文字列 */
  file_content?: string | null;
  /** ファイルサイズ */
  file_size: number | null;
  /** 登録した画像のURL */
  file_url: string | null;
  /** 登録した画像(サムネイル)のURL */
  thumbnail_file_url: string | null;
  /** 登録した画像のメモ */
  memo: string;
  /** 削除フラグ */
  is_delete_file?: boolean;
};

/** @summary 建物個別情報取得API戻り値を格納する型 */
export type Property = {
  /** 建物ID */
  id: number;
  /** 建物名 */
  name: string;
  /** 郵便番号（ハイフン付き） */
  zipCode: string;
  /** 所有者（オーナー） */
  owner: string;
  /** 連絡先（ハイフン付き） */
  contact: string;
  /** 契約数 */
  contractNum: number;
  /** 異常のある設備数 */
  abnormalEquipmentNum: number;
  /** 未完了の工事数 */
  incompleteConstructionNum: number;
  /** 未完了の案件数 */
  incompleteWorkNum: number;
  /** URL */
  url: string;
  /** 建物コード */
  code: string;
  /** 都道府県ID */
  prefectureId: number;
  /** 都道府県名 */
  prefectureName: string;
  /** 市区町村 */
  municipalities: string;
  /** 竣工日 */
  completionDate: string;
  /** 敷地面積 */
  totalSiteArea: number | null;
  /** 延床面積 */
  totalFloorArea: number | null;
  /** 階数(規模) */
  scale: string;
  /** 備考 */
  remarks: string;
  /** ビル名 */
  buildingName: string;
  /** 所有者（企業ID） */
  ownerCompanyId: number | null;
  /** 町域・番地 */
  blockAddress: string;
  /** 駐車場 */
  parking: string;
  /** 設計(企業ID) */
  designCompanyId: number | null;
  /** 施工(企業ID) */
  constructionCompanyId: number | null;
  /** タグリスト */
  tags: {
    /** タグID */
    tag_id: number;
    /** タグ名 */
    tag_name: string;
    /** タグ種別 */
    type_id: number;
    /** タグ種別名 */
    type_name: string;
  }[];
  /** 関連ドキュメントリスト */
  documents: {
    /** 関連ドキュメントID */
    document_id: number;
    /** ドキュメント種別 */
    document_type_id: PropertyDocumentType;
    /** ドキュメントタイトル */
    title: string; //
    /** ドキュメントURL */
    url: string;
    /** ファイルサイズ（ローカルファイルのみ） */
    file_size?: number | null;
  }[];
  /** 外観写真リスト */
  imageList: PropertyImage[];
  /** 設計(企業名) */
  designCompanyName: string;
  /** 施工(企業名) */
  constructionCompanyName: string;
  /** 更新者 */
  updateUserName: string;
  /** 更新日 */
  updateDatetime: string;
  /** ステータス */
  status: PropertyStatus | null;
};

export type PropertiesListInfo = {
  totalHits: number;
  totalPages: number;
};

export type PropertySubmitData = {
  name: string;
  zipCode: string;
  owner: string;
  contact: string;
  code: string;
  prefectureId: number;
  municipalities: string;
  completionDate: string | null;
  totalSiteArea: number | null;
  totalFloorArea: number | null;
  scale: string;
  remarks: string;
  ownerCompanyId: number | null;
  blockAddress: string;
  buildingName: string;
  url: string;
  parking: string;
  designCompanyId: number | null;
  constructionCompanyId: number | null;
  tags: { tag_id: number }[];
  documents: {
    document_type_id: PropertyDocumentType;
    file_name?: string | null;
    file_content?: string | null;
    archive_id?: number;
    title?: string;
    url?: string;
    is_delete_file?: boolean;
  }[];
  imageList: {
    file_name: string;
    file_content: string;
    memo: string;
    is_delete_file?: boolean;
  }[];
};

export type FloorsList = {
  id: number;
  name: string;
  description: string;
  roomNum: number;
};

export type FloorSubmitData = {
  name: string;
  description: string;
};

export type RoomsList = {
  id: number;
  name: string;
  description: string;
};

export type RoomSubmitData = {
  name: string;
  description: string;
};

export type MembersList = {
  id: number;
  name: string;
  type: number;
  roleId: number;
  roleName: string;
};

export type MembersListInfo = PropertiesListInfo;

export type SelectMembersList = {
  id: number;
  name: string;
  type: number;
};

export type SelectMembersListInfo = {
  totalHits: number;
  totalPages: number;
};

export type TenantsList = {
  id: number;
  name: string;
  tel: string;
  email: string;
  remarks: string;
};

export type TenantsListInfo = PropertiesListInfo;

export type TenantSubmitData = {
  name: string;
  tel: string;
  email: string;
  remarks: string;
};

export type Address = {
  address1: string;
  address2: string;
  address3: string;
  prefCode: string;
  zipCode: string;
};

/**
 * 建物の連絡事項のファイル
 */
export type InformationFile = {
  /** ファイルID */
  file_id: number;
  /** ファイル名 */
  file_name: string;
  /** ファイルURL */
  file_url: string;
  /** ファイルサイズ */
  file_size: number;
};
/**
 * 建物の連絡事項
 */
export type Information = {
  /** 建物ID */
  propertyId: number;
  /** 建物名 */
  propertyName: string;
  /** 連絡事項のコメント */
  comment: string;
  /** 建物の連絡事項のファイルリスト */
  fileList: InformationFile[];
};

export type ContractsList = {
  /** 建物ID */
  propertyId: number;
  /** 建物名 */
  propertyName: string;
  /** 契約ID */
  contractId: number;
  /** 計画名 */
  title: string;
  /** 契約種別ID */
  contractTypeId: ContractTypes; //  1: 定期業務、2: 工事
  /** 契約種別 */
  contractTypeName: string;
  /** 定期業務種別ID */
  inspectionTypeId: InspectionTypes; // 契約種別で定期業務を選択した場合のみ 1: 定期、2: 法定、3: 日常、4: その他
  /** 定期業務種別 */
  inspectionTypeName: string;
  /** 定期業務区分ID */
  inspectionDivisionId: InspectionDivisionTypes; // 契約種別で定期業務を選択した場合のみ 1: 設備、2: 清掃、3: 警備、4: 検針、5: 建築、6: その他
  /** 定期業務区分 */
  inspectionDivisionName: string;
  /** 周期ID */
  repeatTypeId: PropertyContractRepeatType; // 1: 毎日、2: 毎週平日、3: 毎週土日、4: 毎月1日、5: 毎月晦日、6: カスタム設定 （NULLで繰り返しなし）
  /** 周期 */
  repeatTypeName: string;
  /** 開始日 */
  startDate: string;
  /** 終了日 */
  endDate: string;
  /** 協力会社ID */
  companyId: number | null;
  /** 協力会社名 */
  companyName: string;
  /** 請求単価 */
  invoiceUnitPrice: number;
  /** 支払単価 */
  purchasesUnitPrice: number;
  /** 契約状況ID */
  statusId: ContractStatusTypes; // 1: 契約中、2: 契約終了
  /** 契約状況 */
  statusName: string;
  /** 点検表ID(契約種別で定期業務を選択した場合のみ) */
  inspectionChecklistId: number;
  /** 報告書フォーマット(契約種別で定期業務を選択した場合のみ) */
  formatFileUrl: string;
};

export type ContractsListInfo = {
  totalHits: number;
  totalPages: number;
};

export type Contract = {
  /** 建物ID */
  propertyId: number;
  /** 建物名 */
  propertyName: string;
  /** 契約ID */
  contractId: number;
  /** 計画名 */
  title: string;
  /** 契約更新月 */
  updateMonth: number; // 1~12
  /** 更新月通知設定 */
  hasNotifyPrior: boolean;
  /** 契約更新月のnヶ月前に通知する */
  priorNotificationMonths: number; // 1~12
  /** 契約種別ID */
  contractTypeId: ContractTypes; //  1: 定期業務、2: 工事
  /** 契約種別 */
  contractTypeName: string;
  /** 定期業務種別ID */
  inspectionTypeId: InspectionTypes; // 契約種別で定期業務を選択した場合のみ 1: 定期、2: 法定、3: 日常、4: その他
  /** 定期業務種別 */
  inspectionTypeName: string;
  /** 定期業務区分ID */
  inspectionDivisionId: InspectionDivisionTypes; // 契約種別で定期業務を選択した場合のみ 1: 設備、2: 清掃、3: 警備、4: 検針、5: 建築、6: その他
  /** 定期業務区分 */
  inspectionDivisionName: string;
  /** 繰り返す */
  isRepeat: boolean;
  /** 周期ID */
  repeatTypeId: PropertyContractRepeatType; // 1: 毎日、2: 毎週平日、3: 毎週土日、4: 毎月1日、5: 毎月晦日、6: カスタム設定 （NULLで繰り返しなし）
  /** 周期 */
  repeatTypeName: string;
  /** カスタム繰り返し設定 */
  customCycles: PropertyContractRepeatCustomCycleInterface;
  /** 開始日 */
  startDate: string;
  /** 終了日設定 */
  endTypeId: PropertyContractEndType; // 1: なし、2: 終了日 = yyyy年mm月dd日、3: 繰り返し = n回
  /** 終了日 */
  endDate: string;
  /** 繰り返し回数 */
  repeatCount: number; // 1~
  /** 協力会社ID */
  companyId: number | null;
  /** 協力会社名 */
  companyName: string;
  /** 請求単価 */
  invoiceUnitPrice: number;
  /** 支払単価 */
  purchasesUnitPrice: number;
  /** 点検表ID(契約種別で定期業務を選択した場合のみ) */
  inspectionChecklistId: number;
  /** 点検表名 */
  inspectionChecklistTitle: string;
  /** 点検表の状態 */
  publishStatusId: InspectionPublicStatusType;
  /** 報告書フォーマット名(契約種別で定期業務を選択した場合のみ) */
  formatFileName: string;
  /** 報告書フォーマット(契約種別で定期業務を選択した場合のみ) */
  formatFileUrl: string;
  /** 契約状況ID */
  statusId: ContractStatusTypes; // 1: 契約中、2: 契約終了
  /** 契約状況 */
  statusName: string;
  /** 備考 */
  remarks: string;
};

// 建物新規登録
export type PropertyCreateSubmitData = {
  property_name: string;
  property_code: string;
  owner_company_id: number | null;
  owner: string;
  zip_code: string;
  prefecture_id: number;
  municipalities: string;
  block_address: string;
  building_name: string;
  url: string;
  contact: string;
  completion_date: string;
  scale: string;
  parking: string;
  total_site_area: number | null;
  total_floor_area: number | null;
  design_company_id: number | null;
  construction_company_id: number | null;
  tags: { tag_id: number }[];
  documents: DocumentsType[];
  remarks: string;
  image_list: ImageListType[];
};

// 建物更新登録
export type PropertyUpdateSubmitData = {
  property_name: string;
  property_code: string;
  owner_company_id: number | null;
  owner: string;
  zip_code: string;
  prefecture_id: number;
  municipalities: string;
  block_address: string;
  building_name: string;
  url: string;
  contact: string;
  completion_date: string;
  scale: string;
  parking: string;
  total_site_area: number | null;
  total_floor_area: number | null;
  design_company_id: number | null;
  construction_company_id: number | null;
  tags: { tag_id: number }[];
  documents: DocumentsType[];
  remarks: string;
  image_list: ImageListType[];
};

export type ImageListType = {
  file_name: string;
  file_content: string;
  memo: string;
  is_delete_file?: boolean;
};

export type DocumentsType = {
  document_id?: number | null;
  document_type_id: PropertyDocumentType;
  file_name?: string | null;
  file_content?: string | null;
  archive_id?: number;
  title?: string;
  url?: string;
  is_delete_document?: boolean;
  // ファイルサイズ合計チェック用に追加(ローカルファイル時のみ)
  file_size?: number | null;
};
// -- API Types ------------------------------------------------------------ //

export type APIDisplayParam = {
  readonly total_page: number;
  readonly total: number;
  readonly num_per_page: number;
  readonly current_page: number;
  readonly sort: string;
};

export type APIProperties = {
  /** 建物ID */
  readonly property_id: number;
  /** 建物名 */
  readonly property_name: string;
  /** 郵便番号（ハイフン付き） */
  readonly zip_code: string;
  /** 住所 */
  readonly address: string;
  /** 所有者（オーナー） */
  readonly owner: string;
  /** 連絡先（ハイフン付き） */
  readonly contact: string;
  /** 契約数 */
  readonly contract_num: number;
  /** 異常のある設備数 */
  readonly abnormal_equipment_num: number;
  /** 未完了の工事数 */
  readonly incomplete_construction_num: number;
  /** 未完了の案件数 */
  readonly incomplete_work_num: number;
  /** URL */
  readonly url: string;
  /** 階数(規模) */
  readonly scale: string;
  /** 駐車場 */
  readonly parking: string;
  /** 更新者 */
  readonly update_user_name: string;
  /** 更新日 */
  readonly update_datetime: string;
};

export type APIProperty = {
  /** 建物ID */
  readonly property_id: number;
  /** 建物名 */
  readonly property_name: string;
  /** 郵便番号（ハイフン付き） */
  readonly zip_code: string;
  /** 所有者（オーナー） */
  readonly owner: string;
  /** 連絡先（ハイフン付き） */
  readonly contact: string;
  /** 契約数 */
  readonly contract_num: number;
  /** 異常のある設備数 */
  readonly abnormal_equipment_num: number;
  /** 未完了の工事数 */
  readonly incomplete_construction_num: number;
  /** 未完了の案件数 */
  readonly incomplete_work_num: number;
  /** ビル名 */
  readonly building_name: string;
  /** URL */
  readonly url: string;
  /** 建物コード */
  readonly property_code: string;
  /** 都道府県ID */
  readonly prefecture_id: number;
  /** 都道府県名 */
  readonly prefecture_name: string;
  /** 市区町村 */
  readonly municipalities: string;
  /** 竣工日 */
  readonly completion_date: string | null;
  /** 敷地面積 */
  readonly total_site_area: number | null;
  /** 延床面積 */
  readonly total_floor_area: number | null;
  /** 階数(規模) */
  readonly scale: string;
  /** 備考 */
  readonly remarks: string;
  /** 所有者（企業ID） */
  readonly owner_company_id: number | null;
  /** 町域・番地 */
  readonly block_address: string;
  /** 駐車場 */
  readonly parking: string;
  /** 設計(企業ID) */
  readonly design_company_id: number | null;
  /** 施工(企業ID) */
  readonly construction_company_id: number | null;
  /** タグリスト */
  readonly tags: {
    /** タグID */
    tag_id: number;
    /** タグ名 */
    tag_name: string;
    /** タグ種別 */
    type_id: number; // 1: 個人、2: 共有
    /** タグ種別名 */
    type_name: string;
  }[];
  /** 関連ドキュメントリスト */
  readonly documents: {
    /** 関連ドキュメントID */
    document_id: number;
    /** ドキュメント種別 */
    document_type_id: PropertyDocumentType;
    /** ドキュメントタイトル */
    title: string;
    /** ドキュメントURL */
    url: string;
  }[];
  /** 外観写真リスト */
  readonly image_list: {
    /** ファイルID */
    file_id: number;
    /** ファイル名 */
    file_name: string | null;
    /** ファイルサイズ */
    file_size: number | null;
    /** 登録した画像のURL */
    file_url: string | null;
    /** 登録した画像(サムネイル)のURL */
    thumbnail_file_url: string | null;
    /** 登録した画像のメモ */
    memo: string;
  }[];
  /** 設計(企業名) */
  readonly design_company_name: string;
  /** 施工(企業名) */
  readonly construction_company_name: string;
  /** 更新者 */
  readonly update_user_name: string;
  /** 更新日 */
  readonly update_datetime: string;
  /** ステータス */
  readonly status: PropertyStatus;
};

export type APICreateProperty = {
  readonly zip_code: string;
  readonly owner: string;
  readonly contact: string;
  readonly property_name: string;
  readonly property_code: string;
  readonly prefecture_id: number;
  readonly municipalities: string;
  readonly completion_date: string | null;
  readonly total_site_area: number | null;
  readonly total_floor_area: number | null;
  readonly scale: string;
  readonly remarks: string;
  readonly owner_company_id: number | null;
  readonly block_address?: string;
  readonly building_name: string;
  readonly url?: string;
  readonly parking?: string;
  readonly design_company_id: number | null;
  readonly construction_company_id: number | null;
  readonly tags: {
    tag_id: number;
  }[];
  readonly documents: {
    document_type_id: PropertyDocumentType;
    file_name?: string | null;
    file_content?: string | null;
    archive_id?: number;
    title?: string;
    url?: string;
    is_delete_file?: boolean;
  }[];
  readonly image_list: {
    file_name: string;
    file_content: string;
    memo: string;
    is_delete_file?: boolean;
  }[];
};

export type APIUpdateProperty = {
  readonly property_name: string;
  readonly property_code: string;
  readonly owner_company_id: number | null;
  readonly zip_code: string;
  readonly prefecture_id: number;
  readonly municipalities: string;
  readonly block_address: string;
  readonly building_name: string;
  readonly url: string;
  readonly contact: string;
  readonly completion_date: string;
  readonly scale: string;
  readonly parking: string;
  readonly total_site_area: number | null;
  readonly total_floor_area: number | null;
  readonly design_company_id: number | null;
  readonly construction_company_id: number | null;
  readonly tags: { tag_id: number }[];
  readonly documents: DocumentsType[];
  readonly remarks: string;
  readonly image_list: ImageListType[];
};

export type APIFloors = {
  readonly floor_id: number;
  readonly floor_name: string;
  readonly description: string;
  readonly room_num: number;
};

export type APICreateFloor = {
  readonly floor_name: string;
  readonly description: string;
};
export type APIUpdateFloor = APICreateFloor;

export type APIRooms = {
  readonly room_id: number;
  readonly room_name: string;
  readonly description: string;
};

export type APICreateRoom = {
  readonly room_name: string;
  readonly description: string;
};
export type APIUpdateRoom = APICreateRoom;

export type APIMembers = {
  readonly group_user_id: number;
  readonly group_user_name: string;
  readonly group_user_type: number;
  readonly property_role_id: number;
  readonly property_role_name: string;
};

export type APIAddMembers = {
  readonly group_user_id: number;
  readonly property_role_id: number;
};

export type APISelectMembers = {
  readonly group_user_id: number;
  readonly group_user_name: string;
  readonly group_user_type: number;
};

export type APITenants = {
  readonly contact_id: number;
  readonly contact_name: string;
  readonly tel: string;
  readonly email: string;
  readonly remarks: string;
};

export type APICreateTenant = {
  readonly contact_name: string;
  readonly tel: string;
  readonly email: string;
  readonly remarks: string;
};
export type APIUpdateTenant = APICreateTenant;

export type APIAddress = {
  readonly address1: string;
  readonly address2: string;
  readonly address3: string;
  readonly prefcode: string;
  readonly zipcode: string;
};

export type APIInformation = {
  /** 建物ID */
  readonly property_id: number;
  /** 建物名 */
  readonly property_name: string;
  /** 連絡事項のコメント */
  readonly comment: string;
  /** 建物の連絡事項のファイルリスト */
  readonly file_list: InformationFile[];
};

export type APIContracts = {
  /** 建物ID */
  readonly property_id: number;
  /** 建物名 */
  readonly property_name: string;
  /** 契約ID */
  readonly contract_id: number;
  /** 計画名 */
  readonly title: string;
  /** 契約種別ID */
  readonly contract_type_id: ContractTypes; //  1: 定期業務、2: 工事
  /** 契約種別 */
  readonly contract_type_name: string;
  /** 定期業務種別ID */
  readonly inspection_type_id: InspectionTypes; // 契約種別で定期業務を選択した場合のみ 1: 定期、2: 法定、3: 日常、4: その他
  /** 定期業務種別 */
  readonly inspection_type_name: string;
  /** 定期業務区分ID */
  readonly inspection_division_id: InspectionDivisionTypes; // 契約種別で定期業務を選択した場合のみ 1: 設備、2: 清掃、3: 警備
  /** 定期業務区分 */
  readonly inspection_division_name: string;
  /** 周期ID */
  readonly repeat_type_id: PropertyContractRepeatType; // 1: 毎日、2: 毎週平日、3: 毎週土日、4: 毎月1日、5: 毎月晦日、6: カスタム設定 （NULLで繰り返しなし）
  /** 周期 */
  readonly repeat_type_name: string;
  /** 開始日 */
  readonly start_date: string;
  /** 終了日 */
  readonly end_date: string;
  /** 協力会社ID */
  readonly company_id: number | null;
  /** 協力会社名 */
  readonly company_name: string;
  /** 請求単価 */
  readonly invoice_unit_price: number;
  /** 支払単価 */
  readonly purchases_unit_price: number;
  /** 契約状況ID */
  readonly status_id: ContractStatusTypes; // 1: 契約中、2: 契約終了
  /** 契約状況 */
  readonly status_name: string;
  /** 点検表ID(契約種別で定期業務を選択した場合のみ) */
  readonly inspection_checklist_id: number;
  /** 報告書フォーマット(契約種別で定期業務を選択した場合のみ) */
  readonly format_file_url: string;
};

export type APIContract = {
  /** 建物ID */
  readonly property_id: number;
  /** 建物名 */
  readonly property_name: string;
  /** 契約ID */
  readonly contract_id: number;
  /** 計画名 */
  readonly title: string;
  /** 契約更新月 */
  readonly update_month: number; // 1~12
  /** 更新月通知設定 */
  readonly has_notify_prior: boolean;
  /** 契約更新月のnヶ月前に通知する */
  readonly prior_notification_months: number; // 1~12
  /** 契約種別ID */
  readonly contract_type_id: ContractTypes; //  1: 定期業務、2: 工事
  /** 契約種別 */
  readonly contract_type_name: string;
  /** 定期業務種別ID */
  readonly inspection_type_id: InspectionTypes; // 契約種別で定期業務を選択した場合のみ 1: 定期、2: 法定、3: 日常、4: その他
  /** 定期業務種別 */
  readonly inspection_type_name: string;
  /** 定期業務区分ID */
  readonly inspection_division_id: InspectionDivisionTypes; // 契約種別で定期業務を選択した場合のみ 1: 設備、2: 清掃、3: 警備、4: 検針、5: 建築、6: その他
  /** 定期業務区分 */
  readonly inspection_division_name: string;
  /** 繰り返す */
  readonly is_repeat: boolean;
  /** 周期ID */
  readonly repeat_type_id: PropertyContractRepeatType; // 1: 毎日、2: 毎週平日、3: 毎週土日、4: 毎月1日、5: 毎月晦日、6: カスタム設定 （NULLで繰り返しなし）
  /** 周期 */
  readonly repeat_type_name: string;
  /** カスタム繰り返し設定 */
  readonly custom_cycles: PropertyContractRepeatCustomCycleInterface;
  /** 開始日 */
  readonly start_date: string;
  /** 終了日設定 */
  readonly end_type_id: PropertyContractEndType; // 1: なし、2: 終了日 = yyyy年mm月dd日、3: 繰り返し = n回
  /** 終了日 */
  readonly end_date: string;
  /** 繰り返し回数 */
  readonly repeat_count: number; // 1~
  /** 協力会社ID */
  readonly company_id: number | null;
  /** 協力会社名 */
  readonly company_name: string;
  /** 請求単価 */
  readonly invoice_unit_price: number;
  /** 支払単価 */
  readonly purchases_unit_price: number;
  /** 点検表ID(契約種別で定期業務を選択した場合のみ) */
  readonly inspection_checklist_id: number;
  /** 点検表名 */
  readonly inspection_checklist_title: string;
  /** 点検表の状態 */
  readonly publish_status_id: InspectionPublicStatusType;
  /** 報告書フォーマット名(契約種別で定期業務を選択した場合のみ) */
  readonly format_file_name: string;
  /** 報告書フォーマット(契約種別で定期業務を選択した場合のみ) */
  readonly format_file_url: string;
  /** 契約状況ID */
  readonly status_id: ContractStatusTypes; // 1: 契約中、2: 契約終了
  /** 契約状況 */
  readonly status_name: string;
  /** 備考 */
  readonly remarks: string;
};

export type APIPostCreateContract = {
  /** 計画名 */
  title: string;
  /** 契約更新月 */
  update_month?: number; // 1~12
  /** 契約更新月のnヶ月前に通知する (契約更新月のnヶ月前に通知する・NULLの場合、設定なし(通知しない)) */
  prior_notification_months: number | null; // 1~12
  /** 契約種別ID */
  contract_type_id: ContractTypes; //  1: 定期業務、2: 工事
  /** 定期業務種別ID */
  inspection_type_id?: InspectionTypes; // 契約種別で定期業務を選択した場合のみ 1: 定期、2: 法定、3: 日常、4: その他
  /** 定期業務区分ID */
  inspection_division_id?: InspectionDivisionTypes; // 契約種別で定期業務を選択した場合のみ 1: 設備、2: 清掃、3: 警備、4: 検針、5: 建築、6: その他
  /** 周期ID */
  repeat_type_id: PropertyContractRepeatType; // 1: 毎日、2: 毎週平日、3: 毎週土日、4: 毎月1日、5: 毎月晦日、6: カスタム設定 （NULLで繰り返しなし）
  /** カスタム繰り返し設定 */
  custom_cycles?: PropertyContractRepeatCustomCycleInterface;
  /** 開始日 */
  start_date: string;
  /** 終了日設定 */
  end_type_id: PropertyContractEndType; // 1: なし、2: 終了日 = yyyy年mm月dd日、3: 繰り返し = n回
  /** 終了日(設定しない場合はNULL) */
  end_date: string | null;
  /** 繰り返し回数(設定しない場合はNULL) */
  repeat_count: number | null; // 1~
  /** 協力会社ID */
  company_id: number | null;
  /** 請求単価 */
  invoice_unit_price: number;
  /** 支払単価 */
  purchases_unit_price: number;
  /** 点検表ID(契約種別で定期業務を選択した場合のみ) */
  inspection_checklist_id?: number;
  /** 点検表を新規作成する */
  should_create_inspection_checklist: boolean;
  /** 点検表 */
  inspection_checklist?: {
    /** 点検表名 */
    title: string; // ~100
    /** 点検表の説明 */
    description: string; // ~1000
    /** 点検種別 */
    inspection_type: InspectionTypes; // 1: 定期、2: 法定、3: 日常、4: その他
  };
  /** 契約状況ID */
  status_id: ContractStatusTypes; // 1: 契約中、2: 契約終了
  /** 備考 */
  remarks: string;
};

export type APIPutEditContract = {
  /** 計画名 */
  title: string;
  /** 契約更新月 */
  update_month?: number; // 1~12
  /** 契約更新月のnヶ月前に通知する (契約更新月のnヶ月前に通知する・NULLの場合、設定なし(通知しない)) */
  prior_notification_months: number | null; // 1~12
  /** 定期業務種別ID */
  inspection_type_id?: InspectionTypes; // 契約種別で定期業務を選択した場合のみ 1: 定期、2: 法定、3: 日常、4: その他
  /** 定期業務区分ID */
  inspection_division_id?: InspectionDivisionTypes; // 契約種別で定期業務を選択した場合のみ 1: 設備、2: 清掃、3: 警備、4: 検針、5: 建築、6: その他
  /** 周期ID */
  repeat_type_id: PropertyContractRepeatType; // 1: 毎日、2: 毎週平日、3: 毎週土日、4: 毎月1日、5: 毎月晦日、6: カスタム設定 （NULLで繰り返しなし）
  /** カスタム繰り返し設定 */
  custom_cycles?: PropertyContractRepeatCustomCycleInterface;
  /** 開始日 */
  start_date: string;
  /** 終了日設定 */
  end_type_id: PropertyContractEndType; // 1: なし、2: 終了日 = yyyy年mm月dd日、3: 繰り返し = n回
  /** 終了日(設定しない場合はNULL) */
  end_date: string | null;
  /** 繰り返し回数(設定しない場合はNULL) */
  repeat_count: number | null; // 1~
  /** 協力会社ID */
  company_id: number | null;
  /** 請求単価 */
  invoice_unit_price: number;
  /** 支払単価 */
  purchases_unit_price: number;
  /** 点検表ID(契約種別で定期業務を選択した場合のみ) */
  inspection_checklist_id?: number;
  /** 点検表を新規作成する */
  should_create_inspection_checklist: boolean;
  /** 点検表 */
  inspection_checklist?: {
    /** 点検表名 */
    title: string; // ~100
    /** 点検表の説明 */
    description: string; // ~1000
    /** 点検種別 */
    inspection_type: InspectionTypes; // 1: 定期、2: 法定、3: 日常、4: その他
  };
  /** 契約状況ID */
  status_id: ContractStatusTypes; // 1: 契約中、2: 契約終了
  /** 備考 */
  remarks: string;
};

/**
 * 建物一覧検索・一括ダウンロードの共通パラメータ
 */
export interface APIGetPropertiesRequestParamsWithoutPage {
  /** 検索キーワード。「建物名、所有者、都道府県」を対象に部分一致検索。 */
  keyword: string;
  /** 1：建物名の昇順、2：契約数の降順、3：未完了の工事数の降順、4：未完了の案件数の降順、5：住所の昇順。省略時は「1」。 */
  sort: string;
  /** 検索キーワード。「建物名」を対象に部分一致検索。 */
  name?: string;
  /** 検索キーワード。「所有者」を対象に部分一致検索。 */
  owner?: string;
  /** 検索キーワード。「都道府県」を対象に部分一致検索。 */
  prefectures?: string;
  /** タグ検索 IDをカンマ区切りで送信することで複数対応化(AND検索)する */
  tag?: string;
  /** 検索開始日(yyyy-mm-dd) 竣工日の期間を絞り込む */
  since?: string;
  /** 検索終了日(yyyy-mm-dd) 竣工日の期間を絞り込む */
  until?: string;
  /** 敷地面積の絞り込み(下限) */
  total_site_area_min?: number;
  /** 敷地面積の絞り込み(上限) */
  total_site_area_max?: number;
  /** 延床面積の絞り込み(下限) */
  total_floor_area_min?: number;
  /** 延床面積の絞り込み(上限) */
  total_floor_area_max?: number;
  /** 建物内設備に異常がある */
  has_abnormal_equipments?: boolean;
  /** 建物内に未完了の工事がある */
  has_incomplete_constructions?: boolean;
  /** 建物内に未完了の案件がある */
  has_incomplete_works?: boolean;
}

/**
 * 一覧検索での追加パラメータ
 */
export interface APIGetPropertiesRequestParamsPage {
  page?: number;
  /** 1ページ当たりのデータ数。省略時は「15」。最大値「100」。「0」以下は「15」扱い。「101」以上は「100」扱い。 */
  size_per_page?: number;
}

/**
 * 一覧ダウンロードでの追加パラメータ
 */
export interface APIGetPropertiesRequestParamsIsAll {
  /** 取得対象のID群を","(カンマ)区切りで返す */
  id: string;
  /** 全件取得フラグ */
  is_all: boolean;
}

/**
 * 建物一覧検索時のapiパラメータ
 */
export type APIGetPropertiesRequestParams = APIGetPropertiesRequestParamsWithoutPage &
  APIGetPropertiesRequestParamsPage;

/**
 * 建物一覧の一括ダウンロード処理のパラメータ
 */
export type APIDownloadPropertiesFormatRequestParams = APIGetPropertiesRequestParamsWithoutPage &
  APIGetPropertiesRequestParamsIsAll;

/**
 * 建物検索のための詳細検索条件のlocationのstateタイプ
 */
export interface PropertiesAdvancedQuery {
  /** 検索キーワード。「建物名」を対象に部分一致検索。 */
  name?: string;
  /** 1：建物名の昇順、2：契約数の降順、3：未完了の工事数の降順、4：未完了の案件数の降順、5：住所の昇順。省略時は「1」。 */
  sort?: string;
  /** 検索キーワード。「所有者」を対象に部分一致検索。 */
  owner?: string;
  /** 検索キーワード。「都道府県」を対象に部分一致検索。 */
  prefectures?: string;
  /** タグ検索 IDをカンマ区切りで送信することで複数対応化(AND検索)する */
  tag?: string;
  /** 検索開始日(yyyy-mm-dd) 竣工日の期間を絞り込む */
  since?: string;
  /** 検索終了日(yyyy-mm-dd) 竣工日の期間を絞り込む */
  until?: string;
  /** 敷地面積の絞り込み(下限) */
  totalSiteAreaMin?: number;
  /** 敷地面積の絞り込み(上限) */
  totalSiteAreaMax?: number;
  /** 延床面積の絞り込み(下限) */
  totalFloorAreaMin?: number;
  /** 延床面積の絞り込み(上限) */
  totalFloorAreaMax?: number;
  /** 建物内設備に異常がある */
  hasAbnormalEquipments?: boolean;
  /** 建物内に未完了の工事がある */
  hasIncompleteConstructions?: boolean;
  /** 建物内に未完了の案件がある */
  hasIncompleteWorks?: boolean;
}

export interface SearchCommonOption {
  order?: boolean;
  pageH?: number;
}

/**
 * 建物一覧検索時に使われるlocationのstate
 */
export type PropertiesListQuery = PropertiesAdvancedQuery &
  APIGetPropertiesRequestParamsPage &
  SearchCommonOption;

/**
 * 建物一覧画面のlocationのstate
 */
export type PropertiesListState = PropertiesListQuery &
  PropertiesAdvancedQuery &
  SearchCommonOption;

export type APIGetPropertiesResponse = {
  result: {
    property_list: APIProperties[];
    display_params: APIDisplayParam;
  };
};
export type APIGetPropertyResponse = {
  result: APIProperty;
};
export type APICreatePropertyRequest = APICreateProperty;
export type APICreatePropertyResponse = {
  result: APIProperty;
};
export type APIUpdatePropertyRequest = APIUpdateProperty;
export type APIUpdatePropertyResponse = {
  result: APIProperty;
};
export type APIDeletePropertyRequest = {
  property_list: { property_id: number }[];
};

export type APIGetFloorsResponse = {
  result: APIFloors[];
};
export type APICreateFloorRequest = APICreateFloor;
export type APIUpdateFloorRequest = APIUpdateFloor;
export type APIUpdateFloorsOrderRequest = {
  floor_list: { floor_id: number }[];
};
export type APIDeleteFloorsRequest = {
  floor_list: { floor_id: number }[];
};

export type APIGetRoomsResponse = {
  result: { room_list: APIRooms[] };
};
export type APICreateRoomRequest = APICreateRoom;
export type APIUpdateRoomRequest = APIUpdateRoom;
export type APIDeleteRoomsRequest = {
  room_list: { room_id: number }[];
};

export type APIGetMembersRequestParams = {
  page: number;
  size_per_page?: number;
};
export type APIGetMembersResponse = {
  result: {
    group_user_list: APIMembers[];
    display_params: APIDisplayParam;
  };
};
export type APIAddMembersRequest = {
  group_user_list: APIAddMembers[];
};
export type APIDeleteMembersRequest = {
  group_user_list: { group_user_id: number }[];
};
export type APIGetSelectMembersRequestParams = {
  keyword: string;
  page?: number;
  size_per_page?: number;
};
export type APIGetSelectMembersResponse = {
  result: {
    group_user_list: APISelectMembers[];
    display_params: APIDisplayParam;
  };
};

export type APIGetTenantsRequestParams = APIGetMembersRequestParams;
export type APIGetTenantsResponse = {
  result: {
    contact_list: APITenants[];
    display_params: APIDisplayParam;
  };
};
export type APICreateTenantRequest = APICreateTenant;
export type APIUpdateTenantRequest = APIUpdateTenant;
export type APIDeleteTenantsRequest = {
  property_contact_list: { contact_id: number }[];
};

export type APIGetAddressResponse = {
  messages: string;
  status: number;
  results: APIAddress[];
};

export type APIGetInformationResponse = {
  messages: string;
  result: APIInformation;
};

/**
 * 契約種別ID
 * @description 1: 定期業務、2: 工事
 */
export type ContractTypes = 1 | 2;
/**
 * 定期業務種別ID
 * @description 1: 定期、2: 法定、3: 日常、4: その他
 */
export type InspectionTypes = 1 | 2 | 3 | 4;

/**
 * 定期業務区分ID
 * @description 1: 設備、2: 清掃、3: 警備、4: 検針、5: 建築、6: その他
 */
export type InspectionDivisionTypes = 1 | 2 | 3 | 4 | 5 | 6;
/**
 * 契約状況ID
 * @description 1: 契約中、2: 契約終了
 */
export type ContractStatusTypes = 1 | 2;
/**
 * 契約に定期業務内容の登録時の対象属性
 * @description 1: 定期業務種別、2: 定期業務区分
 */
export type PropertyContractAttributeType = 1 | 2;
/**
 * 契約に単価内容の登録時の単価種別
 * @description 1: 請求単価、2: 支払単価
 */
export type PropertyContractPriceType = 1 | 2;
/**
 * 契約に繰り返し設定の登録時の周期ID
 * @description 1: 毎日、2: 毎週 平日(月～金)、3: 毎週 週末(土～日)、4: 毎月 1日、5: 毎月 最終日、6: カスタム設定 （NULLで繰り返しなし）
 */
export type PropertyContractRepeatType = 1 | 2 | 3 | 4 | 5 | 6 | null;

/**
 * 繰り返し種別
 * @description 1: 日、2: 週、3: 月、4: 年
 */
export type RepeatCustomCycleType = 1 | 2 | 3 | 4;

/**
 * 終了種別
 * @description 1: なし、2: 終了日 = yyyy年mm月dd日、3: 繰り返し = n回
 */
export type PropertyContractEndType = 1 | 2 | 3;

/**
 * 点検表の状態
 * @description 1: 下書き、2: 発行済み
 */
export type InspectionPublicStatusType = 1 | 2 | null;

/**
 * 契約のカスタム繰り返し設定
 */
export interface PropertyContractRepeatCustomCycleInterface {
  /** 繰り返し種別 */
  cycle_type_id?: RepeatCustomCycleType;
  /** n日毎、n週毎、nヶ月毎、n年毎 */
  cycle_time?: number;
  /** 日 */
  is_enable_sunday?: boolean;
  /** 月 */
  is_enable_monday?: boolean;
  /** 火 */
  is_enable_tuesday?: boolean;
  /** 水 */
  is_enable_wednesday?: boolean;
  /** 木 */
  is_enable_thursday?: boolean;
  /** 金 */
  is_enable_friday?: boolean;
  /** 土 */
  is_enable_saturday?: boolean;
  /** 「3: 月」を選んだ場合に有効、n日を表す。 */
  value?: number;
}

/**
 * 契約に繰り返し設定の登録時のデータ
 */
export type PropertyContractRepeatParamCustomCycleType = PropertyContractRepeatCustomCycleInterface;

/**
 * 契約検索のための詳細検索条件のlocationのstateの値インターフェース
 */
export interface PropertyContractsAdvancedQuery {
  /** 検索キーワード。 */
  keyword: string;
  /** 1: 計画名、2: 契約種別、3: 定期業務種別、4: 開始日。省略時は「1」 */
  sort?: string;

  /** 検索キーワード。「計画名」を対象に部分一致検索。 */
  title?: string;
  /** 契約種別ID 1: 定期業務、2: 工事 */
  contractType?: ContractTypes;
  /** 定期業務種別ID 1: 定期、2: 法定、3: 日常 */
  inspectionType?: InspectionTypes;
  /** 定期業務区分ID 1: 設備、2: 清掃、3: 警備 */
  inspectionDivision?: InspectionDivisionTypes;

  /** 開始日（start_date）の検索開始日 契約の期間を絞り込む*/
  startDateSince?: string;
  /** 開始日（start_date）の検索終了日 契約の期間を絞り込む*/
  startDateUntil?: string;
  /** 終了日（end_date）の検索開始日 契約の期間を絞り込む */
  endDateSince?: string;
  /** 終了日（end_date）の検索終了日 契約の期間を絞り込む */
  endDateUntil?: string;

  /** 協力会社名 */
  companyName?: string;
  /** 契約状況ID 1: 契約中、2: 契約終了 */
  status?: ContractStatusTypes;
}

/**
 * 契約検索のための詳細検索条件type
 */
export type PropertyContractsStateAdvancedQueryType = PropertyContractsAdvancedQuery &
  SearchCommonOption &
  APIGetPropertiesRequestParamsPage;

/**
 * 契約検索のための詳細検索条件のlocationのstate内でのインターフェース
 * @description 建物詳細画面のタブ画面が多いので混乱を避けるためcontractsListQueryを指定。
 */
export interface PropertyContractsAdvancedQueryInState {
  contractsListQuery?: PropertyContractsStateAdvancedQueryType;
}

/**
 * 契約一覧検索・一括ダウンロードの共通パラメータ
 */
export interface APIGetPropertyContractsRequestParamsWithoutPage {
  /** 検索キーワード。 */
  keyword: string;
  /** 1: 計画名、2: 契約種別、3: 定期業務種別、4: 開始日。省略時は「1」。 */
  sort: string;

  /** 検索キーワード。「計画名」を対象に部分一致検索。 */
  title?: string;
  /** 契約種別ID 1: 定期業務、2: 工事 */
  contract_type?: ContractTypes;
  /** 定期業務種別ID 1: 定期、2: 法定、3: 日常、4: その他 */
  inspection_type?: InspectionTypes;
  /** 定期業務区分ID 1: 設備、2: 清掃、3: 警備、4: 検針、5: 建築、6: その他 */
  inspection_division?: InspectionDivisionTypes;

  /** 開始日（start_date）の検索開始日 契約の期間を絞り込む*/
  start_date_since?: string;
  /** 開始日（start_date）の検索終了日 契約の期間を絞り込む*/
  start_date_until?: string;
  /** 終了日（end_date）の検索開始日 契約の期間を絞り込む */
  end_date_since?: string;
  /** 終了日（end_date）の検索終了日 契約の期間を絞り込む */
  end_date_until?: string;

  /** 協力会社名 */
  company_name?: string;
  /** 契約状況ID 1: 契約中、2: 契約終了 */
  status?: ContractStatusTypes;
}

export type ContractInfoMode = 'LIST' | 'DETAIL';
export interface PropertyContractsInfoModeState {
  contractInfoMode: ContractInfoMode;
}

export type APIGetPropertyGuestRequest = { token: string };

/**
 * 建物一覧の一括ダウンロード処理のパラメータ
 */
export type APIDownloadPropertyContractsRequestParams =
  APIGetPropertyContractsRequestParamsWithoutPage & APIGetPropertiesRequestParamsIsAll;

/**
 * 契約一覧画面のlocationのstate
 */
export type PropertyContractsListState = PropertyContractsAdvancedQueryInState &
  PropertyContractsInfoModeState;

/**
 * 契約一覧検索時のapiパラメータ
 */
export type APIGetPropertyContractsRequestParams = APIGetPropertyContractsRequestParamsWithoutPage &
  APIGetPropertiesRequestParamsPage;

export type APIGetContractsResponse = {
  messages: string;
  result: {
    contract_list: APIContracts[];
    display_params: APIDisplayParam;
  };
};

export type APIDeletePropertyContractsRequest = {
  contract_list: { contract_id: number }[];
};

export type APIGetContractResponse = {
  messages: string;
  result: APIContract;
};
