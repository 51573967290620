import {
  NotificationAction as Action,
  NotificationActionTypes as ActionTypes,
  NotificationsType,
} from './types';

export const init = (): Action => ({ type: ActionTypes.INIT });

export const refresh = (): Action => ({ type: ActionTypes.REFRESH });

export const setIsLoading = (isLoading: boolean): Action => ({
  type: ActionTypes.LOADING,
  payload: isLoading,
});

export const setIsSubmitting = (isSubmitting: boolean): Action => ({
  type: ActionTypes.SUBMITTING,
  payload: isSubmitting,
});

export const setNotifications = (notifications: NotificationsType): Action => ({
  type: ActionTypes.SET_NOTIFICATIONS,
  payload: notifications,
});
