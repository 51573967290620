import axios from '@providers/axiosProvider';
import * as actions from './actions';
import _ from 'lodash';

import { APICreateInspectionChecklistRequest, APICreateInspectionChecklistResponse } from './types';

import {
  APIGetInspectionChecklistResponse,
  InspectionChecklist,
} from '@ducks/inspectionChecklist/types';

import { Checklist } from '@pages/inspection/checklist/edit/hooks';

export const getInspectionChecklistAsync =
  ({ inspectionChecklistId }: { inspectionChecklistId: number }) =>
  async (dispatch: any) => {
    dispatch(actions.setIsLoading(true));

    const params = { is_edit: true };

    await axios
      .get<APIGetInspectionChecklistResponse>(`/inspections/layout/${inspectionChecklistId}`, {
        params,
      })
      .then((response) => {
        const checklist = response.data.result;
        const data: InspectionChecklist = {
          inspectionChecklistId: checklist.inspection_checklist_id,
          title: checklist.title,
          description: checklist.description,
          inspectionTypeName: checklist.inspection_type_name,
          publishStatusName: checklist.publish_status_name,
          inspectionTypeId: checklist.inspection_type,
          publishStatus: checklist.publish_status,
          publishStatusId: checklist.publish_status,
          propertyId: checklist.property_id,
          propertyName: checklist.property_name,
          createUserId: checklist.create_user_id,
          createUserName: checklist.create_user_name,
          lastUpdate: checklist.update_datetime,
          layoutInformation: checklist.layout_information,
          inspectionCount: checklist.inspection_count,
          hasFormat: checklist.has_format,
          updateDatetime: checklist.update_datetime,
        };
        dispatch(actions.setChecklist(data));
        dispatch(actions.setLayout(data.layoutInformation ?? { groups: [] }));

        if (checklist.publish_status === 2) {
          const layout = { ...(data.layoutInformation ?? { groups: [] }) };
          const ids = _.flattenDeep(
            layout.groups.map((g) => g.sections.map((s) => s.components.map((c) => c.id))),
          );
          dispatch(actions.setPublishedLayoutComponentIds(ids));
        }
      })
      .catch(() => {
        dispatch(actions.setChecklist(null));
      });

    dispatch(actions.setIsLoading(false));
  };

export const createInspectionChecklistAsync =
  ({ propertyId, checklist, layout }: { propertyId: number; checklist: Checklist; layout: any }) =>
  async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const requestData: APICreateInspectionChecklistRequest = {
      title: checklist.title,
      description: checklist.description,
      inspection_type: checklist.inspectionTypeId,
      layout_information: layout,
    };

    await axios
      .post<APICreateInspectionChecklistResponse>(
        `/inspections/layout?property_id=${propertyId}`,
        requestData,
      )
      .then((response) => {
        const inspectionChecklistId = response.data.result.inspection_checklist_id;
        dispatch(actions.setIsNavigating2(true));
        dispatch(actions.setIsNavigatingTo(inspectionChecklistId));
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const updateInspectionChecklistAsync =
  ({
    inspectionChecklistId,
    checklist,
    layout,
  }: {
    inspectionChecklistId: number;
    checklist: Checklist;
    layout: any;
  }) =>
  async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const requestData: APICreateInspectionChecklistRequest = {
      title: checklist.title,
      description: checklist.description,
      inspection_type: checklist.inspectionTypeId,
      layout_information: layout,
    };

    await axios
      .put<APICreateInspectionChecklistResponse>(
        `/inspections/layout/${inspectionChecklistId}`,
        requestData,
      )
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const publishInspectionChecklistAsync =
  ({
    inspectionChecklistId,
    checklist,
    layout,
  }: {
    inspectionChecklistId: number;
    checklist: Checklist;
    layout: any;
  }) =>
  async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const requestData: APICreateInspectionChecklistRequest = {
      title: checklist.title,
      description: checklist.description,
      inspection_type: checklist.inspectionTypeId,
      layout_information: layout,
    };

    await axios
      .put<APICreateInspectionChecklistResponse>(
        `/inspections/layout/${inspectionChecklistId}`,
        requestData,
      )
      .then(async () => {
        await axios.patch(`/inspections/layout/${inspectionChecklistId}/publish`).then(() => {
          dispatch(actions.setIsNavigating(true));
        });
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };
