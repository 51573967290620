import React from 'react';
import { render } from 'react-dom';
import ConfirmDialog from '@uiParts/confirmDialog';

export const confirmDialog = async (message: string) => {
  return new Promise<boolean>((resolve) => {
    const mountTarget = document.createElement('div');
    document.body.append(mountTarget);

    render(
      <ConfirmDialog
        message={message}
        handleCancel={() => {
          resolve(false);
          mountTarget.remove();
        }}
        handleOK={() => {
          resolve(true);
          mountTarget.remove();
        }}
      />,
      mountTarget,
    );
  });
};
