import {
  AffiliateAction as Action,
  AffiliateActionTypes as ActionTypes,
  SearchParameter,
  DisplayParams,
  User,
  UserDetail,
  Address,
  Company,
  CompanyDetail,
} from './types';

/**
 * @summary 初期化処理
 * @returns {Action}
 */
export const init = (): Action => ({
  type: ActionTypes.INIT,
});

/**
 * @summary リフレッシュ処理
 * @returns {Action}
 */
export const refresh = (): Action => ({
  type: ActionTypes.REFRESH,
});

/**
 * @summary Loading状態を保持
 * @param {boolean} isLoading
 * @returns {Action}
 */
export const setIsLoading = (isLoading: boolean): Action => ({
  type: ActionTypes.LOADING,
  payload: isLoading,
});

/**
 * @summary 送信処理中かを保持
 * @param {boolean} isLoading
 * @returns {Action}
 */
export const setIsSubmitting = (isSubmitting: boolean): Action => ({
  type: ActionTypes.SUBMITTING,
  payload: isSubmitting,
});

/**
 * @summary ページ遷移を行うかを保持
 * @param {boolean} isNavigating
 * @returns {Action}
 */
export const setIsNavigating = (isNavigating: boolean): Action => ({
  type: ActionTypes.NAVIGATING,
  payload: isNavigating,
});

/**
 * @summary 遷移先のURLを保持
 * @param {string} navigatingTo
 * @returns {Action}
 */
export const setNavigatingTo = (navigatingTo: string): Action => ({
  type: ActionTypes.SET_NAVIGATING_TO,
  payload: navigatingTo,
});

/**
 * @summary ページ番号を保持
 * @param {number} page
 * @returns {Action}
 */
export const setPage = (page: number): Action => ({
  type: ActionTypes.SET_PAGE,
  payload: page,
});

/**
 * @summary ソート番号を保持
 * @param {number} sort
 * @returns {Action}
 */
export const setSort = (sort: number): Action => ({
  type: ActionTypes.SET_SORT,
  payload: sort,
});

/**
 * @summary 詳細検索条件を保持
 * @param {SearchParameter} searchParameter
 * @returns {Action}
 */
export const setSearchParameter = (searchParameter: SearchParameter): Action => ({
  type: ActionTypes.SET_SEARCH_PARAMETER,
  payload: searchParameter,
});

/**
 * @summary 検索タグIDを保持
 * @param {number[]} tagIdList
 * @returns {Action}
 */
export const setTagIdList = (tagIdList: number[]): Action => ({
  type: ActionTypes.SET_SEARCH_TAG_ID_LIST,
  payload: tagIdList,
});

/**
 * @summary 連絡先一覧で表示するユーザリストを保持
 * @param {User[]} userList
 * @returns {Action}
 */
export const setUserList = (userList: User[]): Action => ({
  type: ActionTypes.SET_USER_LIST,
  payload: userList,
});

/**
 * @summary 連絡先詳細で表示するユーザを保持
 * @param {UserDetail} userDetail
 * @returns {Action}
 */
export const setUserDetail = (userDetail: UserDetail | null): Action => ({
  type: ActionTypes.SET_USER,
  payload: userDetail,
});

/**
 * @summary 連絡先一覧で表示する企業リストを保持
 * @param {Company[]} companyList
 * @returns {Action}
 */
export const setCompanyList = (companyList: Company[]): Action => ({
  type: ActionTypes.SET_COMPANY_LIST,
  payload: companyList,
});

/**
 * @summary 連絡先詳細で表示する企業を保持
 * @param {CompanyDetail} companyDetail
 * @returns {Action}
 */
export const setCompanyDetail = (companyDetail: CompanyDetail | null): Action => ({
  type: ActionTypes.SET_COMPANY,
  payload: companyDetail,
});

/**
 * @summary Pager情報を保持
 * @param {DisplayParams} displayParams
 * @returns {Action}
 */
export const setDisplayParams = (displayParams: DisplayParams): Action => ({
  type: ActionTypes.SET_DISPLAY_PARAMS,
  payload: displayParams,
});

/**
 * @summary 自動補完に使用する住所を保持
 * @param {Address|null} address
 * @returns {Action}
 */
export const setAddressAutofill = (address: Address | null): Action => ({
  type: ActionTypes.SET_ADDRESS_AUTOFILL,
  payload: address,
});
