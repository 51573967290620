import {
  SelectMultipleAffiliateAction as Action,
  SelectMultipleAffiliateActionTypes as ActionTypes,
  TabType,
  SelectItem,
  User,
  Company,
} from './types';

/**
 * @summary 初期化処理
 */
export const init = (): Action => ({
  type: ActionTypes.INIT,
});

/**
 * @summary Loading状態を保持
 * @param {boolean} isLoading
 */
export const setIsLoading = (isLoading: boolean): Action => ({
  type: ActionTypes.LOADING,
  payload: isLoading,
});

/**
 * @summary APIから取得したユーザを保持
 * @param {User[]} userList
 */
export const setAffiliateUserList = (userList: User[]): Action => ({
  type: ActionTypes.SET_USER_LIST,
  payload: userList,
});

/**
 * @summary APIから取得した企業を保持
 * @param {Company[]} companyList
 */
export const setAffiliateCompanyList = (companyList: Company[]): Action => ({
  type: ActionTypes.SET_COMPANY_LIST,
  payload: companyList,
});

/**
 * @summary APIから取得した建物担当者を保持
 * @param {User[]} systemUserList
 */
export const setAffiliateSystemUserList = (systemUserList: User[]): Action => ({
  type: ActionTypes.SET_SYSTEM_USER_LIST,
  payload: systemUserList,
});

/**
 * @summary タブの切り替え
 * @param {TabType} tab
 */
export const setTab = (tab: TabType): Action => ({
  type: ActionTypes.SET_TAB,
  payload: tab,
});

/**
 * @summary 選択した状態の連絡先リストを保持
 * @param {SelectItem[]} selectedItemList
 */
export const setSelectItemList = (selectedItemList: SelectItem[]): Action => ({
  type: ActionTypes.SET_SELECTED_ITEM_LIST,
  payload: selectedItemList,
});
