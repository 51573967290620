import { AffiliateState, AffiliateAction, AffiliateActionTypes as ActionTypes } from './types';

/**
 * @summary 連絡先一覧画面で管理されるStateの初期値
 */
const initialAffiliateState: AffiliateState = {
  refresh: {},
  /** Load中か */
  isLoading: false,
  /** 送信処理中か */
  isSubmitting: false,
  /** ページ遷移を行うか */
  isNavigating: false,
  /** 遷移先のurl */
  navigatingTo: null,
  /** ページ番号 */
  page: 1,
  /** ソート番号 */
  sort: 1,
  /** 詳細検索条件 */
  searchParameter: {},
  /** 検索タグID */
  tagIdList: [],
  /** 連絡先ユーザリスト */
  userList: [],
  /** 連絡先ユーザ */
  userDetail: null,
  /** 連絡先企業リスト */
  companyList: [],
  /** 連絡先企業 */
  companyDetail: null,
  /** Pager情報 */
  displayParams: null,
  /** 自動補完住所 */
  addressAutofill: null,
};

const affiliateReducer = (
  state: AffiliateState = initialAffiliateState,
  action: AffiliateAction,
): AffiliateState => {
  switch (action.type) {
    case ActionTypes.INIT:
      return initialAffiliateState;

    case ActionTypes.REFRESH:
      return { ...state, refresh: {} };

    case ActionTypes.LOADING:
      return { ...state, isLoading: action.payload };

    case ActionTypes.SUBMITTING:
      return { ...state, isSubmitting: action.payload };

    case ActionTypes.NAVIGATING:
      return { ...state, isNavigating: action.payload };

    case ActionTypes.SET_NAVIGATING_TO:
      return { ...state, navigatingTo: action.payload };

    case ActionTypes.SET_PAGE:
      return { ...state, page: action.payload };

    case ActionTypes.SET_SORT:
      return { ...state, sort: action.payload };

    case ActionTypes.SET_SEARCH_PARAMETER:
      return { ...state, searchParameter: action.payload };

    case ActionTypes.SET_SEARCH_TAG_ID_LIST:
      return { ...state, tagIdList: action.payload };

    case ActionTypes.SET_USER_LIST:
      return { ...state, userList: action.payload };

    case ActionTypes.SET_USER:
      return { ...state, userDetail: action.payload };

    case ActionTypes.SET_COMPANY_LIST:
      return { ...state, companyList: action.payload };

    case ActionTypes.SET_COMPANY:
      return { ...state, companyDetail: action.payload };

    case ActionTypes.SET_DISPLAY_PARAMS:
      return { ...state, displayParams: action.payload };

    case ActionTypes.SET_ADDRESS_AUTOFILL:
      return { ...state, addressAutofill: action.payload };

    default:
      return state;
  }
};

export default affiliateReducer;
