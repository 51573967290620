import { HomeAction as Action, HomeActionTypes as ActionTypes, HomeData } from './types';

export const init = (): Action => ({ type: ActionTypes.INIT });

export const setIsLoading = (isLoading: boolean): Action => ({
  type: ActionTypes.SET_LOADING,
  payload: isLoading,
});

export const setData = (data: HomeData): Action => ({
  type: ActionTypes.SET_DATA,
  payload: data,
});
