import { CheckingAction, CheckingState, CheckingActionTypes as ActionTypes } from './types';

const initialCheckingState: CheckingState = {
  refresh: {},
  isLoading: false,
  isSubmitting: false,
  checkingList: null,
};

const checkingReducer = (
  state: CheckingState = initialCheckingState,
  action: CheckingAction,
): CheckingState => {
  switch (action.type) {
    case ActionTypes.INIT:
      return initialCheckingState;

    case ActionTypes.REFRESH:
      return { ...state, refresh: {} };

    case ActionTypes.LOADING:
      return { ...state, isLoading: action.payload };

    case ActionTypes.SUBMITTING:
      return { ...state, isSubmitting: action.payload };

    case ActionTypes.SET_CHECKING_LIST:
      return { ...state, checkingList: action.payload };
    default:
      return state;
  }
};
export default checkingReducer;
