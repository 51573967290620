import type { Action, PayloadAction } from '@reduxjs/toolkit';

/**
 * @summary 月次計画メール画面で管理されるState
 */
export type PlanMonthlyMailState = {
  refresh: Record<string, never>;
  /** 伝達事項変更時のrefresh */
  messageRefresh: Record<string, never>;
  /** Load中か */
  isLoading: boolean;
  /** 契約リストLoad中か */
  isLoadingContractList: boolean;
  /** 送信処理中か */
  isSubmitting: boolean;
  /** 月次計画-メール送信先一覧取得Data */
  monthlyMailUsers: GetMonthlyMailUsers[];
  /** 月次計画-予定調整本文取得 */
  monthlyMailAdjustment: string;
  /** 月次計画-予定調整プレビュー取得 */
  monthlyMailAdjustmentSend: GetMonthlyMailSend[];
  /** 月次計画-予定確定本文取得 */
  monthlyMailFixed: string;
  /** 月次計画-予定確定プレビュー取得 */
  monthlyMailFixedSend: GetMonthlyMailSend[];
  /** 月次計画-予定確定プレビュー取得 */
  monthlyMailFixedSendPlanList: Array<Array<MonthlyMailPlanList>>;
  /** 月次計画-直前通知メール設定取得 */
  monthlyMailRemind: MonthlyMailRemind[];
  /** 月次計画-直前通知プレビュー取得 */
  monthlyMailRemindSend: GetMonthlyMailSend[];
  /** 月次計画-直前通知プレビュー取得(計画リスト) */
  monthlyMailRemindSendPlanList: Array<Array<MonthlyMailPlanList>>;
  /** 月次計画-報告書提出依頼メール設定 */
  monthlyMailReportRequest: MonthlyMailRemind[];
  /** 月次計画-報告書提出依頼プレビュー取得 */
  monthlyMailReportRequestSend: GetMonthlyMailSend[];
  /** 月次計画-報告書提出依頼プレビュー取得(計画リスト) */
  monthlyMailReportRequestSendPlanList: Array<Array<MonthlyMailPlanList>>;
};

/**
 * @summary 月次計画メール画面のAction Type
 */
export enum PlanMonthlyMailActionTypes {
  INIT = 'plan/monthly_mail/init',
  REFRESH = 'plan/monthly_mail/refresh',
  MESSAGE_REFRESH = 'plan/monthly_mail/messageRefresh',
  LOADING = 'plan/monthly_mail/set/isLoading',
  SUBMITTING = 'plan/monthly_mail/set/isSubmitting',
  SET_MONTHLY_MAIL_USERS = 'plan/monthly_mail/set/monthlyMailUsers',
  SET_MONTHLY_MAIL_ADJUSTMENT = 'plan/monthly_mail/set/monthlyMailAdjustment',
  SET_MONTHLY_MAIL_ADJUSTMENT_SEND = 'plan/monthly_mail/set/monthlyMailAdjustmentSend',
  SET_MONTHLY_MAIL_FIXED = 'plan/monthly_mail/set/monthlyMailFixed',
  SET_MONTHLY_MAIL_FIXED_SEND = 'plan/monthly_mail/set/monthlyMailFixedSend',
  SET_MONTHLY_MAIL_FIXED_SEND_PLAN_LIST = 'plan/monthly_mail/set/monthlyMailFixedSendPlanList',
  SET_MONTHLY_MAIL_REMIND = 'plan/monthly_mail/set/monthlyMailRemind',
  SET_MONTHLY_MAIL_REMIND_SEND = 'plan/monthly_mail/set/monthlyMailRemindSend',
  SET_MONTHLY_MAIL_REMIND_SEND_PLAN_LIST = 'plan/monthly_mail/set/monthlyMailRemindSendPlanList',
  SET_MONTHLY_MAIL_REPORT_REQUEST = 'plan/monthly_mail/set/monthlyMailReportRequest',
  SET_MONTHLY_MAIL_REPORT_REQUEST_SEND = 'plan/monthly_mail/set/monthlyMailReportRequestSend',
  SET_MONTHLY_MAIL_REPORT_REQUEST_SEND_PLAN_LIST = 'plan/monthly_mail/set/monthlyMailReportRequestSendPlanList',
}

/**
 * @summary 月次計画詳細画面のAction
 */
export type PlanMonthlyMailAction =
  /** 初期化 */
  | Action<PlanMonthlyMailActionTypes.INIT>
  /** リフレッシュ */
  | Action<PlanMonthlyMailActionTypes.REFRESH>
  /** 伝達事項変更時のrefresh */
  | Action<PlanMonthlyMailActionTypes.MESSAGE_REFRESH>
  /** Loading状態を保持 */
  | PayloadAction<boolean, PlanMonthlyMailActionTypes.LOADING>
  /** 送信処理中かを保持 */
  | PayloadAction<boolean, PlanMonthlyMailActionTypes.SUBMITTING>
  /** 月次計画-メール送信先一覧取得Data */
  | PayloadAction<GetMonthlyMailUsers[], PlanMonthlyMailActionTypes.SET_MONTHLY_MAIL_USERS>
  /** 月次計画-予定調整本文取得 */
  | PayloadAction<string, PlanMonthlyMailActionTypes.SET_MONTHLY_MAIL_ADJUSTMENT>
  /** 月次計画-予定調整プレビュー取得 */
  | PayloadAction<GetMonthlyMailSend[], PlanMonthlyMailActionTypes.SET_MONTHLY_MAIL_ADJUSTMENT_SEND>
  /** 月次計画-予定確定本文取得 */
  | PayloadAction<string, PlanMonthlyMailActionTypes.SET_MONTHLY_MAIL_FIXED>
  /** 月次計画-予定確定プレビュー取得 */
  | PayloadAction<GetMonthlyMailSend[], PlanMonthlyMailActionTypes.SET_MONTHLY_MAIL_FIXED_SEND>
  /** 月次計画-予定確定プレビュー取得 */
  | PayloadAction<
      Array<Array<MonthlyMailPlanList>>,
      PlanMonthlyMailActionTypes.SET_MONTHLY_MAIL_FIXED_SEND_PLAN_LIST
    >
  /** 月次計画-直前通知メール設定取得 */
  | PayloadAction<MonthlyMailRemind[], PlanMonthlyMailActionTypes.SET_MONTHLY_MAIL_REMIND>
  /** 月次計画-直前通知プレビュー取得 */
  | PayloadAction<GetMonthlyMailSend[], PlanMonthlyMailActionTypes.SET_MONTHLY_MAIL_REMIND_SEND>
  /** 月次計画-直前通知プレビュー取得(計画リスト) */
  | PayloadAction<
      Array<Array<MonthlyMailPlanList>>,
      PlanMonthlyMailActionTypes.SET_MONTHLY_MAIL_REMIND_SEND_PLAN_LIST
    >

  /** 月次計画-報告書提出依頼メール設定 */
  | PayloadAction<MonthlyMailRemind[], PlanMonthlyMailActionTypes.SET_MONTHLY_MAIL_REPORT_REQUEST>
  /** 月次計画-報告書提出依頼プレビュー取得 */
  | PayloadAction<
      GetMonthlyMailSend[],
      PlanMonthlyMailActionTypes.SET_MONTHLY_MAIL_REPORT_REQUEST_SEND
    >
  /** 月次計画-報告書提出依頼プレビュー取得(計画リスト) */
  | PayloadAction<
      Array<Array<MonthlyMailPlanList>>,
      PlanMonthlyMailActionTypes.SET_MONTHLY_MAIL_REPORT_REQUEST_SEND_PLAN_LIST
    >;

///API//////

/**
 * @summary 月次計画-メール送信先一覧取得
 */

export type GetMonthlyMailUsers = {
  division_id: number;
  company_id: number;
  company_name: string;
  location: string;
  belong: string;
  position: string;
  user_id: number;
  user_name: string;
  email: string;
  property_id: number;
  property_name: string;
  plan_id: number;
  title: string;
  start_date: string;
  start_time: string;
  end_date: string;
  end_time: string;
  message: string;
};

export type APIGetMonthlyMailUsersResponse = {
  result: {
    user_list: GetMonthlyMailUsers[];
  };
};

/** 月次計画-予定調整本文取得 */
export type APIGetMonthlyMailAdjustmentResponse = {
  result: {
    message: string;
  };
};

/** 月次計画-予定調整プレビュー取得 */
export type GetMonthlyMailSend = {
  sender: string;
  subject: string;
  text: string;
  from: {
    company_name: string;
    location: string;
    belong: string;
    position: string;
    user_name: string;
    email: string;
  };
  to: {
    company_name: string;
    location: string;
    belong: string;
    position: string;
    user_name: string;
    email: string;
  };
  message: string;
  remarks: string;
};

export type APIGetMonthlyMailAdjustmentSendResponse = {
  result: {
    items: GetMonthlyMailSend[];
  };
};

/** 月次計画-予定確定本文取得 */
export type APIGetMonthlyMailFIxedResponse = {
  result: {
    message: string;
  };
};

/** 月次計画-予定確定プレビュー取得 */
export type MonthlyMailFixedSend = GetMonthlyMailSend & {
  plan_list: MonthlyMailPlanList[];
};
export type MonthlyMailPlanList = {
  start_date: string;
  start_time: string;
  end_date: string;
  end_time: string;
  property_name: string;
  zip_code: string;
  address: string;
  title: string;
  notice: string;
};

export type APIGetMonthlyMailFixedSendResponse = {
  result: {
    items: MonthlyMailFixedSend[];
  };
};

/** 月次計画ー事前通APIGetMonthlyMailRemindResponse通知メール設定取得 */
export type MonthlyMailRemind = {
  property_id: number;
  property_name: string;
  contract_id: number;
  title: string;
  message: string;
  is_set: boolean;
  days: number | null;
};

export type APIGetMonthlyMailRemindResponse = {
  result: {
    items: MonthlyMailRemind[];
  };
};

/** @summary 月次計画-直前通知プレビュー取得 */

export type MonthlyMailRemindSend = GetMonthlyMailSend & {
  plan_list: MonthlyMailPlanList[];
};

export type APIGetMonthlyMailRemindSendResponse = {
  result: {
    items: MonthlyMailRemindSend[];
  };
};

/** @summary 月次計画-直前通知プレビュー取得 */
export type MonthlyMailReportRequestSend = GetMonthlyMailSend & {
  plan_list: MonthlyMailPlanList[];
};

export type APIGetMonthlyMailReportRequestSendResponse = {
  result: {
    items: MonthlyMailReportRequestSend[];
  };
};

/** @summary 月次計画-予定調整メール/予定確定メール送信データ */
export type MonthlyAdjFixedSendData = {
  id: number[];
  should_reuse: boolean;
  message: string;
};

/** @summary 月次計画-報告書提出依頼メール設定取得 */
export type APIGetMonthlyMailReportRequest = {
  result: {
    items: MonthlyMailRemind[];
  };
};

/** @summary 月次計画-直前通知メール設定完了 */
export type MonthlyRemindReportRequestSendRequest = {
  contract_id: number;
  is_set: boolean;
  days: number | null;
};

export type APIPostMonthlyRemindSendRequest = {
  items: MonthlyRemindReportRequestSendRequest[];
};

/** @summary  月次計画-報告書提出依頼メール設定完了 */
export type APIPostMonthlyReportRequestSendRequest = {
  items: MonthlyRemindReportRequestSendRequest[];
};
