import { createSelector } from '@reduxjs/toolkit';
import { AppState as State } from '@ducks/index';

export const getRefreshId = (state: State) => state.common.refresh;

export const checkIsLoading = (state: State) => state.common.isLoading;

export const checkIsProcessing = (state: State) => state.common.isProcessing;

/**
 * @summary タグ選択用の建物リスト取得
 * @description [サーバー側処理]お気に入りを優先的に上位に並べ、次にidを昇順 に並べられたものがリストとして返ってくる
 */
export const getPropertyList = (state: State) => {
  return state.common.propertyList;
};

export const getPropertyListFilteredByTagIds = (state: State) => {
  return state.common.propertyListFilteredByTagIds;
};

export const getSelectedPropertyId = (state: State) => state.common.selectedPropertyId;
export const getSelectedProperty = (state: State) =>
  state.common.propertyList.find((property) => property.id === state.common.selectedPropertyId) ??
  null;

export const getSelectedTagIds = (state: State) => state.common.selectedTagIds;

export const getProfile = (state: State) => state.common.profile;

export const getUserId = (state: State) => state.common.profile?.userId ?? null;

export const checkIsSystemAdmin = (state: State) => state.common.profile?.isSystemAdmin ?? false;

export const checkIsPropertyAdmin = (state: State) =>
  state.common.profile?.properties.filter((v) => v.roleId === 2).map((w) => w.id) ?? [];

export const checkIsPropertyStaff = (state: State) =>
  state.common.profile?.properties.filter((v) => v.roleId === 1).map((w) => w.id) ?? [];

export const checkIsPropertyManager = (id: number) =>
  createSelector(
    [
      (state: State) =>
        state.common.profile?.properties.filter((v) => v.roleId === 2).map((w) => w.id) ?? [],
    ],
    (properties: number[]) => properties.includes(id),
  );

// 建物スタッフかどうかチェック
export const checkIsPropertyStaffUser = (propertyId: number) =>
  createSelector(
    [
      (state: State) =>
        state.common.profile?.properties.filter((v) => v.roleId === 1).map((w) => w.id) ?? [],
    ],
    (properties: number[]) => properties.includes(propertyId),
  );

// 建物担当者(建物スタッフまたは建物管理者のいずれか)かどうかチェック
export const checkIsPropertyAssigneeUser = (propertyId: number) =>
  createSelector(
    [
      (state: State) =>
        state.common.profile?.properties
          .filter((v) => v.roleId === 1 || v.roleId === 2) // roleIdが1スタッフまたは2管理者のプロパティを抽出
          .map((w) => w.id) ?? [],
    ],
    (properties: number[]) => properties.includes(propertyId),
  );
export const getRuntimeError = (state: State) => state.common.runtimeError;
