import { AppState as State } from '@ducks/index';

export const getRefreshId = (state: State) => state.inspectionChecklist.refresh;

export const checkIsLoading = (state: State) => state.inspectionChecklist.isLoading;
export const checkIsSubmitting = (state: State) => state.inspectionChecklist.isSubmitting;
export const checkIsNavigating = (state: State) => state.inspectionChecklist.isNavigating;

export const getInspectionChecklists = (state: State) =>
  state.inspectionChecklist.inspectionChecklists;
export const getInspectionChecklistsListInfo = (state: State) =>
  state.inspectionChecklist.inspectionChecklistsListInfo;

export const getInspectionChecklist = (state: State) =>
  state.inspectionChecklist.inspectionChecklist;

export const getInspectionChecklistFormatPlaceholder = (state: State) =>
  state.inspectionChecklist.inspectionChecklistFormatPlaceholders;
