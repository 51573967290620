import axios from '@providers/axiosProvider';
import * as actions from './actions';

import {
  APICreateWork,
  APICreateWorkHistory,
  APICreateWorkHistoryRequest,
  APICreateWorkHistoryRequestParams,
  APICreateWorkRequest,
  APICreateWorkRequestParams,
  APICreateWorkResponse,
  APIDeleteWorksRequest,
  APIExportWorkReportResponse,
  APIGetWorkHistoryResponse,
  APIGetWorkResponse,
  APIGetWorksItemDataResponse,
  APIGetWorksRequestParams,
  APIGetWorksResponse,
  APIUpdateWork,
  APIUpdateWorkHistory,
  APIUpdateWorkHistoryRequest,
  APIUpdateWorkHistoryRequestParams,
  APIUpdateWorkRequest,
  APIUpdateWorkRequestParams,
  Work,
  WorkHistory,
  WorkHistorySubmitData,
  WorksItemData,
  WorksList,
  WorksListInfo,
  WorkCreateSubmitData,
  WorkUpdateSubmitDate,
  APIGetWorkInformationResponse,
  WorkInfo,
  APIApproveWorkHistoryRequest,
  APITakeBackWorkHistoryRequest,
} from './types';

import { LIST_SIZE_PER_PAGE } from '@utils/constants';

/**
 * @summary 不具合一覧取得（案件一覧）
 */
export const getWorksAsync =
  (propertyId: number | null, params: APIGetWorksRequestParams) => async (dispatch: any) => {
    dispatch(actions.setIsLoading(true));

    const reqParams: APIGetWorksRequestParams = {
      ...params,
      size_per_page: LIST_SIZE_PER_PAGE,
    };

    if (propertyId != null) reqParams.property_id = propertyId;

    await axios
      .get<APIGetWorksResponse>('/troubles', {
        params: reqParams,
      })
      .then((response) => {
        const { trouble_list: result, display_params: info } = response.data.result;

        const works: WorksList[] = result.map((data) => ({
          propertyId: data.property_id,
          propertyName: data.property_name,
          troubleId: data.trouble_id,
          title: data.title,
          priorityId: data.priority_id,
          priorityName: data.priority_name,
          troubleType: data.trouble_type,
          troubleTypeName: data.trouble_type_name,
          statusId: data.status_id,
          statusName: data.status_name,
          occurrenceDate: data.occurrence_date,
          occurrenceTime: data.occurrence_time,
          description: data.description,
          picUserId: data.pic_user_id,
          picUserName: data.pic_user_name,
          isDeletedUser: data.is_deleted_user,
          latestComment: data.latest_comment,
          updateUserName: data.update_user_name,
          updateDateTime: data.update_datetime,
          dueDate: data.due_date,
        }));

        const worksListInfo: WorksListInfo = {
          totalHits: info.total,
          totalPages: info.total_page,
        };

        dispatch(actions.setWorks(works));
        dispatch(actions.setWorksListInfo(worksListInfo));
      })
      .catch(() => {
        dispatch(actions.setWorks([]));
        dispatch(actions.setWorksListInfo(null));
      });

    dispatch(actions.setIsLoading(false));
  };

export const getWorksItemDataAsync = (propertyId: number | null) => async (dispatch: any) => {
  dispatch(actions.setIsLoading2(true));

  await axios
    .get<APIGetWorksItemDataResponse>(`/troubles/items`, {
      params: { property_id: propertyId },
    })
    .then((response) => {
      const result = response.data.result;

      const worksItemData: WorksItemData = {
        troubleTypeList: result.trouble_type_list.map((data) => ({
          troubleTypeId: data.trouble_type_id,
          troubleTypeName: data.trouble_type_name,
        })),
        troublePriorityList: result.trouble_priority_list.map((data) => ({
          troublePriorityId: data.trouble_priority_id,
          troublePriorityName: data.trouble_priority_name,
        })),
        troubleStatusList: result.trouble_status_list.map((data) => ({
          troubleStatusId: data.trouble_status_id,
          troubleStatusName: data.trouble_status_name,
        })),
        diagramList:
          result.diagram_list?.map((data) => ({
            diagramId: data.diagram_id,
            diagramName: data.diagram_name,
          })) ?? [],
        equipmentList:
          result.equipment_list?.map((data) => ({
            equipmentId: data.equipment_id,
            equipmentName: data.equipment_name,
            diagramId: data.diagram_id,
          })) ?? [],
        floorList:
          result.floor_list?.map((data) => ({
            floorId: data.floor_id,
            floorName: data.floor_name,
            roomList: data.room_list.map((room) => ({
              roomId: room.room_id,
              roomName: room.room_name,
            })),
          })) ?? [],
        userList: result.user_list.map((data) => ({
          userId: data.user_id,
          userName: `${data.last_name} ${data.first_name}`,
        })),
      };

      dispatch(actions.setWorksItemData(worksItemData));
    })
    .catch(() => {
      dispatch(actions.setWorksItemData(null));
    });

  dispatch(actions.setIsLoading2(false));
};

export const getWorkAsync = (workId: number) => async (dispatch: any) => {
  dispatch(actions.setIsLoading(true));

  await axios
    .get<APIGetWorkResponse>(`/troubles/${workId}`)
    .then((response) => {
      const v = response.data.result;

      const work: Work = {
        troubleId: v.trouble_id,
        title: v.title,
        matterId: v.matter_id,
        troubleType: v.trouble_type,
        troubleTypeName: v.trouble_type_name,
        dueDate: v.due_date,
        priorityId: v.priority_id,
        priorityName: v.priority_name,
        statusId: v.status_id,
        statusName: v.status_name,
        propertyId: v.property_id,
        propertyName: v.property_name,
        floorId: v.floor_id,
        floorName: v.floor_name,
        roomId: v.room_id,
        roomName: v.room_name,
        diagramId: v.diagram_id,
        diagramName: v.diagram_name,
        equipmentId: v.equipment_id,
        equipmentName: v.equipment_name,
        isDeletedEquipment: v.is_deleted_equipment,
        occurrenceDate: v.occurrence_date,
        occurrenceTime: v.occurrence_time,
        picUserId: v.pic_user_id,
        picUserName: v.pic_user_name,
        isDeletedUser: v.is_deleted_user,
        updateDateTime: v.update_datetime,
        description: v.description,
        imageList: v.image_list.map((i) => ({
          fileId: i.file_id,
          fileName: i.file_name,
          fileSize: i.file_size,
          fileUrl: i.file_url,
          isMovie: i.is_movie,
          memo: i.memo,
          thumbnailContent: i.thumbnail_content,
          thumnailUrl: i.thumnail_url,
        })),
        fileList: v.file_list.map((f) => ({
          fileName: f.file_name,
          fileUrl: f.file_url,
          updateDatetime: f.update_datetime,
          updateUserName: f.update_user_name,
          fileSize: f.file_size,
        })),
        inspectionChecklistId: v.inspection_checklist_id,
        inspectionResultId: v.inspection_result_id,
        componentId: v.component_id,
        componentName: v.component_name,
      };

      dispatch(actions.setWork(work));
    })
    .catch(() => {
      dispatch(actions.setWork(null));
    });

  dispatch(actions.setIsLoading(false));
};

export const getWorkHistoryAsync =
  (propertyId: number, workId: number) => async (dispatch: any) => {
    dispatch(actions.setIsLoading3(true));

    await axios
      .get<APIGetWorkHistoryResponse>(
        `/properties/${propertyId}/equipments/troubles/${workId}/trouble_shooting`,
      )
      .then((response) => {
        const { trouble_shooting_list: result } = response.data.result;

        const workHistory: WorkHistory[] = result.map((data) => ({
          troubleShootingId: data.trouble_shooting_id,
          troubleTypeId: data.trouble_type_id,
          troubleTypeName: data.trouble_type_name,
          iconId: data.icon_id,
          iconName: data.icon_name,
          responseUserId: data.response_user_id,
          responseUserName: data.response_user_name,
          isDeletedResponseUser: data.is_deleted_response_user,
          beforeStatus: data.before_status,
          beforeStatusName: data.before_status_name,
          afterStatus: data.after_status,
          afterStatusName: data.after_status_name,
          responseStartDate: data.response_start_date,
          responseStartTime: data.response_start_time,
          responseEndDate: data.response_end_date,
          responseEndTime: data.response_end_time,
          responseDetail: data.response_detail,
          updateUserId: data.update_user_id,
          updateUserName: data.update_user_name,
          isDeletedUpdateUser: data.is_deleted_update_user,
          createDatetime: data.create_datetime,
          updateDatetime: data.update_datetime,
          fileList: data.file_list.map((f) => ({
            fileName: f.file_name,
            fileUrl: f.file_url,
            updateDatetime: f.update_datetime,
            updateUserName: f.update_user_name,
            fileSize: f.file_size,
          })),
          imageList: data.image_list.map((i) => ({
            fileId: i.file_id,
            fileName: i.file_name,
            fileSize: i.file_size,
            fileUrl: i.file_url,
            isMovie: i.is_movie,
            memo: i.memo,
            thumbnailContent: i.thumbnail_content,
            thumnailUrl: i.thumnail_url,
          })),
        }));

        dispatch(actions.setWorkHistory(workHistory));
      })
      .catch(() => {
        dispatch(actions.setWorkHistory([]));
      });

    dispatch(actions.setIsLoading3(false));
  };

// 新規登録（作業登録）
export const createWorkAsync =
  (propertyId: number, data: WorkCreateSubmitData) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const reqParams: APICreateWorkRequestParams = {
      property_id: propertyId,
    };

    const work: APICreateWork = {
      title: data.title,
      trouble_type: data.troubleType,
      priority_id: data.priorityId,
      status_id: data.statusId,
      due_date: data.dueDate !== '' ? data.dueDate : null,
      diagram_id: data.diagramId,
      equipment_id: data.equipmentId,
      floor_id: data.floorId,
      room_id: data.roomId,
      occurrence_date: data.occurrenceDate,
      occurrence_time: data.occurrenceTime,
      pic_user_id: data.picUserId,
      description: data.description,
      image_list: data.imageList.map((i) => ({
        file_name: i.fileName,
        file_content: i.fileContent,
        memo: i.memo,
      })),
      file_list: data.fileList.map((f) => ({
        file_name: f.fileName,
        file_content: f.fileContent,
      })),
    };

    const requestData: APICreateWorkRequest = work;

    await axios
      .post<APICreateWorkResponse>(`/troubles`, requestData, { params: reqParams })
      .then((response) => {
        const workId = response.data.result.trouble_id;
        dispatch(actions.setIsNavigating(true));
        dispatch(actions.setIsNavigatingTo(workId));
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const updateWorkAsync =
  (propertyId: number, workId: number, data: WorkUpdateSubmitDate) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const reqParams: APIUpdateWorkRequestParams = {
      property_id: propertyId,
    };

    const work: APIUpdateWork = {
      status_id: data.status_id,
      title: data.title,
      trouble_type: data.trouble_type,
      priority_id: data.priority_id,
      due_date: data.due_date,
      diagram_id: data.diagram_id,
      equipment_id: data.equipment_id,
      floor_id: data.floor_id,
      room_id: data.room_id,
      occurrence_date: data.occurrence_date,
      occurrence_time: data.occurrence_time,
      pic_user_id: data.pic_user_id,
      description: data.description,
      image_list: data.image_list.map((i) => ({
        file_name: i.file_name,
        file_content: i.file_content,
        is_delete_file: i.is_delete_file,
        memo: i.memo,
      })),
      file_list: data.file_list.map((f) => ({
        file_name: f.file_name,
        file_content: f.file_content,
        is_delete_file: f.is_delete_file,
      })),
    };

    const requestData: APIUpdateWorkRequest = work;

    await axios
      .put(`/troubles/${workId}`, requestData, { params: reqParams })
      .then(() => {
        dispatch(actions.setIsNavigating(true));
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const deleteWorksAsync = (workIds: number[]) => async (dispatch: any) => {
  dispatch(actions.setIsSubmitting(true));

  const requestData: APIDeleteWorksRequest = {
    trouble_list: workIds.map((v) => ({
      trouble_id: v,
    })),
  };

  await axios
    .post(`/troubles/delete`, requestData)
    .then(() => {
      dispatch(actions.setIsNavigating(true));
      dispatch(actions.setIsNavigatingTo(-1));
    })
    .catch(() => {});

  dispatch(actions.setIsSubmitting(false));
};

export const createWorkHistoryAsync =
  (propertyId: number, workId: number, data: WorkHistorySubmitData) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const reqParams: APICreateWorkHistoryRequestParams = {
      property_id: propertyId,
    };

    const work: APICreateWorkHistory = {
      after_status: data.nextStatus,
      response_detail: data.responseDetail,
      response_end_date: data.responseEndDate,
      response_end_time: data.responseEndTime,
      response_start_date: data.responseStartDate,
      response_start_time: data.responseStartTime,
      response_user_id: data.responseUserId,
      trouble_type_id: data.troubleShootingTypeId,
      file_list: data.fileList.map((f) => ({
        file_name: f.fileName,
        file_content: f.fileContent,
      })),
      image_list: data.imageList.map((i) => ({
        file_name: i.fileName,
        file_content: i.fileContent,
        memo: i.memo,
      })),
    };

    const requestData: APICreateWorkHistoryRequest = work;

    await axios
      .post(
        `/properties/${propertyId}/equipments/troubles/${workId}/trouble_shooting`,
        requestData,
        { params: reqParams },
      )
      .then(() => {
        dispatch(actions.refresh());
        dispatch(actions.refresh3());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const updateWorkHistoryAsync =
  (propertyId: number, workId: number, historyId: number, data: WorkHistorySubmitData) =>
  async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const reqParams: APIUpdateWorkHistoryRequestParams = {
      property_id: propertyId,
    };

    const work: APIUpdateWorkHistory = {
      after_status: data.nextStatus,
      response_detail: data.responseDetail,
      response_end_date: data.responseEndDate,
      response_end_time: data.responseEndTime,
      response_start_date: data.responseStartDate,
      response_start_time: data.responseStartTime,
      response_user_id: data.responseUserId,
      trouble_type_id: data.troubleShootingTypeId,
      file_list: data.fileList.map((f) => ({
        file_name: f.fileName,
        file_content: f.fileContent,
        is_delete_file: f.deleteFlag,
      })),
      image_list: data.imageList.map((i) => ({
        file_name: i.fileName,
        file_content: i.fileContent,
        memo: i.memo,
        is_delete_file: i.deleteFlag,
      })),
    };

    const requestData: APIUpdateWorkHistoryRequest = work;

    await axios
      .put(
        `/properties/${propertyId}/equipments/troubles/${workId}/trouble_shooting/${historyId}`,
        requestData,
        { params: reqParams },
      )
      .then(() => {
        dispatch(actions.refresh());
        dispatch(actions.refresh3());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const approveWorkAsync =
  (workId: number, approveRequest: APIApproveWorkHistoryRequest) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .patch(`/troubles/${workId}/approve`, approveRequest)
      .then(() => {
        dispatch(actions.refresh());
        dispatch(actions.refresh3());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const takeBackWorkAsync =
  (workId: number, approveRequest: APITakeBackWorkHistoryRequest) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .patch(`/troubles/${workId}/uncompleted`, approveRequest)
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const exportWorkReportAsync = async (workId: number, needArchive = false) => {
  const result = await axios
    .get<APIExportWorkReportResponse>(
      `/troubles/${workId}/export${needArchive ? '?need_archive=true' : ''}`,
    )
    .then((response) => {
      return response.data.result;
    })
    .catch(() => {
      return null;
    });

  return result;
};

/** 不具合連絡事項取得 */
export const getWorkInformationAsync = (workId: number) => async (dispatch: any) => {
  dispatch(actions.setIsLoading3(true));

  await axios
    .get<APIGetWorkInformationResponse>(`/troubles/${workId}/information`)
    .then((response) => {
      const resultData = response.data.result;
      const workInfoData: WorkInfo = {
        troubleId: resultData.trouble_id,
        title: resultData.title,
        propertyId: resultData.property_id,
        propertyName: resultData.property_name,
        message: resultData.message,
        files: (resultData.file_list ?? []).map((f) => ({
          fileId: f.file_id,
          fileName: f.file_name,
          fileUrl: f.file_url,
          fileSize: f.file_size,
        })),
      };
      dispatch(actions.setWorkInfo(workInfoData));
    })
    .catch(() => {
      dispatch(actions.setWorkInfo(null));
    });
  dispatch(actions.setIsLoading3(false));
};
