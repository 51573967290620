import type { Action, PayloadAction } from '@reduxjs/toolkit';

/**
 * @summary 年次販売集計画面で管理されるState
 */
export type PlanAnnualTableState = {
  refresh: Record<string, never>;
  /** Load中か */
  isLoading: boolean;
  /** 送信処理中か */
  isSubmitting: boolean;
  /** ソート番号 */
  sort: number;
  /** 詳細検索条件 */
  searchParameter: SearchParameter;
  /** 年次販売集計リスト */
  tables: AnnualPlanTable[];
  /** 年次計画サマリ */
  summary: AnnualPlanTableSummary | null;
  /** 年次販売集計サマリ */
  salesSummary: AnnualPlanTableSalesSummary | null;
};

/**
 * @summary 年次販売集計画面のAction Type
 */
export enum PlanAnnualTableActionTypes {
  INIT = 'plan/annual_table/init',
  REFRESH = 'plan/annual_table/refresh',
  LOADING = 'plan/annual_table/set/isLoading',
  SUBMITTING = 'plan/annual_table/set/isSubmitting',
  SET_SORT = 'plan/annual_table/set/sort',
  SET_SEARCH_PARAMETER = 'plan/annual_table/set/search_parameter',
  SET_TABLES = 'plan/annual_table/set/tables',
  SET_SUMMARY = 'plan/annual_table/set/summary',
  SET_SALES_SUMMARY = 'plan/annual_table/set/sales_summary',
}

/**
 * @summary 年次販売集計画面のAction
 */
export type PlanAnnualTableAction =
  /** 初期化 */
  | Action<PlanAnnualTableActionTypes.INIT>
  /** リフレッシュ */
  | Action<PlanAnnualTableActionTypes.REFRESH>
  /** Loading状態を保持 */
  | PayloadAction<boolean, PlanAnnualTableActionTypes.LOADING>
  /** 送信処理中かを保持 */
  | PayloadAction<boolean, PlanAnnualTableActionTypes.SUBMITTING>
  /** ページ番号を保持 */
  | PayloadAction<number, PlanAnnualTableActionTypes.SET_SORT>
  /** 詳細検索条件を保持 */
  | PayloadAction<SearchParameter, PlanAnnualTableActionTypes.SET_SEARCH_PARAMETER>
  /** 年次販売集計リストを保持 */
  | PayloadAction<AnnualPlanTable[], PlanAnnualTableActionTypes.SET_TABLES>
  /** 年次計画サマリを保持 */
  | PayloadAction<AnnualPlanTableSummary | null, PlanAnnualTableActionTypes.SET_SUMMARY>
  /** 年次販売集計サマリを保持 */
  | PayloadAction<AnnualPlanTableSalesSummary | null, PlanAnnualTableActionTypes.SET_SALES_SUMMARY>;

/**
 * @summary 年次販売集計の詳細検索パラメータ
 */
export type SearchParameter = {
  /** 計画名 */
  title?: string;
  /** 契約種別 */
  contract_type?: string;
  /** 定期業務種別 */
  inspection_type?: string;
  /** 定期業務区分 */
  inspection_division?: string;
  /** 対応状況 */
  status?: string;
  /** 協力会社名 */
  company?: string;
};

/**
 * @summary APIから返却される販売集計リストのプロパティ
 */
export type AnnualPlanTable = {
  /** 計画完了月 */
  complete_month: number;
  /** 建物ID */
  property_id: number;
  /** 建物名 */
  property_name: string;
  /** 契約ID */
  contract_id: number;
  /** 計画名 */
  title: string;
  /** 契約種別ID */
  contract_type_id: number;
  /** 契約種別 */
  contract_type_name: string;
  /** 定期業務種別ID */
  inspection_type_id: number;
  /** 定期業務種別 */
  inspection_type_name: string;
  /** 定期業務区分ID */
  inspection_division_id: number;
  /** 定期業務区分 */
  inspection_division_name: string;
  /** 販売結果 */
  sales_result: {
    /** 請求額 */
    invoice_amount: number;
    /** 支払額 */
    payment_amount: number;
    /** 粗利 */
    gross_profit: number;
    /** 粗利率 */
    gross_profit_rate: number | null;
  };
};

/**
 * @summary APIから返却される年次計画サマリのプロパティ
 */
export type AnnualPlanTableSummary = {
  /** 未確定件数 */
  unfixed_num: number;
  /** 確定件数 */
  fixed_num: number;
  /** 対応中件数 */
  in_progress_num: number;
  /** 査収待ち件数 */
  checking_num: number;
  /** 査収完了件数 */
  completed_num: number;
  /** 保留件数 */
  pending_num: number;
};

/**
 * @summary APIから返却される販売集計サマリのプロパティ
 */
export type AnnualPlanTableSalesSummary = {
  /** 売上 */
  sales: {
    /** 前年実績 */
    previous_year: number;
    /** 販売結果 */
    current_year: number;
    /** 対販売結果伸長率 */
    elongation_rate: number | null;
  };
  /** 粗利 */
  gross_profit: {
    /** 前年実績 */
    previous_year: number;
    /** 販売結果 */
    current_year: number;
    /** 対販売結果伸長率 */
    elongation_rate: number | null;
  };
  /** 粗利率 */
  gross_profit_rate: {
    /** 前年実績 */
    previous_year: number | null;
    /** 販売結果 */
    current_year: number | null;
  };
};

/**
 * @summary APIから返却される年次販売集計のresponse.data.result
 */
export type APIAnnualPlan = {
  /** 年 */
  readonly year: number;
  /** サマリ */
  readonly summary: AnnualPlanTableSummary;
  /** 年次計画 */
  readonly items: AnnualPlanTable[];
  /** 販売集計 */
  readonly sales_summary: AnnualPlanTableSalesSummary;
};

export type APIGetAnnualPlansRequestParams = {
  /** 建物ID */
  property_id?: number;
  /** 年 */
  year?: number;
  /** ソート番号 */
  sort?: number;
  /** キーワード */
  keyword?: string;
  /** タグID */
  tag?: string;
} & SearchParameter;

/**
 * @summary APIから返却される年次計画のresponse.data.result
 */
export type APIGetAnnualPlansResponse = {
  result: APIAnnualPlan;
};
