import {
  PlanAnnualScheduleState,
  PlanAnnualScheduleAction,
  PlanAnnualScheduleActionTypes as ActionTypes,
} from './types';

/**
 * @summary 年次計画画面で管理されるStateの初期値
 */
const initialPlanAnnualScheduleState: PlanAnnualScheduleState = {
  refresh: {},
  /** Load中か */
  isLoading: false,
  /** 送信処理中か */
  isSubmitting: false,
  /** ソート番号 */
  sort: 1,
  /** 詳細検索条件 */
  searchParameter: {},
  /** 年次計画リスト */
  plans: [],
  /** 年次計画サマリ */
  summary: null,
};

const planAnnualScheduleReducer = (
  state: PlanAnnualScheduleState = initialPlanAnnualScheduleState,
  action: PlanAnnualScheduleAction,
): PlanAnnualScheduleState => {
  switch (action.type) {
    case ActionTypes.INIT:
      return initialPlanAnnualScheduleState;

    case ActionTypes.REFRESH:
      return { ...state, refresh: {} };

    case ActionTypes.LOADING:
      return { ...state, isLoading: action.payload };

    case ActionTypes.SUBMITTING:
      return { ...state, isSubmitting: action.payload };

    case ActionTypes.SET_SORT:
      return { ...state, sort: action.payload };

    case ActionTypes.SET_SEARCH_PARAMETER:
      return { ...state, searchParameter: action.payload };

    case ActionTypes.SET_PLANS:
      return { ...state, plans: action.payload };

    case ActionTypes.SET_SUMMARY:
      return { ...state, summary: action.payload };

    default:
      return state;
  }
};

export default planAnnualScheduleReducer;
