import axios from '@providers/axiosProvider';
import axios_ from 'axios';
import * as actions from './actions';

import {
  Address,
  APIAddMembersRequest,
  APIContract,
  APICreateFloorRequest,
  APICreateProperty,
  APICreatePropertyResponse,
  APICreateRoomRequest,
  APICreateTenantRequest,
  APIDeleteFloorsRequest,
  APIDeleteMembersRequest,
  APIDeletePropertyRequest,
  APIDeleteRoomsRequest,
  APIDeleteTenantsRequest,
  APIDownloadPropertiesFormatRequestParams,
  APIDownloadPropertyContractsRequestParams,
  APIGetAddressResponse,
  APIGetContractResponse,
  APIGetContractsResponse,
  APIGetFloorsResponse,
  APIGetInformationResponse,
  APIGetMembersRequestParams,
  APIGetMembersResponse,
  APIGetPropertiesRequestParams,
  APIGetPropertiesRequestParamsWithoutPage,
  APIGetPropertiesResponse,
  APIGetPropertyContractsRequestParams,
  APIGetPropertyContractsRequestParamsWithoutPage,
  APIGetPropertyGuestRequest,
  APIGetPropertyResponse,
  APIGetRoomsResponse,
  APIGetSelectMembersRequestParams,
  APIGetSelectMembersResponse,
  APIGetTenantsRequestParams,
  APIGetTenantsResponse,
  APIPostCreateContract,
  APIPutEditContract,
  APIUpdateFloorRequest,
  APIUpdateFloorsOrderRequest,
  APIUpdatePropertyRequest,
  APIUpdateRoomRequest,
  APIUpdateTenantRequest,
  Contract,
  ContractsList,
  ContractsListInfo,
  ContractStatusTypes,
  FloorsList,
  FloorSubmitData,
  Information,
  InspectionDivisionTypes,
  InspectionTypes,
  MembersList,
  MembersListInfo,
  PropertiesList,
  PropertiesListInfo,
  Property,
  PropertyContractAttributeType,
  PropertyContractPriceType,
  PropertyContractRepeatParamCustomCycleType,
  PropertyContractRepeatType,
  RoomsList,
  RoomSubmitData,
  SelectMembersList,
  SelectMembersListInfo,
  TenantsList,
  TenantsListInfo,
  TenantSubmitData,
} from './types';

import { commonActions, commonOperations } from '@ducks/common';
import { LIST_SIZE_PER_PAGE, LIST_SIZE_PER_PAGE_LARGE } from '@utils/constants';
import { confirmDialog } from '@utils/confirmDialog';

export const getPropertiesAsync =
  (params: APIGetPropertiesRequestParams) => async (dispatch: any) => {
    dispatch(actions.setIsLoading(true));

    const reqParams: APIGetPropertiesRequestParams = {
      ...params,
      size_per_page: LIST_SIZE_PER_PAGE,
    };

    await axios
      .get<APIGetPropertiesResponse>('/properties', {
        params: reqParams,
      })
      .then((response) => {
        const { property_list: list, display_params: info } = response.data.result;

        const properties: PropertiesList[] = list.map(
          (property): PropertiesList => ({
            id: property.property_id,
            name: property.property_name,
            address: property.address,
            owner: property.owner ?? '',
            contact: property.contact ?? '',
            zipCode: property.zip_code,
            contractNum: property.contract_num,
            abnormalEquipmentNum: property.abnormal_equipment_num,
            incompleteConstructionNum: property.incomplete_construction_num,
            incompleteWorkNum: property.incomplete_work_num,
            url: property.url,
            scale: property.scale,
            parking: property.parking,
            updateUserName: property.update_user_name,
            updateDatetime: property.update_datetime,
          }),
        );

        const propertiesListInfo: PropertiesListInfo = {
          totalHits: info.total,
          totalPages: info.total_page,
        };

        dispatch(actions.setProperties(properties));
        dispatch(actions.setPropertiesListInfo(propertiesListInfo));
      })
      .catch(() => {
        dispatch(actions.setProperties([]));
        dispatch(actions.setPropertiesListInfo(null));
      });

    dispatch(actions.setIsLoading(false));
  };

/** @summary GET /api/properties/{property_id} 建物個別情報取得 */
export const getPropertyAsync = (propertyId: number) => async (dispatch: any) => {
  dispatch(actions.setIsLoading(true));

  const property = await getPropertyData(propertyId);
  if (property) {
    dispatch(actions.setProperty(property));
  } else {
    dispatch(actions.setProperty(null));
  }

  dispatch(actions.setIsLoading(false));
};

export const getPropertyData = async (propertyId: number) => {
  return await axios
    .get<APIGetPropertyResponse>(`/properties/${propertyId}`)
    .then((response) => {
      const property = response.data.result;

      const data: Property = {
        id: property.property_id,
        name: property.property_name,
        code: property.property_code,
        zipCode: property.zip_code,
        prefectureId: property.prefecture_id,
        prefectureName: property.prefecture_name,
        municipalities: property.municipalities,
        owner: property.owner,
        contact: property.contact,
        completionDate: property.completion_date || '',
        totalSiteArea: property.total_site_area,
        totalFloorArea: property.total_floor_area,
        scale: property.scale,
        remarks: property.remarks,
        contractNum: property.contract_num,
        abnormalEquipmentNum: property.abnormal_equipment_num,
        incompleteConstructionNum: property.incomplete_construction_num,
        incompleteWorkNum: property.incomplete_work_num,
        buildingName: property.building_name,
        url: property.url,
        ownerCompanyId: property.owner_company_id,
        blockAddress: property.block_address,
        parking: property.parking,
        designCompanyId: property.design_company_id,
        constructionCompanyId: property.construction_company_id,
        tags: property.tags,
        documents: property.documents,
        imageList: property.image_list,
        designCompanyName: property.design_company_name,
        constructionCompanyName: property.construction_company_name,
        updateUserName: property.update_user_name,
        updateDatetime: property.update_datetime,
        status: property.status,
      };

      return data;
    })
    .catch(() => {
      return null;
    });
};

export const createPropertyAsync = (data: APICreateProperty) => async (dispatch: any) => {
  dispatch(actions.setIsSubmitting(true));

  await axios
    .post<APICreatePropertyResponse>(`/properties`, data)
    .then((response) => {
      const propertyId = response.data.result.property_id;
      dispatch(actions.setIsNavigating(true));
      dispatch(actions.setIsNavigatingTo(propertyId));
    })
    .catch(() => {});

  dispatch(actions.setIsSubmitting(false));
};

export const updatePropertyAsync =
  (propertyId: number, data: APIUpdatePropertyRequest) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .put(`/properties/${propertyId}`, data)
      .then(() => {
        dispatch(actions.setIsNavigating(true));
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const deletePropertyImageAsync =
  (propertyId: number, fileId: number) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios.delete(`/properties/${propertyId}/picture/${fileId}`).catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const deletePropertyAsync = (propertyId: number) => async (dispatch: any) => {
  dispatch(actions.setIsSubmitting(true));

  const requestData: APIDeletePropertyRequest = {
    property_list: [{ property_id: propertyId }],
  };

  await axios
    .post(`/properties/delete`, requestData)
    .then(() => {
      dispatch(actions.setIsNavigating(true));
      dispatch(actions.setIsNavigatingTo(-1));
    })
    .catch(() => {});

  dispatch(actions.setIsSubmitting(false));
};

export const getFloorsAsync = (propertyId: number) => async (dispatch: any) => {
  dispatch(actions.setIsLoading2(true));

  await axios
    .get<APIGetFloorsResponse>(`/properties/${propertyId}/floors`)
    .then((response) => {
      const result: FloorsList[] = response.data.result.map((v) => ({
        id: v.floor_id,
        name: v.floor_name,
        description: v.description,
        roomNum: v.room_num,
      }));
      dispatch(actions.setFloors(result));
    })
    .catch(() => {
      dispatch(actions.setFloors([]));
    });

  dispatch(actions.setIsLoading2(false));
};

export const createFloorAsync =
  (propertyId: number, data: FloorSubmitData) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const requestData: APICreateFloorRequest = {
      floor_name: data.name,
      description: data.description,
    };

    await axios
      .post(`/properties/${propertyId}/floors`, requestData)
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const updateFloorAsync =
  (propertyId: number, floorId: number, data: FloorSubmitData) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const requestData: APIUpdateFloorRequest = {
      floor_name: data.name,
      description: data.description,
    };

    await axios
      .put(`/properties/${propertyId}/floors/${floorId}`, requestData)
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const updateFloorsOrderAsync =
  (propertyId: number, floors: number[]) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const requestData: APIUpdateFloorsOrderRequest = {
      floor_list: floors.map((v) => ({
        floor_id: v,
      })),
    };
    await axios
      .put(`/properties/${propertyId}/floors`, requestData)
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const deleteFloorsAsync =
  (propertyId: number, floors: number[], confirmText: string, shouldDelete = false) =>
  async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const requestData: APIDeleteFloorsRequest = {
      floor_list: floors.map((v) => ({
        floor_id: v,
      })),
    };

    await axios
      .delete(`/properties/${propertyId}/floors${shouldDelete ? `?should_delete=${true}` : ''}`, {
        data: requestData,
      })
      .then(async (response) => {
        if (response.status !== 202) {
          dispatch(actions.refresh());
          return;
        }

        if (!(await confirmDialog(confirmText))) return;
        return dispatch(deleteFloorsAsync(propertyId, floors, confirmText, true));
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const getRoomsAsync = (propertyId: number, floorId: number) => async (dispatch: any) => {
  dispatch(actions.setIsLoading3(true));

  await axios
    .get<APIGetRoomsResponse>(`/properties/${propertyId}/floors/${floorId}`)
    .then((response) => {
      const rooms: RoomsList[] = response.data.result.room_list.map((v) => ({
        id: v.room_id,
        name: v.room_name,
        description: v.description,
      }));
      dispatch(actions.setRooms(rooms));
    })
    .catch(() => {
      dispatch(actions.setRooms([]));
    });

  dispatch(actions.setIsLoading3(false));
};

export const createRoomAsync =
  (propertyId: number, floorId: number, data: RoomSubmitData) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const requestData: APICreateRoomRequest = {
      room_name: data.name,
      description: data.description,
    };

    await axios
      .post(`/properties/${propertyId}/floors/${floorId}/room`, requestData)
      .then(() => {
        dispatch(actions.refresh2());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const updateRoomAsync =
  (propertyId: number, floorId: number, roomId: number, data: RoomSubmitData) =>
  async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const requestData: APIUpdateRoomRequest = {
      room_name: data.name,
      description: data.description,
    };

    await axios
      .put(`/properties/${propertyId}/floors/${floorId}/room/${roomId}`, requestData)
      .then(() => {
        dispatch(actions.refresh2());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const deleteRoomsAsync =
  (propertyId: number, floorId: number, rooms: number[]) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const requestData: APIDeleteRoomsRequest = {
      room_list: rooms.map((v) => ({
        room_id: v,
      })),
    };

    await axios
      .delete(`/properties/${propertyId}/floors/${floorId}/room`, { data: requestData })
      .then(() => {
        dispatch(actions.refresh2());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const getMembersAsync =
  (propertyId: number, params: APIGetMembersRequestParams) => async (dispatch: any) => {
    dispatch(actions.setIsLoading2(true));

    const reqParams: APIGetMembersRequestParams = {
      ...params,
      size_per_page: LIST_SIZE_PER_PAGE,
    };

    await axios
      .get<APIGetMembersResponse>(`/properties/${propertyId}/group_users`, {
        params: reqParams,
      })
      .then((response) => {
        const { group_user_list: list, display_params: info } = response.data.result;

        const members: MembersList[] = list.map(
          (v): MembersList => ({
            id: v.group_user_id,
            name: v.group_user_name,
            type: v.group_user_type,
            roleId: v.property_role_id,
            roleName: v.property_role_name,
          }),
        );

        const membersListInfo: MembersListInfo = {
          totalHits: info.total,
          totalPages: info.total_page,
        };

        dispatch(actions.setMembers(members));
        dispatch(actions.setMembersListInfo(membersListInfo));
      })
      .catch(() => {
        dispatch(actions.setMembers([]));
        dispatch(actions.setMembersListInfo(null));
      });

    dispatch(actions.setIsLoading2(false));
  };

export const getSelectMembersAsync =
  (propertyId: number, params: APIGetSelectMembersRequestParams) => async (dispatch: any) => {
    dispatch(actions.setIsLoading3(true));

    const reqParams: APIGetSelectMembersRequestParams = {
      ...params,
      page: 1,
      size_per_page: LIST_SIZE_PER_PAGE_LARGE,
    };

    await axios
      .get<APIGetSelectMembersResponse>(`/properties/${propertyId}/group_users/items`, {
        params: reqParams,
      })
      .then((response) => {
        const { group_user_list: list, display_params: info } = response.data.result;

        const selectMembers: SelectMembersList[] = list.map(
          (v): SelectMembersList => ({
            id: v.group_user_id,
            name: v.group_user_name,
            type: v.group_user_type,
          }),
        );

        const selectMembersListInfo: SelectMembersListInfo = {
          totalHits: info.total,
          totalPages: info.total_page,
        };

        dispatch(actions.setSelectMembers(selectMembers));
        dispatch(actions.setSelectMembersListInfo(selectMembersListInfo));
      })
      .catch(() => {
        dispatch(actions.setSelectMembers([]));
        dispatch(actions.setSelectMembersListInfo(null));
      });

    dispatch(actions.setIsLoading3(false));
  };

export const addMembersAsync =
  (propertyId: number, members: number[], role: number) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const requestData: APIAddMembersRequest = {
      group_user_list: members.map((v) => ({
        group_user_id: v,
        property_role_id: role,
      })),
    };

    await axios
      .post(`/properties/${propertyId}/group_users`, requestData)
      .then(() => {
        dispatch(commonOperations.getProfileAsync());
        dispatch(actions.refresh());
        dispatch(commonActions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const deleteMembersAsync =
  (propertyId: number, members: number[]) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const requestData: APIDeleteMembersRequest = {
      group_user_list: members.map((v) => ({
        group_user_id: v,
      })),
    };

    await axios
      .delete(`/properties/${propertyId}/group_users`, { data: requestData })
      .then(() => {
        dispatch(commonOperations.getProfileAsync());
        dispatch(actions.refresh());
        dispatch(commonActions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const getTenantsAsync =
  (propertyId: number, params: APIGetTenantsRequestParams) => async (dispatch: any) => {
    dispatch(actions.setIsLoading2(true));

    const reqParams: APIGetTenantsRequestParams = {
      ...params,
      size_per_page: LIST_SIZE_PER_PAGE,
    };

    await axios
      .get<APIGetTenantsResponse>(`/properties/${propertyId}/contact`, {
        params: reqParams,
      })
      .then((response) => {
        const { contact_list: list, display_params: info } = response.data.result;

        const tenants: TenantsList[] = list.map(
          (v): TenantsList => ({
            id: v.contact_id,
            name: v.contact_name,
            tel: v.tel,
            email: v.email,
            remarks: v.remarks,
          }),
        );

        const tenantsListInfo: TenantsListInfo = {
          totalHits: info.total,
          totalPages: info.total_page,
        };

        dispatch(actions.setTenants(tenants));
        dispatch(actions.setTenantsListInfo(tenantsListInfo));
      })
      .catch(() => {
        dispatch(actions.setTenants([]));
        dispatch(actions.setTenantsListInfo(null));
      });

    dispatch(actions.setIsLoading2(false));
  };

export const createTenantAsync =
  (propertyId: number, data: TenantSubmitData) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const requestData: APICreateTenantRequest = {
      contact_name: data.name,
      tel: data.tel,
      email: data.email,
      remarks: data.remarks,
    };

    await axios
      .post(`/properties/${propertyId}/contact`, requestData)
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const updateTenantAsync =
  (propertyId: number, tenantId: number, data: TenantSubmitData) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const requestData: APIUpdateTenantRequest = {
      contact_name: data.name,
      tel: data.tel,
      email: data.email,
      remarks: data.remarks,
    };

    await axios
      .put(`/properties/${propertyId}/contact/${tenantId}`, requestData)
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const deleteTenantsAsync =
  (propertyId: number, tenantIds: number[]) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    const requestData: APIDeleteTenantsRequest = {
      property_contact_list: tenantIds.map((v) => ({
        contact_id: v,
      })),
    };

    await axios
      .delete(`/properties/${propertyId}/contact`, { data: requestData })
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const searchAddressAsync = (zipCode: string) => async (dispatch: any) => {
  await axios_
    .get<APIGetAddressResponse>('https://zipcloud.ibsnet.co.jp/api/search', {
      params: { zipcode: zipCode, limit: 1 },
    })
    .then((response) => {
      const results = response.data.results;
      const v = results[0];
      const address: Address = {
        address1: v.address1,
        address2: v.address2,
        address3: v.address3,
        prefCode: v.prefcode,
        zipCode: v.zipcode,
      };
      dispatch(actions.setAddressAutofill(address));
    })
    .catch(() => {
      dispatch(actions.setAddressAutofill(null));
    });
};

/**
 * 建物にタグを追加する
 * @param propertyIds 建物ID
 * @param tagId タグID
 */
export const setPropertyTags = (propertyIds: number[], tagId: number) => async (dispatch: any) => {
  dispatch(actions.setIsSubmitting(true));

  type SetPropertyTagsParams = {
    tag_id: number;
    property_list: { property_id: number }[];
  };
  const requestData: SetPropertyTagsParams = {
    tag_id: tagId,
    property_list: propertyIds.map((id) => {
      return {
        property_id: id,
      };
    }),
  };

  await axios
    .post(`/properties/tags`, requestData)
    .then(() => {
      dispatch(actions.refresh());
    })
    .catch((e) => {
      if (e.response.status === 400) {
        alert('存在しないタグを設定しています。');
      }
    });

  dispatch(actions.setIsSubmitting(false));
};

/**
 * 建物のタグを削除する
 * @param propertyIds 建物ID
 */
export const resetPropertyTags = (propertyIds: number[]) => async (dispatch: any) => {
  dispatch(actions.setIsSubmitting(true));

  const requestData: APIDeletePropertyRequest = {
    property_list: propertyIds.map((id) => {
      return {
        property_id: id,
      };
    }),
  };

  await axios
    .post(`/properties/tags/delete`, requestData)
    .then(() => {
      dispatch(actions.refresh());
    })
    .catch(() => {});

  dispatch(actions.setIsSubmitting(false));
};

/**
 * 建物のタグを編集する
 * @param propertyId 建物ID
 * @param tagIds 新しく設定する複数のタグID
 */
export const updatePropertyTags =
  (propertyId: number, tagId: number[]) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    type UpdatePropertyTagsParams = {
      tags: { tag_id: number }[];
    };
    const requestData: UpdatePropertyTagsParams = {
      tags: tagId.map((id) => {
        return {
          tag_id: id,
        };
      }),
    };

    await axios
      .post(`/properties/${propertyId}/tags`, requestData)
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch((e) => {
        if (e.response.status === 400) {
          alert('存在しないタグを設定しています。');
        }
      });

    dispatch(actions.setIsSubmitting(false));
  };

/**
 * 建物をダウンロードするための返却値
 */
type DownloadPropertiesReturn = {
  /** ファイル名 */
  fileName: string;
  /** ファイルデータ */
  fileContent: string;
} | null;
/**
 * @summary 建物をダウンロードする
 * @param checkedIds 選択した建物ID
 * @param isAll すべてダウンロードのフラグ
 * @param params 検索条件
 * @returns {DownloadPropertiesReturn}
 */
export const downloadProperties = async (
  checkedIds: string[],
  isAll: boolean,
  params: APIGetPropertiesRequestParamsWithoutPage,
): Promise<DownloadPropertiesReturn> => {
  const reqParams: APIDownloadPropertiesFormatRequestParams = {
    ...params,
    id: isAll ? '' : checkedIds.join(','),
    is_all: isAll,
  };

  type APIDownloadPropertiesResponse = {
    result: {
      file_name: string;
      file_content: string;
    };
  };
  const result = await axios
    .get<APIDownloadPropertiesResponse>(`/properties/download`, {
      params: reqParams,
    })
    .then((response) => {
      return {
        fileName: response.data.result.file_name,
        fileContent: response.data.result.file_content,
      };
    })
    .catch(() => {
      return null;
    });

  return result;
};

/**
 * 建物一括登録用のテンプレートをダウンロードするための返却値
 */
type DownloadPropertiesFormatForAddByCsvReturn = {
  /** ファイル名 */
  fileName: string;
  /** ファイルデータ */
  fileContent: string;
} | null;
/**
 * @summary 建物一括登録用のテンプレートをダウンロードする
 * @returns {DownloadPropertiesFormatForAddByCsvReturn}
 */
export const downloadPropertiesFormatForAddByCsv =
  async (): Promise<DownloadPropertiesFormatForAddByCsvReturn> => {
    type APIDownloadPropertiesFormatForAddByCsvResponse = {
      result: {
        file_name: string;
        file_content: string;
      };
    };
    const result = await axios
      .get<APIDownloadPropertiesFormatForAddByCsvResponse>(`/properties/download/format`)
      .then((response) => {
        return {
          fileName: response.data.result.file_name,
          fileContent: response.data.result.file_content,
        };
      })
      .catch(() => {
        return null;
      });

    return result;
  };

/**
 * 建物一括登録処理
 * @param formData 建物一括登録用のファイル
 * @returns {boolean} アップロードされたか否かのフラグ
 */
export const uploadCsvForAddProperties =
  (formData: FormData): ((dispatch: any) => Promise<boolean>) =>
  async (dispatch: any): Promise<boolean> => {
    dispatch(commonActions.setIsProcessing(true));
    dispatch(actions.setIsSubmitting(true));

    /**
     * csvアップロードの処理後の返却値
     */
    type APIPatchUploadFilesResponse = {
      /** メッセージ */
      message: string;
      /** 結果 */
      result: {
        created_num: number;
      };
    };

    const isUploaded = await axios
      .post<APIPatchUploadFilesResponse>(`/properties/bulk_create`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      })
      .then((response) => {
        if (response.status === 201) {
          dispatch(actions.refresh());
          return true;
        }
        return false;
      })
      .catch(() => {
        return false;
      });

    dispatch(commonActions.setIsProcessing(false));
    dispatch(actions.setIsSubmitting(false));

    return isUploaded;
  };

/**
 * @summary 連絡事項データを取得
 * @param propertyId 建物ID
 */
export const getPropertyInformation = (propertyId: number) => async (dispatch: any) => {
  dispatch(actions.setIsLoading2(true));

  await axios
    .get<APIGetInformationResponse>(`/properties/${propertyId}/information`)
    .then((response) => {
      const data = response.data.result;
      const information: Information = {
        propertyId: data.property_id,
        propertyName: data.property_name,
        comment: data.comment,
        fileList: data.file_list ?? [],
      };
      dispatch(actions.setInformation(information));
    })
    .catch(() => {
      dispatch(actions.setInformation(null));
    });

  dispatch(actions.setIsLoading2(false));
};

/**
 * @summary 連絡事項データを取得（ゲストユーザー）
 * @param propertyId 建物ID
 */
export const getPropertyInformationGuest =
  (propertyId: number, token: string) => async (dispatch: any) => {
    dispatch(actions.setIsLoading2(true));
    const reqParams: APIGetPropertyGuestRequest = { token };

    await axios_
      .get<APIGetInformationResponse>(`/api/guest/properties/${propertyId}/information`, {
        params: reqParams,
      })
      .then((response) => {
        const data = response.data.result;
        const information: Information = {
          propertyId: data.property_id,
          propertyName: data.property_name,
          comment: data.comment,
          fileList: data.file_list ?? [],
        };
        dispatch(actions.setInformation(information));
      })
      .catch(() => {
        dispatch(actions.setInformation(null));
      });

    dispatch(actions.setIsLoading2(false));
  };

/**
 * @summary 連絡事項のフォイル追加
 * @param formData 追加するファイル
 * @returns {boolean} 追加されたか否かのフラグ
 */
export const uploadFileForPropertyInformation =
  (propertyId: number, formData: FormData): ((dispatch: any) => Promise<boolean>) =>
  async (dispatch: any): Promise<boolean> => {
    dispatch(actions.setIsSubmitting(true));

    const isUploaded = await axios
      .post(`/properties/${propertyId}/information/files`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      })
      .then((response) => {
        if (response.status === 201) {
          dispatch(actions.refresh());
          return true;
        }
        return false;
      })
      .catch(() => {
        return false;
      });

    dispatch(actions.setIsSubmitting(false));

    return isUploaded;
  };

/**
 * @summary 連絡事項のファイル名変更
 * @param propertyId 建物ID
 * @param fileId 対象のファイルID
 * @param fileName 対象のファイルの新しい名前
 * @returns {Promise<boolean>} 更新の成否
 */
export const renamePropertyInformationFile =
  (propertyId: number, fileId: number, fileName: string): ((dispatch: any) => Promise<boolean>) =>
  async (dispatch: any): Promise<boolean> => {
    dispatch(actions.setIsSubmitting(true));

    type APIPutRenamePropertyInformationFileNameParam = {
      file_name: string;
    };
    const param: APIPutRenamePropertyInformationFileNameParam = {
      file_name: fileName,
    };
    const isUpdated = await axios
      .put(`/properties/${propertyId}/information/files/${fileId}`, param)
      .then((response) => {
        if (response.status === 200) {
          dispatch(actions.refresh());
          return true;
        }
        return false;
      })
      .catch(() => {
        return false;
      });

    dispatch(actions.setIsSubmitting(false));

    return isUpdated;
  };

/**
 * @summary 連絡事項のコメントを変更
 * @param propertyId 建物ID
 * @param comment 新しいコメント
 * @returns {Promise<boolean>} 更新の成否
 */
export const updatePropertyInformationComment =
  (propertyId: number, comment: string): ((dispatch: any) => Promise<boolean>) =>
  async (dispatch: any): Promise<boolean> => {
    dispatch(actions.setIsSubmitting(true));

    type APIPutUpdatePropertyInformationCommentParam = {
      comment: string;
    };
    const param: APIPutUpdatePropertyInformationCommentParam = {
      comment: comment,
    };
    const isUpdated = await axios
      .put(`/properties/${propertyId}/information/comment`, param)
      .then((response) => {
        if (response.status === 200) {
          dispatch(actions.refresh());
          return true;
        }
        return false;
      })
      .catch(() => {
        return false;
      });

    dispatch(actions.setIsSubmitting(false));

    return isUpdated;
  };

/**
 * @summary 連絡事項のファイルを削除
 * @param propertyId 建物ID
 * @param fileId 対象のファイルID
 * @returns {Promise<boolean>} 更新の成否
 */
export const deletePropertyInformationFile =
  (propertyId: number, fileId: number): ((dispatch: any) => Promise<boolean>) =>
  async (dispatch: any): Promise<boolean> => {
    dispatch(actions.setIsSubmitting(true));

    const isDeleted = await axios
      .delete(`/properties/${propertyId}/information/files/${fileId}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch(actions.refresh());
          return true;
        }
        return false;
      })
      .catch(() => {
        return false;
      });

    dispatch(actions.setIsSubmitting(false));

    return isDeleted;
  };

/**
 * @summary 建物の契約一覧を取得する
 * @param propertyId 建物ID
 */
export const getPropertyContracts =
  (propertyId: number, params: APIGetPropertyContractsRequestParams) => async (dispatch: any) => {
    dispatch(actions.setIsLoading2(true));

    const reqParams: APIGetPropertyContractsRequestParams = {
      ...params,
      size_per_page: LIST_SIZE_PER_PAGE,
    };
    await axios
      .get<APIGetContractsResponse>(`/properties/${propertyId}/contracts`, {
        params: reqParams,
      })
      .then((response) => {
        const { contract_list: list, display_params: info } = response.data.result;

        const contracts: ContractsList[] = list.map(
          (property): ContractsList => ({
            propertyId: property.property_id,
            propertyName: property.property_name,
            contractId: property.contract_id,
            title: property.title,
            contractTypeId: property.contract_type_id,
            contractTypeName: property.contract_type_name,
            inspectionTypeId: property.inspection_type_id,
            inspectionTypeName: property.inspection_type_name,
            inspectionDivisionId: property.inspection_division_id,
            inspectionDivisionName: property.inspection_division_name,
            repeatTypeId: property.repeat_type_id,
            repeatTypeName: property.repeat_type_name,
            startDate: property.start_date,
            endDate: property.end_date,
            companyId: property.company_id,
            companyName: property.company_name,
            invoiceUnitPrice: property.invoice_unit_price,
            purchasesUnitPrice: property.purchases_unit_price,
            statusId: property.status_id,
            statusName: property.status_name,
            inspectionChecklistId: property.inspection_checklist_id,
            formatFileUrl: property.format_file_url,
          }),
        );

        const listInfo: ContractsListInfo = {
          totalHits: info.total,
          totalPages: info.total_page,
        };

        dispatch(actions.setContracts(contracts));
        dispatch(actions.setContractsListInfo(listInfo));
      })
      .catch(() => {
        dispatch(actions.setContracts([]));
        dispatch(actions.setContractsListInfo(null));
      });

    dispatch(actions.setIsLoading2(false));
  };

/**
 * 建物の契約一括登録用のテンプレートをダウンロードするための返却値
 */
type DownloadContractsFormatForAddByCsvReturn = {
  /** ファイル名 */
  fileName: string;
  /** ファイルデータ */
  fileContent: string;
} | null;
/**
 * @summary 建物の契約一括登録用のテンプレートをダウンロードする
 * @param propertyId 建物ID
 * @returns {DownloadContractsFormatForAddByCsvReturn}
 */
export const downloadContractsFormatForAddByCsv = async (
  propertyId: number,
): Promise<DownloadContractsFormatForAddByCsvReturn> => {
  type APIDownloadContractsFormatForAddByCsvResponse = {
    result: {
      file_name: string;
      file_content: string;
    };
  };
  const result = await axios
    .get<APIDownloadContractsFormatForAddByCsvResponse>(
      `/properties/${propertyId}/contracts/download/format
      `,
    )
    .then((response) => {
      return {
        fileName: response.data.result.file_name,
        fileContent: response.data.result.file_content,
      };
    })
    .catch(() => {
      return null;
    });

  return result;
};

/**
 * 建物の契約一括登録処理
 * @param {number} propertyId 建物ID
 * @param {FormData} formData 契約一括登録用のファイル
 * @param {string} confirmText 確認テキスト
 * @param {boolean} isOverwrite 実行フラグ
 * @return {Promise<boolean>} 登録成否
 */
export const uploadCsvForAddContracts =
  (
    propertyId: number,
    formData: FormData,
    confirmText: string,
    isOverwrite = false,
  ): ((dispatch: any) => Promise<boolean>) =>
  async (dispatch: any): Promise<boolean> => {
    dispatch(commonActions.setIsProcessing(true));
    dispatch(actions.setIsSubmitting(true));

    /**
     * csvアップロードの処理後の返却値
     */
    type APIPatchUploadFilesResponse = {
      /** メッセージ */
      message: string;
      /** 結果 */
      result: {
        created_num: number;
      };
    };
    return await axios
      .post<APIPatchUploadFilesResponse>(
        `/properties/${propertyId}/contracts/bulk_create${
          isOverwrite ? `?is_overwrite=${true}` : ''
        }`,
        formData,
        {
          headers: { 'content-type': 'multipart/form-data' },
        },
      )
      .then(async (response) => {
        if (response.status !== 202) {
          dispatch(actions.refresh());
          return true;
        }

        if (!(await confirmDialog(confirmText))) return;
        return dispatch(uploadCsvForAddContracts(propertyId, formData, confirmText, true));
      })
      .catch(() => {
        return false;
      })
      .finally(() => {
        dispatch(commonActions.setIsProcessing(false));
        dispatch(actions.setIsSubmitting(false));
      });
  };

/**
 * 建物の契約をダウンロードするための返却値
 */
type DownloadPropertyContractsReturn = {
  /** ファイル名 */
  fileName: string;
  /** ファイルデータ */
  fileContent: string;
} | null;
/**
 * @summary 建物の契約をダウンロードする
 * @param propertyId 建物ID
 * @param checkedIds 選択した建物ID
 * @param isAll すべてダウンロードのフラグ
 * @param params 検索条件
 * @returns {DownloadPropertyContractsReturn}
 */
export const downloadPropertyContracts = async (
  propertyId: number,
  checkedIds: string[],
  isAll: boolean,
  params: APIGetPropertyContractsRequestParamsWithoutPage,
): Promise<DownloadPropertyContractsReturn> => {
  const reqParams: APIDownloadPropertyContractsRequestParams = {
    ...params,
    id: isAll ? '' : checkedIds.join(','),
    is_all: isAll,
  };

  type APIDownloadPropertyContractsResponse = {
    result: {
      file_name: string;
      file_content: string;
    };
  };
  const result = await axios
    .get<APIDownloadPropertyContractsResponse>(`/properties/${propertyId}/contracts/download`, {
      params: reqParams,
    })
    .then((response) => {
      return {
        fileName: response.data.result.file_name,
        fileContent: response.data.result.file_content,
      };
    })
    .catch(() => {
      return null;
    });

  return result;
};

type SetCompanyToPropertyContractArgs = {
  /** 建物ID */
  propertyId: number;
  /** 選択した契約ID */
  contractIds: number[];
  /** 企業ID */
  affiliateCompanyId: number;
};
/**
 * 契約の協力会社を建物の契約に一括登録する
 * @param {SetCompanyToPropertyContractArgs}
 */
export const setCompanyToPropertyContract =
  ({ propertyId, contractIds, affiliateCompanyId }: SetCompanyToPropertyContractArgs) =>
  async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    type ApiPatchCompanyToPropertyContractRequestData = {
      affiliate_company_id: number;
      contract_list: {
        contract_id: number;
      }[];
    };
    const requestData: ApiPatchCompanyToPropertyContractRequestData = {
      affiliate_company_id: affiliateCompanyId,
      contract_list: contractIds.map((id) => {
        return {
          contract_id: id,
        };
      }),
    };

    await axios
      .patch(`/properties/${propertyId}/contracts/affiliate_companies`, requestData)
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

type SetContractAttributeToPropertyContractArgs = {
  /** 建物ID */
  propertyId: number;
  /** 選択した定期業務の契約ID */
  regularContractCheckedIds: number[];
  /** 開始日 */
  attributeType: PropertyContractAttributeType;
  /** 定期業務種別ID・定期業務区分ID */
  attributeId: InspectionTypes | InspectionDivisionTypes;
};
/**
 * 契約の定期業務の区分・種別を建物の契約に一括登録する
 * @param {SetContractAttributeToPropertyContractArgs}
 */
export const setContractAttributeToPropertyContract =
  ({
    propertyId,
    regularContractCheckedIds,
    attributeType,
    attributeId,
  }: SetContractAttributeToPropertyContractArgs) =>
  async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    type ApiPatchAttributeToPropertyContractRequestData = {
      attribute_type: PropertyContractAttributeType;
      attribute_id: InspectionTypes | InspectionDivisionTypes;
      contract_list: {
        contract_id: number;
      }[];
    };
    const requestData: ApiPatchAttributeToPropertyContractRequestData = {
      attribute_type: attributeType,
      attribute_id: attributeId,
      contract_list: regularContractCheckedIds.map((id) => {
        return {
          contract_id: id,
        };
      }),
    };

    await axios
      .patch(`/properties/${propertyId}/contracts/attributes`, requestData)
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

type SetContractPriceToPropertyContractArgs = {
  /** 建物ID */
  propertyId: number;
  /** 選択した契約ID */
  contractIds: number[];
  /** 状況ID */
  priceType: PropertyContractPriceType;
  /** 単価 */
  unitPrice: number;
};
/**
 * 契約の単価を建物の契約に一括登録する
 * @param {SetContractPriceToPropertyContractArgs}
 */
export const setContractPriceToPropertyContract =
  ({ propertyId, contractIds, priceType, unitPrice }: SetContractPriceToPropertyContractArgs) =>
  async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    type ApiPatchPriceToPropertyContractRequestData = {
      price_type: PropertyContractPriceType;
      unit_price: number;
      contract_list: {
        contract_id: number;
      }[];
    };
    const requestData: ApiPatchPriceToPropertyContractRequestData = {
      price_type: priceType,
      unit_price: unitPrice,
      contract_list: contractIds.map((id) => {
        return {
          contract_id: id,
        };
      }),
    };

    await axios
      .patch(`/properties/${propertyId}/contracts/price`, requestData)
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

type SetContractStatusToPropertyContractArgs = {
  /** 建物ID */
  propertyId: number;
  /** 選択した契約ID */
  contractIds: number[];
  /** 状況ID */
  statusId: ContractStatusTypes;
};
/**
 * 契約の状況を建物の契約に一括登録する
 * @param {SetContractStatusToPropertyContractArgs}
 */
export const setContractStatusToPropertyContract =
  ({ propertyId, contractIds, statusId }: SetContractStatusToPropertyContractArgs) =>
  async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    type ApiPatchStatusToPropertyContractRequestData = {
      status_id: ContractStatusTypes;
      contract_list: {
        contract_id: number;
      }[];
    };
    const requestData: ApiPatchStatusToPropertyContractRequestData = {
      status_id: statusId,
      contract_list: contractIds.map((id) => {
        return {
          contract_id: id,
        };
      }),
    };

    await axios
      .patch(`/properties/${propertyId}/contracts/status`, requestData)
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

type SetContractTermsToPropertyContractArgs = {
  /** 建物ID */
  propertyId: number;
  /** 選択した契約ID */
  contractIds: number[];
  /** 開始日 */
  startDate: string;
  /** 終了日 */
  endDate: string;
  /** 上書きフラグ */
  isOverwrite?: boolean;
};
/**
 * 契約の期間を建物の契約に一括登録する
 * @param {SetContractTermsToPropertyContractArgs}
 */
export const setContractTermsToPropertyContract =
  ({
    propertyId,
    contractIds,
    startDate,
    endDate,
    isOverwrite,
  }: SetContractTermsToPropertyContractArgs) =>
  async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    type ApiPatchTermsToPropertyContractRequestData = {
      start_date: string;
      end_date: string | null;
      contract_list: {
        contract_id: number;
      }[];
    };
    const requestData: ApiPatchTermsToPropertyContractRequestData = {
      start_date: startDate,
      end_date: endDate ? endDate : null,
      contract_list: contractIds.map((id) => {
        return {
          contract_id: id,
        };
      }),
    };

    type ApiPatchTermsToPropertyContractRequestParams = {
      is_overwrite?: boolean;
    };
    const params: ApiPatchTermsToPropertyContractRequestParams = {};
    if (isOverwrite) {
      params.is_overwrite = isOverwrite;
    }

    await axios
      .patch(`/properties/${propertyId}/contracts/terms`, requestData, { params })
      .then((response) => {
        if (response.status === 202) {
          // 確認モーダル表示
          dispatch(actions.setIsNeedConfirm(true));
        } else if (response.status === 200) {
          dispatch(actions.setIsNeedConfirm(false));
          dispatch(actions.refresh());
        }
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

type SetContractRepeatToPropertyContractArgs = {
  /** 建物ID */
  propertyId: number;
  /** 選択した契約ID */
  contractIds: number[];
  /** 契約に繰り返し設定の登録時の周期ID */
  repeatTypeId: PropertyContractRepeatType;
  /** 契約に繰り返し設定の登録時のデータ */
  customCycles: PropertyContractRepeatParamCustomCycleType | null;
  /** 上書きフラグ */
  isOverwrite?: boolean;
};
/**
 * 契約の繰り返し設定を建物の契約に一括登録する
 * @param {SetContractRepeatToPropertyContractArgs}
 */
export const setContractRepeatToPropertyContract =
  ({
    propertyId,
    contractIds,
    repeatTypeId,
    customCycles,
    isOverwrite,
  }: SetContractRepeatToPropertyContractArgs) =>
  async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    type ApiPatchRepeatToPropertyContractRequestData = {
      repeat_type_id: PropertyContractRepeatType;
      custom_cycles?: PropertyContractRepeatParamCustomCycleType;
      contract_list: {
        contract_id: number;
      }[];
    };
    const requestData: ApiPatchRepeatToPropertyContractRequestData = {
      repeat_type_id: repeatTypeId,
      contract_list: contractIds.map((id) => {
        return {
          contract_id: id,
        };
      }),
    };
    // カスタムの設定の場合
    if (repeatTypeId === 6 && customCycles) {
      requestData.custom_cycles = customCycles;
    }

    type ApiPatchRepeatToPropertyContractRequestParams = {
      is_overwrite?: boolean;
    };
    const params: ApiPatchRepeatToPropertyContractRequestParams = {};
    if (isOverwrite) {
      params.is_overwrite = isOverwrite;
    }

    await axios
      .patch(`/properties/${propertyId}/contracts/repeat`, requestData, { params })
      .then((response) => {
        if (response.status === 202) {
          // 確認モーダル表示
          dispatch(actions.setIsNeedConfirm(true));
        } else if (response.status === 200) {
          dispatch(actions.setIsNeedConfirm(false));
          dispatch(actions.refresh());
        }
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

/**
 * @summary 契約データを取得
 * @param propertyId 建物ID
 * @param contractId 契約ID
 */
export const getPropertyContractData = async (propertyId: number, contractId: number) => {
  return await axios
    .get<APIGetContractResponse>(`/properties/${propertyId}/contracts/${contractId}`)
    .then((response) => {
      const data: APIContract = response.data.result;
      const contract: Contract = {
        propertyId: data.property_id,
        propertyName: data.property_name,
        contractId: data.contract_id,
        title: data.title,
        updateMonth: data.update_month,
        hasNotifyPrior: data.has_notify_prior,
        priorNotificationMonths: data.prior_notification_months,
        contractTypeId: data.contract_type_id,
        contractTypeName: data.contract_type_name,
        inspectionTypeId: data.inspection_type_id,
        inspectionTypeName: data.inspection_type_name,
        inspectionDivisionId: data.inspection_division_id,
        inspectionDivisionName: data.inspection_division_name,
        isRepeat: data.is_repeat,
        repeatTypeId: data.repeat_type_id,
        repeatTypeName: data.repeat_type_name,
        customCycles: data.custom_cycles,
        startDate: data.start_date,
        endTypeId: data.end_type_id,
        endDate: data.end_date,
        repeatCount: data.repeat_count,
        companyId: data.company_id,
        companyName: data.company_name,
        invoiceUnitPrice: data.invoice_unit_price,
        purchasesUnitPrice: data.purchases_unit_price,
        inspectionChecklistId: data.inspection_checklist_id,
        inspectionChecklistTitle: data.inspection_checklist_title,
        publishStatusId: data.publish_status_id,
        formatFileName: data.format_file_name,
        formatFileUrl: data.format_file_url,
        statusId: data.status_id,
        statusName: data.status_name,
        remarks: data.remarks,
      };
      return contract;
    })
    .catch(() => {
      return null;
    });
};

/**
 * @summary 取得した契約データをreduxに保存
 * @param propertyId 建物ID
 * @param contractId 契約ID
 */
export const getPropertyContract =
  (propertyId: number, contractId: number) => async (dispatch: any) => {
    dispatch(actions.setIsLoading2(true));

    const contract = await getPropertyContractData(propertyId, contractId);
    if (contract) {
      dispatch(actions.setContract(contract));
    } else {
      dispatch(actions.setContract(null));
    }

    dispatch(actions.setIsLoading2(false));
  };

export const createPropertyContract =
  (propertyId: number, requestData: APIPostCreateContract, closeForm?: () => void) =>
  async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .post(`/properties/${propertyId}/contracts`, requestData)
      .then(() => {
        dispatch(actions.refresh());
        if (closeForm) closeForm();
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };

export const editPropertyContract =
  (
    propertyId: number,
    contractId: number,
    requestData: APIPutEditContract,
    confirmText: string,
    closeForm?: () => void,
    isOverwrite = false,
  ) =>
  async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .put(
        `/properties/${propertyId}/contracts/${contractId}${
          isOverwrite ? '?is_overwrite=true' : ''
        }`,
        requestData,
      )
      .then(async (response) => {
        if (response.status !== 202) {
          if (closeForm) closeForm();
          dispatch(actions.refresh());
          return;
        }

        if (!(await confirmDialog(confirmText))) return;
        return dispatch(
          editPropertyContract(propertyId, contractId, requestData, confirmText, closeForm, true),
        );
      })
      .catch(() => {});

    dispatch(actions.setIsSubmitting(false));
  };
