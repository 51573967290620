import {
  Equipment,
  EquipmentAction as Action,
  EquipmentActionTypes as ActionTypes,
  EquipmentHistory,
  EquipmentsList,
  EquipmentsListInfo,
  EquipmentSqsesData,
  OptionsForEdit,
} from './types';

export const init = (): Action => ({ type: ActionTypes.INIT });

export const refresh = (): Action => ({ type: ActionTypes.REFRESH });

export const setIsLoading = (isLoading: boolean): Action => ({
  type: ActionTypes.LOADING,
  payload: isLoading,
});

export const setIsLoading2 = (isLoading: boolean): Action => ({
  type: ActionTypes.LOADING2,
  payload: isLoading,
});

export const setIsSubmitting = (isSubmitting: boolean): Action => ({
  type: ActionTypes.SUBMITTING,
  payload: isSubmitting,
});

export const setIsNavigating = (isNavigating: boolean): Action => ({
  type: ActionTypes.NAVIGATING,
  payload: isNavigating,
});

export const setIsNavigatingTo = (isNavigatingTo: number | null): Action => ({
  type: ActionTypes.NAVIGATING_TO,
  payload: isNavigatingTo,
});

export const setEquipments = (equipments: EquipmentsList[]): Action => ({
  type: ActionTypes.SET_EQUIPMENTS,
  payload: equipments,
});

export const setEquipmentsListInfo = (info: EquipmentsListInfo | null): Action => ({
  type: ActionTypes.SET_EQUIPMENTS_LIST_INFO,
  payload: info,
});

export const setEquipment = (equipment: Equipment | null): Action => ({
  type: ActionTypes.SET_EQUIPMENT,
  payload: equipment,
});

export const setEquipmentHistory = (equipmentHistory: EquipmentHistory[]): Action => ({
  type: ActionTypes.SET_EQUIPMENT_HISTORY,
  payload: equipmentHistory,
});

export const setEquipmentSqsesData = (equipmentSqsesData: EquipmentSqsesData[]): Action => ({
  type: ActionTypes.SET_EQUIPMENT_SQSES_DATA,
  payload: equipmentSqsesData,
});

export const setOptionsForEdit = (data: OptionsForEdit | null): Action => ({
  type: ActionTypes.SET_OPTIONS_FOR_EDIT,
  payload: data,
});
