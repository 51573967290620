import reducer from './reducers';

import * as affiliateSelectors from './selectors';
import * as affiliateActions from './actions';
import * as affiliateOperations from './operations';
import {
  AffiliateState,
  AffiliateType,
  AffiliateEditMode,
  SearchParameter,
  AffiliateUserFormData,
  AffiliateCompanyFormData,
} from './types';

export { affiliateSelectors, affiliateActions, affiliateOperations };
export type {
  AffiliateState,
  AffiliateType,
  AffiliateEditMode,
  SearchParameter,
  AffiliateUserFormData,
  AffiliateCompanyFormData,
};
export default reducer;
