import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { OptionItem } from '@uiParts/selectBox';

/**
 * @summary 設備に設定されるファイルの種類
 * @description 1: ローカル添付ファイル、2: 書庫リンク、3: URL手入力
 */
export type EquipmentDocumentTypeId = 1 | 2 | 3;

export type EquipmentState = {
  refresh: Record<string, never>;
  isLoading: boolean;
  isLoading2: boolean; // sub-loading
  isSubmitting: boolean;
  isNavigating: boolean;
  isNavigatingTo: number | null;
  equipments: EquipmentsList[];
  equipmentsListInfo: EquipmentsListInfo | null;
  equipment: Equipment | null;
  equipmentHistory: EquipmentHistory[];
  equipmentHistoryLoaded: boolean;
  equipmentSqsesData: EquipmentSqsesData[];
  equipmentSqsesDataLoaded: boolean;
  optionsForEdit: OptionsForEdit | null;
};

export enum EquipmentActionTypes {
  INIT = 'equipment/init',
  REFRESH = 'equipment/refresh',
  LOADING = 'equipment/set/isLoading',
  LOADING2 = 'equipment/set/isLoading2',
  SUBMITTING = 'equipment/set/isSubmitting',
  NAVIGATING = 'equipment/set/isNavigating',
  NAVIGATING_TO = 'equipment/set/isNavigatingTo',
  SET_EQUIPMENTS = 'equipment/set/equipments',
  SET_EQUIPMENTS_LIST_INFO = 'equipment/set/equipmentsListInfo',
  SET_EQUIPMENT = 'equipment/set/equipment',
  SET_EQUIPMENT_HISTORY = 'equipment/set/equipmentHistory',
  SET_EQUIPMENT_SQSES_DATA = 'equipment/set/equipmentSqsesData',
  SET_OPTIONS_FOR_EDIT = 'equipment/set/optionsForEdit',
}

export type EquipmentAction =
  | Action<EquipmentActionTypes.INIT>
  | Action<EquipmentActionTypes.REFRESH>
  | PayloadAction<boolean, EquipmentActionTypes.LOADING>
  | PayloadAction<boolean, EquipmentActionTypes.LOADING2>
  | PayloadAction<boolean, EquipmentActionTypes.SUBMITTING>
  | PayloadAction<boolean, EquipmentActionTypes.NAVIGATING>
  | PayloadAction<number | null, EquipmentActionTypes.NAVIGATING_TO>
  | PayloadAction<EquipmentsList[], EquipmentActionTypes.SET_EQUIPMENTS>
  | PayloadAction<EquipmentsListInfo | null, EquipmentActionTypes.SET_EQUIPMENTS_LIST_INFO>
  | PayloadAction<Equipment | null, EquipmentActionTypes.SET_EQUIPMENT>
  | PayloadAction<EquipmentHistory[], EquipmentActionTypes.SET_EQUIPMENT_HISTORY>
  | PayloadAction<EquipmentSqsesData[], EquipmentActionTypes.SET_EQUIPMENT_SQSES_DATA>
  | PayloadAction<OptionsForEdit | null, EquipmentActionTypes.SET_OPTIONS_FOR_EDIT>;

// -- State Types ---------------------------------------------------------- //

export type Equipment = {
  propertyId: number;
  propertyName: string;
  diagramId: number | null;
  diagramName: string;
  equipmentId: number;
  equipmentName: string;
  floorId: number | null;
  floorName: string;
  roomId: number | null;
  roomName: string;
  equipmentTypeId: number | null;
  equipmentTypeName: string;
  modelNumber: string;
  serialNumber: string;
  manufacturer: string;
  manufactureDate: string;
  installDate: string;
  diagramSymbol: string;
  remarks: string;
  imageId: number | null;
  imageUrl: string;
  model: EquipmentModel | null;
  isGpf: boolean;
  gpfData: EquipmentGpfData | null;
  isKirei: boolean;
  kireiData: EquipmentKireiWatchData | null;
  isSqses: boolean;

  usefulYear: number; // 耐用年数
  elapsedYear: number; // 経過年数
  previousEquipmentId: number | null; //全機種id
  previousEquipmentName: string; // 全機種name
  successorEquipmentId: number | null; // 後継種設備id
  successorEquipmentName: string; //// 後継種設備name
  isUnused: boolean; //未使用
  documents: DocumentFormType[];

  /** 外観写真リスト */
  imageList: EquipmentImage[];
};

export type EquipmentImage = {
  /** ファイルID */
  file_id?: number;
  /** ファイル名 */
  file_name: string | null;
  /** 画像をBase64エンコードした文字列 */
  file_content?: string | null;
  /** ファイルサイズ */
  file_size: number | null;
  /** 登録した画像のURL */
  file_url: string | null;
  /** 登録した画像(サムネイル)のURL */
  thumbnail_file_url: string | null;
  /** 登録した画像のメモ */
  memo: string;
  /** 削除フラグ */
  is_delete_file?: boolean;
};

/** リンク追加編集の form data */
export type EquipmentDocumentFormData = {
  title: string;
  url: string;
};

export type EquipmentModel = {
  mstEquipmentStdId: number;
  modelNumber: string;
  modelName: string;
  equipmentTypeName: string;
  manufacturer: string;
  releaseDate: string;
  manualUrl: string;
  detail: EquipmentModelDetail[];
};

export type EquipmentModelDetail = {
  stdName: string;
  stdValue: string;
};

export type EquipmentGpfData = {
  onOff: boolean;
  operatingMode: string | null;
  setPoint: number | null;
  fanSpeed: string | null;
  equipmentError: boolean;
  equipmentErrorCode: string | null;
  equipmentErrorLevel: string | null;
};

export type EquipmentKireiWatchData = {
  image: string | null;
  dirtRate: number | null;
  timestamp: number | null;
};

export type EquipmentHistory = {
  troubleId: number;
  title: string;
  priorityId: number;
  priorityName: string;
  troubleType: number;
  troubleTypeName: string;
  statusId: number;
  statusName: string;
  diagramId: number | null;
  diagramName: string;
  equipmentId: number | null;
  equipmentName: string;
  floorId: number | null;
  floorName: string;
  roomId: number | null;
  roomName: string;
  occurrenceDate: string;
  occurrenceTime: string;
  description: string;
  picUserId: number;
  picUserName: string;
  isDeletedUser: boolean;
  updateDatetime: string;
};

export type EquipmentSqsesData = {
  sqsesId: number;
  drno: string | null;
  occurrenceDate: string;
  keitomei: string | null;
  modelNumber: string;
  serialNumber: string | null;
  sqsesFlag: string;
  kashoDetail: string | null;
  jokyoDetail: string | null;
  ijocd: string | null;
  sgnaiyo: string | null;
};

/**
 * @summary 設備一覧の詳細検索パラメータ
 */
export type SearchParameter = {
  /** 設備名 */
  name?: string;
  /** 型番 */
  model?: string;
  /** 製造番号 */
  serial?: string;
  /** 系統ID */
  diagram?: number;
  /** 機器種別ID */
  equipment_type?: number;
  /** 耐用年数(最小) */
  useful_year_min?: number;
  /** 耐用年数(最大) */
  useful_year_max?: number;
  /** 経過年数(最小) */
  elapsed_year_min?: number;
  /** 経過年数(最大) */
  elapsed_year_max?: number;
  /** 異常があるか */
  is_abnormal?: boolean;
  /** 点検中か */
  in_inspection?: boolean;
  /** 案件対応中か */
  in_trouble?: boolean;
};

export type EquipmentsList = Pick<
  Equipment,
  | 'propertyId'
  | 'propertyName'
  | 'equipmentId'
  | 'equipmentName'
  | 'equipmentTypeId'
  | 'equipmentTypeName'
  | 'modelNumber'
  // | 'serialNumber'
  | 'manufacturer'
  | 'manufactureDate'
  | 'floorId'
  | 'floorName'
  | 'diagramId'
  | 'diagramName'
  | 'roomName'
  | 'isUnused'
  // | 'installDate'
> & {
  installYearMonth: string | null;
  usefulYear: number;
  elapsedYear: number;
  inInspection: boolean;
  inTrouble: boolean;
  isAbnormal: boolean;
};

/**
 * 詳細検索の検索句制御の返却値
 */
export type UseAdvancedSearchReturn = {
  name: string;
  handleNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  model: string;
  handleModelChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  floor: string;
  handleFloorChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  room: string;
  handleRoomChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  serial: string;
  handleSerialChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  diagram: string;
  handleDiagramChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  equipmentType: number | null;
  handleEquipmentTypeChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  propertyId: number | null;
  handlePropertyIdChange: (e: React.ChangeEvent<HTMLInputElement>) => void;

  usefulYearMin: number | null;
  handleUsefulYearMinChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  usefulYearMax: number | null;
  handleUsefulYearMaxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  elapsedYearMin: number | null;
  handleElapsedYearMinChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  elapsedYearMax: number | null;
  handleElapsedYearMaxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;

  isAbnormal: boolean;
  handleIsAbnormalChange: () => void;
  inInspection: boolean;
  handleInInspectionChange: () => void;
  inTrouble: boolean;
  handleInTroubleChange: () => void;

  handleSearch: (handleClose: () => void) => void;
  handleClear: () => void;

  mstEquipmentOptions: OptionItem[];
  diagramOptions: OptionItem[];
};

export type EquipmentsListInfo = {
  totalHits: number;
  totalPages: number;
};

export type OptionsForEdit = {
  equipmentTypeOptions: OptionItem[];
  diagramOptions: OptionItem[];
  floorOptions: (OptionItem & { roomOptions: OptionItem[] })[];
};

export type DocumentFormType = {
  document_id?: number | null;
  document_type_id: EquipmentDocumentTypeId;
  file_name?: string | null;
  file_content?: string | null;
  archive_id?: number;
  title?: string;
  url?: string;
  is_delete_file?: boolean;
  // ファイルサイズ合計チェック用に追加(ローカルファイル時のみ)
  file_size?: number | null;
};

export type EquipmentFormData = {
  equipmentName: string;
  modelNumber: string;
  serialNumber: string;
  equipmentTypeId: number | null;
  manufacturer: string;
  diagramId: number | null;
  diagramSymbol: string;
  installDate: string;
  manufactureDate: string;
  remarks: string;
  usefulYear: number | null;
  previousEquipmentId: number | null;
  successorEquipmentId: number | null;
  isUnused: boolean;
  documents: DocumentFormType[];
};

export type EquipmentDiagramFormData = {
  name: string;
  description: string;
};

export type EquipmentSubmitData = {
  floorId: number | null;
  roomId: number | null;
  fileName: string;
  fileContent: string;
  equipmentName: string;
  modelNumber: string;
  serialNumber: string;
  equipmentTypeId: number | null;
  manufacturer: string;
  installDate: string;
  manufactureDate: string;
  remarks: string;
  diagramId: number | null;
  createNewDiagramFlag: boolean;
  diagramName: string;
  diagramDescription: string;
  diagramSymbol: string;
  usefulYear: number | null;
  documents: DocumentFormType[];
  previousEquipmentId: number | null;
  successorEquipmentId: number | null;
  isUnused: boolean;
  imageList: ImageListType[];
};

export type ImageListType = {
  file_name: string;
  file_content: string;
  memo: string;
  is_delete_file?: boolean;
};

// -- API Types ------------------------------------------------------------ //

export type APIDisplayParam = {
  readonly total_page: number;
  readonly total: number;
};

export type APIEquipments = {
  readonly property_id: number;
  readonly property_name: string;
  readonly diagram_id: number | null;
  readonly diagram_name: string;
  readonly equipment_id: number;
  readonly equipment_name: string;
  readonly floor_id: number | null;
  readonly floor_name: string;
  readonly room_id: number | null;
  readonly room_name: string;
  readonly equipment_type_id: number | null;
  readonly equipment_type_name: string;
  readonly model_number: string;
  readonly serial_number: string;
  readonly manufacturer: string;
  readonly manufacture_date: string;
  readonly install_date: string | null;
  readonly useful_year: number;
  readonly elapsed_year: number;
  readonly install_year_month: string | null;
  readonly is_unused: boolean;
  readonly in_inspection: boolean;
  readonly in_trouble: boolean;
  readonly is_abnormal: boolean;
};

export type APIEquipment = APIEquipments & {
  readonly diagram_symbol: string;
  readonly equipment_type_id: number;
  readonly remarks: string;
  readonly is_gpf: boolean;
  readonly gpf_data: APIEquipmentGpfData | null;
  readonly is_kirei: boolean;
  readonly kirei_data: APIEquipmentKireiWatchData | null;
  readonly is_sqses: boolean;
  readonly file_id: number | null;
  readonly file_url: string | null;
  readonly useful_year_elapsed_rate: number;
  readonly previous_equipment_id: number | null;
  readonly previous_equipment_name: string;
  readonly successor_equipment_id: number | null;
  readonly successor_equipment_name: string;
  readonly documents: DocumentFormType[];
  /** 外観写真リスト */
  readonly image_list: {
    /** ファイルID */
    file_id: number;
    /** ファイル名 */
    file_name: string | null;
    /** ファイルサイズ */
    file_size: number | null;
    /** 登録した画像のURL */
    file_url: string | null;
    /** 登録した画像(サムネイル)のURL */
    thumbnail_file_url: string | null;
    /** 登録した画像のメモ */
    memo: string;
  }[];
};

export type APIEquipmentModel = {
  readonly mst_equipment_std_id: number;
  readonly model_number: string;
  readonly model_name: string;
  readonly equipment_type_name: string;
  readonly manufacturer: string;
  readonly release_date: string | null;
  readonly manual_url: string;
  readonly detail: APIEquipmentModelDetail[];
};

export type APIEquipmentModelDetail = {
  readonly std_name: string;
  readonly std_value: string;
};

export type APIEquipmentGpfData = {
  readonly on_off: boolean;
  readonly operating_mode_name: string | null;
  readonly set_point: number | null;
  readonly fan_speed_name: string | null;
  readonly equipment_error: boolean;
  readonly equipment_error_code: string | null;
  readonly equipment_error_level_name: string | null;
};

export type APIEquipmentKireiWatchData = {
  readonly image: string | null;
  readonly dirt_rate: number | null;
  readonly timestamp: number | null;
};

export type APIEquipmentHistory = {
  readonly trouble_id: number;
  readonly title: string;
  readonly priority_id: number;
  readonly priority_name: string;
  readonly trouble_type: number;
  readonly trouble_type_name: string;
  readonly status_id: number;
  readonly status_name: string;
  readonly diagram_id: number | null;
  readonly diagram_name: string;
  readonly equipment_id: number | null;
  readonly equipment_name: string;
  readonly floor_id: number | null;
  readonly floor_name: string;
  readonly room_id: number | null;
  readonly room_name: string;
  readonly occurrence_date: string;
  readonly occurence_time: string;
  readonly description: string;
  readonly pic_user_id: number;
  readonly pic_user_name: string;
  readonly is_deleted_user: boolean;
  readonly update_datetime: string;
};

export type APIEquipmentSqsesData = {
  readonly sqses_id: number;
  readonly drno: string | null;
  readonly occurrence_date: string | null;
  readonly keitomei: string | null;
  readonly model_number: string;
  readonly serial_number: string | null;
  readonly sqses_flag: string;
  readonly kasho_detail: string | null;
  readonly jokyo_detail: string | null;
  readonly ijocd: string | null;
  readonly sgnaiyo: string | null;
};

export type APIEquipmentOptionsForEdit = {
  readonly equipment_type_list: {
    readonly mst_equipment_type_id: number;
    readonly mst_equipment_type_name: string;
  }[];
  readonly diagram_list: {
    readonly diagram_id: number;
    readonly diagram_name: string;
  }[];
  readonly floor_list: {
    readonly floor_id: number;
    readonly floor_name: string;
    readonly room_list: {
      readonly room_id: number;
      readonly room_name: string;
    }[];
  }[];
};

export type APICreateEquipment = {
  floor_id: number | null;
  room_id: number | null;
  equipment_name: string;
  model_number: string;
  serial_number: string;
  equipment_type_id: number | null;
  diagram_symbol: string;
  manufacturer: string;
  manufacture_date: string | null;
  install_date: string | null;
  remarks: string;
  image_list: ImageListType[];
  file_name?: string;
  file_content?: string;
  useful_year: number | null;
  previous_equipment_id: number | null;
  successor_equipment_id: number | null;
  is_unused?: boolean;
  documents: DocumentFormType[];
};

export type APIUpdateEquipment = APICreateEquipment;

export type APICreateEquipmentDiagram = {
  diagram_id: number | null;
  diagram_name: string;
  description: string;
  is_set: boolean;
};

export type APIUpdateEquipmentDiagram = APICreateEquipmentDiagram;

export type APIGetEquipmentsResponse = {
  result: {
    equipment_list: APIEquipments[];
    display_params: APIDisplayParam;
  };
};

export type APIGetEquipmentResponse = {
  result: {
    equipment: APIEquipment;
    equipment_master: APIEquipmentModel;
  };
};

export type APIGetEquipmentHistoryResponse = {
  result: { trouble_list: APIEquipmentHistory[] };
};

export type APIGetEquipmentSqsesDataResponse = {
  result: { maintenance_list: APIEquipmentSqsesData[] };
};

export type APIGetEquipmentOptionsForEditResponse = {
  result: APIEquipmentOptionsForEdit;
};

export type APICreateEquipmentRequest = {
  equipment: APIUpdateEquipment;
  diagram: APIUpdateEquipmentDiagram;
};
export type APICreateEquipmentResponse = {
  result: { equipment_id: number };
};

export type APIUpdateEquipmentRequest = {
  property_id: number;
  equipment: APIUpdateEquipment;
  diagram: APIUpdateEquipmentDiagram;
};

export type APIDeleteEquipmentsRequest = {
  equipment_list: { property_id: number; equipment_id: number }[];
};

export type APIDownloadExcelFormatResponse = {
  result: {
    file_name: string;
    file_content: string;
  };
};

export type APIExportCsvFormatResponse = APIDownloadExcelFormatResponse;

/**
 * 一覧検索・一括ダウンロードの共通パラメータ
 */
export interface APIGetEquipmentsRequestParamsWithoutPage {
  /** 検索キーワード */
  keyword?: string;
  /** 1：設備名の昇順、2：型番の昇順、3：機器種別名の昇順、4：メーカー名の昇順、5：系統名の昇順、6：建物名の昇順、7：フロア表示順の降順。省略時「1」 */
  sort?: string;
  /** 詳細検索用キーワード 「設備名」を対象に部分一致検索を行う */
  name?: string;
  /** 詳細検索用キーワード 「型番」を対象に部分一致検索を行う */
  model?: string;
  /** 詳細検索用キーワード 「製造番号」を対象に部分一致検索を行う */
  serial?: string;
  /** 詳細検索用キーワード 「フロア」を対象に部分一致検索を行う */
  floor?: string;
  /** 詳細検索用キーワード 「部屋」を対象に部分一致検索を行う */
  room?: string;
  /** 建物ID */
  property_id?: number | null;
  /** タグ検索 IDをカンマ区切りで送信することで複数対応化(AND検索)する */
  tag?: string;
  /** 系統ID */
  diagram?: string;
  /** 機器種別ID */
  equipment_type?: number;
  /** 耐用年数(下限) */
  useful_year_min?: number;
  /** 耐用年数(上限) */
  useful_year_max?: number;
  /** 経過年数(下限) */
  elapsed_year_min?: number;
  /** 経過年数(上限) */
  elapsed_year_max?: number;
  /** 未使用か */
  in_unused?: boolean;
  /** 異常がある設備か */
  is_abnormal?: boolean;
  /** 点検中の設備か */
  in_inspection?: boolean;
  /** 案件対応中の設備か */
  in_trouble?: boolean;
}

export type APIDownloadEquipmentRequestParam = APIGetEquipmentsRequestParamsWithoutPage & {
  /** 設備ID (複数あるので、カンマ区切りのストリングで渡す) */
  id?: string;
  /**ダウンロードの対象範囲 Trueの時は「全て」ダウンロードする */
  is_all?: boolean;
};

/**
 * 設備検索のための詳細検索条件のlocationのstateタイプ
 */
export interface EquipmentsAdvancedQuery {
  /** 検索キーワード */
  name?: string;
  /** 1：設備名の昇順、2：型番の昇順、3：機器種別名の昇順、4：メーカー名の昇順、5：系統名の昇順、6：建物名の昇順、7：フロア表示順の降順。省略時「1」 */
  sort?: string;
  /** 詳細検索用キーワード 「型番」を対象に部分一致検索を行う */
  model?: string;
  /** 詳細検索用キーワード 「製造番号」を対象に部分一致検索を行う */
  serial?: string;
  /** 詳細検索用キーワード 「製造番号」を対象に部分一致検索を行う */
  floor?: string;
  /** 詳細検索用キーワード 「部屋」を対象に部分一致検索を行う */
  room?: string;
  /** 系統ID */
  diagram?: string;
  /** タグ検索 IDをカンマ区切りで送信することで複数対応化(AND検索)する */
  tag?: string;
  /** 建物ID */
  propertyId?: number;
  /** 機器種別ID */
  equipmentType?: number;
  /** 耐用年数(下限) */
  usefulYearMin?: number;
  /** 耐用年数(上限) */
  usefulYearMax?: number;
  /** 経過年数(下限)*/
  elapsedYearMin?: number;
  /** 経過年数(上限) */
  elapsedYearMax?: number;
  /** 未使用か */
  inUnused?: boolean;
  /** 異常がある設備か */
  isAbnormal?: boolean;
  /** 点検中の設備か */
  inInspection?: boolean;
  /** 案件対応中の設備か */
  inTrouble?: boolean;
}

/**
 * 一覧検索での追加パラメータ
 */
export interface APIGetEquipmentsRequestParamsPage {
  page?: number;
  /** 1ページ当たりのデータ数。省略時は「15」。最大値「100」。「0」以下は「15」扱い。「101」以上は「100」扱い。 */
  size_per_page?: number;
}

export interface SearchCommonOption {
  order?: boolean;
  pageH?: number;
}

/**
 * 設備一覧検索時のapiパラメータ
 */
export type APIGetEquipmentsRequestParams = APIGetEquipmentsRequestParamsWithoutPage &
  APIGetEquipmentsRequestParamsPage &
  SearchCommonOption;

/**
 * 設備一覧検索時に使われるlocationのstate
 */
export type EquipmentsListQuery = EquipmentsAdvancedQuery &
  APIGetEquipmentsRequestParamsPage &
  SearchCommonOption;

/**
 * 設備一覧画面のlocationのstate
 */
export type EquipmentsListState = EquipmentsListQuery;
