import reducer from './reducers';

import * as inspectionChecklistEditSelectors from './selectors';
import * as inspectionChecklistEditActions from './actions';
import * as inspectionChecklistEditOperations from './operations';
import { InspectionChecklistEditState } from './types';

export {
  inspectionChecklistEditSelectors,
  inspectionChecklistEditActions,
  inspectionChecklistEditOperations,
};
export type { InspectionChecklistEditState };

export default reducer;
