import { AppState as State } from '@ducks/index';
import { SearchParameter, MonthlyPlanSchedule, MonthlyPlanScheduleSummary } from './types';

/**
 * @summary リフレッシュを行うためのstateを返却する
 * @param {State} state
 * @returns {Record<string, never>}
 */
export const getRefreshId = (state: State) => state.plan.monthlySchedule.refresh;

/**
 * @summary ローディング状態かを返却する
 * @param {State} state
 * @returns {boolean} ローディング状態か
 */
export const checkIsLoading = (state: State) => state.plan.monthlySchedule.isLoading;

/**
 * @summary 送信処理中かを返却する
 * @param {State} state
 * @returns {boolean} 送信処理中か
 */
export const checkIsSubmitting = (state: State) => state.plan.monthlySchedule.isSubmitting;

/**
 * @summary ソート番号を返却する
 * @param {State} state
 * @returns {number}
 */
export const getSort: (state: State) => number = (state: State) => state.plan.monthlySchedule.sort;

/**
 * @summary 詳細検索条件を返却する
 * @param {State} state
 * @returns {SearchParameter}
 */
export const getSearchParameter: (state: State) => SearchParameter = (state: State) =>
  state.plan.monthlySchedule.searchParameter;

/**
 * @summary 月次計画リストを返却する
 * @param {State} state
 * @returns {MonthlyPlanSchedule[]}
 */
export const getPlans: (state: State) => MonthlyPlanSchedule[] = (state: State) =>
  state.plan.monthlySchedule.plans;

/**
 * @summary 月次計画サマリを返却する
 * @param {State} state
 * @returns {MonthlyPlanScheduleSummary|null}
 */
export const getSummary: (state: State) => MonthlyPlanScheduleSummary | null = (state: State) =>
  state.plan.monthlySchedule.summary;
