import { useMessageSource } from 'react-message-source';
import { OptionItem } from '~/components/uiParts/selectBox';

/** テーブル一覧表示: 1ページあたりの標準表示件数 */
export const LIST_SIZE_PER_PAGE = 20;

/** テーブル一覧表示: 1ページあたりの標準拡張表示件数 */
export const LIST_SIZE_PER_PAGE_LARGE = 100;

/** 建物の契約単価の限度 */
export const CONTRACT_PRICE_UNIT_LIMIT = 9999999999;

/** int32最大数 */
export const MAX_INT_32 = 2147483647;

/** 数値入力における有効桁数の上限値 */
export const MAX_SIGNIFICANT_FIGURES = 15;

/** 削除操作を示すInputEvent.inputTypeのリスト */
export const DELETE_INPUT_TYPE_LIST = [
  'deleteContentBackward',
  'deleteContentForward',
  'deleteWordBackward',
  'deleteWordForward',
];

/** 都道府県マスタ */
export const MST_PREFECTURES = [
  { id: 1, name: '北海道' },
  { id: 2, name: '青森県' },
  { id: 3, name: '岩手県' },
  { id: 4, name: '宮城県' },
  { id: 5, name: '秋田県' },
  { id: 6, name: '山形県' },
  { id: 7, name: '福島県' },
  { id: 8, name: '茨城県' },
  { id: 9, name: '栃木県' },
  { id: 10, name: '群馬県' },
  { id: 11, name: '埼玉県' },
  { id: 12, name: '千葉県' },
  { id: 13, name: '東京都' },
  { id: 14, name: '神奈川県' },
  { id: 15, name: '新潟県' },
  { id: 16, name: '富山県' },
  { id: 17, name: '石川県' },
  { id: 18, name: '福井県' },
  { id: 19, name: '山梨県' },
  { id: 20, name: '長野県' },
  { id: 21, name: '岐阜県' },
  { id: 22, name: '静岡県' },
  { id: 23, name: '愛知県' },
  { id: 24, name: '三重県' },
  { id: 25, name: '滋賀県' },
  { id: 26, name: '京都府' },
  { id: 27, name: '大阪府' },
  { id: 28, name: '兵庫県' },
  { id: 29, name: '奈良県' },
  { id: 30, name: '和歌山県' },
  { id: 31, name: '鳥取県' },
  { id: 32, name: '島根県' },
  { id: 33, name: '岡山県' },
  { id: 34, name: '広島県' },
  { id: 35, name: '山口県' },
  { id: 36, name: '徳島県' },
  { id: 37, name: '香川県' },
  { id: 38, name: '愛媛県' },
  { id: 39, name: '高知県' },
  { id: 40, name: '福岡県' },
  { id: 41, name: '佐賀県' },
  { id: 42, name: '長崎県' },
  { id: 43, name: '熊本県' },
  { id: 44, name: '大分県' },
  { id: 45, name: '宮崎県' },
  { id: 46, name: '鹿児島県' },
  { id: 47, name: '沖縄県' },
];

/**
 * 機器種別マスタ
 * NOTE: 機器種別は7タイプに変更 -> @summary [設備] 機器種別マスタ を参照
 */

export const MST_EQUIPMENT_TYPE = [
  { id: 1, name: '空調 - 空調機器', category: 1 },
  { id: 2, name: '空調 - ユニット類', category: 1 },
  { id: 3, name: '空調 - ファン類', category: 1 },
  { id: 4, name: '空調 - 自動制御機器', category: 1 },
  { id: 5, name: '空調 - その他', category: 1 },
  { id: 6, name: '電気 - 高圧機器', category: 2 },
  { id: 7, name: '電気 - 発電機', category: 2 },
  { id: 8, name: '電気 - 盤類', category: 2 },
  { id: 9, name: '電気 - 照明器具', category: 2 },
  { id: 10, name: '電気 - 弱電設備', category: 2 },
  { id: 11, name: '電気 - 防災設備', category: 2 },
  { id: 12, name: '電気 - 避雷針', category: 2 },
  { id: 13, name: '電気 - その他', category: 2 },
  { id: 14, name: '衛生防災 - ボイラ類', category: 3 },
  { id: 15, name: '衛生防災 - 水槽類', category: 3 },
  { id: 16, name: '衛生防災 - ポンプ類', category: 3 },
  { id: 17, name: '衛生防災 - 衛生器具', category: 3 },
  { id: 18, name: '衛生防災 - 一般消火機器', category: 3 },
  { id: 19, name: '衛生防災 - 特殊消火機器', category: 3 },
  { id: 20, name: '衛生防災 - 配管材', category: 3 },
  { id: 21, name: '衛生防災 - その他', category: 3 },
  { id: 22, name: '輸送 - 昇降機', category: 4 },
  { id: 23, name: '輸送 - その他', category: 4 },
  { id: 24, name: 'その他 - その他', category: 5 },
];

/**
 * @summary システムで利用する共通の定数を定義する
 * @description 定数に定義したid値はAPI引き渡し用データとして利用する
 * フックもしくは、画面側においては以下のように利用する
 *【フック側コード】例
 * const [fixedTermTaskType, setFixedTermTaskType] = useState('');
 * const fixedTermTaskTypes: OptionItem[] = MST_FIXED_TERM_TASK_TYPES.map((v) => ({
 *    title: v.name,
 *    value: v.id,
 * }));
 *
 * const handleFixedTermTaskTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
 *   setFixedTermTaskType(e.target.value);
 * };
 * return {
 *   // Type 定期業務種別 Value、 Option、Changeイベントハンドラー を画面へ提供
 *   fixedTermTaskType,
 *   fixedTermTaskTypes,
 *   handleFixedTermTaskTypeChange,
 * };
 *【画面側コード】例
 * const {
 *   fixedTermTaskType,
 *   fixedTermTaskTypes,
 *   handleFixedTermTaskTypeChange,
 * } = useFilterOptions();
 *
 * <SelectBox
 *   value={fixedTermTaskType}
 *   options={fixedTermTaskTypes}
 *   onChange={handleFixedTermTaskTypeChange}
 *   headerValue={''}
 *   headerTitle={''}
 * />
 * @returns  定義したシステム共通で利用できる定数
 */
export const useConstants = () => {
  const { getMessage } = useMessageSource();

  /**
   * @summary 定期業務種別(Type)のAPI引き渡し用Idの管理テーブル
   */
  const MST_FIXED_TERM_TASK_TYPES = [
    { id: 1, name: getMessage(`common.fixedTermTaskType.0001`) },
    { id: 2, name: getMessage(`common.fixedTermTaskType.0002`) },
    { id: 3, name: getMessage(`common.fixedTermTaskType.0003`) },
    { id: 4, name: getMessage(`common.fixedTermTaskType.0004`) },
  ];

  /**
   * @summary 定期業務区分(Division)のAPI引き渡し用Idの管理テーブル
   */
  const MST_FIXED_TERM_TASK_DIVISIONS = [
    { id: 1, name: getMessage(`common.fixedTermTaskDivision.0001`) },
    { id: 2, name: getMessage(`common.fixedTermTaskDivision.0002`) },
    { id: 3, name: getMessage(`common.fixedTermTaskDivision.0003`) },
    { id: 4, name: getMessage(`common.fixedTermTaskDivision.0004`) },
    { id: 5, name: getMessage(`common.fixedTermTaskDivision.0005`) },
    { id: 6, name: getMessage(`common.fixedTermTaskDivision.0006`) },
  ];

  /**
   * @summary 対応状況(Status)のAPI引き渡し用Idの管理テーブル
   */
  const MST_FIXED_TERM_TASK_STATUSES = [
    { id: 1, name: getMessage(`common.fixedTermTaskStatus.0001`) },
    { id: 2, name: getMessage(`common.fixedTermTaskStatus.0002`) },
    { id: 3, name: getMessage(`common.fixedTermTaskStatus.0003`) },
    { id: 4, name: getMessage(`common.fixedTermTaskStatus.0004`) },
    { id: 5, name: getMessage(`common.fixedTermTaskStatus.0005`) },
    { id: 6, name: getMessage(`common.fixedTermTaskStatus.0006`) },
  ];

  /**
   * @summary [設備] 機器種別マスタ
   */
  const MST_EQUIPMENT_TYPES = [
    { id: 1, name: '空気調和設備' },
    { id: 2, name: '給排水設備' },
    { id: 3, name: '昇降機設備' },
    { id: 4, name: '弱電設備' },
    { id: 5, name: '強電設備' },
    { id: 6, name: '防災設備' },
    { id: 7, name: 'その他' },
  ];

  /**
   * @summary 案件分類
   * @description
   * 1.不具合・2.問い合わせ・3.その他・4.工事
   */
  const WORK_TYPE_OPTIONS: OptionItem[] = [
    { title: getMessage(`common.workTypeOptions.0001`), value: 1 },
    { title: getMessage(`common.workTypeOptions.0002`), value: 2 },
    { title: getMessage(`common.workTypeOptions.0004`), value: 3 },
    { title: getMessage(`common.workTypeOptions.0003`), value: 4 },
  ];

  /**
   * @summary 優先度
   * @description
   * 1.高・2中・3.低
   */
  const WORK_PRIORITY_OPTIONS: OptionItem[] = [
    { title: getMessage(`common.workPriorityOption.0001`), value: 1 },
    { title: getMessage(`common.workPriorityOption.0002`), value: 2 },
    { title: getMessage(`common.workPriorityOption.0003`), value: 3 },
  ];

  /**
   * @summary 対応状況
   * @description
   * 1.未実施・2対応中・3.査収待ち・4.査収完了・5.保留
   */
  const WORK_STATUS_OPTIONS: OptionItem[] = [
    { title: getMessage(`common.workStatusOptions0001`), value: 1 },
    { title: getMessage(`common.workStatusOptions0002`), value: 2 },
    { title: getMessage(`common.workStatusOptions0003`), value: 3 },
    { title: getMessage(`common.workStatusOptions0004`), value: 4 },
    { title: getMessage(`common.workStatusOptions0005`), value: 5 },
  ];

  /**
   * @summary 対応状況（査収待ち前段階）
   * @description
   * 1.未実施・2対応中・5.保留
   */

  const WORK_CHANGEABLE_STATUS_OPTIONS: OptionItem[] = [
    { title: getMessage(`common.workStatusOptions0001`), value: 1 },
    { title: getMessage(`common.workStatusOptions0002`), value: 2 },
    { title: getMessage(`common.workStatusOptions0005`), value: 5 },
  ];

  return {
    MST_FIXED_TERM_TASK_TYPES,
    MST_FIXED_TERM_TASK_DIVISIONS,
    MST_FIXED_TERM_TASK_STATUSES,
    MST_EQUIPMENT_TYPES,
    WORK_TYPE_OPTIONS,
    WORK_PRIORITY_OPTIONS,
    WORK_STATUS_OPTIONS,
    WORK_CHANGEABLE_STATUS_OPTIONS,
  };
};
