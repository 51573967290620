import {
  Address,
  FloorsList,
  MembersList,
  MembersListInfo,
  PropertiesList,
  PropertiesListInfo,
  Property,
  PropertyAction as Action,
  PropertyActionTypes as ActionTypes,
  RoomsList,
  SelectMembersList,
  SelectMembersListInfo,
  TenantsList,
  TenantsListInfo,
  Information,
  ContractsList,
  ContractsListInfo,
  Contract,
} from './types';

export const init = (): Action => ({ type: ActionTypes.INIT });

export const refresh = (): Action => ({ type: ActionTypes.REFRESH });

export const refresh2 = (): Action => ({ type: ActionTypes.REFRESH2 });

export const setIsLoading = (isLoading: boolean): Action => ({
  type: ActionTypes.LOADING,
  payload: isLoading,
});

export const setIsLoading2 = (isLoading2: boolean): Action => ({
  type: ActionTypes.LOADING2,
  payload: isLoading2,
});

export const setIsLoading3 = (isLoading3: boolean): Action => ({
  type: ActionTypes.LOADING3,
  payload: isLoading3,
});

export const setIsSubmitting = (isSubmitting: boolean): Action => ({
  type: ActionTypes.SUBMITTING,
  payload: isSubmitting,
});

export const setIsNavigating = (isNavigating: boolean): Action => ({
  type: ActionTypes.NAVIGATING,
  payload: isNavigating,
});

export const setIsNavigatingTo = (isNavigatingTo: number | null): Action => ({
  type: ActionTypes.NAVIGATING_TO,
  payload: isNavigatingTo,
});

export const setIsNeedConfirm = (isNeedConfirm: boolean): Action => ({
  type: ActionTypes.NEED_CONFIRM,
  payload: isNeedConfirm,
});

export const setProperties = (properties: PropertiesList[]): Action => ({
  type: ActionTypes.SET_PROPERTIES,
  payload: properties,
});

export const setPropertiesListInfo = (info: PropertiesListInfo | null): Action => ({
  type: ActionTypes.SET_PROPERTIES_LIST_INFO,
  payload: info,
});

export const setProperty = (property: Property | null): Action => ({
  type: ActionTypes.SET_PROPERTY,
  payload: property,
});

export const setFloors = (floors: FloorsList[]): Action => ({
  type: ActionTypes.SET_FLOORS,
  payload: floors,
});

export const setRooms = (rooms: RoomsList[]): Action => ({
  type: ActionTypes.SET_ROOMS,
  payload: rooms,
});

export const setMembers = (members: MembersList[]): Action => ({
  type: ActionTypes.SET_MEMBERS,
  payload: members,
});

export const setMembersListInfo = (info: MembersListInfo | null): Action => ({
  type: ActionTypes.SET_MEMBERS_LIST_INFO,
  payload: info,
});

export const setSelectMembers = (selectMembers: SelectMembersList[]): Action => ({
  type: ActionTypes.SET_SELECT_MEMBERS,
  payload: selectMembers,
});

export const setSelectMembersListInfo = (info: SelectMembersListInfo | null): Action => ({
  type: ActionTypes.SET_SELECT_MEMBERS_LIST_INFO,
  payload: info,
});

export const setTenants = (tenants: TenantsList[]): Action => ({
  type: ActionTypes.SET_TENANTS,
  payload: tenants,
});

export const setTenantsListInfo = (info: TenantsListInfo | null): Action => ({
  type: ActionTypes.SET_TENANTS_LIST_INFO,
  payload: info,
});

export const setAddressAutofill = (data: Address | null): Action => ({
  type: ActionTypes.SET_ADDRESS_AUTOFILL,
  payload: data,
});

export const setInformation = (information: Information | null): Action => ({
  type: ActionTypes.SET_INFORMATION,
  payload: information,
});

export const setContracts = (contracts: ContractsList[]): Action => ({
  type: ActionTypes.SET_CONTRACTS,
  payload: contracts,
});

export const setContractsListInfo = (info: ContractsListInfo | null): Action => ({
  type: ActionTypes.SET_CONTRACTS_LIST_INFO,
  payload: info,
});

export const setContract = (contract: Contract | null): Action => ({
  type: ActionTypes.SET_CONTRACT,
  payload: contract,
});

/**
 * @summary 計算した関連ドキュメント(ローカルファイル)のファイルサイズ合計を設定する
 * @param documentFileSize 計算した関連ドキュメント(ローカルファイル)のファイルサイズ合計
 */
export const setDocumentFileSize = (documentFileSize: number): Action => ({
  type: ActionTypes.SET_DOCUMENT_FILE_SIZE,
  payload: documentFileSize,
});
