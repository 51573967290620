import {
  DiagramAction as Action,
  DiagramActionTypes as ActionTypes,
  Diagram,
  Equipment,
} from './types';

export const init = (): Action => ({
  type: ActionTypes.INIT,
});

export const refresh = (): Action => ({
  type: ActionTypes.REFRESH,
});

export const setIsLoading = (isLoading: boolean): Action => ({
  type: ActionTypes.LOADING,
  payload: isLoading,
});

export const setIsLoading2 = (isLoading2: boolean): Action => ({
  type: ActionTypes.LOADING2,
  payload: isLoading2,
});

export const setIsSubmitting = (isLoading: boolean): Action => ({
  type: ActionTypes.SUBMITTING,
  payload: isLoading,
});

export const setIsNavigating = (isNavigating: boolean): Action => ({
  type: ActionTypes.NAVIGATING,
  payload: isNavigating,
});

export const setIsNavigatingTo = (isNavigatingTo: number | null): Action => ({
  type: ActionTypes.NAVIGATING_TO,
  payload: isNavigatingTo,
});

export const setDiagram = (data: Diagram | null): Action => ({
  type: ActionTypes.SET_DIAGRAM,
  payload: data,
});

export const setSelectEquipments = (data: Equipment[]): Action => ({
  type: ActionTypes.SET_SELECT_EQUIPMENTS,
  payload: data,
});
