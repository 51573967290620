import axios from '@providers/axiosProvider';
import * as actions from './actions';
import { APIGetLicenseInfoResponse } from './types';

export const getLicenseInfoAsync = () => async (dispatch: any) => {
  dispatch(actions.setIsLoading(true));

  await axios
    .get<APIGetLicenseInfoResponse>('/users', { params: { size_per_page: 1 } })
    .then((response) => {
      const data = response.data.result.contract_user_info;
      dispatch(
        actions.setLicenseInfo({
          maxNumUsers: data.max_contract_user,
          currentNumUsers: data.current_active_user,
        }),
      );
    })
    .catch(() => {});

  dispatch(actions.setIsLoading(false));
};
