import axios from '@providers/axiosProvider';
import * as actions from './actions';
import { APIGetPropertiesRequestParams, APIGetUserResponse, APIGetCompanyResponse } from './types';

/**
 * 連絡先リストをAPIから取得
 *
 * @param params APIGetPropertiesRequestParams
 * @returns void
 */
export const getAffiliateUserAsync =
  (params: APIGetPropertiesRequestParams) => async (dispatch: any) => {
    dispatch(actions.setIsLoading(true));

    const reqParams: APIGetPropertiesRequestParams = {
      ...params,
    };
    await axios
      .get<APIGetUserResponse>('/items/affiliates/users', {
        params: reqParams,
      })
      .then((response) => {
        const userList = response.data.result;
        dispatch(actions.setAffiliateUserList(userList));
      })
      .catch(() => {
        dispatch(actions.setAffiliateUserList([]));
        alert('連絡先のリスト取得に失敗しました');
      });

    dispatch(actions.setIsLoading(false));
  };

/**
 * 企業リストをAPIから取得
 *
 * @param params APIGetPropertiesRequestParams
 * @returns void
 */
export const getAffiliateCompanyAsync =
  (params: APIGetPropertiesRequestParams) => async (dispatch: any) => {
    dispatch(actions.setIsLoading(true));

    const reqParams: APIGetPropertiesRequestParams = {
      ...params,
    };

    await axios
      .get<APIGetCompanyResponse>('/items/affiliates/companies', {
        params: reqParams,
      })
      .then((response) => {
        const companyList = response.data.result;
        dispatch(actions.setAffiliateCompanyList(companyList));
      })
      .catch(() => {
        dispatch(actions.setAffiliateCompanyList([]));
        alert('企業のリスト取得に失敗しました');
      });

    dispatch(actions.setIsLoading(false));
  };

/**
 * 建物担当者リストをAPIから取得
 *
 * @param params APIGetPropertiesRequestParams
 * @returns void
 */
export const getAffiliatePropertyUserAsync =
  (propertyID: number, params: APIGetPropertiesRequestParams) => async (dispatch: any) => {
    dispatch(actions.setIsLoading(true));

    const reqParams: APIGetPropertiesRequestParams = {
      ...params,
    };
    if (propertyID) reqParams.property_id = propertyID;
    await axios
      .get<APIGetUserResponse>(`/items/users`, {
        params: reqParams,
      })
      .then((response) => {
        const propertyUserList = response.data.result;
        dispatch(actions.setAffiliatePropertyUserList(propertyUserList));
      })
      .catch(() => {
        dispatch(actions.setAffiliatePropertyUserList([]));
        alert('建物担当者のリスト取得に失敗しました');
      });

    dispatch(actions.setIsLoading(false));
  };
