import type { Action, PayloadAction } from '@reduxjs/toolkit';

export type UserLicenseState = {
  refresh: Record<string, never>;
  isLoading: boolean;
  licenseInfo: LicenseInfo | null;
};

export enum UserLicenseActionTypes {
  INIT = 'userLicense/init',
  REFRESH = 'userLicense/refresh',
  LOADING = 'userLicense/set/isLoading',
  SET_LICENSE_INFO = 'userLicense/set/licenseInfo',
}

export type UserLicenseAction =
  | Action<UserLicenseActionTypes.INIT>
  | Action<UserLicenseActionTypes.REFRESH>
  | PayloadAction<boolean, UserLicenseActionTypes.LOADING>
  | PayloadAction<LicenseInfo | null, UserLicenseActionTypes.SET_LICENSE_INFO>;

// -- State Types ---------------------------------------------------------- //

export type LicenseInfo = {
  maxNumUsers: number;
  currentNumUsers: number;
};

// -- API Types ------------------------------------------------------------ //

export type APIGetLicenseInfoResponse = {
  result: {
    contract_user_info: {
      current_active_user: number;
      max_contract_user: number;
    };
  };
};
