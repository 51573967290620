import {
  SelectAffiliateAction,
  SelectAffiliateState,
  SelectAffiliateActionTypes as ActionTypes,
} from './types';

const initialSelectAffiliateState: SelectAffiliateState = {
  isLoading: false,
  userList: [],
  companyList: [],
  propertyUserList: [],
  tab: null,
  propertyUserParameter: {property_id: 0, is_admin: false},
  selectedItem: null,
  submitItemType: null,
  submitUserItem: null,
  submitCompanyItem: null,
  submitPropertyUserItem: null,
};

const selectAffiliateReducer = (
  state: SelectAffiliateState = initialSelectAffiliateState,
  action: SelectAffiliateAction,
): SelectAffiliateState => {
  switch (action.type) {
    case ActionTypes.INIT:
      return initialSelectAffiliateState;

    case ActionTypes.LOADING:
      return { ...state, isLoading: action.payload };

    case ActionTypes.SET_USER_LIST:
      return { ...state, userList: action.payload };

    case ActionTypes.SET_COMPANY_LIST:
      return { ...state, companyList: action.payload };

    case ActionTypes.SET_PROPERTY_USER_LIST:
      return { ...state, propertyUserList: action.payload };

    case ActionTypes.SET_TAB:
      return { ...state, tab: action.payload };

    case ActionTypes.SET_PROPERTY_USER_SEARCH_PARAMETER:
      return { ...state, propertyUserParameter: action.payload };

    case ActionTypes.SET_SELECTED_ITEM:
      return { ...state, selectedItem: action.payload };

    case ActionTypes.SET_SUBMIT_ITEM_TYPE:
      return { ...state, submitItemType: action.payload };

    case ActionTypes.SET_SUBMIT_USER_ITEM:
      return { ...state, submitUserItem: action.payload };

    case ActionTypes.SET_SUBMIT_COMPANY_ITEM:
      return { ...state, submitCompanyItem: action.payload };

    case ActionTypes.SET_SUBMIT_PROPERTY_USER_ITEM:
      return { ...state, submitPropertyUserItem: action.payload };

    default:
      return state;
  }
};

export default selectAffiliateReducer;
