import type { Action, PayloadAction } from '@reduxjs/toolkit';

export type ArchiveState = {
  refresh: Record<string, never>;
  isLoading: boolean;
  isSubmitting: boolean;
  archives: ArchiveList[];
  paths: PathInfo[];
  parentArchiveId: number | null;
  checkFilesOverWrite: string[];
};

export enum ArchiveActionTypes {
  INIT = 'archives/init',
  REFRESH = 'archives/refresh',
  LOADING = 'archives/set/isLoading',
  SUBMITTING = 'archives/set/isSubmitting',
  SET_ARCHIVES = 'archives/set/archives',
  SET_CHECK_FILES_OVERWRITE = 'archives/set/checkFilesOverWrite',
}

export type ArchiveAction =
  | Action<ArchiveActionTypes.INIT>
  | Action<ArchiveActionTypes.REFRESH>
  | PayloadAction<boolean, ArchiveActionTypes.LOADING>
  | PayloadAction<boolean, ArchiveActionTypes.SUBMITTING>
  | PayloadAction<
      {
        parentArchiveId: number | null;
        paths: PathInfo[];
        archives: ArchiveList[];
      },
      ArchiveActionTypes.SET_ARCHIVES
    >
  | PayloadAction<string[], ArchiveActionTypes.SET_CHECK_FILES_OVERWRITE>;

// -- State Types ---------------------------------------------------------- //

/**
 * @summary 書庫ファイルリストのinterface
 */
export interface ArchiveListInterface {
  /** 建物ID */
  propertyId: number;
  /** 書庫ID */
  archiveId: number;
  /**
   * 書庫アイテムの種類
   * @description 1: ファイル / 2: ディレクトリー
   */
  archiveTypeId: number;
  /** 書庫アイテムの種類名 */
  archiveTypeName: string;
  /** ファイルID */
  fileId: number | null;
  /** ファイルURL */
  fileUrl: string | null;
  /** タイトル */
  title: string;
  /** サイズ */
  fileSize: number | null;
  /** 作成者 */
  createUserName: string;
  /** 作成日次 */
  createDatetime: string;
  /** 更新者 */
  updateUserName: string;
  /** 更新日次 */
  updateDatetime: string;
  /** 最終アクセスユーザ */
  lastAccessUserName: string;
  /** 最終アクセス日次 */
  lastAccessDatetime: string;
}
/**
 * @summary 書庫ファイルリストタイプ
 */
export type ArchiveList = ArchiveListInterface;

/**
 * @summary 書庫ファイルリストのパスタイプ
 */
export type PathInfo = {
  /** 建物ID */
  propertyId: number;
  /** 書庫ID */
  archiveId: number;
  /** タイトル */
  title: string;
};

// -- API Types ------------------------------------------------------------ //

export type APIArchiveList = {
  readonly property_id: number;
  readonly archive_id: number;
  readonly archive_type_id: number;
  readonly archive_type_name: string;
  readonly file_id: number | null;
  readonly file_url: string | null;
  readonly title: string;
  readonly file_size: number | null;
  readonly create_user_name: string;
  readonly create_datetime: string;
  readonly update_user_name: string;
  readonly update_datetime: string;
  readonly last_access_user_name: string;
  readonly last_access_datetime: string;
};

export type APIPathInfo = {
  readonly property_id: number;
  readonly archive_id: number;
  readonly title: string;
};

export type FileList = {
  readonly file_list: { file_name: string }[];
};

/**
 * 書庫ファイルリスト取得apiのパラメータ
 */
export type APIGetArchivesRequestParams = {
  /** ソート */
  sort: number;
  /** キーワード */
  keyword: string;
  /** グローバル建物ID */
  g_property_id?: number;
};
export type APIGetArchivesResponse = {
  result: {
    parent_archive_id: number;
    path: APIPathInfo[];
    archive_list: APIArchiveList[];
  };
};

export type APIPostUploadFilesRequestParams = {
  is_overwrite: boolean;
};
export type APIPostUploadFilesResponse = {
  message: string;
  result: FileList;
};

/**
 * 書庫ファイル・ディレクトリーの移動処理後の返却値
 */
export type APIPatchUploadFilesResponse = {
  /** メッセージ */
  message: string;
  /** 結果 */
  result: string;
};

export type APICreateDirectoryRequest = {
  title: string;
};
export type APICreateDirectoryResponse = {
  message: string;
  result: string;
};

export type APIRenameArchiveRequest = {
  title: string;
};
export type APIRenameArchiveResponse = {
  message: string;
  result: string;
};

export type APIDownloadArchiveResponse = {
  result: {
    file_content: string;
    file_name: string;
  };
};

export type APIDeleteArchiveRequest = {
  archive_list: { archive_id: number }[];
};

/**
 * @summary 書庫ファイルリスト取得apiの保存・返却値
 */
export type GetArchiveResult = {
  /** 書庫ファイルリスト */
  archives: ArchiveList[];
  /** 書庫ファイルリストまでのパス情報 */
  paths: PathInfo[];
  /** 書庫ファイルリストの親書庫ID */
  parentArchiveId: number;
};
