import {
  Equipment,
  InspectionChecklistEditAction as Action,
  InspectionChecklistEditActionTypes as ActionTypes,
  Layout,
  LayoutComponent,
  LayoutComponentConfig,
  LayoutComponentType,
} from './types';

import { InspectionChecklist } from '@ducks/inspectionChecklist/types';

export const init = (): Action => ({
  type: ActionTypes.INIT,
});

export const refresh = (): Action => ({
  type: ActionTypes.REFRESH,
});

export const setIsLoading = (isLoading: boolean): Action => ({
  type: ActionTypes.LOADING,
  payload: isLoading,
});

export const setIsSubmitting = (isSubmitting: boolean): Action => ({
  type: ActionTypes.SUBMITTING,
  payload: isSubmitting,
});

export const setIsNavigating = (isNavigating: boolean): Action => ({
  type: ActionTypes.NAVIGATING,
  payload: isNavigating,
});

export const setIsNavigating2 = (isNavigating2: boolean): Action => ({
  type: ActionTypes.NAVIGATING2,
  payload: isNavigating2,
});

export const setIsNavigatingTo = (isNavigatingTo: number | null): Action => ({
  type: ActionTypes.NAVIGATING_TO,
  payload: isNavigatingTo,
});

export const setChecklist = (data: InspectionChecklist | null): Action => ({
  type: ActionTypes.SET_CHECKLIST,
  payload: data,
});

export const setLayout = (data: Layout): Action => ({
  type: ActionTypes.SET_LAYOUT,
  payload: data,
});

export const setPublishedLayoutComponentIds = (ids: string[]): Action => ({
  type: ActionTypes.SET_PUBLISHED,
  payload: ids,
});

export const addNewGroup = (data: { groupName: string; sectionName: string }): Action => ({
  type: ActionTypes.ADD_NEW_GROUP,
  payload: data,
});

export const addNewSection = (data: { groupIndex: number; sectionName: string }): Action => ({
  type: ActionTypes.ADD_NEW_SECTION,
  payload: data,
});

export const addNewComponent = (data: {
  groupIndex: number;
  sectionIndex: number;
  component: {
    label: string;
    type: LayoutComponentType;
    required: boolean;
    config?: LayoutComponentConfig[];
  };
}): Action => ({
  type: ActionTypes.ADD_NEW_COMPONENT,
  payload: data,
});

export const setGroupName = (data: { groupIndex: number; groupName: string }): Action => ({
  type: ActionTypes.SET_GROUP_NAME,
  payload: data,
});

export const setSectionName = (data: {
  groupIndex: number;
  sectionIndex: number;
  sectionName: string;
}): Action => ({
  type: ActionTypes.SET_SECTION_NAME,
  payload: data,
});

export const setSectionEquipment = (data: {
  groupIndex: number;
  sectionIndex: number;
  equipment: Equipment | null;
}): Action => ({
  type: ActionTypes.SET_SECTION_EQUIPMENT,
  payload: data,
});

export const setComponent = (data: {
  groupIndex: number;
  sectionIndex: number;
  componentIndex: number;
  component: Partial<LayoutComponent>;
}): Action => ({
  type: ActionTypes.SET_COMPONENT,
  payload: data,
});

export const copyGroup = (data: { groupIndex: number }): Action => ({
  type: ActionTypes.COPY_GROUP,
  payload: data,
});

export const copySection = (data: { groupIndex: number; sectionIndex: number }): Action => ({
  type: ActionTypes.COPY_SECTION,
  payload: data,
});

export const copyComponent = (data: {
  groupIndex: number;
  sectionIndex: number;
  componentIndex: number;
}): Action => ({
  type: ActionTypes.COPY_COMPONENT,
  payload: data,
});

export const changeGroupOrder = (data: { groupIndex: number; isUp: boolean }): Action => ({
  type: ActionTypes.CHANGE_GROUP_ORDER,
  payload: data,
});

export const changeSectionOrder = (data: {
  groupIndex: number;
  sectionIndex: number;
  isUp: boolean;
}): Action => ({
  type: ActionTypes.CHANGE_SECTION_ORDER,
  payload: data,
});

export const changeComponentOrder = (data: {
  groupIndex: number;
  sectionIndex: number;
  componentIndex: number;
  isUp: boolean;
}): Action => ({
  type: ActionTypes.CHANGE_COMPONENT_ORDER,
  payload: data,
});

export const disableGroup = (data: { groupIndex: number; disable: boolean }): Action => ({
  type: ActionTypes.DISABLE_GROUP,
  payload: data,
});

export const disableSection = (data: {
  groupIndex: number;
  sectionIndex: number;
  disable: boolean;
}): Action => ({
  type: ActionTypes.DISABLE_SECTION,
  payload: data,
});

export const disableComponent = (data: {
  groupIndex: number;
  sectionIndex: number;
  componentIndex: number;
  disable: boolean;
}): Action => ({
  type: ActionTypes.DISABLE_COMPONENT,
  payload: data,
});

export const deleteGroup = (data: { groupIndex: number }): Action => ({
  type: ActionTypes.DELETE_GROUP,
  payload: data,
});

export const deleteSection = (data: { groupIndex: number; sectionIndex: number }): Action => ({
  type: ActionTypes.DELETE_SECTION,
  payload: data,
});

export const deleteComponent = (data: {
  groupIndex: number;
  sectionIndex: number;
  componentIndex: number;
}): Action => ({
  type: ActionTypes.DELETE_COMPONENT,
  payload: data,
});
