import axios from '@providers/axiosProvider';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { TagDataTypeForSettings, EditModeType, TagFormDataToAdd } from './types';
import * as actions from './actions';

/**
 * @summary タグリストの取得
 * @description フィルタリングはサーバー側で行う
 * @param {string} name 検索キーワード
 * @returns {Promise<TagDataType[] | null>}
 */
export const getTagsAsync =
  (tagKeyword?: string) =>
  async (dispatch: any): Promise<void> => {
    dispatch(actions.setIsLoading(true));

    await axios
      .get('/tags', {
        params: { name: tagKeyword },
      })
      .then((response) => {
        const tags = response.data.result.tag_list;
        dispatch(actions.setTags(tags));
      })
      .catch(() => {
        dispatch(actions.setTags([]));
      });

    dispatch(actions.setIsLoading(false));
  };

/**
 * @summary タグリストの取得
 * @description query(edit_mode) によって登録件数(using_count)の参照先を動的に変更する
 * @param {EditModeType} edit_mode
 * @returns {void}
 */
export const getTagSettingsListAsync =
  (editMode: EditModeType | null) =>
  async (dispatch: any): Promise<void> => {
    if (editMode == null) {
      return;
    }
    dispatch(actions.setIsLoading(true));

    await axios
      .get(`/tags/settings?edit_mode=${editMode}`)
      .then((response) => {
        const tagList = response.data.result.tag_list;
        dispatch(actions.setTagSettingList(tagList));
      })
      .catch(() => {
        /* axiosProvider で処理するため、ここでは何もしない */
        return;
      });

    dispatch(actions.setIsLoading(false));
  };

/**
 * @summary タグの追加
 * @description フィルタリングはサーバー側で行う
 * @param {string} name 検索キーワード
 * @returns {Promise<TagDataType[] | null>}
 */
export const addNewTagAsync =
  (tag: TagFormDataToAdd) =>
  async (dispatch: any): Promise<void> => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .post('/tags', {
        tag_name: tag.tagNameToAdd,
        type_id: tag.tagTypeIdToAdd,
      })
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch(() => {
        /* axiosProvider で処理するため、ここでは何もしない */
        return;
      });

    dispatch(actions.setIsSubmitting(false));
  };

/**
 * @summary タグの編集
 * @description フィルタリングはサーバー側で行う
 * @param {string} name 検索キーワード
 * @returns {Promise<TagDataType[] | null>}
 */
export const editTagAsync =
  (tagIdToEdit: number, newTagName: string) =>
  async (dispatch: any): Promise<void> => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .put(`/tags/${tagIdToEdit}`, {
        tag_name: newTagName,
      })
      .then((response) => {
        dispatch(actions.refresh());
        const tagsData = response.data.result.tag_list;
        return tagsData;
      })
      .catch(() => {
        /* axiosProvider で処理するため、ここでは何もしない */
        return;
      });

    dispatch(actions.setIsSubmitting(false));
  };

/**
 * @summary タグの削除
 * @description DELETE /api/tags/{tag_id}
 * @returns {Promise<{message: string, result: string}>}
 */
export const deleteTagAsync =
  (tagId: number) =>
  async (dispatch: any): Promise<void> => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .delete(`/tags/${tagId}`)
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch(() => {
        /* axiosProvider で処理するため、ここでは何もしない */
        return;
      });

    dispatch(actions.setIsSubmitting(false));
  };
