import React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeftLongToLine,
  faCircleCheck,
  faCircleXmark,
  faCloudArrowDown,
  faCloudArrowUp,
  faCommentPlus,
  faFileExport,
  faFileImport,
  faFilePlus,
  faFloppyDisk,
  faFolderPlus,
  faGear,
  faMagnifyingGlass,
  faMessagePlus,
  faPen,
  faPlus,
  faTrashCan,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons';

type ButtonProps = {
  children?: React.ReactNode;
  className?: string;
  type?: ButtonType;
  htmlType?: 'button' | 'submit';
  color?: 'primary' | 'white' | 'red' | 'green' | 'disabled';
  title?: string;
  linkTo?: string;
  disabled?: boolean;
  onClick?: () => void;
};

type ButtonType =
  | 'default'
  | 'add'
  | 'approve'
  | 'cancel'
  | 'comment-plus'
  | 'delete'
  | 'download'
  | 'edit'
  | 'file-export'
  | 'file-import'
  | 'file-plus'
  | 'folder-plus'
  | 'message-plus'
  | 'save'
  | 'search'
  | 'send-back'
  | 'setting'
  | 'upload';

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  let className = `button${props.className ? ' ' + props.className : ''}`;
  className += `${props.type ? ' ' + props.type : ' default'}`;
  className += `${props.color ? ' ' + props.color : ' primary'}`;

  return (
    <button
      ref={ref}
      className={className}
      title={props.title}
      type={props.htmlType ?? 'button'}
      disabled={props.disabled}
      onClick={(e) => {
        e.stopPropagation();
        props.onClick && props.onClick();
      }}
    >
      {props.linkTo ? (
        <Link to={props.linkTo}>
          <ButtonIcon type={props.type ?? 'default'} />
          {props.children}
        </Link>
      ) : (
        <>
          <ButtonIcon type={props.type ?? 'default'} />
          {props.children}
        </>
      )}
    </button>
  );
});

export default Button;

type ButtonIconProps = {
  type: ButtonType;
};
const ButtonIcon = (props: ButtonIconProps) => {
  const className = 'button-icon';

  const icon: IconDefinition | null = (() => {
    switch (props.type) {
      case 'add':
        return faPlus;
      case 'approve':
        return faCircleCheck;
      case 'cancel':
        return faCircleXmark;
      case 'comment-plus':
        return faCommentPlus;
      case 'delete':
        return faTrashCan;
      case 'download':
        return faCloudArrowDown;
      case 'edit':
        return faPen;
      case 'file-export':
        return faFileExport;
      case 'file-import':
        return faFileImport;
      case 'file-plus':
        return faFilePlus;
      case 'folder-plus':
        return faFolderPlus;
      case 'message-plus':
        return faMessagePlus;
      case 'save':
        return faFloppyDisk;
      case 'search':
        return faMagnifyingGlass;
      case 'send-back':
        return faArrowLeftLongToLine;
      case 'setting':
        return faGear;
      case 'upload':
        return faCloudArrowUp;
      case 'default':
      default:
        return null;
    }
  })();

  return icon != null ? <FontAwesomeIcon className={className} icon={icon} /> : <></>;
};
