import { AppState as State } from '@ducks/index';
import { SearchParameter, AnnualPlanSchedule, AnnualPlanScheduleSummary } from './types';

/**
 * @summary リフレッシュを行うためのstateを返却する
 * @param {State} state
 * @returns {Record<string, never>}
 */
export const getRefreshId = (state: State) => state.plan.annualSchedule.refresh;

/**
 * @summary ローディング状態かを返却する
 * @param {State} state
 * @returns {boolean} ローディング状態か
 */
export const checkIsLoading = (state: State) => state.plan.annualSchedule.isLoading;

/**
 * @summary 送信処理中かを返却する
 * @param {State} state
 * @returns {boolean} 送信処理中か
 */
export const checkIsSubmitting = (state: State) => state.plan.annualSchedule.isSubmitting;

/**
 * @summary ソート番号を返却する
 * @param {State} state
 * @returns {number}
 */
export const getSort: (state: State) => number = (state: State) => state.plan.annualSchedule.sort;

/**
 * @summary 詳細検索条件を返却する
 * @param {State} state
 * @returns {SearchParameter}
 */
export const getSearchParameter: (state: State) => SearchParameter = (state: State) =>
  state.plan.annualSchedule.searchParameter;

/**
 * @summary 年次計画リストを返却する
 * @param {State} state
 * @returns {AnnualPlanSchedule[]}
 */
export const getPlans: (state: State) => AnnualPlanSchedule[] = (state: State) =>
  state.plan.annualSchedule.plans;

/**
 * @summary 年次計画サマリを返却する
 * @param {State} state
 * @returns {AnnualPlanScheduleSummary|null}
 */
export const getSummary: (state: State) => AnnualPlanScheduleSummary | null = (state: State) =>
  state.plan.annualSchedule.summary;
