import reducer from './reducers';

import * as archiveSelectors from './selectors';
import * as archiveActions from './actions';
import * as archiveOperations from './operations';
import { ArchiveState } from './types';

export { archiveSelectors, archiveActions, archiveOperations };
export type { ArchiveState };

export default reducer;
