import { GetTokenSilentlyOptions } from '@auth0/auth0-spa-js';

let getAccessTokenSilently: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>;

export const auth0 = {
  getAccessTokenSilently: () => getAccessTokenSilently(),
  setAccessTokenSilently: (
    func: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>,
  ) => (getAccessTokenSilently = func),
};
