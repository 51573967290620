import {
  ConstructionState,
  ConstructionAction,
  ConstructionActionTypes as ActionTypes,
} from './types';

const initialConstructionState: ConstructionState = {
  refresh: {},
  refresh2: {},
  refresh3: {},
  isLoading: false,
  isLoading2: false,
  isSubmitting: false,
  isInvalidToken: false,
  construction: null,
  constructionsList: [],
  constructionsListDisplayStatus: null,
  constructionInfo: null,
  constructionComments: null,
};

const constructionReducer = (
  state: ConstructionState = initialConstructionState,
  action: ConstructionAction,
): ConstructionState => {
  switch (action.type) {
    case ActionTypes.INIT:
      return initialConstructionState;

    case ActionTypes.REFRESH:
      return { ...state, refresh: {} };

    case ActionTypes.REFRESH2:
      return { ...state, refresh2: {} };

    case ActionTypes.REFRESH3:
      return { ...state, refresh3: {} };

    case ActionTypes.LOADING:
      return { ...state, isLoading: action.payload };

    case ActionTypes.LOADING2:
      return { ...state, isLoading2: action.payload };

    case ActionTypes.SUBMITTING:
      return { ...state, isSubmitting: action.payload };

    case ActionTypes.INVALID:
      return { ...state, isInvalidToken: action.payload };

    case ActionTypes.SET_CONSTRUCTIONS_LIST:
      return { ...state, constructionsList: action.payload };

    case ActionTypes.SET_CONSTRUCTIONS_LIST_DISPLAY_STATUS:
      return { ...state, constructionsListDisplayStatus: action.payload };

    case ActionTypes.SET_CONSTRUCTION:
      return { ...state, construction: action.payload };

    case ActionTypes.SET_CONSTRUCTIONS_INFO:
      return { ...state, constructionInfo: action.payload };

    case ActionTypes.SET_CONSTRUCTIONS_COMMENTS:
      return { ...state, constructionComments: action.payload };

    default:
      return state;
  }
};

export default constructionReducer;
