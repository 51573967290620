import type { Action, PayloadAction } from '@reduxjs/toolkit';

export type NotificationState = {
  refresh: Record<string, never>;
  isLoading: boolean;
  isSubmitting: boolean;
  notifications: NotificationsType;
};

export enum NotificationActionTypes {
  INIT = 'notification/init',
  REFRESH = 'notification/refresh',
  LOADING = 'notification/set/isLoading',
  SUBMITTING = 'notification/set/isSubmitting',
  SET_NOTIFICATIONS = 'notification/set/notifications',
}

export type NotificationAction =
  | Action<NotificationActionTypes.INIT>
  | Action<NotificationActionTypes.REFRESH>
  | PayloadAction<boolean, NotificationActionTypes.LOADING>
  | PayloadAction<boolean, NotificationActionTypes.SUBMITTING>
  | PayloadAction<NotificationsType, NotificationActionTypes.SET_NOTIFICATIONS>;

// -- State Types ---------------------------------------------------------- //

export type NotificationsType = {
  is_property_updated: boolean;
  is_contract_updated: boolean;
  is_property_information_updated: boolean;
  is_property_group_user_updated: boolean;
  is_inspection_applied: boolean;
  is_inspection_approved: boolean;
  is_inspection_rejected: boolean;
  is_inspection_comments: boolean;
  is_construction_applied: boolean;
  is_construction_approved: boolean;
  is_construction_rejected: boolean;
  is_construction_comments: boolean;
  is_trouble_created: boolean;
  is_trouble_updated: boolean;
  is_trouble_applied: boolean;
  is_trouble_completed: boolean;
  is_trouble_rejected: boolean;
  is_trouble_pending: boolean;
  is_trouble_shooting: boolean;
  is_trouble_comments: boolean;
};

export type NotificationSubmitData = Readonly<NotificationsType>;

// -- API Types ------------------------------------------------------------ //

export type APIUpdateNotification = Readonly<NotificationsType>;

export type APIGetNotificationsResponse = {
  result: {
    user_id: number;
    last_name: string;
    first_name: string;
    kana_last: string;
    kana_first: string;
    notifications: {
      is_property_updated: boolean;
      is_contract_updated: boolean;
      is_property_information_updated: boolean;
      is_property_group_user_updated: boolean;
      is_inspection_applied: boolean;
      is_inspection_approved: boolean;
      is_inspection_rejected: boolean;
      is_inspection_comments: boolean;
      is_construction_applied: boolean;
      is_construction_approved: boolean;
      is_construction_rejected: boolean;
      is_construction_comments: boolean;
      is_trouble_created: boolean;
      is_trouble_updated: boolean;
      is_trouble_applied: boolean;
      is_trouble_completed: boolean;
      is_trouble_rejected: boolean;
      is_trouble_pending: boolean;
      is_trouble_shooting: boolean;
      is_trouble_comments: boolean;
    };
  };
};

export type APIPatchNotificationsResponse = {
  result: {
    notifications: {
      is_property_updated: boolean;
      is_contract_updated: boolean;
      is_property_information_updated: boolean;
      is_property_group_user_updated: boolean;
      is_inspection_applied: boolean;
      is_inspection_approved: boolean;
      is_inspection_rejected: boolean;
      is_inspection_comments: boolean;
      is_construction_applied: boolean;
      is_construction_approved: boolean;
      is_construction_rejected: boolean;
      is_construction_comments: boolean;
      is_trouble_created: boolean;
      is_trouble_updated: boolean;
      is_trouble_applied: boolean;
      is_trouble_completed: boolean;
      is_trouble_rejected: boolean;
      is_trouble_pending: boolean;
      is_trouble_shooting: boolean;
      is_trouble_comments: boolean;
    };
  };
};
