import type { Action, PayloadAction } from '@reduxjs/toolkit';

/**
 * @summary 連絡先複数選択モーダルで管理されるState
 */
export type SelectMultipleAffiliateState = {
  /** Load中か */
  isLoading: boolean;
  /** 連絡先リスト */
  userList: User[];
  /** 企業リスト */
  companyList: Company[];
  /** システムユーザリスト */
  systemUserList: User[];
  /** 選択中のタブ */
  tab: TabType | null;
  /** 選択状態の連絡先リスト */
  selectedItemList: SelectItem[];
};

/**
 * @summary 連絡先選択モーダルのAction Type
 */
export enum SelectMultipleAffiliateActionTypes {
  INIT = 'selectMultipleAffiliate/init',
  LOADING = 'selectMultipleAffiliate/set/isLoading',
  SET_USER_LIST = 'selectMultipleAffiliate/set/user_list',
  SET_COMPANY_LIST = 'selectMultipleAffiliate/set/company_list',
  SET_SYSTEM_USER_LIST = 'selectMultipleAffiliate/set/system_user_list',
  SET_TAB = 'selectMultipleAffiliate/set/tab',
  SET_SELECTED_ITEM_LIST = 'selectMultipleAffiliate/set/selected_item_list',
}

/**
 * @summary 連絡先選択モーダルのAction
 */
export type SelectMultipleAffiliateAction =
  /** 初期化 */
  | Action<SelectMultipleAffiliateActionTypes.INIT>
  /** Loading状態を保持 */
  | PayloadAction<boolean, SelectMultipleAffiliateActionTypes.LOADING>
  /** APIから取得したユーザを保持 */
  | PayloadAction<User[], SelectMultipleAffiliateActionTypes.SET_USER_LIST>
  /** APIから取得した企業を保持 */
  | PayloadAction<Company[], SelectMultipleAffiliateActionTypes.SET_COMPANY_LIST>
  /** APIから取得した建物担当者を保持 */
  | PayloadAction<User[], SelectMultipleAffiliateActionTypes.SET_SYSTEM_USER_LIST>
  /** タブの切り替え */
  | PayloadAction<TabType, SelectMultipleAffiliateActionTypes.SET_TAB>
  /** 選択した状態の連絡先リストを保持 */
  | PayloadAction<SelectItem[], SelectMultipleAffiliateActionTypes.SET_SELECTED_ITEM_LIST>;

/**
 * @summary 連絡先・企業・建物担当者選択モーダルの表示対象のタブタイプ
 */
export type TabType =
  /** 連絡先 */
  | 'USER'
  /** 企業 */
  | 'COMPANY'
  /** システムユーザ */
  | 'SYSTEM_USER';

/**
 * @summary 選択状態の連絡先|企業|建物担当者
 */
export type SelectItem = {
  /** id */
  id: number;
  /** 表示名 */
  name: string;
  /** 連絡先、企業、建物担当者のいずれか */
  type: TabType;
};

/**
 * @summary 連絡先情報
 */
export type User = {
  /** ユーザID */
  readonly user_id: number;
  /** ユーザ名 */
  readonly user_name: string;
  /** 企業ID */
  readonly company_id: number;
  /** 企業名 */
  readonly company_name: string;
  /** 所属 */
  readonly department: string;
  /** 担当 */
  readonly belong: string;
  /** メールアドレス */
  readonly email: string;
  /** 備考 */
  readonly remarks: string;
};

/**
 * @summary APIから返却される連絡先リスト
 */
export type APIGetUserResponse = {
  /** 連絡先リスト */
  result: User[];
};

/**
 * @summary 企業情報
 */
export type Company = {
  /** 企業ID */
  readonly company_id: number;
  /** 企業名 */
  readonly company_name: string;
  /** 住所 */
  readonly address: string;
};

/**
 * @summary APIから返却される企業リスト
 */
export type APIGetCompanyResponse = {
  /** 企業リスト */
  result: Company[];
};

/**
 * @summary 連絡先リスト検索Param
 */
export type APIGetRequestParams = {
  /** 建物ID */
  /** 検索キーワード */
  keyword: string;
  property_id?: number;
  /** 管理者のみか */
  is_admin?: boolean;
};
