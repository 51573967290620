import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { InspectionChecklist } from '@ducks/inspectionChecklist/types';

export type InspectionChecklistEditState = {
  refresh: Record<string, never>;
  isLoading: boolean;
  isSubmitting: boolean;
  isNavigating: boolean; // edit -> detail (when publish)
  isNavigating2: boolean; // new -> edit (when create new)
  isNavigatingTo: number | null;
  checklist: InspectionChecklist | null;
  layout: Layout;
  publishedLayoutComponentIds: string[];
};

export enum InspectionChecklistEditActionTypes {
  INIT = 'inspectionChecklistEdit/init',
  REFRESH = 'inspectionChecklistEdit/refresh',
  LOADING = 'inspectionChecklistEdit/set/isLoading',
  SUBMITTING = 'inspectionChecklistEdit/set/isSubmitting',
  NAVIGATING = 'inspectionChecklistEdit/set/isNavigating',
  NAVIGATING2 = 'inspectionChecklistEdit/set/isNavigating2',
  NAVIGATING_TO = 'inspectionChecklistEdit/set/isNavigatingTo',
  SET_CHECKLIST = 'inspectionChecklistEdit/set/checklist',
  SET_LAYOUT = 'inspectionChecklistEdit/set/layout',
  SET_PUBLISHED = 'inspectionChecklistEdit/set/publishedLayoutComponentIds',
  ADD_NEW_GROUP = 'inspectionChecklistEdit/add/group',
  ADD_NEW_SECTION = 'inspectionChecklistEdit/add/section',
  ADD_NEW_COMPONENT = 'inspectionChecklistEdit/add/component',
  SET_GROUP_NAME = 'inspectionChecklistEdit/set/groupName',
  SET_SECTION_NAME = 'inspectionChecklistEdit/set/sectionName',
  SET_SECTION_EQUIPMENT = 'inspectionChecklistEdit/set/sectionEquipment',
  SET_COMPONENT = 'inspectionChecklistEdit/set/component',
  COPY_GROUP = 'inspectionChecklistEdit/copy/group',
  COPY_SECTION = 'inspectionChecklistEdit/copy/section',
  COPY_COMPONENT = 'inspectionChecklistEdit/copy/component',
  CHANGE_GROUP_ORDER = 'inspectionChecklistEdit/change/groupOrder',
  CHANGE_SECTION_ORDER = 'inspectionChecklistEdit/change/sectionOrder',
  CHANGE_COMPONENT_ORDER = 'inspectionChecklistEdit/change/componentOrder',
  DISABLE_GROUP = 'inspectionChecklistEdit/disable/group',
  DISABLE_SECTION = 'inspectionChecklistEdit/disable/section',
  DISABLE_COMPONENT = 'inspectionChecklistEdit/disable/component',
  DELETE_GROUP = 'inspectionChecklistEdit/delete/group',
  DELETE_SECTION = 'inspectionChecklistEdit/delete/section',
  DELETE_COMPONENT = 'inspectionChecklistEdit/delete/component',
}

export type InspectionChecklistEditAction =
  | Action<InspectionChecklistEditActionTypes.INIT>
  | Action<InspectionChecklistEditActionTypes.REFRESH>
  | PayloadAction<boolean, InspectionChecklistEditActionTypes.LOADING>
  | PayloadAction<boolean, InspectionChecklistEditActionTypes.SUBMITTING>
  | PayloadAction<boolean, InspectionChecklistEditActionTypes.NAVIGATING>
  | PayloadAction<boolean, InspectionChecklistEditActionTypes.NAVIGATING2>
  | PayloadAction<number | null, InspectionChecklistEditActionTypes.NAVIGATING_TO>
  | PayloadAction<InspectionChecklist | null, InspectionChecklistEditActionTypes.SET_CHECKLIST>
  | PayloadAction<Layout, InspectionChecklistEditActionTypes.SET_LAYOUT>
  | PayloadAction<string[], InspectionChecklistEditActionTypes.SET_PUBLISHED>
  | PayloadAction<
      { groupName: string; sectionName: string },
      InspectionChecklistEditActionTypes.ADD_NEW_GROUP
    >
  | PayloadAction<
      { groupIndex: number; sectionName: string },
      InspectionChecklistEditActionTypes.ADD_NEW_SECTION
    >
  | PayloadAction<
      {
        groupIndex: number;
        sectionIndex: number;
        component: {
          label: string;
          type: LayoutComponentType;
          required: boolean;
          config?: LayoutComponentConfig[];
        };
      },
      InspectionChecklistEditActionTypes.ADD_NEW_COMPONENT
    >
  | PayloadAction<
      { groupIndex: number; groupName: string },
      InspectionChecklistEditActionTypes.SET_GROUP_NAME
    >
  | PayloadAction<
      { groupIndex: number; sectionIndex: number; sectionName: string },
      InspectionChecklistEditActionTypes.SET_SECTION_NAME
    >
  | PayloadAction<
      { groupIndex: number; sectionIndex: number; equipment: Equipment | null },
      InspectionChecklistEditActionTypes.SET_SECTION_EQUIPMENT
    >
  | PayloadAction<
      {
        groupIndex: number;
        sectionIndex: number;
        componentIndex: number;
        component: Partial<LayoutComponent>;
      },
      InspectionChecklistEditActionTypes.SET_COMPONENT
    >
  | PayloadAction<{ groupIndex: number }, InspectionChecklistEditActionTypes.COPY_GROUP>
  | PayloadAction<
      { groupIndex: number; sectionIndex: number },
      InspectionChecklistEditActionTypes.COPY_SECTION
    >
  | PayloadAction<
      { groupIndex: number; sectionIndex: number; componentIndex: number },
      InspectionChecklistEditActionTypes.COPY_COMPONENT
    >
  | PayloadAction<
      { groupIndex: number; isUp: boolean },
      InspectionChecklistEditActionTypes.CHANGE_GROUP_ORDER
    >
  | PayloadAction<
      { groupIndex: number; sectionIndex: number; isUp: boolean },
      InspectionChecklistEditActionTypes.CHANGE_SECTION_ORDER
    >
  | PayloadAction<
      { groupIndex: number; sectionIndex: number; componentIndex: number; isUp: boolean },
      InspectionChecklistEditActionTypes.CHANGE_COMPONENT_ORDER
    >
  | PayloadAction<
      { groupIndex: number; disable: boolean },
      InspectionChecklistEditActionTypes.DISABLE_GROUP
    >
  | PayloadAction<
      { groupIndex: number; sectionIndex: number; disable: boolean },
      InspectionChecklistEditActionTypes.DISABLE_SECTION
    >
  | PayloadAction<
      { groupIndex: number; sectionIndex: number; componentIndex: number; disable: boolean },
      InspectionChecklistEditActionTypes.DISABLE_COMPONENT
    >
  | PayloadAction<{ groupIndex: number }, InspectionChecklistEditActionTypes.DELETE_GROUP>
  | PayloadAction<
      { groupIndex: number; sectionIndex: number },
      InspectionChecklistEditActionTypes.DELETE_SECTION
    >
  | PayloadAction<
      { groupIndex: number; sectionIndex: number; componentIndex: number },
      InspectionChecklistEditActionTypes.DELETE_COMPONENT
    >;

// -- State Types ---------------------------------------------------------- //

export type Layout = {
  groups: LayoutGroup[];
};

export type LayoutGroup = { title: string; disable: boolean; sections: LayoutSection[] };

export type LayoutSection = {
  title: string;
  disable: boolean;
  equipment: Equipment | null;
  components: LayoutComponent[];
};

export type LayoutComponent = {
  id: string;
  type: LayoutComponentType;
  label: string;
  disable: boolean;
  required: boolean;
  config: LayoutComponentConfig[];
};

export type LayoutComponentType = 'Choice' | 'NumberInput' | 'ComboBox' | 'TextBox' | 'TextArea';

export type LayoutComponentConfig = { label: string; value: any; abnormal?: boolean };

export type Equipment = {
  equipment_id: number;
  equipment_name: string;
};

// -- API Types ------------------------------------------------------------ //

export type APICreateInspectionChecklistRequest = {
  title: string;
  description: string;
  inspection_type: number;
  layout_information: any;
};

export type APICreateInspectionChecklistResponse = {
  message: string;
  result: { inspection_checklist_id: number };
};
