import { HomeState, HomeAction, HomeActionTypes as ActionTypes } from './types';

const initialHomeState: HomeState = {
  isLoading: false,
  tasks: [],
  tasksCount: 0,
  checkingTasks: [],
  checkingTasksCount: 0,
  incompleteConstruction: [],
  incompleteConstructionCount: 0,
  checkingConstruction: [],
  checkingConstructionCount: 0,
  incompleteTrouble: [],
  incompleteTroubleCount: 0,
  checkingTrouble: [],
  checkingTroubleCount: 0,
};

const homeReducer = (state: HomeState = initialHomeState, action: HomeAction): HomeState => {
  switch (action.type) {
    case ActionTypes.INIT:
      return initialHomeState;

    case ActionTypes.SET_LOADING:
      return { ...state, isLoading: action.payload };

    case ActionTypes.SET_DATA: {
      const data = action.payload;
      return {
        ...state,
        tasks: data.tasks,
        tasksCount: data.tasksCount,
        checkingTasks: data.checkingTasks,
        checkingTasksCount: data.checkingTasksCount,
        incompleteConstruction: data.incompleteConstruction,
        incompleteConstructionCount: data.incompleteConstructionCount,
        checkingConstruction: data.checkingConstruction,
        checkingConstructionCount: data.checkingConstructionCount,
        incompleteTrouble: data.incompleteTrouble,
        incompleteTroubleCount: data.incompleteTroubleCount,
        checkingTrouble: data.checkingTrouble,
        checkingTroubleCount: data.checkingTroubleCount,
      };
    }

    default:
      return state;
  }
};

export default homeReducer;
