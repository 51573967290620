import {
  CheckingAction as Action,
  CheckingActionTypes as ActionTypes,
  CheckingListState,
} from './types';

export const init = (): Action => ({ type: ActionTypes.INIT });

export const refresh = (): Action => ({ type: ActionTypes.REFRESH });

export const setIsLoading = (isLoading: boolean): Action => ({
  type: ActionTypes.LOADING,
  payload: isLoading,
});

export const setIsSubmitting = (isSubmitting: boolean): Action => ({
  type: ActionTypes.SUBMITTING,
  payload: isSubmitting,
});

/** 査収一覧 */
export const setCheckingList = (checkingList: CheckingListState | null): Action => ({
  type: ActionTypes.SET_CHECKING_LIST,
  payload: checkingList,
});
