import {
  SelectAffiliateAction as Action,
  SelectAffiliateActionTypes as ActionTypes,
  TabType,
  SelectedItem,
  User,
  Company,
  APIGetPropertyUserPropertiesRequestParams
} from './types';

/**
 * @summary 初期化処理
 */
export const init = (): Action => ({
  type: ActionTypes.INIT,
});

/**
 * @summary Loading状態を保持
 * @param {boolean} isLoading
 */
export const setIsLoading = (isLoading: boolean): Action => ({
  type: ActionTypes.LOADING,
  payload: isLoading,
});

/**
 * @summary APIから取得したユーザを保持
 * @param {User[]} userList
 */
export const setAffiliateUserList = (userList: User[]): Action => ({
  type: ActionTypes.SET_USER_LIST,
  payload: userList,
});

/**
 * @summary APIから取得した企業を保持
 * @param {Company[]} companyList
 */
export const setAffiliateCompanyList = (companyList: Company[]): Action => ({
  type: ActionTypes.SET_COMPANY_LIST,
  payload: companyList,
});

/**
 * @summary APIから取得した建物担当者を保持
 * @param {User[]} propertyUserList
 */
export const setAffiliatePropertyUserList = (propertyUserList: User[]): Action => ({
  type: ActionTypes.SET_PROPERTY_USER_LIST,
  payload: propertyUserList,
});

/**
 * @summary タブの切り替え
 * @param {TabType} tab
 */
export const setTab = (tab: TabType): Action => ({
  type: ActionTypes.SET_TAB,
  payload: tab,
});

/**
 * @summary 建物担当者でのみ使用する検索パラメータを保持
 * @param {APIGetPropertyUserPropertiesRequestParams} propertyUserParameter
 */
export const setPropertyUserParameter = (propertyUserParameter: APIGetPropertyUserPropertiesRequestParams): Action => ({
  type: ActionTypes.SET_PROPERTY_USER_SEARCH_PARAMETER,
  payload: propertyUserParameter,
});

/**
 * @summary 選択した状態の連絡先を保持
 * @param {SelectedItem} selectedItem
 */
export const setSelectedItem = (selectedItem: SelectedItem): Action => ({
  type: ActionTypes.SET_SELECTED_ITEM,
  payload: selectedItem,
});

/**
 * @summary 選択確定したSelectAffiliateTabTypeを保持
 * @param {TabType} submitItemType
 */
export const setSubmitItemType = (submitItemType: TabType): Action => ({
  type: ActionTypes.SET_SUBMIT_ITEM_TYPE,
  payload: submitItemType,
});

/**
 * @summary 選択確定した状態の連絡先を保持
 * @param {User|null} submitItem
 */
export const setSubmitUserItem = (submitItem: User|null): Action => ({
  type: ActionTypes.SET_SUBMIT_USER_ITEM,
  payload: submitItem,
});

/**
 * @summary 選択確定した状態の企業を保持
 * @param {Company|null} submitItem
 */
export const setSubmitCompanyItem = (submitItem: Company|null): Action => ({
  type: ActionTypes.SET_SUBMIT_COMPANY_ITEM,
  payload: submitItem,
});

/**
 * @summary 選択確定した状態の建物担当者を保持
 * @param {User|null} submitItem
 */
export const setSubmitPropertyUserItem = (submitItem: User|null): Action => ({
  type: ActionTypes.SET_SUBMIT_PROPERTY_USER_ITEM,
  payload: submitItem,
});
