import React, { useEffect } from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { auth0 } from '@providers/auth0AccessToken';
import { useMessageSource } from 'react-message-source';

const axiosInstance = axios.create({
  baseURL: '/api',
  timeout: process.env.NODE_ENV === 'development' ? 30000 : 3600000,
  retries: 5,
  retryCount: 0,
  // 内部固定のエラー通知判定？使用してないけど既存(v1.3～)で条件として参照しているため、各判定で使用する。
  ignoreErrors: false,
});

export default axiosInstance;

export const AxiosProvider = ({ children }: { children: React.ReactNode }) => {
  const { getMessage } = useMessageSource();
  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance.interceptors.request.use(async (request) => {
      // Auth0 アクセストークンで API を認証する
      if (request.headers) request.headers['Authorization'] = `Bearer ${await getApiToken()}`;
      return request;
    });

    axiosInstance.interceptors.response.use(
      (response) => {
        if (process.env.NODE_ENV === 'development') printApiDebugLog(response);
        return response;
      },
      async (error: AxiosError<{ message: string; status: number }>) => {
        // [リトライ考慮]
        // 通信のリトライ発生時に2回目以降のタイムアウトは無視したい。
        // 初回（1）と2回目以降（2～）を内部通信資源（ error.config.retryCount, error.config.ignoreErrors ）で切り分ける
        error.config.retryCount = (error.config.retryCount ?? 0) + 1;
        // タイムアウトが発生した場合に retries の回数までリトライする
        if (error.code === 'ECONNABORTED') {
          // ただし安全性確保のためリトライするのは GET メソッドのみに限定する
          if (error.config.method === 'get') {
            if ((error.config.retries ?? 0) > error.config.retryCount) {
              return axiosInstance.request(error.config);
            }
            // タイムアウトエラーケースに該当（HTTPリトライのすべてがタイムアウトケースで、特定ケースのみ）
            //  <メイン判定>
            // ・リトライ回数はすでに消化=>メッセージ出さないといけない
            else if (
              !error.config.ignoreErrors &&
              error.config.retryCount === (error.config.retries ?? 0)
            ) {
              alert(getMessage('common.system.timeout'));
              console.error(`Timeout: ${error.config.url}`);
            }
          }
          // POST系のタイムアウトは、リトライ送信はしないがHTTP規約に従って複数回通知されるので、
          // 「初回」のエラーだけの分をエラー表示するため一度アラート表示を行ったらignoreErrorsをtrueに設定する
          else if (!error.config.ignoreErrors) {
            error.config.ignoreErrors = true;
            alert(getMessage('common.system.timeout'));
            console.error(`Timeout: ${error.config.url}`);
          }
        }
        // タイムアウト以外（4xx～5xx）が発生時は、同様にリトライした結果のエラー
        // 初回のエラー通知ケースだけを見るように一度アラート表示を行ったらignoreErrorsをtrueに設定する
        else if (!error.config.ignoreErrors) {
          printApiErrorLog(error);
          error.config.ignoreErrors = true;
          switch (error.response?.status) {
            case 400:
              // アラートダイアログを表示する
              if (error.response?.data.message) {
                alert(error.response.data.message);
              }
              break;
            case 403:
            case 404:
              // アラートダイアログを表示してホーム画面に遷移する
              if (error.response?.data.message) {
                alert(error.response.data.message);
              }
              navigate('/home');
              break;
            case 500:
              // エラー画面に遷移する
              navigate('/error', { state: { status: 500 } });
              break;
            default:
              if (error.response?.data.message) {
                alert(error.response.data.message);
              }
              break;
          }
        }
        return Promise.reject(error);
      },
    );
  }, [getMessage, navigate]);

  return <>{children}</>;
};

const getApiToken = () => {
  const token = auth0.getAccessTokenSilently();
  return token;
};

const printApiDebugLog = (response: AxiosResponse) => {
  try {
    const method = _.upperCase(response.config.method);
    const apiRoute = `/api${response.config.url}`;
    console.groupCollapsed(
      `%c api%c ${method} ${apiRoute}`,
      'color: gray; font-weight: 100',
      'color: black; font-weight: bold',
    );
    console.debug(
      `Status: ${response.status}`,
      `\nParams: `,
      response.config.params ?? {},
      `\nRequest: `,
      JSON.parse(response.config.data ?? '{}') ?? {},
      `\nResponse: `,
      response.data ?? {},
    );
  } catch {
    console.debug('response data error!');
  } finally {
    console.groupEnd();
  }
};

const printApiErrorLog = (error: AxiosError) => {
  if (process.env.NODE_ENV === 'development') {
    const method = _.upperCase(error.config.method);
    const apiRoute = `/api${error.config.url}`;
    console.groupCollapsed(
      `%c api%c ${method} ${apiRoute}`,
      'color: gray; font-weight: 100',
      'color: red; font-weight: bold',
    );
    console.error(
      `Status: ${error.response?.status}`,
      `\nParams: `,
      error.config.params ?? {},
      `\nRequest: `,
      error.config.data ?? {},
      `\nResponse: `,
      error.response?.data ?? {},
    );
    console.groupEnd();
  }
};
