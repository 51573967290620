import { ArchiveState, ArchiveAction, ArchiveActionTypes as ActionTypes } from './types';

const initialArchiveState: ArchiveState = {
  refresh: {},
  isLoading: false,
  isSubmitting: false,
  archives: [],
  paths: [],
  parentArchiveId: null,
  checkFilesOverWrite: [],
};

const archiveReducer = (
  state: ArchiveState = initialArchiveState,
  action: ArchiveAction,
): ArchiveState => {
  switch (action.type) {
    case ActionTypes.INIT:
      return initialArchiveState;

    case ActionTypes.REFRESH:
      return { ...state, refresh: {} };

    case ActionTypes.LOADING:
      return { ...state, isLoading: action.payload };

    case ActionTypes.SUBMITTING:
      return { ...state, isSubmitting: action.payload };

    case ActionTypes.SET_ARCHIVES:
      return {
        ...state,
        archives: action.payload.archives,
        paths: action.payload.paths,
        parentArchiveId: action.payload.parentArchiveId,
      };

    case ActionTypes.SET_CHECK_FILES_OVERWRITE:
      return { ...state, checkFilesOverWrite: action.payload };

    default:
      return state;
  }
};

export default archiveReducer;
