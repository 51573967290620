import moment from 'moment';

type FormatPattern = {
  [key: string]: string[];
};

/**
 * 日付文字列を異なるフォーマット形式を変換する
 * @params value 変換対象の日付文字列
 * @params formatPattern フォーマットのパターン
 *
 *   'ui': 'YYYY-MM-DD' -> 'YYYY/MM/DD'
 *
 *   'data': 'YYYY/MM/DD' -> 'YYYY-MM-DD'
 *
 * @returns 変換後の日付文字列
 */
export const formatDateString = (value: string, formatPattern: 'ui' | 'data' = 'ui'): string => {
  const [f1, f2] = DATE_FORMAT_PATTERN[formatPattern];
  const date = moment(value, f1);
  const result = date.isValid() ? date.format(f2) : '';
  return result;
};

const DATE_FORMAT_PATTERN: FormatPattern = {
  ui: ['YYYY-MM-DD', 'YYYY/MM/DD'],
  data: ['YYYY/MM/DD', 'YYYY-MM-DD'],
};

/**
 * Datetime 文字列を異なるフォーマット形式を変換する
 * @params value 変換対象の日付文字列
 * @params formatPattern フォーマットのパターン
 *
 *   'ui': 'YYYY-MM-DDTHH:mm:SS' -> 'YYYY/MM/DD HH:mm'
 *
 * @returns 変換後の Datetime 文字列
 */
export const formatDateTimeString = (value: string, formatPattern: 'ui' = 'ui'): string => {
  const [f1, f2] = DATETIME_FORMAT_PATTERN[formatPattern];
  const date = moment(value, f1);
  const result = date.isValid() ? date.format(f2) : '';
  return result;
};

const DATETIME_FORMAT_PATTERN: FormatPattern = {
  ui: ['YYYY-MM-DDTHH:mm:SS', 'YYYY/MM/DD HH:mm'],
};

/**
 * Time 文字列を異なるフォーマット形式を変換する
 * @params value 変換対象の日付文字列
 * @params formatPattern フォーマットのパターン
 *
 *   'ui': 'HH:mm:SS' -> 'HH:mm'
 *
 * @returns 変換後の Datetime 文字列
 */
export const formatTimeString = (value: string, formatPattern: 'ui' = 'ui'): string => {
  const [f1, f2] = TIME_FORMAT_PATTERN[formatPattern];
  const date = moment(value, f1);
  const result = date.isValid() ? date.format(f2) : '';
  return result;
};

const TIME_FORMAT_PATTERN: FormatPattern = {
  ui: ['HH:mm:SS', 'HH:mm'],
};

/**
 * 与えられた日付時刻が現時点からどれくらい前のものかを取得する
 * @params value 日付時刻 ('YYYY-MM-DDTHH:mm:SS')
 */
export const getDateTimeFromNow = (value: string): string => {
  const datetime = moment(value, 'YYYY-MM-DDTHH:mm:SS');
  const result = datetime.isValid() ? datetime.fromNow() : '';
  return result;
};

/**
 * 与えられた日付が本日の日付かどうかを判別する
 * @params value 日付 ('YYYY-MM-DD')
 */
export const checkDateIsToday = (value: string): boolean => {
  const date = moment(value, 'YYYY-MM-DD');
  const result = date.isValid() ? date.isSame(moment(), 'day') : false;
  return result;
};

/**
 * 現在の日付を取得する
 */
export const getDateNow = (): string => {
  const date = moment();
  const result = date.format('YYYY-MM-DD');
  return result;
};

/**
 * 現在の年を取得する
 */
export const getYearNow = (): string => {
  const date = moment();
  const result = date.format('YYYY');
  return result;
};

/**
 * 現在の月を取得する
 */
export const getMonthNow = (): string => {
  const date = moment();
  const result = date.format('MM');
  return result;
};

/**
 * 現在の日付を取得する
 */
export const getDayNow = (): string => {
  const date = moment();
  const result = date.format('DD');
  return result;
};

/**
 * 現在の時刻を取得する
 */
export const getTimeNow = (): string => {
  const date = moment();
  const result = date.format('HH:mm');
  return result;
};

/**
 * 指定した日付の曜日を取得する
 */
export const getWeekday = (year: number, month: number, day: number): number => {
  const target = moment([year, month - 1, day]);
  const result = target.day();
  return result;
};

/**
 * 与えられた年月から年と月を取得する
 * @params value 年月 ('YYYY/MM')
 */
export const getYearAndMonth = (value: string) => {
  const m = moment(value, 'YYYY/MM');
  const year = Number(m.format('YYYY')) || null;
  const month = Number(m.format('MM')) || null;
  const result = year != null && month != null ? { year, month } : null;
  return result;
};

/**
 * 与えられた年月から月部分を抽出する
 * @params value 年月 ('YYYY/MM')
 */
export const getMonth = (value: string): number | null => {
  const month = moment(value, 'YYYY/MM').format('MM');
  const result = Number(month);
  return result || null;
};

/**
 * 与えられた日付から日付部分を抽出する
 * @params value 日付 ('YYYY/MM/DD')
 */
export const getDay = (value: string): number | null => {
  const day = moment(value, 'YYYY/MM/DD').format('DD');
  const result = Number(day);
  return result || null;
};

/**
 * 指定した月の日数を取得する
 */
export const getDaysInMonth = (year: number, month: number): number => {
  return new Date(year, month, 0).getDate();
};

/**
 * 'YYYY/MM' 形式の月がある範囲内に収まっているかどうかを取得する
 *
 * case: m1 <= v <= m2 - return true else return false
 *
 * e.g. [m1:"2022/01" < v:"2022/06" < m2:"2022/12"] => true
 */
export const checkIsMonthInRange = (v: string, m1: string, m2: string): boolean => {
  const _v = moment(v, 'YYYY/MM');
  const _m1 = moment(m1, 'YYYY/MM');
  const _m2 = moment(m2, 'YYYY/MM');

  const check1 = _v.isAfter(_m1) || _v.isSame(_m1);
  const check2 = _v.isBefore(_m2) || _v.isSame(_m2);

  return check1 && check2;
};

/**
 * 現在からn年後の月を取得する
 */
export const calculateYearFromNow = (n: number) => {
  const date = moment();
  const year = Number(date.format('YYYY'));
  const month = Number(date.format('MM'));
  const targetYear = year + n;
  const result = moment(new Date(targetYear, month - 1, 1)).format('YYYY-MM');
  return result;
};

export const checkComparisonIsAfter = (m1: string, m2: string): boolean => {
  if (!m1 || !m2) return false;
  const _m1 = moment(m1, 'YYYY/MM/DD');
  const _m2 = moment(m2, 'YYYY/MM/DD');

  const check = _m1.isAfter(_m2);
  return check;
};
