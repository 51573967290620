import axios from '@providers/axiosProvider';
import * as actions from './actions';

import {
  APIGetNotificationsResponse,
  APIUpdateNotification,
  NotificationSubmitData,
  APIPatchNotificationsResponse,
} from './types';

/**
 * @summary 通知設定のデータ取得用 GET API
 */
export const getNotificationsAsync = () => async (dispatch: any) => {
  dispatch(actions.setIsLoading(true));

  await axios
    .get<APIGetNotificationsResponse>('/users/notifications')
    .then((response) => {
      const { notifications } = response.data.result;

      dispatch(actions.setNotifications(notifications));
    })
    .catch(() => {
      /* axiosProvider で処理するため、ここでは何もしない */
    });

  dispatch(actions.setIsLoading(false));
};

/**
 * @summary 通知設定のデータ更新用 PATCH API
 */
export const updateNotificationsAsync = (data: NotificationSubmitData) => async (dispatch: any) => {
  dispatch(actions.setIsSubmitting(true));

  const requestData: APIUpdateNotification = {
    is_property_updated: data.is_property_updated,
    is_contract_updated: data.is_contract_updated,
    is_property_information_updated: data.is_property_information_updated,
    is_property_group_user_updated: data.is_property_group_user_updated,
    is_inspection_applied: data.is_inspection_applied,
    is_inspection_approved: data.is_inspection_approved,
    is_inspection_rejected: data.is_inspection_rejected,
    is_inspection_comments: data.is_inspection_comments,
    is_construction_applied: data.is_construction_applied,
    is_construction_approved: data.is_construction_approved,
    is_construction_rejected: data.is_construction_rejected,
    is_construction_comments: data.is_construction_comments,
    is_trouble_created: data.is_trouble_created,
    is_trouble_updated: data.is_trouble_updated,
    is_trouble_applied: data.is_trouble_applied,
    is_trouble_completed: data.is_trouble_completed,
    is_trouble_rejected: data.is_trouble_rejected,
    is_trouble_pending: data.is_trouble_pending,
    is_trouble_shooting: data.is_trouble_shooting,
    is_trouble_comments: data.is_trouble_comments,
  };

  await axios
    .patch<APIPatchNotificationsResponse>(`/users/notifications/edit`, requestData)
    .then(() => {
      dispatch(actions.refresh());
    })
    .catch(() => {
      /* axiosProvider で処理するため、ここでは何もしない */
    });

  dispatch(actions.setIsSubmitting(false));
};
