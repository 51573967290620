import type { Action, PayloadAction } from '@reduxjs/toolkit';
export type CheckingState = {
  refresh: Record<string, never>;
  isLoading: boolean;
  isSubmitting: boolean;
  checkingList: CheckingListState | null;
};

export enum CheckingActionTypes {
  INIT = 'checking/init',
  REFRESH = 'checking/refresh',
  LOADING = 'checking/set/isLoading',
  SUBMITTING = 'checking/set/isSubmitting',
  SET_CHECKING_LIST = 'checking/set/setCheckingList',
}

export type CheckingAction =
  | Action<CheckingActionTypes.INIT>
  | Action<CheckingActionTypes.REFRESH>
  | PayloadAction<boolean, CheckingActionTypes.LOADING>
  | PayloadAction<boolean, CheckingActionTypes.SUBMITTING>
  | PayloadAction<CheckingListState | null, CheckingActionTypes.SET_CHECKING_LIST>;

// -- State Types ---------------------------------------------------------- //
export type CheckingList = {
  categoryId: number;
  categoryName: string;
  startDate: string;
  updateDate: string;
  typeId: string;
  typeName: string;
  checkingId: number;
  title: string;
  abnormalNum: string;
  inspectionComment: string;
  lastPicUserId: number;
  lastPicUserName: string;
  propertyId: number;
  propertyName: string;
};

export type display_params = {
  numPerPage: number;
  currentPage: number;
  totalPage: number;
  total: number;
  sort: string;
};

/** 査収一覧 reduxState*/
export type CheckingListState = {
  checkingList: CheckingList[];
  displayParams: display_params;
};

/**
 * 査収一覧のlocationのstateタイプ
 */
export interface CheckingQuery {
  propertyId?: number | null;
  page?: number;
  sort?: string;
  sizePerPage?: number;
  tag?: string | null;
}

/** 査収一覧取得時に使用されるlocationのstate */
export type CheckingStateQuery = CheckingQuery;

/** 査収一覧画面のlocationのstate */
export type CheckingListQuery = CheckingStateQuery &
  CheckingQuery & {
    order: boolean;
    pageH: number;
  };

// -- API Types ------------------------------------------------------------ //

export type APICheckingList = {
  category_id: number;
  category_name: string;
  start_date: string;
  update_date: string;
  type_id: string;
  type_name: string;
  checking_id: number;
  title: string;
  abnormal_num: string;
  inspection_comment: string;
  last_pic_user_id: number;
  last_pic_user_name: string;
  property_id: number;
  property_name: string;
};

export type APICheckingListInfo = {
  num_per_page: number;
  current_page: number;
  total_page: number;
  total: number;
  sort: string;
};

/** 査収一覧取得API requestParams */
export type APIGetCheckingRequestParams = {
  property_id: number | null;
  page: number;
  sort: string;
  size_per_page?: number;
  tag: string | null;
};

/** 査収一覧取得API response*/
export type APIGetCheckingResponse = {
  result: {
    checking_list: APICheckingList[];
    display_params: APICheckingListInfo;
  };
};
