import type { Action, PayloadAction } from '@reduxjs/toolkit';

export type PlanAdjustmentState = {
  refresh: Record<string, never>;
  isLoading: boolean;
  isSubmitting: boolean;
  isInvalidToken: boolean;
  plans: AdjustmentPlan[];
  summary: AdjustmentPlanSummary | null;
};

export enum PlanAdjustmentActionTypes {
  INIT = 'plan/adjustment/init',
  REFRESH = 'plan/adjustment/refresh',
  LOADING = 'plan/adjustment/set/isLoading',
  SUBMITTING = 'plan/adjustment/set/isSubmitting',
  INVALID = 'plan/adjustment/set/isInvalidToken',
  SET_PLANS = 'plan/adjustment/set/plans',
  SET_SUMMARY = 'plan/adjustment/set/summary',
}

export type PlanAdjustmentAction =
  | Action<PlanAdjustmentActionTypes.INIT>
  | Action<PlanAdjustmentActionTypes.REFRESH>
  | PayloadAction<boolean, PlanAdjustmentActionTypes.LOADING>
  | PayloadAction<boolean, PlanAdjustmentActionTypes.SUBMITTING>
  | PayloadAction<boolean, PlanAdjustmentActionTypes.INVALID>
  | PayloadAction<AdjustmentPlan[], PlanAdjustmentActionTypes.SET_PLANS>
  | PayloadAction<AdjustmentPlanSummary | null, PlanAdjustmentActionTypes.SET_SUMMARY>;

// -- State Types ---------------------------------------------------------- //

export type AdjustmentPlanSummary = {
  month: string; // "YYYY/MM"
  comment: string;
};

export type AdjustmentPlan = {
  dailyTaskId: number;
  startDate: string;
  endDate: string;
  schedulingStatusId: number;
  schedulingStatusName: string;
  startTime: string;
  endTime: string;
  propertyId: number;
  propertyName: string;
  typeId: number;
  typeName: string;
  title: string;
  readjustmentRequest: {
    startDate: string | null;
    startTime: string | null;
    endDate: string | null;
    endTime: string | null;
    comment: string;
  } | null;
  isAnswered: boolean;
};

export type ReadjustmentsSubmitData = {
  dailyTaskId: number;
  status: number;
  startDate: string | null;
  startTime: string | null;
  endDate: string | null;
  endTime: string | null;
  comment: string | null;
}[];

// -- API Types ------------------------------------------------------------ //

export type APIAdjustmentPlans = {
  readonly month: string;
  readonly comment: string;
  readonly data: {
    readonly property_id: number;
    readonly property_name: string;
    readonly plan_id: number;
    readonly start_date: string;
    readonly end_date: string;
    readonly scheduling_status_id: number;
    readonly scheduling_status_name: string;
    readonly start_time: string;
    readonly end_time: string;
    readonly type_id: number;
    readonly type_name: string;
    readonly title: string;
    readonly readjustment_request: {
      readonly start_date: string | null;
      readonly start_time: string | null;
      readonly end_date: string | null;
      readonly end_time: string | null;
      readonly comment: string;
    };
  }[];
};

export type APISendReadjustments = {
  daily_task_id: number;
  status: number;
  start_date: string | null;
  start_time: string | null;
  end_date: string | null;
  end_time: string | null;
  comment: string | null;
};

export type APIGetAdjustmentPlansRequestParams = {
  token: string;
  month: string;
};
export type APIGetAdjustmentPlansResponse = {
  result: APIAdjustmentPlans;
};

export type APISendReadjustmentsRequestParams = {
  token: string;
};
export type APISendReadjustmentsRequest = {
  month: string;
  daily_task_list: APISendReadjustments[];
};
