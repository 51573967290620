import axios from '@providers/axiosProvider';
import * as actions from './actions';
import {
  APIGetProfileResponse,
  APIGetPropertyListResponse,
  PropertyListWithIsFavorite,
  APIPostRegisterAndRemovePropertyFavoriteResponse,
} from './types';

/**
 * @summary 建物選択フィールド用 建物リスト取得 GET API
 * @param {string} keyword
 */
export const getPropertyListAsync = (keyword?: string) => async (dispatch: any) => {
  dispatch(actions.setIsLoading(true));

  await axios
    .get<APIGetPropertyListResponse>(`/users/properties`, {
      params: {
        name: keyword,
      },
    })
    .then((response) => {
      const properties = response.data.result;
      const propertyList: PropertyListWithIsFavorite[] = properties.map((property) => ({
        id: property.property_id,
        name: property.property_name,
        is_favorite: property.is_favorite,
      }));
      dispatch(actions.setPropertyList(propertyList));
    })
    .catch(() => {
      dispatch(actions.setPropertyList([]));
    });

  dispatch(actions.setIsLoading(false));
};

/**
 * @summary タグ選択フィールド用 建物リスト取得 GET API
 * @param {number[]} tagIds
 * @param {string} keyword
 */
export const getPropertyListFilteredByTagIdsAsync =
  (tagIds: number[], keyword?: string) => async (dispatch: any) => {
    dispatch(actions.setIsLoading(true));

    const tagIdsStr = tagIds.join(',');

    await axios
      .get<APIGetPropertyListResponse>(`/users/properties`, {
        params: {
          tag: tagIdsStr,
          name: keyword,
        },
      })
      .then((response) => {
        const properties = response.data.result;
        const propertyList: PropertyListWithIsFavorite[] = properties.map((property) => ({
          id: property.property_id,
          name: property.property_name,
          is_favorite: property.is_favorite,
        }));
        dispatch(actions.setPropertyListFilteredByTagIds(propertyList));
      })
      .catch(() => {
        dispatch(actions.setPropertyListFilteredByTagIds([]));
      });

    dispatch(actions.setIsLoading(false));
  };

/**
 * @summary 建物お気に入り登録・解除
 * @param {number} property_id
 */
export const registerAndRemovePropertyFavoriteAsync =
  (property_id: number, is_favorite: boolean) => async (dispatch: any) => {
    dispatch(actions.setIsSubmitting(true));

    await axios
      .post<APIPostRegisterAndRemovePropertyFavoriteResponse>(
        `/users/properties/${property_id}/favorite`,
        { is_favorite },
      )
      .then(() => {
        dispatch(actions.refresh());
      })
      .catch(() => {});

    actions.setIsSubmitting(false);
  };

export const getProfileAsync = () => async (dispatch: any) => {
  await axios
    .get<APIGetProfileResponse>('/users/authority')
    .then((response) => {
      const profile = response.data.result;
      dispatch(
        actions.setProfile({
          userId: profile.user_id,
          firstName: profile.first_name,
          lastName: profile.last_name,
          email: profile.email,
          icon: profile.file_url,
          isSystemAdmin: profile.is_system_role,
          properties: profile.property_list.map((v) => ({
            id: v.property_id,
            roleId: v.property_role_id,
          })),
        }),
      );
    })
    .catch(() => {
      dispatch(actions.setProfile(null));
    });
};
