import type { Action, PayloadAction } from '@reduxjs/toolkit';

export type DiagramState = {
  refresh: Record<string, never>;
  isLoading: boolean;
  isLoading2: boolean;
  isSubmitting: boolean;
  isNavigating: boolean;
  isNavigatingTo: number | null;
  diagram: Diagram | null;
  selectEquipments: Equipment[];
};

export enum DiagramActionTypes {
  INIT = 'diagram/init',
  REFRESH = 'diagram/refresh',
  LOADING = 'diagram/set/isLoading',
  LOADING2 = 'diagram/set/isLoading2',
  SUBMITTING = 'diagram/set/isSubmitting',
  NAVIGATING = 'diagram/set/isNavigating',
  NAVIGATING_TO = 'diagram/set/isNavigatingTo',
  SET_DIAGRAM = 'diagram/set/diagram',
  SET_SELECT_EQUIPMENTS = 'diagram/set/selectEquipments',
}

export type DiagramAction =
  | Action<DiagramActionTypes.INIT>
  | Action<DiagramActionTypes.REFRESH>
  | PayloadAction<boolean, DiagramActionTypes.LOADING>
  | PayloadAction<boolean, DiagramActionTypes.LOADING2>
  | PayloadAction<boolean, DiagramActionTypes.SUBMITTING>
  | PayloadAction<boolean, DiagramActionTypes.NAVIGATING>
  | PayloadAction<number | null, DiagramActionTypes.NAVIGATING_TO>
  | PayloadAction<Diagram | null, DiagramActionTypes.SET_DIAGRAM>
  | PayloadAction<Equipment[], DiagramActionTypes.SET_SELECT_EQUIPMENTS>;

// -- State Types ---------------------------------------------------------- //

export type Diagram = {
  diagramId: number;
  diagramName: string;
  propertyId: number;
  propertyName: string;
  description: string;
  equipmentList: Equipment[];
};

export type Equipment = {
  equipmentId: number;
  equipmentName: string;
  floorId: number;
  floorName: string;
  modelNumber: string;
  diagramSymbol: string;
  equipmentTypeName: string;
  manufacturer: string;
};

export type DiagramSubmitData = {
  name: string;
  description: string;
};

export type DiagramEquipmentSubmitData = {
  equipmentId: number;
  diagramSymbol: string;
};

// -- API Types ------------------------------------------------------------ //

export type APIDiagram = {
  readonly diagram_id: number;
  readonly diagram_name: string;
  readonly property_id: number;
  readonly property_name: string;
  readonly description: string;
  readonly equipment_list: APIEquipment[];
};

export type APIEquipment = {
  readonly equipment_id: number;
  readonly equipment_name: string;
  readonly floor_id: number;
  readonly floor_name: string;
  readonly model_number: string;
  readonly diagram_symbol: string;
  readonly equipment_type_name: string;
  readonly manufacturer: string;
};

export type APIUpdateDiagram = {
  readonly diagram_name: string;
  readonly description: string;
};

export type APIGetDiagramResponse = {
  result: APIDiagram;
};

export type APIUpdateDiagramRequest = APIUpdateDiagram;

export type APIDeleteDiagramsRequest = {
  diagram_list: { diagram_id: number }[];
};

export type APIAddDiagramEquipmentsRequest = {
  equipment_list: {
    equipment_id: number;
    diagram_symbol: string;
  }[];
};

export type APIDeleteDiagramEquipmentsRequest = {
  equipment_list: {
    equipment_id: number;
  }[];
};

export type APIGetSelectEquipmentsResponse = {
  result: APIEquipment[];
};
