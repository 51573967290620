import reducer from './reducers';

import * as inspectionChecklistsSelectors from './selectors';
import * as inspectionChecklistsActions from './actions';
import * as inspectionChecklistsOperations from './operations';
import { InspectionChecklistState } from './types';

export {
  inspectionChecklistsSelectors,
  inspectionChecklistsActions,
  inspectionChecklistsOperations,
};
export type { InspectionChecklistState };

export default reducer;
