import React from 'react';
import ReactLoader from 'react-loader-spinner';
import './style.scss';

export type LoaderProps = {
  height?: number;
  width?: number;
};

const Loader = ({ height = 50, width = 50 }: LoaderProps) => {
  return (
    <div className="loader">
      <ReactLoader type="ThreeDots" height={height} width={width} />
    </div>
  );
};

export default Loader;
