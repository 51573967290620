import type { Action, PayloadAction } from '@reduxjs/toolkit';

/**
 * @summary 月次計画詳細画面で管理されるState
 */
export type PlanMonthlyTableState = {
  refresh: Record<string, never>;
  /** Load中か */
  isLoading: boolean;
  /** 契約リストLoad中か */
  isLoadingContractList: boolean;
  /** 送信処理中か */
  isSubmitting: boolean;
  /** 編集中か */
  isEditing: boolean;
  /** ページ番号 */
  page: number;
  /** ソート番号 */
  sort: number;
  /** 詳細検索条件 */
  searchParameter: SearchParameter;
  /** 月次計画詳細リスト */
  tables: MonthlyTableTable[];
  /** 月次計画サマリ */
  summary: MonthlyTableTableSummary | null;
  /** Pager情報 */
  displayParams: DisplayParams;
  /** 契約リスト */
  contractList: Contract[];
  /** 契約リストPager情報 */
  contractDisplayParams: DisplayParams;
  /** 選択を確定した契約 */
  contract: { id: number; name: string } | null;
};

/**
 * @summary 月次計画詳細画面のAction Type
 */
export enum PlanMonthlyTableActionTypes {
  INIT = 'plan/monthly_table/init',
  REFRESH = 'plan/monthly_table/refresh',
  LOADING = 'plan/monthly_table/set/isLoading',
  LOADING_CONTRACT_LIST = 'plan/monthly_table/set/isLoading_contract_list',
  SUBMITTING = 'plan/monthly_table/set/isSubmitting',
  EDITING = 'plan/monthly_table/set/isEditing',
  SET_PAGE = 'plan/monthly_table/set/page',
  SET_SORT = 'plan/monthly_table/set/sort',
  SET_SEARCH_PARAMETER = 'plan/monthly_table/set/search_parameter',
  SET_TABLES = 'plan/monthly_table/set/tables',
  SET_SUMMARY = 'plan/monthly_table/set/summary',
  SET_SALES_SUMMARY = 'plan/monthly_table/set/sales_summary',
  SET_DISPLAY_PARAMS = 'plan/monthly_table//set/display_params',
  SET_CONTRACT_LIST = 'plan/monthly_table/set/contract_list',
  SET_CONTRACT_DISPLAY_PARAMS = 'plan/monthly_table//set/contract_display_params',
  SET_CONTRACT = 'plan/monthly_table//set/contract',
}

/**
 * @summary 月次計画詳細画面のAction
 */
export type PlanMonthlyTableAction =
  /** 初期化 */
  | Action<PlanMonthlyTableActionTypes.INIT>
  /** リフレッシュ */
  | Action<PlanMonthlyTableActionTypes.REFRESH>
  /** Loading状態を保持 */
  | PayloadAction<boolean, PlanMonthlyTableActionTypes.LOADING>
  /** 契約リストのLoading状態を保持 */
  | PayloadAction<boolean, PlanMonthlyTableActionTypes.LOADING_CONTRACT_LIST>
  /** 送信処理中かを保持 */
  | PayloadAction<boolean, PlanMonthlyTableActionTypes.SUBMITTING>
  /** 編集中かを保持 */
  | PayloadAction<boolean, PlanMonthlyTableActionTypes.EDITING>
  /** ページ番号を保持 */
  | PayloadAction<number, PlanMonthlyTableActionTypes.SET_PAGE>
  /** ソート番号を保持 */
  | PayloadAction<number, PlanMonthlyTableActionTypes.SET_SORT>
  /** 詳細検索条件を保持 */
  | PayloadAction<SearchParameter, PlanMonthlyTableActionTypes.SET_SEARCH_PARAMETER>
  /** 月次計画詳細リストを保持 */
  | PayloadAction<MonthlyTableTable[], PlanMonthlyTableActionTypes.SET_TABLES>
  /** 月次計画サマリを保持 */
  | PayloadAction<MonthlyTableTableSummary | null, PlanMonthlyTableActionTypes.SET_SUMMARY>
  /** Pager情報を保持 */
  | PayloadAction<DisplayParams, PlanMonthlyTableActionTypes.SET_DISPLAY_PARAMS>
  /** 契約リスト */
  | PayloadAction<Contract[], PlanMonthlyTableActionTypes.SET_CONTRACT_LIST>
  /** 契約リストPager情報 */
  | PayloadAction<DisplayParams | null, PlanMonthlyTableActionTypes.SET_CONTRACT_DISPLAY_PARAMS>
  /** 選択を確定した契約 */
  | PayloadAction<{ id: number; name: string } | null, PlanMonthlyTableActionTypes.SET_CONTRACT>;

/**
 * @summary 月次計画詳細の詳細検索パラメータ
 */
export type SearchParameter = {
  /** 計画名 */
  title?: string;
  /** 契約種別 */
  contract_type?: string;
  /** 定期業務種別 */
  inspection_type?: string;
  /** 定期業務区分 */
  inspection_division?: string;
  /** 対応状況 */
  status?: string;
  /** 協力会社名 */
  company?: string;
  /** 開始日From */
  start_since?: string;
  /** 開始日To */
  start_until?: string;
  /** 終了日From */
  end_since?: string;
  /** 終了日To */
  end_until?: string;
  /** 監督者 */
  supervisor?: string;
  /** 作業者 */
  worker?: string;
  /** 予定調整状況 */
  is_adjustment_email_sent?: string | boolean;
  /** 確定連絡状況 */
  is_fixed_email_sent?: string | boolean;
  /** 直前連絡状況 */
  is_remind_email_sent?: string | boolean;
  /** 報告書提出依頼状況 */
  is_report_request_email_sent?: string | boolean;
  /** 請求額登録状況 */
  has_invoice_amount?: string | boolean;
  /** 支払額登録状況 */
  has_payment_amount?: string | boolean;
};

/**
 * @summary APIから返却される販売集計リストのプロパティ
 */
export type MonthlyTableTable = {
  /** 計画名 */
  plan_id: number;
  /** 建物ID */
  property_id: number;
  /** 建物名 */
  property_name: string;
  /** 契約ID */
  contract_id: number;
  /** 計画名 */
  title: string;
  /** 契約種別ID */
  contract_type_id: number;
  /** 契約種別 */
  contract_type_name: string;
  /** 定期業務種別ID */
  inspection_type_id: number;
  /** 定期業務種別 */
  inspection_type_name: string;
  /** 定期業務区分ID */
  inspection_division_id: number;
  /** 定期業務区分 */
  inspection_division_name: string;
  /** 対応状況ID */
  status_id: number;
  /** 対応状況 */
  status_name: string;
  /** 開始日 */
  start_date: string;
  /** 開始時間 */
  start_time: string;
  /** 終了日 */
  end_date: string;
  /** 終了時間 */
  end_time: string;
  /** 監督者 */
  supervisors: {
    /** 企業ID */
    company_id: number | null;
    /** 企業名 */
    company_name: string;
    /** ユーザID */
    user_id: number;
    /** ユーザ名 */
    user_name: string;
  }[];
  /** 作業者 */
  workers: {
    /** 企業ID */
    company_id: number | null;
    /** 企業名 */
    company_name: string;
    /** ユーザID */
    user_id: number;
    /** ユーザ名 */
    user_name: string;
  }[];
  /** 協力会社 */
  companies: {
    /** 企業ID */
    company_id: number | null;
    /** 企業名 */
    company_name: string;
  }[];
  /** 予定調整先 */
  adjustment_targets: {
    /** 企業ID */
    company_id: number | null;
    /** 企業名 */
    company_name: string;
    /** ユーザID */
    user_id: number;
    /** ユーザ名 */
    user_name: string;
  }[];
  /** 連絡事項 */
  message: string;
  /** 自動メール送信状況 */
  send_status: {
    /** 予定調整メール */
    adjustment_email: 1 | 2 | 3;
    /** 予定確定メール */
    fixed_email: 1 | 2 | 3;
    /** 事前通知メール */
    remind_email: 1 | 2 | 3;
    /** 報告書提出依頼メール */
    report_request_email: 1 | 2 | 3;
  };
  /** 予定日次 */
  plan_daily: {
    /** 開始日 */
    start_date: string;
    /** 開始時間 */
    start_time: string;
    /** 終了日 */
    end_date: string;
    /** 終了時間 */
    end_time: string;
  };
  /** 販売結果 */
  sales_result: {
    /** 請求額 */
    invoice_amount: number;
    /** 支払額 */
    payment_amount: number;
    /** 粗利額 */
    gross_profit: number;
    /** 粗利率 */
    gross_profit_rate: number | null;
  };
};

/**
 * @summary APIから返却される月次計画サマリのプロパティ
 */
export type MonthlyTableTableSummary = {
  /** 未確定件数 */
  unfixed_num: number;
  /** 確定件数 */
  fixed_num: number;
  /** 対応中件数 */
  in_progress_num: number;
  /** 査収待ち件数 */
  checking_num: number;
  /** 査収完了件数 */
  completed_num: number;
  /** 保留件数 */
  pending_num: number;
};

/**
 * @summary APIから返却される月次計画詳細のresponse.data.result
 */
export type APIMonthlyTable = {
  /** 年 */
  readonly year: number;
  /** 月 */
  readonly month: number;
  /** サマリ */
  readonly summary: MonthlyTableTableSummary;
  /** 月次計画 */
  readonly items: MonthlyTableTable[];
  /** Pager情報 */
  readonly display_params: DisplayParams;
};

/**
 * @summary 合計件数等Pagerの表示で使用する情報
 */
export type DisplayParams = {
  /** 連絡先数 */
  total: number;
  /** 全体ページ数 */
  total_page: number;
  /** 現在のページ */
  current_page: number;
  /** ソート番号 */
  sort: number;
} | null;

/**
 * @summary 契約情報
 */
export type Contract = {
  /** 契約ID */
  contract_id: number;
  /** 計画名 */
  title: string;
  /** 契約種別 */
  contract_type_name: string;
  /** 定期業務種別 */
  inspection_type_name: string;
  /** 定期業務区分 */
  inspection_division_name: string;
  /** 周期 */
  repeat_type_name: string;
  /** 契約状況 */
  status_name: string;
};

/**
 * @summary 月次計画の取得APIで指定できるリクエストパラメータ
 */
export type APIGetMonthlyTablesRequestParams = {
  /** 契約ID */
  id?: string;
  /** 建物ID */
  property_id?: number;
  /** 年 */
  year?: number;
  /** 月 */
  month?: number;
  /** ページ番号 */
  page?: number;
  /** ソート番号 */
  sort?: number;
  /** キーワード */
  keyword?: string;
  /** タグID */
  tag?: string;
  /** タグID */
  is_all?: boolean;
  /** 取得件数 */
  size_per_page?: number;
} & SearchParameter;

/**
 * @summary APIから返却される月次計画のresponse.data.result
 */
export type APIGetMonthlyTablesResponse = {
  result: APIMonthlyTable;
};

/**
 * @summary 月次計画の追加で使用するフォームデータ
 */
export type AddMonthlyTableFormData = {
  /** 契約ID */
  contract_id: number | null;
  /** 対応状況ID */
  status_id: number | null;
  /** 開始日 */
  start_date: string;
  /** 終了日 */
  end_date: string;
  /** 開始時間 */
  start_time?: string;
  /** 終了時間 */
  end_time?: string;
  /** 監督者 */
  supervisors?: {
    /** ユーザID */
    user_id: number;
  }[];
  /** 作業者 */
  workers?: {
    /** ユーザID */
    user_id: number;
  }[];
  /** 協力会社 */
  companies?: {
    /** 企業ID */
    company_id: number;
  }[];
  /** 予定調整先 */
  adjustment_targets?: {
    /** ユーザID */
    user_id: number;
  }[];
  /** 連絡事項 */
  message?: string;
  /** 販売結果 */
  sales_result?: {
    /** 請求額 */
    invoice_amount?: number | null;
    /** 支払額 */
    payment_amount?: number | null;
  };
};

/**
 * @summary 月次計画の編集で使用するフォームデータ
 */
export type EditMonthlyTableFormData = {
  /** 計画ID */
  plan_id: number | null;
  /** 対応状況ID */
  status_id: number | null;
  /** 開始日 */
  start_date: string;
  /** 終了日 */
  end_date: string;
  /** 開始時間 */
  start_time?: string | null;
  /** 終了時間 */
  end_time?: string | null;
  /** 監督者 */
  supervisors?: {
    /** ユーザID */
    user_id: number;
    /** ユーザ名 */
    user_name?: string;
  }[];
  /** 作業者 */
  workers?: {
    /** ユーザID */
    user_id: number;
    /** ユーザ名 */
    user_name?: string;
  }[];
  /** 協力会社 */
  companies?: {
    /** 企業ID */
    company_id: number;
    /** 企業名 */
    company_name?: string;
  }[];
  /** 予定調整先 */
  adjustment_targets?: {
    /** ユーザID */
    user_id: number;
    /** ユーザ名 */
    user_name?: string;
  }[];
  /** 連絡事項 */
  message: string;
  /** 販売結果 */
  sales_result: {
    /** 請求額 */
    invoice_amount: number;
    /** 支払額 */
    payment_amount: number;
  };
};

/**
 * @summary 販売結果登録のAPIリクエストパラメータ
 */
export type APIPostMonthlyTablePriceRequestParams = {
  /** 計画IDリスト */
  plan_list: {
    /** 計画ID */
    plan_id: number;
  }[];
  /** 請求額 */
  invoice_amount?: number;
  /** 支払額 */
  payment_amount?: number;
};

export type APIGetContractListResponse = {
  result: {
    contract_list: Contract[];
    display_params: DisplayParams;
  };
};
