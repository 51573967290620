import {
  PlanMonthlyMailState,
  PlanMonthlyMailActionTypes as ActionTypes,
  PlanMonthlyMailAction,
} from './types';

/**
 * @summary 月次計画詳細画面で管理されるStateの初期値
 */
const initialPlanMonthlyMailState: PlanMonthlyMailState = {
  refresh: {},
  /** 伝達事項変更時のrefresh */
  messageRefresh: {},
  /** Load中か */
  isLoading: false,
  /** 契約リストLoad中か */
  isLoadingContractList: false,
  /** 送信処理中か */
  isSubmitting: false,
  /** 月次計画-メール送信先一覧取得Data */
  monthlyMailUsers: [],
  /** 月次計画-予定調整本文 */
  monthlyMailAdjustment: '',
  /** 月次計画-予定調整プレビュー取得 */
  monthlyMailAdjustmentSend: [],
  /** 月次計画-予定確定本文取得 */
  monthlyMailFixed: '',
  /** 月次計画-予定確定プレビュー取得 */
  monthlyMailFixedSend: [],
  /** 月次計画-予定確定プレビュー(計画)取得 */
  monthlyMailFixedSendPlanList: [],
  /** 月次計画-直前通知メール設定取得 */
  monthlyMailRemind: [],
  /** 月次計画-直前通知プレビュー取得 */
  monthlyMailRemindSend: [],
  /** 月次計画-直前通知プレビュー取得(計画リスト) */
  monthlyMailRemindSendPlanList: [],
  /** 月次計画-報告書提出依頼メール設定 */
  monthlyMailReportRequest: [],
  /** 月次計画-報告書提出依頼プレビュー取得 */
  monthlyMailReportRequestSend: [],
  /** 月次計画-報告書提出依頼プレビュー取得(計画リスト) */
  monthlyMailReportRequestSendPlanList: [],
};

const planMonthlyMailReducer = (
  state: PlanMonthlyMailState = initialPlanMonthlyMailState,
  action: PlanMonthlyMailAction,
): PlanMonthlyMailState => {
  switch (action.type) {
    case ActionTypes.INIT:
      return initialPlanMonthlyMailState;

    case ActionTypes.REFRESH:
      return { ...state, refresh: {} };

    case ActionTypes.MESSAGE_REFRESH:
      return { ...state, messageRefresh: {} };

    case ActionTypes.LOADING:
      return { ...state, isLoading: action.payload };

    case ActionTypes.SUBMITTING:
      return { ...state, isSubmitting: action.payload };

    case ActionTypes.SET_MONTHLY_MAIL_USERS:
      return { ...state, monthlyMailUsers: action.payload };

    case ActionTypes.SET_MONTHLY_MAIL_ADJUSTMENT:
      return { ...state, monthlyMailAdjustment: action.payload };

    case ActionTypes.SET_MONTHLY_MAIL_ADJUSTMENT_SEND:
      return { ...state, monthlyMailAdjustmentSend: action.payload };

    case ActionTypes.SET_MONTHLY_MAIL_FIXED:
      return { ...state, monthlyMailFixed: action.payload };

    case ActionTypes.SET_MONTHLY_MAIL_FIXED_SEND:
      return { ...state, monthlyMailFixedSend: action.payload };

    case ActionTypes.SET_MONTHLY_MAIL_FIXED_SEND_PLAN_LIST:
      return { ...state, monthlyMailFixedSendPlanList: action.payload };

    case ActionTypes.SET_MONTHLY_MAIL_REMIND:
      return { ...state, monthlyMailRemind: action.payload };

    case ActionTypes.SET_MONTHLY_MAIL_REMIND_SEND:
      return { ...state, monthlyMailRemindSend: action.payload };

    case ActionTypes.SET_MONTHLY_MAIL_REMIND_SEND_PLAN_LIST:
      return { ...state, monthlyMailRemindSendPlanList: action.payload };

    case ActionTypes.SET_MONTHLY_MAIL_REPORT_REQUEST:
      return { ...state, monthlyMailReportRequest: action.payload };

    case ActionTypes.SET_MONTHLY_MAIL_REPORT_REQUEST_SEND:
      return { ...state, monthlyMailReportRequestSend: action.payload };

    case ActionTypes.SET_MONTHLY_MAIL_REPORT_REQUEST_SEND_PLAN_LIST:
      return { ...state, monthlyMailReportRequestSendPlanList: action.payload };

    default:
      return state;
  }
};

export default planMonthlyMailReducer;
